import { LeftOutlined } from "@ant-design/icons";
import { IconDotsVertical } from "@tabler/icons-react";
import { Dropdown } from "antd";
import { MenuItemType } from "antd/es/menu/hooks/useItems";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { mapQueue } from "../../../utils/map/mapQueue";
import styles from "./NavigationBanner.module.css";
export interface BannerOption extends MenuItemType {
  onClick?: () => void;
}
type Props = {
  name: string;
  index?: boolean;
  prevRoute?: string;
  options?: BannerOption[];
};

export default function NavigationBanner(props: Props) {
  const nav = useNavigate();
  return (
    <div className={styles.container}>
      {props.index ? (
        <div />
      ) : (
        <LeftOutlined
          className={`${styles.navigationButton} ${styles.growOnHover}`}
          onClick={() => {
            mapQueue.add((canvas) => {
              canvas.discardActiveObject();
            });
            if (props.prevRoute) {
              nav(props.prevRoute);
            } else {
              nav(-1);
            }
          }}
        />
      )}
      <p className={styles.title}>{props.name}</p>
      {props.options && props.options.length > 0 && (
        <SettingsDropDown options={props.options} />
      )}
    </div>
  );
}

const SettingsDropDown = ({ options }: { options?: BannerOption[] }) => {
  return (
    <Dropdown
      menu={{
        items: _.sortBy(options,['a.key'])
      }}
    >
      <IconDotsVertical className={`${styles.options} ${styles.growOnHover}`} />
    </Dropdown>
  );
};
