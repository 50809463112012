import { Button, Flex } from "antd";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import LoadingBoundry from "../../../../components/common/LoadingBoundry/LoadingBoundry";
import { CreateTeamRequest } from "../../../../repo";
import { useTeamCreate } from "../../../../repo/teams";
import CenteredFloatingContainer from "../../../common/CenteredFloatingContainer/CenteredFloatingContainer";
import { TeamForm, TeamFormBody } from "../TeamEditView/TeamEditView";
import styles from "./TeamCreateView.module.css";
type Props = {};

export default function TeamCreateView({}: Props) {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<CreateTeamRequest>();
  const nav = useNavigate();
  const {
    mutate: createTeam,
    isPending: isCreating,
    isError,
    reset,
  } = useTeamCreate((data) => {
    nav(`/teams/${data.id}`, { replace: true });
  });
  const onSubmit = (data: CreateTeamRequest) => {
    createTeam(data);
  };
  return (
    <LoadingBoundry
      loading={isCreating}
      error={isError}
      description="Failed to create team"
      onAction={reset}
    >
      <CenteredFloatingContainer header={"Create team"} backRoute={"/teams"}>
        <>
          <TeamForm onSubmit={handleSubmit(onSubmit)}>
            <TeamFormBody control={control} />
            <Flex id={styles.buttonContainer} justify="space-between">
              <Button size="middle" danger onClick={() => nav(-1)}>
                Cancel
              </Button>
              <Button size="middle" htmlType="submit" type="primary">
                Save
              </Button>
            </Flex>
          </TeamForm>
        </>
      </CenteredFloatingContainer>
    </LoadingBoundry>
  );
}
