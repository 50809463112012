import { Button, InputNumber } from "antd";
import { useEffect } from "react";
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
} from "react-hook-form";
import { useParams } from "react-router-dom";
import {
  ComponentWithError,
  ComponentWithLabel,
  CustomInputText,
} from "../../../../../components/common/CustomInputText/CustomInputText";
import DisableByRole from "../../../../../components/common/DisableByRole";
import Explanation from "../../../../../components/common/Explanation/Explanation";
import NavigationBanner from "../../../../../components/common/NavigationBanner/NavigationBanner";
import { CreateRoomRequest } from "../../../../../repo";
import { useCreateRoom } from "../../../../../repo/rooms";
import { useNav } from "../../../../../utils/reactUtils";
import { useMapEntityContext } from "../../context/mapEntityContext";
import styles from "./CreateMapRoom.module.css";
type Props = {};

export default function CreateMapRoom({}: Props) {
  const { mapId } = useParams();
  const formMethods = useForm<CreateRoomRequest>();
  const nav = useNav();
  const selectedEntity = useMapEntityContext.use.selectedEntity?.();
  const { mutate: createRoom } = useCreateRoom();
  const onSubmit = (data: CreateRoomRequest) => {
    if (
      selectedEntity?.type !== "meetingRoom" ||
      !mapId ||
      !selectedEntity.id
    ) {
      return;
    }
    createRoom(
      { mapId: mapId, room: { ...data, mapRoomId: selectedEntity.id } },
      {
        onSuccess(data, variables, context) {
          nav(`/maps/${mapId}/rooms/${data.id}`, { replace: true });
        },
      }
    );
  };
  useEffect(() => {
    if (!selectedEntity?.id) {
      nav(`/maps/${mapId}`);
      return;
    }
  }, [selectedEntity, mapId, nav]);
  const name = formMethods.watch("name");
  return (
    <FormProvider {...formMethods}>
      <NavigationBanner
        name={name || "New Room"}
        prevRoute={`/maps/${mapId}`}
      />
      <MapRoomForm onSubmit={formMethods.handleSubmit(onSubmit)}>
        <MapRoomButtons>
          <Button type="primary" htmlType="submit">
            Create
          </Button>
        </MapRoomButtons>
      </MapRoomForm>
    </FormProvider>
  );
}

export const MapRoomButtons = ({ children }: { children: React.ReactNode }) => {
  return <div className={styles.buttonContainer}>{children}</div>;
};

export const MapRoomForm = ({
  children,
  ...props
}: React.FormHTMLAttributes<HTMLFormElement>) => {
  const { control } = useFormContext();
  return (
    <form className={styles.form} {...props}>
      <div className={styles.inputs}>
        <DisableByRole disabledRole="USER">
          <Controller
            control={control}
            name="name"
            render={({ field, fieldState }) => (
              <CustomInputText
                label="Room name"
                required
                disabledForRole="USER"
                value={field.value}
                onChange={(e) => field.onChange(e.target.value)}
                placeholder="Room 1"
                error={fieldState.error?.message}
              />
            )}
            rules={{
              required: "This value is required",
              maxLength: { value: 250, message: "Value too long" },
            }}
          />
        </DisableByRole>
        <DisableByRole disabledRole="USER">
          <Controller
            control={control}
            name="capacity"
            render={({ field, fieldState }) => (
              <ComponentWithError error={fieldState.error?.message}>
                <ComponentWithLabel label="Capacity" required>
                  <InputNumber
                    value={field.value}
                    onChange={(e) => field.onChange(e)}
                    placeholder="10"
                    min={1}
                    status={fieldState.error?.message && "error"}
                  />
                </ComponentWithLabel>
              </ComponentWithError>
            )}
            rules={{
              required: "This value is required",
            }}
          />
        </DisableByRole>
        <DisableByRole disabledRole="USER">
          <Controller
            control={control}
            name="integrationId"
            render={({ field, fieldState }) => (
              <CustomInputText
                label={
                  <>
                    Room integration
                    <Explanation description="integration id of your provider" />
                  </>
                }
                value={field.value}
                onChange={(e) => field.onChange(e.target.value)}
                placeholder="room@integration.com"
                error={fieldState.error?.message}
              />
            )}
            rules={{
              maxLength: { value: 250, message: "Value too long" },
            }}
          />
        </DisableByRole>
      </div>
      {children}
    </form>
  );
};
