import { useSearchParams } from "react-router-dom";
import GraphControlContainer from "../components/GraphControlContainer/GraphControlContainer";
import { default as LabelPicker } from "../components/LabelPicker";
import { ExpirationReport } from "./ReportTypes/ExpirationReport";
import { InventoryReport } from "./ReportTypes/InventoryReport";

type Props = {};

export default function EquipmentReport({}: Props) {
  const [searchParams, setSearchParams] = useSearchParams({
    reportType: "INVENTORY",
  });
  const reportType = searchParams.get("reportType");
  return (
    <>
      <GraphControlContainer>
        <div style={{ paddingInline: ".5rem" }}>
          <LabelPicker
            searchParam="reportType"
            label="Report type"
            values={["INVENTORY", "EXPIRATION"]}
          />
        </div>
      </GraphControlContainer>
      {reportType === "INVENTORY" && <InventoryReport />}
      {reportType === "EXPIRATION" && <ExpirationReport />}
    </>
  );
}
