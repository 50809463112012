import { useEffect } from "react";
import { mapService } from "../../../../../../services/map/MapService";
import { RoomBookingNavigator } from "../../../../../../services/map/navigator/RoomBookingNavigator";
import { useRoomBookingStore } from "../context/useRoomBookingStore";
import RoomBookingColorer from "./RoomBookingColorer";

type Props = {};

export default function RoomBookingObserver({}: Props) {
  const { availableRooms } = useRoomBookingStore();
  useEffect(() => {
    const oldNav = mapService.navigator;
    mapService.navigator = new RoomBookingNavigator(availableRooms);
    return () => {
      mapService.navigator = oldNav;
    };
  }, [availableRooms]);
  return <RoomBookingColorer />;
}
