type Props = {};

export default function BorderDivider({
  horizontal,
  ...props
}: React.HTMLAttributes<HTMLDivElement> & { horizontal?: boolean }) {
  const border = "1px solid var(--border-color)";
  return (
    <div
      {...props}
      style={{
        borderBottom: horizontal ? "" : border,
        borderLeft: horizontal ? border : "",
        ...props.style,
      }}
    />
  );
}
