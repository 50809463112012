import { IconLine, IconRectangle } from "@tabler/icons-react";
import { ColorPicker, ColorPickerProps, Flex, Select, SelectProps } from "antd";
import {
  Chart,
  ChartDataset,
  LegendElement,
  LegendItem,
  LegendOptions,
} from "chart.js";
import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import CustomCheckbox from "../../../../../components/common/CustomCheckbox/CustomCheckbox";
import { useChartContext } from "../../context/chartContext";
import styles from "./ConfigPopup.module.css";
export interface ChartPopupActions {
  showPopup: (
    x: number,
    y: number,
    legend: LegendElement<any>,
    legendItem: LegendItem
  ) => void;
  hidePopup: () => void;
}
export const ChartPopup = React.forwardRef<
  ChartPopupActions,
  { noType?: boolean }
>((props, ref) => {
  const [coords, setCoords] = useState<{ x: number; y: number }>();

  const { updateSetting } = useChartContext();
  const [chartOpts, setChartOpts] = useState<{
    legend: LegendElement<any>;
    legendItem: LegendItem;
    dataset: ChartDataset;
  }>();
  const [divRef, setDivRef] = useState<HTMLDivElement>();
  useEffect(() => {
    if (!divRef) {
      return;
    }
    const clickHandler = (e: MouseEvent) => {
      if (
        !isEventInElement(e, divRef) &&
        !isEventInElement(
          e,
          document.getElementsByClassName("ant-color-picker")[0] as any
        )
      ) {
        setCoords(undefined);
      }
    };
    document.addEventListener("click", clickHandler);
    return () => {
      document.removeEventListener("click", clickHandler);
    };
  }, [divRef]);
  const onRefChange = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      setDivRef(node);
    }
  }, []);
  function isEventInElement(event: MouseEvent, element?: HTMLDivElement) {
    if (!element) {
      return false;
    }
    var rect = element.getBoundingClientRect();
    var x = event.clientX;
    if (x < rect.left || x >= rect.right) return false;
    var y = event.clientY;
    if (y < rect.top || y >= rect.bottom) return false;
    return true;
  }
  useImperativeHandle(ref, () => ({
    showPopup(
      x: number,
      y: number,
      legend: LegendElement<any>,
      legendItem: LegendItem
    ) {
      setCoords({ x, y });
      setChartOpts({
        legend,
        legendItem,
        dataset: legend.chart.data.datasets[legendItem.datasetIndex!],
      });
    },
    hidePopup() {
      setCoords(undefined);
    },
  }));
  const onColorChange: ColorPickerProps["onChange"] = (_, hex) => {
    if (chartOpts) {
      chartOpts.dataset.borderColor = hex;
      chartOpts.dataset.backgroundColor = hex;
      if ((chartOpts.dataset as any).pointBackgroundColor) {
        (chartOpts.dataset as any).pointBackgroundColor = hex;
      }
      chartOpts.dataset.label &&
        updateSetting(chartOpts.dataset.label, { color: hex });
      chartOpts.legend.chart.update();
    }
  };
  const onCheckboxChange = (val: boolean) => {
    (Chart.defaults.plugins.legend as any).onClick(
      {} as any,
      chartOpts?.legendItem,
      chartOpts?.legend
    );
    chartOpts?.dataset.label &&
      updateSetting(chartOpts.dataset.label, {
        hidden: val,
      });
  };
  const onTypeChange: SelectProps["onChange"] = (val) => {
    if (chartOpts && chartOpts.dataset.label) {
      chartOpts.dataset.type = val;
      updateSetting(chartOpts.dataset.label, { type: val });
      chartOpts.legend.chart.update();
    }
  };
  if (coords?.x && coords.y) {
    return (
      <div
        ref={onRefChange}
        className={styles.legendPopup}
        style={{
          top: coords?.y,
          left: coords?.x,
        }}
      >
        <>
          {"Hidden  "}
          <CustomCheckbox
            checked={chartOpts?.legendItem.hidden}
            onChange={onCheckboxChange}
          />
        </>
        {!props.noType && (
          <Select
            placeholder="Type"
            value={chartOpts?.dataset.type}
            onChange={onTypeChange}
          >
            <Select.Option key={"bar"}>
              <Flex align="center">
                <IconRectangle
                  color={chartOpts?.dataset.backgroundColor as any}
                />
              </Flex>
            </Select.Option>
            <Select.Option key={"line"}>
              <Flex align="center">
                <IconLine color={chartOpts?.dataset.borderColor as any} />
              </Flex>
            </Select.Option>
          </Select>
        )}
        <div>
          <ColorPicker
            defaultValue={chartOpts?.dataset.borderColor as any}
            onChange={onColorChange}
          />
        </div>
      </div>
    );
  }
  return <></>;
});

export const chartLegend: (
  ref: React.RefObject<ChartPopupActions>
) => Partial<LegendOptions<any>> = (ref) => ({
  position: "top" as const,
  onClick(event, legendItem, legend) {
    if (legendItem.datasetIndex !== undefined) {
      ref.current?.showPopup(event.x!, event.y!, legend, legendItem);
    }
  },
  onHover: function (event, legendItem, legend) {
    var index = legendItem.datasetIndex;
    if (index === undefined) {
      return;
    }
    legend.chart.data.datasets.forEach((dataSet, dsIndex) => {
      const color =
        dsIndex === index
          ? dataSet.backgroundColor
          : dimColor(dataSet.backgroundColor, 0.5);
      dataSet.borderColor = color;
      dataSet.backgroundColor = color;
    });
    legend.chart.update();
  },
  onLeave: function (event, legendItem, legend) {
    var index = legendItem.datasetIndex;
    if (index === undefined) {
      return;
    }
    legend.chart.data.datasets.forEach((dataSet, dsIndex) => {
      const color =
        dsIndex === index
          ? dataSet.backgroundColor
          : unDimColor(dataSet.backgroundColor, 0.5);
      dataSet.borderColor = color;
      dataSet.backgroundColor = color;
    });
    legend.chart.update();
  },
});
const dimColor = (color: string, level: number) => {
  const isArgb = color.length === 5 || color.length === 9;
  if (isArgb) {
    const modifiedColor =
      color.substring(0, 7) +
      Math.min(
        255,
        Math.round(parseInt(color.substring(7), 16) * level)
      ).toString(16);
    return modifiedColor;
  } else {
    return (
      color + Math.min(255, Math.round(parseInt("ff", 16) * level)).toString(16)
    );
  }
};
const unDimColor = (color: string, level: number) => {
  const isArgb = color.length === 5 || color.length === 9;
  if (isArgb) {
    const modifiedColor =
      color.substring(0, 7) +
      Math.min(
        255,
        Math.round(parseInt(color.substring(7), 16) / level)
      ).toString(16);
    return modifiedColor;
  } else {
    return (
      color + Math.min(255, Math.round(parseInt("ff", 16) / level)).toString(16)
    );
  }
};
