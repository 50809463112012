import { fabric } from "fabric";
import { IPathOptions } from "fabric/fabric-impl";

fabric.Door = fabric.util.createClass(fabric.Group, {
  type: "door",
  initialize: function (options: any) {
    const defaults: IPathOptions = {
      fill: "white",
      stroke: "black",
      strokeWidth: 3,
      originX: "center",
      originY: "center",
      height: 100,
      width: 100
    };
    const door = new fabric.Path("M200 180 V0",{...defaults, strokeWidth: 10,left: 197})
    const opening = new fabric.Path("M193 1 Q0 0 0 180 H193 Z",defaults)
    const hinge = new fabric.Path("M0 180 H200 V210 H0 Z",{...defaults, stroke: 'white'})
    this.callSuper("initialize", [door, opening, hinge], {
      snapAngle: 5,
      ...options,
    });
  },

  toObject: function () {
    const obj = fabric.util.object.extend(this.callSuper("toObject"),{});
    const {objects,...rest} = obj;
    return rest;
  },
});
fabric.Door.fromObject = function (object, callback) {
  return fabric.Object._fromObject("Door", object, callback) as any;
};
