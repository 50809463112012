import {
  useAuthenticationStore,
  usePermissionsStore,
  useProviderAuthenticationStore,
} from "../context/authContext";

export const getToken = () => {
  let token = useAuthenticationStore.getState().auth?.token;
  if (!token) {
    console.error("Token not found");
    throw Error();
  }
  return token;
};

export const getOverrides = () => {
  // const { overrides } = usePermissionsStore.getState();
  // if (overrides) {
  //   return {
  //     "X-Whd-Role-Override": overrides.role,
  //     "X-Whd-Email-Override": overrides.userEmail,
  //   };
  // }
  return {};
};

export const getHeaders = () => {
  const token = getToken();
  return { Authorization: "Bearer " + token, ...getOverrides() };
};
export const providerAuthHeader = "X-Whd-provider-auth";
export const getProviderHeaders = () => {
  const token = useProviderAuthenticationStore.getState().providerAuth?.token;
  const headers:any = {};
  headers[providerAuthHeader] = token;
  return headers;
};
export const getCompanyId = () => {
  const { companyId } = usePermissionsStore.getState();
  if (companyId) {
    return companyId;
  } else {
    console.error("CompanyId not found");
    throw Error("Company id is null");
  }
};
