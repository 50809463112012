import {
  IconBookOff,
  IconExclamationMark,
  IconHourglassLow,
} from "@tabler/icons-react";
import dayjs from "dayjs";
import { useCallback, useEffect, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { IconWithBackground } from "../../../../components/IconWithBackground/IconWithBackground";
import CenteredEmptyPlaceholder from "../../../../components/common/CenteredEmptyPlaceholder";
import LoadingBoundry from "../../../../components/common/LoadingBoundry/LoadingBoundry";
import {
  useAuthenticationStore,
  usePermissionsStore,
} from "../../../../context/authContext";
import {
  NotificationResponse,
  NotificationType,
} from "../../../../repo";
import {
  setNotificationAsViewed,
  useNotifications,
} from "../../../../repo/notifications";
import { isEventInElement } from "../../../../utils/reactUtils";
import BorderDivider from "../../BorderDivider/ListDivider";
import styles from "./NotificationsPopup.module.css";
type Props = {
  onClose?: () => void;
};
export default function NotificationsPopup({ onClose }: Props) {
  const { auth } = useAuthenticationStore();
  const { isUser } = usePermissionsStore();
  const containerRef = useRef<HTMLDivElement>(null);
  const {
    data: notifications,
    isLoading,
    isError,
  } = useNotifications({
    userEmail: isUser() ? auth?.userEmail : undefined,
  });
  const onClickOutside = useCallback(
    (e: MouseEvent) => {
      if (containerRef.current && !isEventInElement(e, containerRef.current)) {
        onClose?.();
      }
    },
    [onClose]
  );
  useEffect(() => {
    window.addEventListener("mouseup", onClickOutside);
    return () => {
      window.removeEventListener("mouseup", onClickOutside);
    };
  }, [onClickOutside]);
  const newNotifications = notifications?.filter((n) => !n.viewedAt) || [];
  const oldNotification = notifications?.filter((n) => n.viewedAt) || [];
  return (
    <div className={`${styles.container}`} ref={containerRef}>
      <div className={styles.title}>Notifications</div>
      <BorderDivider />
      <LoadingBoundry loading={isLoading} error={isError}>
        <div className={styles.notificationListContainer} onClick={onClose}>
          {newNotifications.length > 0 && (
            <>
              <div className={styles.notificationAge}>New: </div>
              {newNotifications?.map((notification) => {
                return (
                  <NotificationListItem
                    key={notification.id}
                    notification={notification}
                  />
                );
              })}
              <BorderDivider />
            </>
          )}
          {oldNotification.length > 0 && (
            <>
              <div className={styles.notificationAge}>Earlier: </div>
              {oldNotification?.map((notification) => {
                return (
                  <NotificationListItem
                    key={notification.id}
                    notification={notification}
                  />
                );
              })}
            </>
          )}
          {(notifications?.length || 0) === 0 && (
            <CenteredEmptyPlaceholder description="There are no notifications" />
          )}
        </div>
      </LoadingBoundry>
    </div>
  );
}

const NotificationListItem = ({
  notification,
}: {
  notification: NotificationResponse;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const nav = useNavigate();
  const goToAction = () => {
    if (!notification.viewedAt) {
      setNotificationAsViewed(notification.id);
    }
    if (notification.type === "ISSUE") {
      searchParams.set("openIssueId", notification.metadata.issueId);
      setSearchParams(searchParams);
    }
    if (notification.type === "EXPIRATION") {
      nav(`/equipment/${notification.metadata.equipmentId}`);
    }
  };
  const title = (() => {
    if (notification.type === "BOOKING_CANCELED") {
      return "Booking for {dateFrom} - {dateTo} has been canceled".format(
        notification.metadata
      );
    }
    return notification.title;
  })();
  return (
    <div className={styles.listItemContainer} onClick={goToAction}>
      <IconWithBackground
        icon={<NotificationIcon type={notification.type} />}
      />
      <div className={styles.listItemContent}>
        <div className={styles.listItemTitle} title={title}>
          {title}
        </div>
        <span className="listItemMetadata">
          Created: {dayjs(notification.createdAt).from(dayjs.utc())}
        </span>
      </div>
    </div>
  );
};

const NotificationIcon = ({ type }: { type: NotificationType }) => {
  if (type === "EXPIRATION") {
    return <IconHourglassLow />;
  }
  if (type === "BOOKING_CANCELED") {
    return <IconBookOff />;
  }
  return <IconExclamationMark />;
};
