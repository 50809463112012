import { IconX } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import styles from "./CenteredFloatingContainer.module.css";
type Props = {
  children: React.ReactNode;
  header?: React.ReactNode;
  withoutClose?: boolean;
  backRoute?: string;
};

export default function CenteredFloatingContainer({
  children,
  header,
  withoutClose,
  backRoute,
}: Props) {
  const nav = useNavigate();
  const goBack = () => {
    backRoute ? nav(backRoute + window.location.search) : nav(-1);
  };
  return (
    <div className={styles.outerContainer}>
      <div className={`${styles.container} borderBox`}>
        {header && (
          <div className={styles.header}>
            {!withoutClose && (
              <div className={styles.goBack} onClick={goBack}>
                <IconX size={35} />
              </div>
            )}
            {header}
          </div>
        )}
        <div className={styles.body}>{children}</div>
      </div>
    </div>
  );
}
