import {
  IconArrowRight,
  IconChevronDown,
  IconTrash,
} from "@tabler/icons-react";
import { Button } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { IconWithBackground } from "../../../../../components/IconWithBackground/IconWithBackground";
import { ConfirmationDialog } from "../../../../../components/common/ConfirmationDialog/ConfirmationDialog";
import { DeskBookingResponse } from "../../../../../repo";
import { UserAvatar } from "../../../../hr/users/UsersViewPage/UsersViewPage";
import styles from "./BookingListItem.module.css";

export const HrBookingListItem = ({
  booking,
  onDelete,
}: {
  booking: DeskBookingResponse;
  onDelete: (booking: DeskBookingResponse) => void;
}) => {
  const nav = useNavigate();
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    const selectedBookingId = searchParams.get("selectedBooking");
    if (selectedBookingId) {
      if (selectedBookingId === booking.id) {
        setIsCollapsed(false);
      } else {
        setIsCollapsed(true);
      }
    }
  }, [searchParams, booking.id]);
  const collapse = () => {
    if (isCollapsed) {
      searchParams.set("selectedBooking", booking.id);
    } else {
      searchParams.delete("selectedBooking");
    }
    setSearchParams(searchParams, { replace: true });
    setIsCollapsed(!isCollapsed);
  };
  const deleteBooking = () => {
    onDelete(booking);
  };
  const isInThePast = isDateInThePast(booking.dateTo);
  return (
    <>
      <div className={styles.bookingItem} onClick={collapse}>
        <IconWithBackground
          icon={
            <UserAvatar identifier={booking.userEmail} src={booking.photoUrl} />
          }
        />
        <div className={styles.bookingItemContent}>
          <span className={styles.bookingItemName}>{booking.mapName}</span>
          <div className={styles.metadata}>
            <span className={styles.userName}>
              <strong>User: </strong>
              {booking.userName}
            </span>
            <div className={styles.bookingItemDateContainer}>
              {booking.dateFrom}
              <IconArrowRight size={18} />
              {booking.dateTo}
            </div>
          </div>
        </div>

        <IconChevronDown
          className={`${styles.collapseIndicator} ${
            isCollapsed ? styles.collapsed : styles.notCollapsed
          }`}
        />
      </div>
      <div
        className={`${styles.collapsedView} ${
          isCollapsed ? styles.collapsed : styles.notCollapsed
        }`}
      >
        <span className={styles.metadata}>
          <strong>Created at: </strong>
          {dayjs(booking.createdAt).format("YYYY-MM-DD HH:mm:ss")}{" "}
        </span>
        <div className={styles.collapsedActionContainer}>
          {!isInThePast && <BookingDeleteIcon onDelete={deleteBooking} />}
          {booking.userEmail && (
            <Button
              onClick={() => {
                nav(`/people/${booking.userEmail}`);
              }}
            >
              View user
            </Button>
          )}
          {booking.deskId && (
            <Button
              onClick={() => {
                nav(
                  `/maps/${booking.mapId}/desks/${booking.deskId}?focus=true`
                );
              }}
            >
              View desk
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

export const UserBookingListItem = ({
  booking,
  onDelete,
}: {
  booking: DeskBookingResponse;
  onDelete: (booking: DeskBookingResponse) => void;
}) => {
  const nav = useNavigate();
  const deleteBooking = () => {
    onDelete(booking);
  };

  const isInThePast = isDateInThePast(booking.dateTo);
  return (
    <div
      className={styles.bookingItem}
      onClick={() => {
        nav(`/maps/${booking.mapId}/desks/${booking.deskId}?focus=true`);
      }}
    >
      <IconWithBackground
        icon={
          <UserAvatar identifier={booking.userEmail} src={booking.photoUrl} />
        }
      />
      <div className={styles.bookingItemContent}>
        <span className={styles.bookingItemName}>{booking.mapName}</span>
        <div className={styles.metadata}>
          <div className={styles.bookingItemDateContainer}>
            {booking.dateFrom}
            <IconArrowRight size={18} />
            {booking.dateTo}
          </div>
        </div>
      </div>
      {!isInThePast && <BookingDeleteIcon onDelete={deleteBooking} />}
    </div>
  );
};
const BookingDeleteIcon = ({ onDelete }: { onDelete: () => void }) => {
  return (
    <ConfirmationDialog
      primaryButtonProps={{ danger: true, type: "primary", icon: <IconTrash/> }}
      confirmText="Delete"
      heading={
        "Are you sure you want to delete this booking?"
      }
      type="danger"
      subHeading="This cannot be undone"
      onConfirm={onDelete}
    >
      <Button title="Delete booking" className={styles.deleteButton} icon={<IconTrash size={20} />} danger />
    </ConfirmationDialog>
  );
};

function isDateInThePast(dateToString: string) {
  const dateTo = dayjs(dateToString);
  if (!dateTo.isValid()) {
    return true;
  }
  const today = dayjs();
  return dateTo.isBefore(today, "d");
}
