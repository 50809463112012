import { DatePicker } from "antd";
import { RangePickerProps } from "antd/es/date-picker";
import dayjs, { Dayjs } from "dayjs";
import { useSearchParams } from "react-router-dom";
import { useMapSettings } from "../../routes/common/hooks";
import { DEFAULT_MAP_FUTURE_BOOKING_DAYS } from "../../routes/hr/maps/MapEditPage/components/MapSettingsDialog/MapSettingsDialog";
import { DATE_FORMAT } from "../../utils/constants";
import { largeOnMobile } from "../../utils/reactUtils";

interface AvailabilityDatePickerProps extends RangePickerProps {
  unavailableRanges?: [Dayjs, Dayjs][];
  panesEnabled?: "BOTH" | "LEFT" | "RIGHT";
}
const { RangePicker } = DatePicker;
export const AvailabilityDatePicker = ({
  panesEnabled = "BOTH",
  unavailableRanges = [],
  ...props
}: AvailabilityDatePickerProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const mapSettings = useMapSettings();
  let dateDisabledMemo: any = {};
  const disabledDate: RangePickerProps["disabledDate"] = (curr, { from }) => {
    if (dateDisabledMemo.lastDate?.isAfter(curr)) {
      dateDisabledMemo = {};
    }
    dateDisabledMemo.lastDate = curr;
    if (dateDisabledMemo.firstUnavailableDateInRange?.isBefore(curr)) {
      return true;
    }
    if (mapSettings && !mapSettings.workDays.includes(curr.weekday())) {
      return true;
    }
    for (let range of unavailableRanges) {
      if (curr.isBetween(range[0], range[1], "day", "[]")) {
        if (from && curr.isAfter(from)) {
          dateDisabledMemo.firstUnavailableDateInRange = curr;
        }
        return true;
      }
    }
    if (from && curr.isBefore(from)) {
      return true;
    }
    return false;
  };
  let dateFrom = dayjs(searchParams.get("dateFrom"));
  let dateTo = dayjs(searchParams.get("dateTo"));
  const clearDate = () => {
    searchParams.delete("dateTo");
    searchParams.delete("dateFrom");
    setSearchParams(searchParams, { replace: true });
  };
  const setDates: RangePickerProps["onChange"] = (dates) => {
    if (dates === null) {
      clearDate();
    } else if (dates?.[0] && dates[1]) {
      searchParams.set("dateFrom", dates[0].format(DATE_FORMAT));
      searchParams.set("dateTo", dates[1].format(DATE_FORMAT));
      setSearchParams(searchParams, { replace: true });
    }
  };
  return (
    <RangePicker
      popupClassName={`dateRangePicker paneSides${panesEnabled.capitalizeFirstLetter()}`}
      disabledDate={disabledDate}
      placeholder={["Date from", "Date to"]}
      value={dateFrom.isValid() && dateTo.isValid() ? [dateFrom, dateTo] : null}
      maxDate={dayjs().add(
        mapSettings?.futureBookingDays || DEFAULT_MAP_FUTURE_BOOKING_DAYS,
        "days"
      )}
      minDate={dayjs()}
      onChange={setDates}
      style={{ flex: 1 }}
      size={largeOnMobile()}
      inputReadOnly={true}
      {...props}
    />
  );
};
