import { CloseOutlined } from "@ant-design/icons";
import { Button, Flex } from "antd";
export interface ErrorIndicatorProps {
  description?: string;
  onAction?: () => void;
  action?:string
}

export const ErrorIndicator = (props: ErrorIndicatorProps) => {
  return (
    <Flex vertical justify="center" align="center" style={{ padding: "2rem" }}>
      <CloseOutlined style={{ fontSize: "3rem" }} />
      {props.description && (
        <span
          style={{ paddingTop: "10px", textAlign: "center", fontWeight: "500" }}
        >
          {props.description}
        </span>
      )}
      {props.onAction && (
        <Button style={{ marginTop: ".5rem" }} onClick={props.onAction}>
          {props.action||"Retry"}
        </Button>
      )}
    </Flex>
  );
};
