import { IconPlus } from "@tabler/icons-react";
import { Button, Tooltip } from "antd";
import { Outlet, useParams, useSearchParams } from "react-router-dom";
import { SearchBar } from "../../../../components/SearchBar";
import { AnimatingListItem } from "../../../../components/common/AnimatingListItem/AnimatingListItem";
import LoadingBoundry from "../../../../components/common/LoadingBoundry/LoadingBoundry";
import { TeamResponse } from "../../../../repo";
import { useTeams } from "../../../../repo/teams";
import { filterByTerm } from "../../../../utils/arrayUtils";
import { useNav } from "../../../../utils/reactUtils";
import IndicatingList from "../../../common/IndicatingList/IndicatingList";
import ListAndView from "../../../common/ListAndView/ListAndView";
import Scrollable from "../../../common/Scrollable/Scrollable";
import styles from "./TeamsPage.module.css";
type Props = {};

export default function TeamsPage({}: Props) {
  return <ListAndView list={<TeamListContainer />} content={<Outlet />} />;
}

const TeamListContainer = () => {
  const nav = useNav();
  const [searchParams, setSearchParams] = useSearchParams();
  let searchTerm = searchParams.get("searchTerm");
  const { isLoading, isError, data, refetch } = useTeams();
  let teams = filterByTerm<TeamResponse>(data, searchTerm);
  return (
    <LoadingBoundry loading={isLoading} error={isError} onAction={refetch}>
      <div className={styles.controlsContainer}>
        <SearchBar path="/teams" />
        <Tooltip title={"Add a new team"} placement="bottom">
          <Button
            type="primary"
            size="large"
            style={{ width: "3rem" }}
            onClick={() => nav("/teams/new" + window.location.search)}
            icon={<IconPlus/>}
          />
        </Tooltip>
      </div>
      <Scrollable>
        <IndicatingList<TeamResponse>
          data={teams}
          errorTitle="Could not fetch teams"
          emptyTitle="No teams found"
          render={(team) => <TeamListElement key={team.id} team={team} />}
        />
      </Scrollable>
    </LoadingBoundry>
  );
};

const TeamListElement: React.FC<{ team: TeamResponse }> = ({ team }) => {
  const nav = useNav();
  const { teamId } = useParams();
  const selected = teamId === team.id;
  return (
    <AnimatingListItem
      active={selected}
      className={styles.teamListItemContainer}
      onClick={() => {
        nav(`/teams/${team.id}`);
      }}
    >
      <strong className={styles.teamListItemName}>{team.name}</strong>
      <TeamColorIndicator color={team.color} />
    </AnimatingListItem>
  );
};
export const TeamColorIndicator = ({
  color,
  square,
}: {
  color: string;
  square?: boolean;
}) => {
  return (
    <div
      className={`${styles.teamListItemColorContainer} ${
        square ? styles.square : styles.rect
      }`}
      style={{ backgroundColor: color }}
    />
  );
};
