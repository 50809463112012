import { create } from "zustand";

interface ListCloserContext {
  getForName: (name: string) => boolean | undefined;
  setForName: (name: string, val: boolean) => void;
  map: Map<string, boolean>;
}
export const listCloserContext = create<ListCloserContext>((set, get) => ({
  map: new Map(),
  getForName(name: string) {
    const mapEntry = get().map.get(name);
    return mapEntry;
  },
  setForName(name, val) {
    const map = get().map;
    map.set(name, val);
    set((state) => ({ ...state, map: new Map(map) }));
  },
}));
