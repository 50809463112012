import { Button, Input } from "antd";
import { BaseButtonProps } from "antd/es/button/button";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  ComponentWithError,
  ComponentWithLabel,
} from "../../../../components/common/CustomInputText/CustomInputText";
import { ModalDialog } from "../../../../components/common/ModalDialog/ModalDialog";
import { useAuthenticationStore } from "../../../../context/authContext";
import { CreateIssueRequest, EquipmentAssociation, IssueMetadata } from "../../../../repo";
import { useCreateIssue } from "../../../../repo/issues";
import { largeOnMobile } from "../../../../utils/reactUtils";
import styles from "./ReportIssueModal.module.css";

interface Props extends BaseButtonProps {
  association?: EquipmentAssociation;
  issueType: string;
  metadata: IssueMetadata;
}
export default function ReportIssueModal({
  issueType,
  metadata,
  ...props
}: Props) {
  const { control, handleSubmit, reset } = useForm<CreateIssueRequest>();
  const [showModal, setShowModal] = useState(false);
  const { mutate: createIssue } = useCreateIssue(() => {
    setShowModal(false);
    reset();
  });
  const toggleModal = () => {
    setShowModal(!showModal);
  };
  const { auth } = useAuthenticationStore();
  const submitForm = (data: CreateIssueRequest) => {
    data.metadata = metadata;
    data.userEmail = auth!.userEmail!;
    data.type = issueType as any;
    createIssue(data);
  };
  return (
    <>
      <Button {...props} danger onClick={toggleModal} 
        size={largeOnMobile()} />
      <ModalDialog
        open={showModal}
        title={"Create issue"}
        footer={
          <>
            <Button onClick={toggleModal}>Cancel</Button>
            <Button danger htmlType="submit" form="reportIssueForm">
              Report
            </Button>
          </>
        }
        onClose={toggleModal}
      >
        <form
          id="reportIssueForm"
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleSubmit(submitForm)(e);
          }}
        >
          <div className={styles.body}>
            <ComponentWithLabel label="Describe the issue:" />
            <Controller
              control={control}
              name="description"
              render={({ field, fieldState }) => (
                <ComponentWithError error={fieldState.error?.message}>
                  <Input.TextArea
                    value={field.value}
                    onChange={(e) => field.onChange(e.target.value)}
                    placeholder="Issue description"
                    status={fieldState.error && "error"}
                    count={{
                      max: 500,
                      show: true,
                    }}
                    size="large"
                  />
                </ComponentWithError>
              )}
              rules={{
                maxLength: { message: "Input length is too long", value: 499 },
                minLength: { message: "Input length is too short", value: 10 },
                required: "Description is required",
              }}
            />
          </div>
        </form>
      </ModalDialog>
    </>
  );
}
