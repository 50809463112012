import { EmptyProps } from "antd";
import CenteredEmptyPlaceholder from "../../../components/common/CenteredEmptyPlaceholder";
import styles from "./EmptyPage.module.css";
type Props = {};

export default function EmptyPage(props: EmptyProps) {
  return (
    <div className={`${styles.container}`}>
      <CenteredEmptyPlaceholder
        {...props}
        className={`borderBox`}
        style={{ paddingBlock: "1rem" }}
      />
    </div>
  );
}
