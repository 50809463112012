import { Flex, Spin } from "antd";
import { useEffect, useRef } from "react";
import LoadingBoundry from "../../../../../components/common/LoadingBoundry/LoadingBoundry";
import { DeskBookingResponse } from "../../../../../repo";
import { useDeleteBooking } from "../../../../../repo/bookings";
import CenteredFloatingContainer from "../../../CenteredFloatingContainer/CenteredFloatingContainer";
import IndicatingList from "../../../IndicatingList/IndicatingList";
import Scrollable from "../../../Scrollable/Scrollable";
import {
  BookingOverviewBody,
  BookingOverviewFilter,
  useFetchBookings,
} from "../HrBookingOverview/HrBookingOverview";
import { UserBookingListItem } from "../components/BookingListItem";
import { useBookingsContext } from "../context/bookingsContext";

type Props = {};

export default function UserBookingsOverview({}: Props) {
  const { bookings } = useBookingsContext();
  const { isError, isPending, refetch, hasNextPage, getNextPage } =
    useFetchBookings();
  const { mutate: deleteBooking } = useDeleteBooking();
  const onDelete = (booking: DeskBookingResponse) => {
    deleteBooking(booking.id, {
      onSuccess() {
        refetch();
      },
    });
  };
  return (
    <div className="appContent">
      <LoadingBoundry
        error={isError}
        description="Failed to fetch bookings"
        onAction={refetch}
      >
        <CenteredFloatingContainer header={"My bookings"} withoutClose>
          <BookingOverviewBody>
            <BookingOverviewFilter />
            <InfiniteBookingList
              bookings={bookings}
              hasNextPage={hasNextPage}
              isLoading={isPending}
              onLoadMore={getNextPage}
              render={(booking) => (
                <UserBookingListItem
                  key={booking.id}
                  booking={booking}
                  onDelete={onDelete}
                />
              )}
            />
          </BookingOverviewBody>
        </CenteredFloatingContainer>
      </LoadingBoundry>
    </div>
  );
}

export const BookingList = ({
  bookings,
  render,
  isLoading,
}: {
  bookings?: DeskBookingResponse[];
  render: (booking: DeskBookingResponse) => React.ReactNode;
  isLoading?: boolean;
}) => {
  return (
    <Scrollable>
      <IndicatingList<DeskBookingResponse>
        data={bookings}
        isLoading={isLoading}
        render={render}
        emptyTitle="No bookings found"
        style={{ gap: "0.5rem", marginBlock: "1rem" }}
      />
    </Scrollable>
  );
};
export const InfiniteBookingList = ({
  bookings,
  render,
  isLoading,
  onLoadMore,
  hasNextPage,
}: {
  bookings?: DeskBookingResponse[];
  render: (booking: DeskBookingResponse) => React.ReactNode;
  isLoading?: boolean;
  hasNextPage?: boolean;
  onLoadMore?: () => void;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const observerTarget = useRef(null);

  useEffect(() => {
    let target = observerTarget.current;
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !isLoading) {
          onLoadMore?.();
        }
      },
      { threshold: 1 }
    );

    if (target) {
      observer.observe(target);
    }

    return () => {
      if (target) {
        observer.unobserve(target);
      }
    };
  }, [observerTarget, bookings, isLoading, onLoadMore]);
  return (
    <Scrollable ref={ref}>
      <IndicatingList<DeskBookingResponse>
        data={bookings}
        isLoading={isLoading}
        render={render}
        emptyTitle="No bookings found"
        style={{ gap: "var(--spacing-sm)", marginBlock: "1rem" }}
        footer={
          hasNextPage && (
            <Flex justify="center" ref={observerTarget}>
              <Spin />
            </Flex>
          )
        }
      />
    </Scrollable>
  );
};
