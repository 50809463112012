import axios from "axios";
import {
  logOut,
  useAuthenticationStore,
  useProviderAuthenticationStore,
} from "../context/authContext";
import {refreshProviderToken, refreshToken} from "../repo/auth";
import {providerAuthHeader} from "../repo/common";
import {useDialogContext} from "../routes/common/DialogProvider/DialogProvider";

export const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
});

function createAxiosResponseInterceptor() {
  const interceptor = axiosClient.interceptors.response.use(
      (response) => response,
      (error) => {
        if (
            error.response.status !== 401 ||
            error.response.data.code !== "UNAUTHORIZED"
        ) {
          return Promise.reject(error);
        }
        axiosClient.interceptors.response.eject(interceptor);
        return refreshToken(useAuthenticationStore.getState().auth?.refreshToken)
        .then((response) => {
          useAuthenticationStore.getState().logIn(response);
          if (!useAuthenticationStore.getState().auth?.token) {
            return Promise.reject();
          }
          error.response.config.headers["Authorization"] =
              "Bearer " + response.token;
          // Retry the initial call, but with the updated token in the headers.
          // Resolves the promise if successful
          return axiosClient(error.response.config);
        })
        .catch((error2) => {
          if (error2.response.status === 401) {
            logOut();
            showSessionEndedDialog();
          }
          return Promise.reject(error2);
        })
        .finally(createAxiosResponseInterceptor); // Re-attach the interceptor by running the method
      }
  );
  const interceptorProvider = axiosClient.interceptors.response.use(
      (response) => response,
      (error) => {
        if (
            error.response.status !== 401 ||
            error.response.data.code !== "UNAUTHORIZED_PROVIDER" ||
            !useProviderAuthenticationStore.getState().providerAuth
        ) {
          return Promise.reject(error);
        }
        axiosClient.interceptors.response.eject(interceptorProvider);
        return refreshProviderToken(
            useProviderAuthenticationStore.getState().providerAuth!
        )
        .then((response) => {
          response.providerInfo &&
          useProviderAuthenticationStore
          .getState()
          .setProvider(response.providerInfo);
          if (!useAuthenticationStore.getState().auth?.token) {
            return Promise.reject();
          }
          error.response.config.headers[providerAuthHeader] =
              response.providerInfo?.token;
          // Retry the initial call, but with the updated token in the headers.
          // Resolves the promise if successful
          return axiosClient(error.response.config);
        })
        .catch((error2) => {
          if (error2.response.status === 401) {
            logOut();
            showSessionEndedDialog();
          }
          return Promise.reject(error2);
        })
        .finally(createAxiosResponseInterceptor); // Re-attach the interceptor by running the method
      }
  );
}

createAxiosResponseInterceptor();

function showSessionEndedDialog() {
  useDialogContext.getState().show({title: 'Session expired', content: "Your session has expired, please log in again"})
}
