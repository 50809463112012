import { ColorFactory } from "antd/es/color-picker/color";
import { DeskResponse } from "../repo";

export const getTextColor = (rgb: number[]) => {
  return rgb[0] * 0.299 + rgb[1] * 0.587 + rgb[2] * 0.114 > 186
    ? "#101720"
    : "#ffffff";
};
export function getTextColorRGB(r: number, g: number, b: number) {
  return getTextColor([r, g, b]);
}

export function getTextColorFromHex(hex: string) {
  let color = new ColorFactory(hex).toRgb();
  return getTextColor([color.r, color.g, color.b]);
}

export function hexToRGB(hex: string, alpha: number) {
  var r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
  } else {
    return "rgb(" + r + ", " + g + ", " + b + ")";
  }
}

export function getRandomColor() {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export const BOOKABLE_CHAIR_COLOR = "#03C03C";

export function getDeskColor(desk: DeskResponse): string {
  return (
    desk.user?.teamColor || (desk.isBookable ? BOOKABLE_CHAIR_COLOR : "grey")
  );
}
