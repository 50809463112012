import { Flex, Select } from "antd";
import { Control, UseFormSetValue, useWatch } from "react-hook-form";
import { ComponentWithLabel } from "../../../../../components/common/CustomInputText/CustomInputText";
import { useTeams } from "../../../../../repo/teams";
import styles from "./TeamSelector.module.css";
type Props = {
  control: Control<any, any>;
  setValue: UseFormSetValue<any>;
  disabled?: boolean;
};

export default function TeamSelector({ control, setValue, disabled }: Props) {
  const { data: teams, isLoading: teamsLoading } = useTeams();
  const teamId = useWatch({ control, name: "teamId" });
  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  return (
    <div>
      <ComponentWithLabel label="Team:">
        <Flex style={{ width: "100%" }} align="center">
          <Select
            allowClear
            showSearch
            disabled={disabled}
            filterOption={filterOption}
            value={teamId}
            onChange={(key) => {
              let selectedTeam = teams?.find((t) => t.id === key);
              setValue("teamColor", selectedTeam?.color);
              setValue("teamName", selectedTeam?.name);
              setValue("teamId", selectedTeam?.id);
            }}
            placeholder="Select a team"
            loading={teamsLoading}
            className={styles.selector}
            optionRender={(opt, { index }) => {
              let team = teams?.[index];
              return (
                <div className={styles.selectionContainer}>
                  <span className={styles.dropdownTeamName}>{team?.name}</span>
                  <span
                    className={styles.selectionColor}
                    style={{ backgroundColor: team?.color }}
                  />
                </div>
              );
            }}
            options={
              teams?.map((team, index) => {
                return { value: team.id, label: team.name };
              }) || []
            }
          />
          <span
            className={styles.teamColorIndicator}
            style={{
              backgroundColor: teams?.find((team) => team.id === teamId)?.color,
            }}
          />
        </Flex>
      </ComponentWithLabel>
    </div>
  );
}
