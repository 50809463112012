import { Button, Input } from "antd";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  ComponentWithError,
  CustomInputText,
} from "../../../components/common/CustomInputText/CustomInputText";
import LoadingBoundry from "../../../components/common/LoadingBoundry/LoadingBoundry";
import WhoDeskLogo, { DEFAULT_LOGO } from "../../../components/common/WhoDeskLogo";
import { useAuthenticationStore } from "../../../context/authContext";
import { LoginRequest } from "../../../repo";
import { useLogin } from "../../../repo/auth";
import BorderDivider from "../../common/BorderDivider/ListDivider";
import styles from "./LoginPage.module.css";
type Props = {};

export default function LoginPage({}: Props) {
  const nav = useNavigate();
  const { handleSubmit, control } = useForm<LoginRequest>();
  const { logIn } = useAuthenticationStore();
  const {
    mutate: login,
    isError,
    isPending,
    reset,
  } = useLogin((res) => {
    logIn(res);
    nav("/");
  });
  const sendLogin = (data: LoginRequest) => {
    login(data);
  };
  return (
    <div className={styles.loginContainer}>
      <div className={styles.header}>
        <p className={styles.welcomeText}>Login to</p>
        <div>
          <WhoDeskLogo className={styles.logo} src={DEFAULT_LOGO} />
        </div>
        <BorderDivider style={{ width: "80%", marginTop: "1.5rem" }} />
      </div>
      <form id={styles.loginForm} onSubmit={handleSubmit(sendLogin)}>
        <LoadingBoundry
          loading={isPending}
          error={isError}
          description="Failed to login"
          onAction={reset}
        >
          <div className={styles.formFields}>
            <Controller
              control={control}
              name="email"
              render={({ field, fieldState }) => {
                return (
                  <CustomInputText
                    error={fieldState.error?.message}
                    onChange={(e) => {
                      field.onChange(e.target.value.trim());
                    }}
                    size="large"
                    name="username"
                    autoComplete="username"
                    placeholder="Input email"
                  />
                );
              }}
              rules={{
                required: "Email is required",
                pattern: {
                  value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                  message: "Invalid email address",
                },
              }}
            />
            <Controller
              control={control}
              name="password"
              render={({ field, fieldState }) => {
                return (
                  <ComponentWithError error={fieldState.error?.message}>
                    <Input.Password
                      {...field}
                      size="large"
                      name="password"
                      autoComplete="password"
                      placeholder="Input password"
                    />
                  </ComponentWithError>
                );
              }}
              rules={{
                required: "Password is required",
              }}
            />
            <div className={styles.buttonContainer}>
              <Button onClick={() => nav(-1)}>Cancel</Button>
              <Button type="primary" htmlType="submit" loading={isPending}>
                Login
              </Button>
            </div>
          </div>
        </LoadingBoundry>
      </form>
    </div>
  );
}
