import {
  IconCaretRightFilled,
  IconCircleCheck,
  IconXboxX,
} from "@tabler/icons-react";
import { Flex } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  createSearchParams,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { AvailabilityDatePicker } from "../../../../../../../components/common/AvailabilityDatePicker";
import { ButtonWithIconProps } from "../../../../../../../components/common/ButtonWithIcon";
import { DialogWithButtons } from "../../../../../../../components/common/ConfirmationDialog/ConfirmationDialog";
import { ComponentWithLabel } from "../../../../../../../components/common/CustomInputText/CustomInputText";
import DisableByRole from "../../../../../../../components/common/DisableByRole";
import { useAuthenticationStore } from "../../../../../../../context/authContext";
import { BookingUnavailability } from "../../../../../../../repo";
import {
  useCreateBooking,
  useUnavailableBookingsQuery,
} from "../../../../../../../repo/bookings";
import { DATE_FORMAT } from "../../../../../../../utils/constants";
import { largeOnMobile } from "../../../../../../../utils/reactUtils";
import { DEFAULT_MAP_FUTURE_BOOKING_DAYS } from "../../../../../../hr/maps/MapEditPage/components/MapSettingsDialog/MapSettingsDialog";
import BorderDivider from "../../../../../BorderDivider/ListDivider";
import { useDialogContext } from "../../../../../DialogProvider/DialogProvider";
import { useMapSettings, useUserCanBookDesk } from "../../../../../hooks";
import {
  BookingOptionsBehindPermissions,
  bookingCreatedAlert,
} from "../../../../bookings/CreateMapBooking/CreateMapBooking";
import { UserSelection } from "../../../../components/UserSelection/UserSelection";
import { cannotBookADeskDialog } from "../common";
import styles from "./ExtendedDeskBookingAction.module.css";
type Props = {};

export default function ExtendedDeskBookingAction({}: Props) {
  const today = dayjs();
  const { mapId, deskId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { auth } = useAuthenticationStore();
  const mapSettings = useMapSettings();
  const [userEmail, setUserEmail] = useState(auth?.userEmail);
  const firstRender = useRef(true);
  const canBookADesk = useUserCanBookDesk();
  const nav = useNavigate();
  const close = useCallback(() => {
    searchParams.delete("action");
    searchParams.delete("dateFrom");
    searchParams.delete("dateTo");
    searchParams.delete("actions");
    setSearchParams(searchParams, { replace: true });
  }, [searchParams, setSearchParams]);
  useEffect(() => {
    if (firstRender.current && mapSettings) {
      if (mapSettings.workDays.includes(today.weekday())) {
        searchParams.set("dateFrom", today.format(DATE_FORMAT));
        searchParams.set("dateTo", today.format(DATE_FORMAT));
        setSearchParams(searchParams, { replace: true });
      }
      firstRender.current = false;
    }
  }, [searchParams, setSearchParams, today, mapSettings]);
  const { show } = useDialogContext();
  const {
    mutate: createBooking,
    isPending,
    error,
  } = useCreateBooking((data) => {
    close();
    show(bookingCreatedAlert(nav, data));
  });
  const { data = [], isLoading } = useUnavailableBookingsQuery(
    {
      mapId: mapId!,
      dateFrom: today.format(DATE_FORMAT),
      dateTo: today
        .add(
          mapSettings?.futureBookingDays || DEFAULT_MAP_FUTURE_BOOKING_DAYS,
          "days"
        )
        .format(DATE_FORMAT),
    },
    mapSettings !== undefined
  );
  const ranges = data
    .filter((b) => b.deskId === deskId)
    .map((u) => [dayjs(u.dateFrom), dayjs(u.dateTo)] as [Dayjs, Dayjs]);
  const dateFrom = dayjs(searchParams.get("dateFrom"));
  const dateTo = dayjs(searchParams.get("dateTo"));
  const initiateBooking = () => {
    if (!mapId || !deskId || !userEmail) {
      return;
    }
    createBooking({
      mapId: mapId,
      deskId: deskId,
      dateFrom: dateFrom.format(DATE_FORMAT),
      dateTo: dateTo.format(DATE_FORMAT),
      userEmail: userEmail,
      actions: searchParams.getAll("actions") as any,
    });
  };
  const bookingForUserAndDate = data
    ?.filter((b) => b.user.email === userEmail)
    .find((b) => {
      if (!dateFrom.isValid() || !dateTo.isValid()) {
        return false;
      }
      if (
        dayjs(b.dateFrom).isBetween(dateFrom, dateTo, "d", "[]") ||
        dayjs(b.dateTo).isBetween(dateFrom, dateTo, "d", "[]")
      ) {
        return true;
      }
      return false;
    });
  const selectedDatesAreNotValid = ranges.find(
    (r) =>
      dayjs(r[0]).isBetween(dateFrom, dateTo, "d", "[]") ||
      dayjs(r[1]).isBetween(dateFrom, dateTo, "d", "[]")
  );
  const canBook =
    !bookingForUserAndDate &&
    dateFrom.isValid() &&
    dateTo.isValid() &&
    !selectedDatesAreNotValid;

  const confirmButtonProps = (() => {
    let confirmText = "Book";
    let onConfirm = initiateBooking;
    let primaryButtonProps: ButtonWithIconProps = {
      disabled: !canBook,
      variant: "primary",
    };
    if (bookingForUserAndDate && mapId) {
      confirmText = "View booking";
      onConfirm = () =>
        nav({
          pathname: "/bookings",
          search: createSearchParams({
            mapId: mapId,
            userEmail: bookingForUserAndDate.user.email,
            dateFrom: bookingForUserAndDate.dateFrom,
            dateTo: bookingForUserAndDate.dateTo,
            deskId: bookingForUserAndDate.deskId,
          }).toString(),
        });
      primaryButtonProps = {
        icon: <IconCaretRightFilled />,
        location: "after",
        variant: "link",
      };
    }
    return { confirmText, onConfirm, primaryButtonProps };
  })();
  if (!canBookADesk.canBook&&canBookADesk.reasonCode!=='BOOKING_PRESENT') {
    return cannotBookADeskDialog({
      reason: canBookADesk.reason,
      onClose: close,
    });
  }
  return (
    <>
      <DialogWithButtons
        open={true}
        onClose={close}
        state={{
          loading: isLoading || isPending,
          error: error,
          description: "Failed to create a booking",
        }}
        title="Desk booking"
        cancelText="Cancel"
        {...confirmButtonProps}
      >
        <Flex justify="center" vertical gap={"var(--spacing-sm)"}>
          <DisableByRole disabledRole="USER">
            <ComponentWithLabel label="Select a user">
              <UserSelection
                value={userEmail}
                onSelect={(user) => {
                  setUserEmail(user.email);
                }}
                size={largeOnMobile()}
              />
            </ComponentWithLabel>
            <BorderDivider />
          </DisableByRole>
          <AvailabilityDatePicker
            panesEnabled="RIGHT"
            unavailableRanges={ranges}
            size="large"
          />
          {dateFrom.isValid() &&
            dateTo.isValid() &&
            (!selectedDatesAreNotValid || bookingForUserAndDate) && (
              <BookingStatusInfo
                bookingForUserAndDate={bookingForUserAndDate}
                bookings={data}
                userEmail={userEmail}
              />
            )}
        </Flex>
      </DialogWithButtons>
    </>
  );
}

const BookingStatusInfo = ({
  bookingForUserAndDate,
  userEmail
}: {
  bookingForUserAndDate?: BookingUnavailability;
  bookings?: BookingUnavailability[];
  userEmail?:string;
}) => {
  let body = <></>;
  if (bookingForUserAndDate) {
    body = (
      <>
        <IconXboxX color="red" />
        <span className={styles.availabilityText}>
          {"Booking in this period exists"}
        </span>
      </>
    );
  } else {
    body = (
      <>
        <IconCircleCheck color="green" />
        <span className={styles.availabilityText}>{"Date is available"}</span>
        <BookingOptionsBehindPermissions selectedEmail={userEmail} />
      </>
    );
  }
  return <div className={styles.availabilityContainer}>{body}</div>;
};
