import { IconMinus, IconPlus, IconX } from "@tabler/icons-react";
import { Button, ColorPicker } from "antd";
import { useEffect, useRef, useState } from "react";
import { mapQueue } from "../../../../../../utils/map/mapQueue";
import BorderDivider from "../../../../../common/BorderDivider/ListDivider";
import style from "./MapObjectControl.module.css";
type Props = {};

export default function MapObjectControl({}: Props) {
  const [selectedObject, setSelectedObject] = useState<{
    event: MouseEvent;
    target: fabric.Object;
  }>();
  const containerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const hideOptions = () => {
      setSelectedObject(undefined);
    };
    const listener = mapQueue.onReload((map) => {
      map.on("settings:open", (e) => {
        setSelectedObject(e);
      });
      map.on("selection:updated", hideOptions);
      map.on("selection:cleared", hideOptions);
    });
    return () => {
      mapQueue.add((map) => {
        map.off("selection:updated", hideOptions);
        map.off("selection:cleared", hideOptions);
      });
      mapQueue.remove(listener);
    };
  }, [setSelectedObject]);
  return (
    <div
      className={style.container}
      style={{
        left: selectedObject?.event.pageX,
        visibility: selectedObject === undefined ? "hidden" : undefined,
        top:
          Math.max((selectedObject?.event.pageY || 0) -
          (containerRef.current?.offsetHeight || 0),30),
      }}
      ref={containerRef}
    >
      <div className={style.header}>
        <span>Controls</span>
        <IconX onClick={() => setSelectedObject(undefined)} />
      </div>
      <BorderDivider />
      {!((selectedObject?.target as any)?._objects instanceof Array) && (
        <>
          <div className={style.control}>
            <span>Stroke width:</span>
            <IncreaseDecrease
              onChange={(val) => {
                if (!selectedObject) return;
                selectedObject.target.strokeWidth = val;
                selectedObject.target.dirty = true;
                setSelectedObject({ ...selectedObject });
                selectedObject.target.canvas?.requestRenderAll();
              }}
              val={selectedObject?.target.strokeWidth}
            />
          </div>
          <div className={style.control}>
            <span>Fill:</span>
            <ColorPicker
              value={(selectedObject?.target.fill as any) || "black"}
              onChange={(v, hex) => {
                if (!selectedObject) return;
                selectedObject.target.fill = hex;
                selectedObject.target.dirty = true;
                setSelectedObject({ ...selectedObject });
                selectedObject.target.canvas?.requestRenderAll();
              }}
            />
          </div>
          <div className={style.control}>
            <span>Stroke:</span>
            <ColorPicker
              value={(selectedObject?.target.stroke as any) || "black"}
              onChange={(v, hex) => {
                if (!selectedObject) return;
                selectedObject.target.stroke = hex;
                selectedObject.target.dirty = true;
                setSelectedObject({ ...selectedObject });
                selectedObject.target.canvas?.requestRenderAll();
              }}
            />
          </div>
        </>
      )}
      <div className={style.control}>
        <span>Order</span>
        <Button
          onClick={() => {
            if (!selectedObject) return;
            selectedObject.target.bringToFront();
            selectedObject.target.canvas?.requestRenderAll();
          }}
        >
          Top
        </Button>
        <Button
          onClick={() => {
            if (!selectedObject) return;
            selectedObject.target.sendToBack();
            selectedObject.target.canvas?.requestRenderAll();
          }}
        >
          Last
        </Button>
        <Button
          onClick={() => {
            if (!selectedObject) return;
            selectedObject.target.sendBackwards();
            selectedObject.target.canvas?.requestRenderAll();
          }}
          icon={<IconMinus />}
        ></Button>
      </div>
    </div>
  );
}

const IncreaseDecrease = ({
  val = 0,
  min = 1,
  onChange,
}: {
  val?: number;
  min?: number;
  onChange?: (num: number) => void;
}) => {
  const increase = () => {
    onChange?.(val + 1);
  };
  const decrease = () => {
    if (val - 1 < min) {
      return;
    }
    onChange?.(val - 1);
  };
  return (
    <div className={style.increaseDecreaseContainer}>
      <Button icon={<IconMinus />} onClick={decrease} />
      <span>{val}</span>
      <Button icon={<IconPlus />} onClick={increase} />
    </div>
  );
};
