import ReactDOM from "react-dom/client";
import "./flags.css";
import "./index.css";
import "./utils/map/customTypes";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <App/>
);
reportWebVitals();
