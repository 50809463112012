import {
  IconBellFilled,
  IconHome,
  IconLogout,
  IconMenu2,
  IconMessagePlus,
  IconUser,
  IconUserFilled,
  IconX,
} from "@tabler/icons-react";
import { Badge, Dropdown, MenuProps } from "antd";
import { MouseEventHandler, useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import DisableByRole, {
  AdminEnabled,
} from "../../../components/common/DisableByRole";
import WhoDeskLogo from "../../../components/common/WhoDeskLogo";
import {
  logOut,
  useAuthenticationStore,
  usePermissionsStore,
} from "../../../context/authContext";
import { useNotificationMetadata } from "../../../repo/notifications";
import { useUser } from "../../../repo/users";
import { useNav } from "../../../utils/reactUtils";
import { UserAvatar } from "../../hr/users/UsersViewPage/UsersViewPage";
import DialogProvider from "../DialogProvider/DialogProvider";
import { NavigatorProvider } from "../NavigatorProvider/NavigatorProvider";
import WavyBackground from "../WavyBackground/WavyBackground";
import IssueViewer from "../issues/IssueViewer/IssueViewer";
import NotificationsPopup from "./NotificationsPopup/NotificationsPopup";
import styles from "./PageWithToolbar.module.css";

type Props = {};

const PageWithToolbar = (props: Props) => {
  const [hamburgerOut, setHamburgerOut] = useState(false);
  const toggleHamburger: MouseEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setHamburgerOut(!hamburgerOut);
  };
  useEffect(() => {
    if (hamburgerOut) {
      const listener = () => {
        setHamburgerOut(false);
      };
      document.addEventListener("click", listener);
      return () => {
        document.removeEventListener("click", listener);
      };
    }
  }, [hamburgerOut]);
  return (
    <WavyBackground>
      <NavigatorProvider/>
      <section id={styles.toolbar}>
        <div className={styles.nameImageContainer}>
          <WhoDeskLogo className={styles.nameImage} />
        </div>
        <div
          className={`${styles.backDrop} ${hamburgerOut && styles.display}`}
        />
        <div
          className={`${styles.tabItemContainer} ${
            hamburgerOut && styles.display
          }`}
          onClick={toggleHamburger}
        >
          <TabItem link="/maps" text="Maps" refresh />
          <DisableByRole disabledRole="USER">
            <TabItem link="/teams" text="Teams" />
          </DisableByRole>
          <DisableByRole disabledRole="USER">
            <TabItem link="/people" text="People" />
          </DisableByRole>
          <DisableByRole disabledRole="USER">
            <TabItem link="/equipment" text="Equipment" />
          </DisableByRole>
          <TabItem link="/bookings" text="Bookings" />
          <DisableByRole disabledRole="USER">
            <TabItem link="/reporting" text="Reporting" />
          </DisableByRole>
          <AdminEnabled>
            <TabItem link="/companies" text="Companies" />
          </AdminEnabled>
        </div>
        <div className={styles.toolbarEndContainer}>
          <NotificationButton />
          <UserMenu />
          <div
            className={`${styles.hamburgerMenuIcon} ${
              hamburgerOut ? styles.active : styles.inactive
            }`}
            onClick={toggleHamburger}
          >
            {hamburgerOut ? <IconX /> : <IconMenu2 />}
          </div>
        </div>
      </section>
      <section className={styles.content}>
        <IssueViewer />
        <Outlet />
      </section>
      <DialogProvider />
    </WavyBackground>
  );
};
const NotificationButton = () => {
  const { auth } = useAuthenticationStore();
  const { isUser } = usePermissionsStore();
  const { data: notifications } = useNotificationMetadata({
    userEmail: isUser() ? auth?.userEmail : undefined,
  });
  const [open, setOpen] = useState(false);

  const close = () => {
    setOpen(false);
  };
  let listener: any;
  if (open) {
    listener = (e: MouseEvent) => {
      setOpen(false);
      e.preventDefault();
      e.stopPropagation();
    };
  } else {
    listener = (e: MouseEvent) => {
      setOpen(true);
      e.preventDefault();
      e.stopPropagation();
    };
  }
  return (
    <div style={{ position: "relative" }}>
      <div className={styles.iconButton}>
        <Badge count={notifications?.count} size="small">
          <IconBellFilled
            onMouseUp={listener}
            className={"toolbarBell"}
            style={{
              color: open
                ? "var(--supplementary-color)"
                : "var(--primary-color)",
              cursor: "pointer",
            }}
          />
        </Badge>
        {open && <NotificationsPopup onClose={close} />}
      </div>
    </div>
  );
};
const UserMenu: React.FC = () => {
  const nav = useNav();
  const { auth } = useAuthenticationStore();
  const { isHr } = usePermissionsStore();
  const { data: user } = useUser(auth?.userEmail);
  const items: MenuProps["items"] = [];
  if (isHr()) {
    items.push({
      key: "4",
      label: "Company settings",
      title: "Company settings",
      icon: <IconHome />,
      onClick: () => {
        nav(`/company`);
      },
    });
  }
  items.push(
    ...[
      {
        key: "2",
        label: (
          <a href="mailto:feedback@who-desk.com?subject=I have some feedback to share">
            Give feedback
          </a>
        ),
        title: "Feedback",
        icon: <IconMessagePlus />,
      },
      {
        key: "3",
        label: "Profile",
        title: "Profile",
        icon: <IconUser />,
        onClick: () => {
          nav(`/profile`);
        },
      },
      {
        key: "1",
        danger: true,
        label: "Log out",
        title: "Log out",
        icon: <IconLogout />,
        onClick: logOut,
      },
    ]
  );
  return (
    <Dropdown menu={{ items }}>
      <div onClick={(e) => e.preventDefault()} className={styles.iconButton}>
        {user ? (
          <UserAvatar
            src={user?.photoUrl}
            identifier={auth?.userEmail}
            style={{ cursor: "pointer" }}
          />
        ) : (
          <IconUserFilled
            style={{
              color: "var(--primary-color)",
              cursor: "pointer",
            }}
          />
        )}
      </div>
    </Dropdown>
  );
};

function TabItem({
  link,
  text,
  refresh,
}: {
  link: string;
  text: string;
  refresh?: boolean;
}) {
  const location = useLocation();
  let isActive = location.pathname
    .split("/")[1]
    .includes(link.replace("/", ""));
  const nav = useNavigate();
  useEffect(() => {
    if (isActive) {
      document.title = `WhoDesk - ${text}`;
    }
    return () => {
      document.title = `WhoDesk`;
    };
  }, [isActive, text]);
  const onClick = () => {
    if (!isActive || refresh) {
      nav(link);
    }
  };

  return (
    <div
      onClick={onClick}
      className={`${styles.tabItem} ${isActive ? styles.activeTab : ""}`}
    >
      <span>{text}</span>
    </div>
  );
}

export default PageWithToolbar;
