import { Flex } from "antd";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useCompanySettings } from "../../../context/companyContext";
import WavyBackground from "../../common/WavyBackground/WavyBackground";
import styles from "./UnauthenticatedPage.module.css";
type Props = {};

export default function UnauthenticatedPage({}: Props) {
  const {reset} = useCompanySettings();
  useEffect(() => {
    reset();
  }, [reset]);
  return (
    <WavyBackground withLogo>
      <Flex justify="center" align="center" style={{ height: "100dvh" }}>
        <div id={styles.loginContainer} className={`borderBox`}>
          <Outlet />
        </div>
      </Flex>
    </WavyBackground>
  );
}
