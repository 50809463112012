import { Button } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FullHeightCentered from "../../../components/common/FullHeightCentered";
import WhoDeskLogo, {
  DEFAULT_LOGO,
} from "../../../components/common/WhoDeskLogo";
import {
  useAuthenticationStore,
  usePermissionsStore,
} from "../../../context/authContext";
import { useGoogleAuth } from "../../../repo/auth";
import BorderDivider from "../../common/BorderDivider/ListDivider";
import styles from "./WelcomePage.module.css";
import GoogleLoginButton from "./components/GoogleLogin";

export default function WelcomePage({}: {}) {
  const [error, setError] = useState<string | undefined>(undefined);
  const { logIn } = useAuthenticationStore();
  const { permissions } = usePermissionsStore();
  const {
    mutate: loginWithGoogle,
    error: loginError,
    reset,
  } = useGoogleAuth((res) => {
    logIn(res);
  });
  useEffect(() => {
    if (permissions?.length === 0) {
      setError("You have no companies associated to your email");
    }
  }, [permissions]);
  const nav = useNavigate();
  return (
    <>
      {error || loginError ? (
        <FullHeightCentered>
          <p>{error}</p>
          <Button
            onClick={() => {
              setError(undefined);
              reset();
            }}
          >
            Retry
          </Button>
        </FullHeightCentered>
      ) : (
        <>
          <div className={styles.header}>
            <p className={styles.welcomeText}>Welcome to</p>
            <div>
              <WhoDeskLogo className={styles.logo} src={DEFAULT_LOGO} />
            </div>
            <BorderDivider style={{ width: "80%", marginTop: "1.5rem" }} />
          </div>
          <div className={styles.body}>
            <div className={styles.loginMethodContainer}>
              <GoogleLoginButton
                onError={() => {
                  setError("failed to login");
                }}
              />
              <button
                onClick={() => {
                  nav("/login");
                }}
                className={styles.passwordButton}
                type="button"
              >
                Login with password
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
}
