import React from "react";
import styles from "./GraphControlContainer.module.css";
type Props = {};

export default function GraphControlContainer(
  props: React.HTMLAttributes<HTMLDivElement>
) {
  return (
    <>
      <div className={`${styles.graphControls} borderBox`} {...props} />
    </>
  );
}
