import { IImageOptions, MapImage } from "fabric/fabric-impl";

fabric.MapImage = fabric.util.createClass(fabric.Image, {
  type: "mapImage",
  initialize: function (element: any, options: any) {
    this.callSuper("initialize", element, options);
    this.set({ snapAngle: 5 });
  },
});

(fabric.MapImage as any).fromObject = (_object: any, callback: any) => {
  var object = fabric.util.object.clone(_object);
  (fabric.util as any).loadImage(
    object.src,
    (img: any, isError: boolean) => {
      if (isError) {
        callback && callback(null, true);
        return;
      }
      (fabric.Image.prototype as any)._initFilters.call(
        object,
        object.filters,
        function (filters: any) {
          object.filters = filters || [];
          (fabric.Image.prototype as any)._initFilters.call(
            object,
            [object.resizeFilter],
            (resizeFilters: any) => {
              object.resizeFilter = resizeFilters[0];
              (fabric.util as any).enlivenObjectEnlivables(
                object,
                object,
                function () {
                  var image = new fabric.MapImage(img, object);
                  callback(image, false);
                }
              );
            }
          );
        }
      );
    },
    null,
    object.crossOrigin
  );
};
(fabric.MapImage as any).fromURL = (
  url: string,
  callback?: ((image: MapImage) => void) | undefined,
  imgOptions?: IImageOptions | undefined
) => {
  fabric.util.loadImage(
    url,
    (img) => {
      callback && callback(new fabric.MapImage(img, imgOptions));
    },
    null,
    imgOptions && imgOptions.crossOrigin
  );
  return;
};

