import { Canvas, EditMapDynamicLine } from "fabric/fabric-impl";
import { Rectangle } from "../customTypes";

fabric.EditMapDynamicLineControl = fabric.util.createClass(fabric.Circle, {
    type: "editMapDynamicLineControl",
    excludeFromExport: true,
    initialize: function (options: any) {
      this.callSuper("initialize", {
        radius: 4,
        strokeWidth: 2,
        fill: "#fff",
        stroke: "#666",
        hasBorders: false,
        hasControls: false,
        hoverCursor: "pointer",
        originX: "center",
        originY: "center",
        visible: false,
        ...options,
      });
    },
  });
  
fabric.EditMapDynamicLine = fabric.util.createClass(fabric.Line, {
    type: "editMapDynamicLine",
    initialize: function (points: any, opts: any) {
      if (!points) {
        points = [0, 0, 0, 0];
      }
      this.callSuper("initialize", points, {
        stroke: "black",
        hasControls: false,
        strokeWidth: 1,
        ...opts,
      });
    },
    addControls: function (canvas: Canvas) {
      let line: EditMapDynamicLine = this;
      let begin = new fabric.EditMapDynamicLineControl({
        left: line.x1! + line.left!,
        top: line.y1! + line.top!,
      });
      let end = new fabric.EditMapDynamicLineControl({
        left: line.x2! + line.left!,
        top: line.y2! + line.top!,
      });
      const positionCircles = () => {
        let displacementY = line.y1! > line.y2! ? line.height! : 0;
        let displacementX = line.x1! > line.x2! ? line.width! : 0;
        begin.set({
          left: line.left! + displacementX,
          top: line.top! + displacementY,
        });
        begin.setCoords();
        end.set({
          left: line.left! + line.width! - displacementX,
          top: line.top! + line.height! - displacementY,
        });
        end.setCoords();
        line.setCoords();
        canvas.renderAll();
      };
      const updateLineCoords = () => {
        const coords = {
          x1: begin.left!,
          y1: begin.top!,
          x2: end.left!,
          y2: end.top!,
        };
        let angle =
          (Math.atan2(coords.y1! - coords.y2!, coords.x1! - coords.x2!) * 180) /
            Math.PI +
          180;
        let snappingAngle = 45;
        if (angle % snappingAngle === 0) {
          line.set({ ...coords, stroke: "red", strokeWidth: 2 });
        } else {
          line.set({ ...coords, stroke: "black", strokeWidth: 1 });
        }
        line.setCoords();
        end.setCoords();
        begin.setCoords();
        canvas.renderAll();
      };
      end.on("moving", (e) => {
        updateLineCoords();
        let curr = end;
        canvas.forEachObject(function (obj) {
          if (obj === curr) return;
          if (obj === line) return;
          if (obj.type !== "circle") return;
          if (curr.intersectsWithObject(obj)) {
            curr.set({ left: obj.left, top: obj.top });
            updateLineCoords();
          }
        });
      });
      begin.on("moving", (e) => {
        updateLineCoords();
  
        let curr = begin;
        canvas.forEachObject(function (obj) {
          if (obj === curr) return;
          if (obj === line) return;
          if (obj.type !== "circle") return;
          if (curr.intersectsWithObject(obj)) {
            curr.set({ left: obj.left, top: obj.top });
  
            updateLineCoords();
          }
        });
      });
  
      begin.on("mouseover", () => {
        begin.set({ visible: true });
        canvas.renderAll();
      });
      begin.on("mouseout", () => {
        begin.set({ visible: false });
        canvas.renderAll();
      });
      end.on("mouseover", () => {
        end.set({ visible: true });
        canvas.renderAll();
      });
      end.on("mouseout", () => {
        end.set({ visible: false });
        canvas.renderAll();
      });
      end.on("mouseup", () => {
        positionCircles();
  
        line.set({ stroke: "black", strokeWidth: 1 });
      });
      begin.on("mouseup", () => {
        positionCircles();
        line.set({ stroke: "black", strokeWidth: 1 });
      });
      line.on("mouseover", (e) => {
        begin.set({ visible: true });
        end.set({ visible: true });
        canvas.renderAll();
      });
      line.on("mouseout", (e) => {
        begin.set({ visible: false });
        end.set({ visible: false });
        canvas.renderAll();
      });
      let moveBox: Rectangle | null;
      line.on("moving", (e) => {
        if (!moveBox || moveBox.contains(e.pointer)) {
          canvas.forEachObject(function (obj) {
            if (obj === line) return;
            if (obj === end) return;
            if (obj === begin) return;
            if (obj.type !== "circle") return;
            if (end.intersectsWithObject(obj)) {
              end.set({ left: obj.left, top: obj.top });
              moveBox = new Rectangle(e.pointer!, 5);
              updateLineCoords();
            } else if (begin.intersectsWithObject(obj)) {
              begin.set({ left: obj.left, top: obj.top });
              moveBox = new Rectangle(e.pointer!, 5);
              updateLineCoords();
            }
          });
        } else {
          moveBox = null;
        }
        positionCircles();
      });
      canvas.add(end);
      canvas.add(begin);
      end.setCoords();
      begin.setCoords();
      positionCircles();
    },
  });
  fabric.EditMapDynamicLine.fromObject = function (object: any, callback?: any) {
    function _callback(instance: any) {
      delete instance.points;
      callback && callback(instance);
    }
    let options = { ...object };
    options.points = [object.x1, object.y1, object.x2, object.y2];
    return fabric.Object._fromObject(
      "EditMapDynamicLine",
      options,
      _callback,
      "points"
    ) as any;
  };