import {
  InfiniteData,
  QueryKey,
  useInfiniteQuery,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import { message } from "antd";
import { AxiosError } from "axios";
import {
  BookingUnavailability,
  BookingUnavailabilityQuery,
  CreateBookingRequest,
  DeskBookingResponse,
  ErrorResponse,
  GetBookingsRequestParams,
} from ".";
import { axiosClient } from "../config/axios";
import { invalidateQueries, queryClient } from "../config/useQueryClientConfig";
import { getCompanyId, getHeaders, getProviderHeaders } from "./common";
export const useUnavailableBookings = () => {
  return useMutation<
    BookingUnavailability[],
    Error,
    BookingUnavailabilityQuery
  >({
    mutationFn: (req) => getUnavailableBookingIds(req),
  });
};

export const useUnavailableBookingsQuery = (
  req: BookingUnavailabilityQuery,
  enabled?: boolean
) => {
  return useQuery<BookingUnavailability[], Error>({
    queryKey: ["bookings", "unavailable", req],
    queryFn: () => getUnavailableBookingIds(req),
    staleTime: 1000 * 60 * 1,
    enabled: enabled,
  });
};

export const useCreateBooking = (
  onSuccess?: (data: DeskBookingResponse) => void
) => {
  const msgKey = "bookingCreate";
  return useMutation<
    DeskBookingResponse,
    AxiosError<ErrorResponse>,
    CreateBookingRequest
  >({
    mutationFn: createBooking,
    onError: (err) => {
      let content = "Failed to create booking";
      if (
        err.response?.status === 400 &&
        err.response?.data.code === "UNAVAILABLE"
      ) {
        content = "Desk is no more available";
      }
      message.open({
        type: "error",
        content: content,
        key: msgKey,
      });
    },

    onSuccess: (data) => {
      message.open({
        type: "success",
        content: "Booking created",
        key: msgKey,
      });
      invalidateQueries({
        predicate: (query) => {
          return query.queryKey.includes("bookings");
        },
      });
      queryClient.removeQueries({ queryKey: ["bookings"] });
      onSuccess?.(data);
    },
  });
};
const getUnavailableBookingIds = async (req: BookingUnavailabilityQuery) => {
  let companyId = getCompanyId();
  const res = await axiosClient.get<BookingUnavailability[]>(
    `/companies/${companyId}/maps/${req.mapId}/bookings/unavailable`,
    {
      headers: getHeaders(),
      params: req,
    }
  );
  return res.data;
};
export const useBookings = (
  params?: GetBookingsRequestParams,
  enabled: boolean = true
) => {
  const query = useInfiniteQuery<
    DeskBookingResponse[],
    AxiosError,
    InfiniteData<DeskBookingResponse[], number>,
    QueryKey,
    number
  >({
    queryKey: ["bookings"],
    queryFn: (par) => getBookings({ ...params, page: par.pageParam }),
    initialPageParam: 0,

    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (lastPage.length === 0 || lastPage.length < (params?.pageSize || 1)) {
        return undefined;
      }
      return lastPageParam + 1;
    },
    getPreviousPageParam: (firstPage, allPages, firstPageParam) => {
      if (firstPageParam <= 1) {
        return undefined;
      }
      return firstPageParam - 1;
    },
    enabled,
  });
  const getNextPage = () => {
    if (query.hasNextPage && !query.isFetching) {
      query.fetchNextPage();
    }
  };
  return { ...query, getNextPage };
};
export const useDeleteBooking = () => {
  const msgKey = "bookingDelete";
  return useMutation<any, Error, string>({
    mutationFn: (id: string) => deleteBooking(id),
    onMutate(variables) {
      message.open({
        type: "loading",
        content: "Deleting booking...",
        key: msgKey,
      });
    },
    onError(error, variables, context) {
      message.open({
        type: "error",
        content: "Failed to delete booking",
        key: msgKey,
      });
    },
    onSuccess: () => {
      message.open({
        type: "success",
        content: "Booking deleted",
        key: msgKey,
      });
      queryClient.removeQueries({ queryKey: ["bookings"] });
      return invalidateQueries({ queryKey: ["bookings"] });
    },
  });
};
const createBooking = async (booking: CreateBookingRequest) => {
  let companyId = getCompanyId();
  const res = await axiosClient.post<DeskBookingResponse>(
    `/companies/${companyId}/bookings`,
    booking,
    {
      headers: { ...getHeaders(), ...getProviderHeaders() },
    }
  );
  return res.data;
};
const getBookings = async (params?: GetBookingsRequestParams) => {
  let companyId = getCompanyId();
  const res = await axiosClient.get<DeskBookingResponse[]>(
    `/companies/${companyId}/bookings`,
    {
      headers: getHeaders(),
      params,
    }
  );
  return res.data;
};

const deleteBooking = async (id?: string) => {
  let companyId = await getCompanyId();
  const res = await axiosClient.delete(
    `/companies/${companyId}/bookings/${id}`,
    {
      headers: { ...getHeaders(), ...getProviderHeaders() },
    }
  );
  return res.data;
};
