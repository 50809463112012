import {
  IconCalendarMonth,
  IconChevronLeft,
  IconChevronRight,
} from "@tabler/icons-react";
import { DatePicker, Tooltip } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useUnavailableBookingsQuery } from "../../../../../repo/bookings";
import { useDesks } from "../../../../../repo/desks";
import { DATE_FORMAT } from "../../../../../utils/constants";
import { DEFAULT_MAP_FUTURE_BOOKING_DAYS } from "../../../../hr/maps/MapEditPage/components/MapSettingsDialog/MapSettingsDialog";
import BorderDivider from "../../../BorderDivider/ListDivider";
import { useMapSettings } from "../../../hooks";
import { useDateContext } from "../../context/dateContext";
import { useDeskContext } from "../../context/deskContext";
import styles from "./MapViewDatePicker.module.css";
export const MapViewDatePicker = () => {
  const { mapId } = useParams();
  const date = useDateContext.use.date();
  const setBookings = useDeskContext.use.setBookings();
  const setDesks = useDeskContext.use.setDesks();
  const { data: bookings } = useUnavailableBookingsQuery({
    dateFrom: date?.format(DATE_FORMAT),
    dateTo: date?.format(DATE_FORMAT),
    mapId: mapId!,
  });
  const { data: desks } = useDesks(mapId);
  useEffect(() => {
    if (bookings) {
      setBookings(bookings);
    }
  }, [bookings, setBookings]);
  useEffect(() => {
    if (desks) {
      setDesks(desks);
    }
  }, [desks, setDesks]);
  return <MapViewDatePickerInner />;
};
export const MapViewDatePickerInner = () => {
  const today = dayjs();
  const date = useDateContext.use.date();
  const setDate = useDateContext.use.setDate();
  const settings = useMapSettings();
  const maxDate = today.add(
    settings?.futureBookingDays || DEFAULT_MAP_FUTURE_BOOKING_DAYS,
    "days"
  );
  useEffect(() => {
    if (isNotWorkDay(date)) {
      increase();
    }
    // eslint-disable-next-line
  }, [date, settings]);
  const increase = () => {
    changeDate(date.add(1, "d"));
  };
  const decrease = () => {
    changeDate(date.subtract(1, "d"));
  };

  const changeDate = (d: Dayjs) => {
    while (isNotWorkDay(d)) {
      if (d.isAfter(date, "d")) {
        d = d.add(1, "d");
      } else {
        d = d.subtract(1, "d");
      }
    }
    if (!dayIsUnavailable(d)) {
      setDate(d);
    }
  };

  const dayIsUnavailable = (d: Dayjs) => {
    return (
      d.isBefore(today, "d") ||
      isNotWorkDay(d) ||
      d.isAfter(maxDate, "d") ||
      d.isSame(maxDate, "d")
    );
  };
  const isNotWorkDay = (d: Dayjs) => {
    if (!settings) {
      return false;
    }
    return !settings.workDays.includes(d.weekday());
  };

  return (
    <div className={`${styles.container} borderBox`}>
      <div className={styles.innerContainer}>
        <Tooltip title="The day this map is viewed at" placement="bottom">
          <div className={styles.calendarIcon}>
            <IconCalendarMonth />
          </div>
        </Tooltip>
        <BorderDivider style={{ height: "100%" }} horizontal />
        <div className={styles.dateContainer}>
          <ArrowButton onClick={decrease} disabled={date.isSame(today, "d")}>
            <IconChevronLeft />
          </ArrowButton>
          <DatePicker
            value={date}
            format={"MM-DD (dddd)"}
            variant="borderless"
            size="large"
            allowClear={false}
            className={styles.datePicker}
            inputReadOnly={true}
            suffixIcon={null}
            disabledDate={dayIsUnavailable}
            maxDate={maxDate}
            onChange={changeDate}
          />
          <ArrowButton
            onClick={increase}
            disabled={maxDate.diff(date, "d") === 1}
          >
            <IconChevronRight />
          </ArrowButton>
        </div>
      </div>
    </div>
  );
};

function ArrowButton({
  onClick,
  children,
  disabled,
}: {
  onClick: () => void;
  children?: any;
  disabled?: boolean;
}) {
  return (
    <button
      tabIndex={0}
      className={styles.arrowButton}
      onClick={onClick}
      disabled={disabled}
      type="button"
    >
      {children}
    </button>
  );
}
