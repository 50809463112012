import { IconArrowRight } from "@tabler/icons-react";
import { Button } from "antd";
import dayjs from "dayjs";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ComponentWithLabel } from "../../../../components/common/CustomInputText/CustomInputText";
import { ErrorIndicator } from "../../../../components/common/ErrorIndicator";
import { ModalDialog } from "../../../../components/common/ModalDialog/ModalDialog";
import { IssueResponse } from "../../../../repo";
import { useIssue } from "../../../../repo/issues";
import styles from "./IssueViewer.module.css";
type Props = {};

export default function IssueViewer({}: Props) {
  const [params, setParams] = useSearchParams();
  const issueId = params.get("openIssueId") || undefined;
  const { data: issue, error } = useIssue(issueId);
  const nav = useNavigate();
  const navToUser = () => {
    issue?.issuer.email && nav(`/people/${issue?.issuer.email}`);
  };
  const closeModal = () => {
    params.delete("openIssueId");
    setParams(params);
  };
  return (
    <ModalDialog
      open={issue !== undefined}
      footer={<IssueAction issue={issue!} />}
      title={"View issue"}
      onClose={closeModal}
    >
      {error ? (
        <ErrorIndicator description="Could not fetch issue" />
      ) : (
        <div>
          <strong>
            <ComponentWithLabel label="Description:" />
          </strong>
          <div className={styles.descriptionContainer}>
            {issue?.description}
          </div>
          <div className={styles.metadataContainer}>
            {issue?.issuer?.email && (
              <div className={styles.metadata}>
                <strong>Created by: </strong>
                {issue?.issuer.name}{" "}
                <Button
                  onClick={navToUser}
                  type="primary"
                  icon={<IconArrowRight />}
                />
              </div>
            )}
            <div className={styles.metadata}>
              <strong>Created at: </strong>
              {dayjs(issue?.createdAt).format("YYYY-MM-DD HH:mm")}
            </div>
          </div>
        </div>
      )}
    </ModalDialog>
  );
}

const IssueAction = ({ issue }: { issue: IssueResponse }) => {
  const nav = useNavigate();
  const doAction = () => {
    nav(`/maps/${issue.metadata.mapId}/desks/${issue.metadata.deskId}`);
  };
  return (
    <Button type="primary" onClick={doAction}>
      Go to desk
    </Button>
  );
};
