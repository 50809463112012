import { fabric } from "fabric";

fabric.Tooltip = fabric.util.createClass(fabric.Group, {
    type: "tooltip",
    toObject: function () {},
    initialize: function (options: fabric.TooltipOptions) {
      const objects: Object[] = [];
      if (options.text) {
        const text = new fabric.Text(options.text.trunc(options.maxLength), {
          fill: "white",
          fontFamily: "roboto",
        });
        objects.push(text);
      }
      if (options.meta) {
        const metaText = new fabric.Text(options.meta.trunc(options.maxLength), {
          top: 48,
          fontSize: 30,
          fill: "#fffd",
          fontFamily: "roboto",
        });
        objects.push(metaText);
      }
      this.callSuper("initialize", objects, {
        ...options,
        evented: false,
        opacity: 1,
      });
      const paddingInline = 20;
      const paddingBlock = 20;
      const height = this.height! + 40;
      const width = this.width! + 20;
      var polygon = new fabric.Polygon(
        [
          { x: -paddingInline, y: -paddingBlock },
          { x: -paddingInline, y: height },
          { x: 30, y: height },
          { x: 45, y: 100 },
          { x: 60, y: height },
          { x: width, y: height },
          { x: width, y: -paddingBlock },
        ],
        {
          fill: "#000000d0",
          stroke: "black",
          strokeWidth: 10,
          strokeLineJoin: "round",
          top: this.top! - paddingBlock * 1.5,
          left: this.left! - paddingInline,
        }
      );
      this.addWithUpdate(polygon);
      polygon.sendToBack();
    },
  });