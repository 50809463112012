import { Flex } from "antd";
import styles from "./ListAndView.module.css";
type Props = {
  list: React.ReactElement;
  content: React.ReactElement;
};

export default function ListAndView({ list, content }: Props) {
  return (
    <Flex className="appContent">
      <div id={styles.listContainer} className="borderBox">
        {list}
      </div>
      <div id={styles.viewContainer}>{content}</div>
    </Flex>
  );
}
