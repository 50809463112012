import { useGoogleLogin } from "@react-oauth/google";
import { Dayjs } from "dayjs";
import { useParams } from "react-router-dom";
import {
  useAuthenticationStore,
  usePermissionsStore,
  useProviderAuthenticationStore,
} from "../../context/authContext";
import { useGoogleAuth } from "../../repo/auth";
import { useUnavailableBookingsQuery } from "../../repo/bookings";
import { useDesk, useDesks } from "../../repo/desks";
import { useMap } from "../../repo/maps";
import { useRoom, useRooms } from "../../repo/rooms";
import { DATE_FORMAT } from "../../utils/constants";
import { useDeskContext } from "./MapViewPage/context/deskContext";

export function useProviderLogin(scopes?: string, onError?: () => void) {
  const { logIn } = useAuthenticationStore();
  const { setProvider } = useProviderAuthenticationStore();
  const {
    mutate: loginWithGoogle,
    error: loginError,
    reset,
  } = useGoogleAuth((res) => {
    res.providerInfo && setProvider(res.providerInfo);
    logIn(res);
  });
  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      loginWithGoogle({ code: codeResponse.code }, { onError });
    },
    overrideScope: scopes !== undefined,
    scope: scopes,
    flow: "auth-code",
  });
  return login;
}

export function useMapSettings() {
  return useCurrentMap()?.map?.settings;
}
export function useCurrentMap() {
  const { mapId } = useParams();
  const res = useMap(mapId);
  return { ...res, map: res.data };
}

export function useCurrentRooms() {
  const { mapId } = useParams();
  const res = useRooms(mapId);
  return { ...res, rooms: res.data || [] };
}

export function useCurrentRoom() {
  const { mapId, roomId } = useParams();
  const res = useRoom(mapId, roomId);
  return { ...res, room: res.data, mapId };
}

export function useCurrentDesks() {
  const { mapId } = useParams();
  const res = useDesks(mapId);
  return { ...res, desks: res.data || [] };
}
export function useCurrentDesk() {
  const { mapId, deskId } = useParams();
  const res = useDesk(mapId, deskId);
  return { ...res, desk: res.data };
}

export function useUserCanBookDesk() {
  const { map } = useCurrentMap();
  const { desk } = useCurrentDesk();
  const { desks, bookings } = useDeskContext();
  const { auth } = useAuthenticationStore();
  const { isUser } = usePermissionsStore();
  if (map?.status === "DRAFT") {
    return {
      canBook: false,
      reason: "Map is in draft state",
      reasonCode: "DRAFT_MAP",
    };
  }
  if (!desk?.isBookable) {
    return {
      canBook: false,
      reason: "Desk is permanent",
      reasonCode: "PERMANENT_DESK",
    };
  }
  const hasPermanentDesk =
    isUser() &&
    desks.find((d) => d.user?.email === auth?.userEmail) !== undefined;
  if (hasPermanentDesk) {
    return {
      canBook: false,
      reason: "You have a permanent desk",
      reasonCode: "USER_HAS_PERMANENT_DESK",
    };
  }
  const hasBooking =
    isUser() && bookings.find((d) => d.user.email === auth?.userEmail);
  if (hasBooking) {
    return {
      canBook: false,
      reason: "You have a booking for this date",
      reasonCode: "BOOKING_PRESENT",
    };
  }
  return {
    canBook: true,
  };
}

export const useDeskBookings = (date: Dayjs) => {
  const { mapId, deskId } = useParams();

  return useUnavailableBookingsQuery({
    dateFrom: date.format(DATE_FORMAT),
    dateTo: date.format(DATE_FORMAT),
    mapId: mapId!,
    deskId: deskId,
  });
};
