import dayjs from "dayjs";

export function getMonthName(date: string) {
  let currentDate = dayjs(date);
  return currentDate.format("MMMM");
}

export function toMilliseconds({
  seconds = 0,
  minutes = 0,
  hours = 0,
}: {
  seconds?: number;
  minutes?: number;
  hours?: number;
}) {
  const minute = 60 * 1000;
  return seconds * 1000 + minutes * minute + hours * 60 * minute;
}
