import { IconPlus } from "@tabler/icons-react";
import { Button, Empty, Flex, Select, Tooltip } from "antd";
import { useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { usePermissionsStore } from "../../../context/authContext";
import {
  CreateEquipmentAssociationRequest,
  DeskResponse,
  EquipmentMinified,
} from "../../../repo";
import {
  useAvailableEquipment,
  useCreateEquipmentAssociation,
  useRemoveEquipmentAssoc,
  useUpdateEquipmentAssoc,
} from "../../../repo/equipment";
import BorderDivider from "../../../routes/common/BorderDivider/ListDivider";
import Scrollable from "../../../routes/common/Scrollable/Scrollable";
import { largeOnMobile } from "../../../utils/reactUtils";
import { DialogWithButtons } from "../ConfirmationDialog/ConfirmationDialog";
import { ComponentWithError } from "../CustomInputText/CustomInputText";
import DisableByRole from "../DisableByRole";
import { EquipmentItem } from "../EquipmentItem/EquipmentItem";
import styles from "./EquipmentSection.module.css";

interface EquipmentSectionProps {
  equipment: EquipmentMinified[];
  onAdd: (eq: EquipmentMinified) => void;
  onRemove: (id: string) => void;
  onHide: (id: string) => void;
  headerClassName?: string;
  disabled?: boolean;
}

export const EquipmentSection: React.FC<{
  disabled?: boolean;
  headerClassName?: string;
  assocId?: CreateEquipmentAssociationRequest;
}> = ({ disabled, headerClassName, assocId }) => {
  const { mutate: addEquipment } = useCreateEquipmentAssociation(assocId);
  const { mutate: updateEquipmentForRoom } = useUpdateEquipmentAssoc(assocId);
  const { mutate: removeEquipment } = useRemoveEquipmentAssoc(assocId);
  const { control } = useFormContext<Partial<DeskResponse>>();
  const { fields, append, remove, update } = useFieldArray({
    control: control,
    name: "equipment",
    keyName: "fieldId",
  });

  const onAddEquipment = (eq: EquipmentMinified) => {
    if (assocId) {
      addEquipment(
        { request: assocId, equipment: eq },
        {
          onSuccess(data) {
            append(data);
          },
        }
      );
    }
  };
  const onRemoveEquipment = (id: string) => {
    if (id) {
      removeEquipment(
        { id },
        {
          onSuccess(data, variables, context) {
            const indexToRemove = fields.findIndex(
              (e) => e.id === variables.id
            );
            remove(indexToRemove);
          },
        }
      );
    }
  };

  const onHideEquipment = (id: string) => {
    const equipmentIndex = fields.findIndex((e) => e.id === id);

    if (equipmentIndex > -1) {
      const hidden = fields[equipmentIndex].hidden;
      updateEquipmentForRoom({ id, req: { hidden: !hidden } });
      update(equipmentIndex, { ...fields[equipmentIndex], hidden: !hidden });
    }
  };
  if (fields.length === 0 && disabled) {
    return <></>;
  }
  return (
    <InnerEquipmentSection
      disabled={disabled}
      equipment={fields}
      onAdd={onAddEquipment}
      onHide={onHideEquipment}
      onRemove={onRemoveEquipment}
      headerClassName={headerClassName}
    />
  );
};
const InnerEquipmentSection: React.FC<EquipmentSectionProps> = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const addEquipment = () => {
    setIsModalOpen(true);
  };
  const handleRemove = (id: string) => {
    props.onRemove(id);
  };
  const handleHide = (id: string) => {
    props.onHide(id);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleSave = (eq: EquipmentMinified) => {
    setIsModalOpen(false);
    props.onAdd(eq);
  };
  const { isHr, isAdmin } = usePermissionsStore();
  const removable = isHr() || isAdmin();
  return (
    <>
      <div
        className={`${styles.equipmentListHeader} ${
          props.headerClassName || ""
        }`}
      >
        <span>Equipment</span>
        {!props.disabled && (
          <DisableByRole disabledRole="USER">
            <Tooltip title="Add equipment">
              <Button
                className={styles.equipmentAddButton}
                type="primary"
                onClick={addEquipment}
                icon={<IconPlus size={20} />}
              />
            </Tooltip>
          </DisableByRole>
        )}
        <BorderDivider />
      </div>
      <Scrollable className={styles.equipmentContainer}>
        {props.equipment.map((field, index) => {
          return (
            field && (
              <EquipmentItem
                modifyable={removable && !props.disabled}
                key={field.id + "eq"}
                index={index}
                remove={handleRemove}
                hide={handleHide}
                equipment={field}
              />
            )
          );
        })}
      </Scrollable>

      {isModalOpen && (
        <EquipmentSelectionModal
          isModalOpen={isModalOpen}
          onCancel={handleCancel}
          onSave={handleSave}
        />
      )}
    </>
  );
};

const EquipmentSelectionModal = ({
  isModalOpen,
  onSave,
  ...props
}: {
  isModalOpen: boolean;
  onCancel: () => void;
  onSave: (equipment: EquipmentMinified) => void;
}) => {
  const { data: equipmentList } = useAvailableEquipment();
  const [selectedEquipmentId, setSelectedEquipmentId] = useState<string>();
  const [error, setError] = useState<string>();
  const handleSave = () => {
    if (selectedEquipmentId) {
      const eq = equipmentList?.find((eq) => eq.id === selectedEquipmentId);
      if (!eq) {
        setError("Equipment is required");
      } else {
        setSelectedEquipmentId(undefined);
        onSave(eq);
      }
    } else {
      setError("Equipment is required");
    }
  };
  const onCancel = () => {
    setSelectedEquipmentId(undefined);
    props.onCancel();
  };
  return (
    <DialogWithButtons
      title={"Choose equipment"}
      open={isModalOpen}
      onClose={onCancel}
      onConfirm={handleSave}
      primaryButtonProps={{icon: <IconPlus/>}}
      secondaryButtonProps={{icon: undefined}}
      confirmText="Add"
      cancelText="Cancel"
    >
      <Flex justify="center">
        <div className={styles.equipmentModalContainer}>
          <ComponentWithError
            flex={1}
            error={error}
            className={styles.equipmentDropdown}
          >
            <Select
              showSearch
              value={selectedEquipmentId}
              notFoundContent={
                <Empty
                  description="No available equipment"
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                />
              }
              optionFilterProp="label"
              placeholder={"Select equipment"}
              onChange={(equ) => {
                setSelectedEquipmentId(equ);
                setError(undefined);
              }}
              size={largeOnMobile()}
            >
              {equipmentList?.map((equ) => (
                <Select.Option
                  value={equ.id}
                  key={equ.id}
                  label={`${equ.name}${equ.identifier ? equ.identifier : ""}`}
                >
                  {equ.name}
                </Select.Option>
              ))}
            </Select>
          </ComponentWithError>
        </div>
      </Flex>
    </DialogWithButtons>
  );
};
