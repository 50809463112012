import { useMutation, useQuery } from "@tanstack/react-query";
import { message } from "antd";
import { AxiosError } from "axios";
import {
  CreateCompanyUserRequest,
  ErrorResponse,
  UpdateCompanyUserRequest,
  UserMinifiedResponse,
  UserResponse,
} from ".";
import { axiosClient } from "../config/axios";
import { invalidateQueries, persistentQueryClient, queryClient } from "../config/useQueryClientConfig";
import { toMilliseconds } from "../utils/dateUtils";
import { getCompanyId, getHeaders } from "./common";

export interface LoginRequest {
  displayName?: string;
  email?: string;
  photoURL?: string;
  id?: string;
}
export const useUserDelete = (onSuccess?: () => void) => {
  const msgKey = "userDelete";

  return useMutation<any, Error, UserResponse, { previousUsers: any }>({
    mutationFn: ({ email }) => {
      return deleteCompanyUser(email);
    },
    onMutate: async ({ email }) => {
      message.open({
        type: "loading",
        content: "Deleting user...",
        key: msgKey,
      });
      await queryClient.cancelQueries({ queryKey: ["users"] });
      const previousUsers = queryClient.getQueryData(["users"]);
      queryClient.setQueryData(["users"], (old: any[]) => {
        return old.filter((obj) => obj.id !== email);
      });
      return { previousUsers };
    },
    onError: (err, oldUser, context) => {
      message.open({
        type: "error",
        content: "Could not delete user",
        key: msgKey,
      });
      queryClient.setQueryData(["users"], context?.previousUsers);
    },
    onSuccess(data, params) {
      onSuccess?.();

      message.open({
        type: "success",
        content: "User deleted",
        key: msgKey,
      });
      invalidateQueries({ queryKey: ["users"] });
      params.equipment?.forEach((e) =>
        invalidateQueries({ queryKey: ["equipment", e.id] })
      );
    },
  });
};
export const useUserCreate = () => {
  const msgKey = "userCreate";
  return useMutation<
    UserResponse,
    AxiosError<ErrorResponse>,
    { user: CreateCompanyUserRequest },
    any
  >({
    mutationFn: ({ user }) => addUserToCompany(user),
    onMutate: async ({ user }) => {
      message.open({
        type: "loading",
        content: "Creating user...",
        key: msgKey,
      });
    },
    onError: (err, newTodo, context) => {
      message.open({
        type: "error",
        content: "Failed to create user",
        key: msgKey,
      });
    },
    onSuccess(data, variables, context) {
      message.open({
        type: "success",
        content: "User created",
        key: msgKey,
      });
      invalidateQueries({ queryKey: ["users"] });
    },
  });
};
export const useUserUpdate = () => {
  const msgKey = "userUpdate";
  return useMutation<UserResponse, Error, { user: UpdateCompanyUserRequest }>({
    mutationFn: ({ user }) => {
      return updateCompanyUser(user);
    },
    onMutate: ({ user }) => {
      message.open({
        type: "loading",
        content: "Updating user...",
        key: msgKey,
      });
    },
    onError: (err, updatedUser, context) => {
      message.open({
        type: "error",
        content: "Failed to update user",
        key: msgKey,
      });
    },
    onSuccess: (data, params) => {
      message.open({
        type: "success",
        content: "User updated",
        key: msgKey,
      });
      invalidateQueries({ queryKey: ["users", params.user.email] });
      invalidateQueries({ queryKey: ["users"] });
    },
  });
};
export const useUserPhotoUpdate = () => {
  const msgKey = "userUpdate";
  return useMutation<UserResponse, Error, { photoUrl: string; email: string }>({
    mutationFn: ({ photoUrl, email }) => {
      return updateCompanyUserPhoto(photoUrl, email);
    },
    onMutate: () => {
      message.open({
        type: "loading",
        content: "Updating user...",
        key: msgKey,
      });
    },
    onError: (err, updatedUser, context) => {
      message.open({
        type: "error",
        content: "Failed to update user",
        key: msgKey,
      });
    },
    onSuccess: (data, params) => {
      message.open({
        type: "success",
        content: "User updated",
        key: msgKey,
      });
      invalidateQueries({ queryKey: ["users"] });
    },
  });
};
export const useUsers = (enabled?: boolean) => {
  return useQuery({
    queryKey: ["users"],
    queryFn: async () => {
      let companyId = await getCompanyId();
      const res = await axiosClient.get<UserMinifiedResponse[]>(
        `/companies/${companyId}/users`,
        { headers: getHeaders() }
      );
      return res.data;
    },
    staleTime: Infinity,
    enabled,
  });
};
export const useUser = (userEmail?: string) => {
  return useQuery(
    {
      queryKey: ["users", userEmail],
      queryFn: async () => {
        return getUser(userEmail);
      },
      enabled: userEmail !== undefined,
      staleTime: toMilliseconds({ minutes: 10 }),
    },
    persistentQueryClient
  );
};
export const getUser = async (userEmail?: string) => {
  let companyId = getCompanyId();
  const res = await axiosClient.get<UserResponse>(
    `/companies/${companyId}/users/${userEmail}`,
    { headers: getHeaders() }
  );
  return res.data;
};
export const addUserToCompany = async (user: CreateCompanyUserRequest) => {
  let companyId = getCompanyId();
  const res = await axiosClient.post<UserResponse>(
    `/companies/${companyId}/users`,
    user,
    { headers: getHeaders() }
  );
  return res.data;
};
export const updateCompanyUser = async (user: UpdateCompanyUserRequest) => {
  let companyId = getCompanyId();
  const res = await axiosClient.put<UserResponse>(
    `/companies/${companyId}/users/${user.email}`,
    user,
    { headers: getHeaders() }
  );
  return res.data;
};
export const updateCompanyUserPhoto = async (
  photoUrl: string,
  email: string
) => {
  let companyId = getCompanyId();
  const res = await axiosClient.put<UserResponse>(
    `/companies/${companyId}/users/${email}/photo`,
    { photoUrl },
    { headers: getHeaders() }
  );
  return res.data;
};
export const deleteCompanyUser = async (userEmail: string) => {
  let companyId = getCompanyId();
  const res = await axiosClient.delete<any[]>(
    `/companies/${companyId}/users/${userEmail}`,
    { headers: getHeaders() }
  );
  return res.data;
};
