import { IconDesk, IconDoor, IconUser } from "@tabler/icons-react";
import { Avatar, AvatarProps, Flex, Tooltip } from "antd";
import React, { useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { SearchBar } from "../../../../../components/SearchBar";
import { AnimatingListItem } from "../../../../../components/common/AnimatingListItem/AnimatingListItem";
import { ListCloser } from "../../../../../components/common/ListCloser/ListCloser";
import { useProviderAuthenticationStore } from "../../../../../context/authContext";
import { DeskResponse, RoomResponse } from "../../../../../repo";
import { useDesks } from "../../../../../repo/desks";
import { useRooms } from "../../../../../repo/rooms";
import { filterByTerm } from "../../../../../utils/arrayUtils";
import { getDeskColor } from "../../../../../utils/colorUtils";
import { useNav } from "../../../../../utils/reactUtils";
import { TeamColorIndicator } from "../../../../hr/teams/TeamsPage/TeamsPage";
import {
  UserAvatar,
  UserTeamName,
} from "../../../../hr/users/UsersViewPage/UsersViewPage";
import BorderDivider from "../../../BorderDivider/ListDivider";
import IndicatingList from "../../../IndicatingList/IndicatingList";
import Scrollable from "../../../Scrollable/Scrollable";
import {
  useCurrentDesks,
  useCurrentMap,
  useCurrentRooms,
  useUserCanBookDesk,
} from "../../../hooks";
import { useDeskContext } from "../../context/deskContext";
import { useMapEntityContext } from "../../context/mapEntityContext";
import styles from "./MapDeskList.module.css";

type Props = {};
const MapDeskList = (props: Props) => {
  const { map } = useCurrentMap();
  let [searchParams, setSearchParams] = useSearchParams();
  const setSearchTerm = useDeskContext.use.setSearchTerm();
  const reset = useMapEntityContext.use.reset();
  let searchTerm = searchParams.get("searchTerm");
  useEffect(() => {
    setSearchTerm(searchTerm || "");
  }, [searchTerm, setSearchTerm]);
  useEffect(() => {
    reset();
  }, [reset]);
  return (
    <div className={styles.container}>
      <div className={styles.controlContainer}>
        <div className={styles.buttonContainer}>
          {/* <FindADeskButton /> */}
          <FindARoomButton />
        </div>
        <SearchBar
          placeholder="Search for people or teams"
          path={`/maps/${map?.id}/desks`}
        />
      </div>
      <BorderDivider />
      <ListSection />
    </div>
  );
};
const FindARoomButton = () => {
  const { map } = useCurrentMap();
  const { rooms } = useCurrentRooms();
  const nav = useNav();
  const { providerAuth } = useProviderAuthenticationStore();
  const canBookRooms =
    rooms.find((r) => r.integrationId) && providerAuth?.refreshToken;
  return (
    <>
      {canBookRooms && (
        <Tooltip
          title={
            map?.status === "DRAFT" ? "The map is in draft state" : undefined
          }
        >
          <BookButton
            onClick={() => {
              nav(`/maps/${map?.id}/bookings/rooms`);
            }}
            disabled={map?.status === "DRAFT"}
          >
            <IconDoor size={30} />
            Find a room
          </BookButton>
        </Tooltip>
      )}
    </>
  );
};
const FindADeskButton = () => {
  const { map } = useCurrentMap();
  const nav = useNavigate();
  const { desks } = useCurrentDesks();
  const { canBook, reason } = useUserCanBookDesk();
  return (
    <>
      {desks.length > 0 && (
        <Tooltip title={reason}>
          <BookButton
            onClick={() => {
              nav(`/maps/${map?.id}/bookings/desks`);
            }}
            disabled={!canBook}
          >
            <IconDesk size={30} />
            Find a desk
          </BookButton>
        </Tooltip>
      )}
    </>
  );
};

const BookButton = ({
  onClick,
  children,
  disabled,
  ...props
}: {
  onClick: () => void;
  children: React.ReactNode;
  disabled?: boolean;
}) => {
  return (
    <Flex flex={1} {...props}>
      <div
        className={`${styles.bookButton} ${disabled ? styles.disabled : ""}`}
        onClick={disabled ? undefined : onClick}
      >
        {children}
      </div>
    </Flex>
  );
};

const ListSection = () => {
  const { mapId } = useParams();
  const { isLoading, isError } = useDesks(mapId);
  const filteredDesks = useDeskContext.use.filteredDesks();
  const { data } = useRooms(mapId);
  const [searchParams] = useSearchParams();
  const rooms = filterByTerm(data, searchParams.get("searchTerm"));
  return (
    <Scrollable>
      <RoomList rooms={rooms} collapsible={filteredDesks.length !== 0} />
      <DeskList
        isLoading={isLoading}
        isError={isError}
        desks={filteredDesks}
        collapsible={rooms.length !== 0}
      />
    </Scrollable>
  );
};

const RoomList = ({
  rooms,
  collapsible,
}: {
  rooms: RoomResponse[];
  collapsible?: boolean;
}) => {
  if (rooms.length === 0) {
    return <></>;
  }
  return (
    <ListCloser name={"Rooms"} hidden={!collapsible}>
      <IndicatingList<RoomResponse>
        data={rooms}
        render={(r) => <RoomListItem key={r.id} room={r} />}
      />
    </ListCloser>
  );
};
export const RoomListItem = ({
  room,
  style,
}: {
  room: RoomResponse;
  style?: AvatarProps["style"];
}) => {
  const setHoveredEntityId = useMapEntityContext.use.setHoveredEntityId();
  const setSelectedEntityId = useMapEntityContext.use.setSelectedEntityId();
  const onMouseEnter = () => {
    setHoveredEntityId(room.mapRoomId);
  };
  const onMouseLeave = () => {
    setHoveredEntityId(undefined);
  };
  const navToRoom = () => {
    setSelectedEntityId(room.mapRoomId);
  };
  return (
    <AnimatingListItem
      className={styles.deskListItemContainer}
      onClick={navToRoom}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Avatar style={style} icon={<IconDoor />} />
      <div className={styles.deskListItemContent}>
        <div className={styles.deskListItemContent}>
          <span className={styles.deskListItemTitle}>{room.name}</span>
        </div>
      </div>
      <Flex justify="center" align="center">
        {room.capacity}
        <IconUser size={16} />
      </Flex>
    </AnimatingListItem>
  );
};
export default MapDeskList;

export const DeskList: React.FC<{
  isError: boolean;
  isLoading: boolean;
  desks?: DeskResponse[];
  emptyTitle?: string;
  collapsible?: boolean;
}> = ({ isError, isLoading, desks = [], emptyTitle, collapsible }) => {
  if (desks.length === 0) {
    return <></>;
  }
  return (
    <ListCloser name={"Desks"} hidden={!collapsible}>
      <IndicatingList<DeskResponse>
        isLoading={isLoading}
        isError={isError}
        data={desks}
        emptyTitle={emptyTitle || "No desks found"}
        errorTitle="Could not fetch desks"
        render={(desk) => <MapDesksListItem key={desk.id} desk={desk} />}
      />
    </ListCloser>
  );
};

const MapDesksListItem = ({ desk }: { desk: DeskResponse }) => {
  const setHoveredEntityId = useMapEntityContext.use.setHoveredEntityId();
  const setSelectedEntityId = useMapEntityContext.use.setSelectedEntityId();
  const onMouseEnter = () => {
    setHoveredEntityId(desk.mapDeskId);
  };
  const onMouseLeave = () => {
    setHoveredEntityId(undefined);
  };
  const onClick = () => {
    setSelectedEntityId(desk.mapDeskId);
  };
  return (
    <AnimatingListItem
      className={styles.deskListItemContainer}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {desk.user ? (
        <UserAvatar identifier={desk.user.email} src={desk.user.photoUrl} />
      ) : (
        <Avatar icon={<IconDesk />} />
      )}
      <div className={styles.deskListItemContent}>
        <span className={styles.deskListItemTitle}>
          {!desk.user ? desk.name || "Bookable desk" : `${desk.user?.name}`}
        </span>
        {desk.user && (
          <div className={`listItemMetadata ${styles.listItemMetadata}`}>
            <UserTeamName teamName={desk.user.teamName} />
          </div>
        )}
      </div>

      <TeamColorIndicator square color={getDeskColor(desk)} />
    </AnimatingListItem>
  );
};
