import { forwardRef } from "react";
import styles from "./Scrollable.module.css";
interface Props
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {}
const Scrollable = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { children, ...rest } = props;

  return (
    <div
      {...rest}
      ref={ref}
      className={`${styles.container} ${props.className}`}
    >
      {children}
    </div>
  );
});

export default Scrollable;
