import { DeleteOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Button, Input, InputRef, Popconfirm, Popover, message } from "antd";
import { forwardRef, useCallback, useEffect, useRef, useState } from "react";
import { useBeforeUnload, useNavigate, useParams } from "react-router-dom";
import { ConfirmDialog } from "../../../../components/common/ConfirmationDialog/ConfirmationDialog";
import { AdminEnabled } from "../../../../components/common/DisableByRole";
import { ErrorIndicator } from "../../../../components/common/ErrorIndicator";
import {
  FabricCanvas,
  MapSettings,
  MapStatus,
} from "../../../../repo";
import { useDeleteMap, useMap, useUpdateMap } from "../../../../repo/maps";
import { editMapHolder } from "../../../../utils/map/MapHolder";
import { WhoDeskMap } from "../../../../utils/map/WhoDeskMap";
import "../../../../utils/map/canvasObjects";
import { mapQueue } from "../../../../utils/map/mapQueue";
import styles from "./MapEditPage.module.css";
import MapObjectControl from "./components/MapObjectControl/MapObjectControl";
import MapObjectSelector from "./components/MapObjectSelector/MapObjectSelector";
import { MapSettingsDialog } from "./components/MapSettingsDialog/MapSettingsDialog";
import { MapUpdateErrorDialog } from "./components/MapUpdateErrorDialog/MapUpdateErrorDialog";
type Props = {};
export interface AdditionalMapSettings {
  settings: MapSettings;
  status: MapStatus;
}
export default function MapEditPage({}: Props) {
  const { mapId } = useParams();
  const { data: map, error } = useMap(mapId);
  const [mapSettings, setMapSettings] = useState<AdditionalMapSettings>();
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const nameInputRef = useRef<InputRef>(null);
  const [showBookingsPresentError, setShowBookingsPresentError] =
    useState(false);
  const nav = useNavigate();
  const { mutate: deleteMap } = useDeleteMap(() => {
    nav(`/maps`, { replace: true });
  });
  const { mutate: updateMap } = useUpdateMap();
  useEffect(() => {
    if (map?.canvas) {
      loadMapFromJson(map.canvas);
    }
    map && setMapSettings({ status: map.status, settings: map.settings });
  }, [map]);
  useEffect(() => {
    const editingMap = new WhoDeskMap(canvasRef.current, {
      height: canvasRef.current?.offsetHeight,
      width: canvasRef.current?.offsetWidth,
      containerClass: styles.canvasContainer,
      keyActionsEnabled: true,
      gridSize: 20,
      deskInteraction: {
        onDeleteFailure: (type) => {
          message.open({
            type: "error",
            content: `Cannot delete ${
              type === "desk" ? "desk" : "room"
            } with content associated`,
            key: "deleteMapDesk",
          });
        },
      },
    });
    editMapHolder.current = editingMap;
    editingMap.addCenterIndicator();
    mapQueue.fireReadyEvent(editingMap);
    return () => {
      mapQueue.fire("dispose");
      editMapHolder.dispose();
    };
  }, []);
  if (error) {
    return (
      <div className="centeredFlexBox appContent">
        <ErrorIndicator description="Could not load the map" />
      </div>
    );
  }
  return (
    <div className="appContent" id={styles.content}>
      <MapStateSaver />
      <MapObjectSelector/>
      <div
        ref={containerRef}
        id={styles.mapEditContainer}
        className="borderBox"
      >
        <AdminEnabled>
          <div className={`${styles.mapCornerBottomLeft} ${styles.mapCorner}`}>
            <Popconfirm
              title="Delete the map"
              description="Are you sure you want to delete this map? All data associated with it will be lost"
              onConfirm={(e) => {
                e?.stopPropagation();
                deleteMap({ mapId: mapId! });
              }}
              onCancel={(e) => {
                e?.stopPropagation();
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button
                icon={<DeleteOutlined />}
                onClick={(e) => e.stopPropagation()}
                danger
              >
                Delete
              </Button>
            </Popconfirm>
          </div>
        </AdminEnabled>
        <div className={`${styles.mapCornerTopRight} ${styles.mapCorner}`}>
          <MapInfoIcon />
        </div>
        <div className={`${styles.mapCornerTopLeft} ${styles.mapCorner}`}>
          <MapSettingsDialog
            settings={mapSettings}
            onChange={(settings) => {
              setMapSettings(settings);
            }}
          />
        </div>
        <div className={`${styles.mapCornerBottomRight} ${styles.mapCorner}`}>
          <Button type="primary" onClick={saveMap}>
            Save
          </Button>
        </div>
        <MapNameContainer ref={nameInputRef} name={map?.name} />
        <canvas id={styles.mapEditCanvas} ref={canvasRef}></canvas>
        <MapUpdateErrorDialog
          open={showBookingsPresentError}
          onClose={() => setShowBookingsPresentError(false)}
        />
        <MapObjectControl />
      </div>
    </div>
  );


  function loadMapFromJson(json: Object) {
    mapQueue.add((canvas) => {
      canvas.loadMapFromJson(json);
      canvas.addCenterIndicator();
      canvas.isDirty = false;
    });
  }

  async function saveMap() {
    if (!map) {
      return;
    }
    updateMap(
      {
        mapId: mapId!,
        map: {
          ...map,
          name: nameInputRef.current?.input?.value || map.name,
          canvas: editMapHolder.getMap().toJSON() as FabricCanvas,
          settings: mapSettings!.settings,
          status: mapSettings!.status,
        },
      },
      {
        onError(error, variables, context) {
          if (error.response?.data.code === "DESK_BOOKINGS_PRESENT") {
            setShowBookingsPresentError(true);
          }
        },
        onSuccess(data, variables, context) {
          editMapHolder.getMap().isDirty = false;
        },
      }
    );
  }

}
const stateKey = "editingMap";
const MapStateSaver = () => {
  const { mapId } = useParams();
  const [open, setOpen] = useState(false);
  const mapRef = useRef<WhoDeskMap>();
  const mapKey = stateKey + mapId;
  const saveMapChanges = useCallback(
    (map?: WhoDeskMap) => {
      if (map?.isDirty) {
        window.localStorage.setItem(mapKey, JSON.stringify(map.toJSON()));
      }
    },
    [mapKey]
  );

  useBeforeUnload(() => saveMapChanges(mapRef.current));

  const toggleOpen = useCallback(() => {
    setOpen(!open);
  }, [open]);

  useEffect(() => {
    const onReload = mapQueue.onReload((m) => (mapRef.current = m));
    const onDispose = mapQueue.on("dispose", (m) => {
      saveMapChanges(m);
    });
    return () => {
      mapQueue.off("dispose", onDispose);
      mapQueue.remove(onReload);
    };
  }, [saveMapChanges]);

  useEffect(() => {
    const savedMapJson = window.localStorage.getItem(mapKey);
    if (savedMapJson && !open) {
      toggleOpen();
    }
  }, [toggleOpen, open, mapKey]);

  const restoreMap = () => {
    const savedMapJson = window.localStorage.getItem(mapKey);
    clearStorage();
    if (savedMapJson) {
      const savedMap = JSON.parse(savedMapJson);
      mapQueue.add((map) => {
        map.loadMapFromJson(savedMap);
        map.isDirty = true;
      });
    }
  };
  const clearStorage = () => {
    toggleOpen();
    window.localStorage.removeItem(mapKey);
  };
  return (
    <ConfirmDialog
      open={open}
      cancelText="No, remove them"
      onConfirm={restoreMap}
      heading={"You have unsaved map changes, do you want to restore?"}
      onClose={clearStorage}
    />
  );
};

const MapNameContainer = forwardRef<InputRef, { name?: string }>(
  ({ name }, ref) => {
    const [currName, setCurrName] = useState(name);
    useEffect(() => setCurrName(name), [name]);
    return (
      <div id={styles.mapNameEditContainer}>
        <Input
          className={styles.mapNameInput}
          ref={ref}
          size="middle"
          value={currName}
          onChange={(e) => setCurrName(e.target.value)}
          maxLength={250}
        />
      </div>
    );
  }
);


const MapInfoIcon: React.FC = () => {
  let isMac = navigator.platform.toUpperCase().indexOf("MAC") >= 0;
  let ctrlKey = isMac ? "Cmd" : "Ctrl";
  let altKey = isMac ? "Option" : "Alt";
  return (
    <Popover
      placement="bottomLeft"
      title={<h3>Usage notes</h3>}
      content={
        <>
          <p>
            <strong>Ctrl + mouse wheel</strong> to zoom in or out
          </p>
          <p>
            <strong>{altKey} + click</strong> to move around
            <p>
              <strong>{ctrlKey} + c</strong> to copy and{" "}
              <strong>{ctrlKey} + v</strong> to paste
            </p>
          </p>
          <p>
            <strong>{ctrlKey} + z</strong> to undo an action
          </p>
          <p>
            <strong>{ctrlKey} + delete</strong> to delete an object
          </p>
        </>
      }
      trigger="hover"
    >
      <InfoCircleOutlined id={styles.mapInfoIcon} />
    </Popover>
  );
};
