import { Divider, Flex, Switch } from "antd";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import {
  ComponentWithError,
  ComponentWithLabel,
  CustomInputText,
} from "../../../../../components/common/CustomInputText/CustomInputText";
import DisableByRole from "../../../../../components/common/DisableByRole";
import { usePermissionsStore } from "../../../../../context/authContext";
import { DeskResponse } from "../../../../../repo";
import { UserListItem } from "../../../../hr/users/UsersViewPage/UsersViewPage";
import DeskUserSelection from "../../components/UserSelection/UserSelection";
import styles from "./DeskForm.module.css";
type Props = {
  noName?: boolean;
};

export default function DeskForm({ noName }: Props) {
  const formMethods = useFormContext<DeskResponse>();
  const { role } = usePermissionsStore();
  const user = useWatch({ name: "user" });
  const isBookable = formMethods.watch("isBookable");
  return (
    <div className={styles.formInputs}>
      <div className={styles.bookableText}>
        <strong>Workspace</strong> is{" "}
        <Controller
          name="isBookable"
          render={({ field }) => {
            return (
              <>
                <DisableByRole enabledRole="USER">
                  <span>
                    {field.value
                      ? user
                        ? "occupied"
                        : "bookable"
                      : "permanent"}
                  </span>
                </DisableByRole>
                <DisableByRole disabledRole="USER">
                  <Switch
                    {...field}
                    defaultValue={false}
                    checkedChildren="bookable"
                    unCheckedChildren="permanent"
                  />
                </DisableByRole>
              </>
            );
          }}
        />
      </div>
      <Divider />
      {!noName && (
        <DisableByRole disabledRole="USER">
          <Controller
            name="name"
            control={formMethods.control}
            rules={{
              required: "Desk name is required",
              maxLength: { value: 50, message: "Name is too long" },
            }}
            render={({ field, fieldState }) => {
              return (
                <CustomInputText
                  value={field.value}
                  label="Desk name"
                  placeholder="My wonderful desk"
                  maxLength={50}
                  error={fieldState.error?.message}
                  onChange={(e) => field.onChange(e.target.value)}
                  required={true}
                />
              );
            }}
          />
        </DisableByRole>
      )}
      {(!isBookable || user) && (
        <Controller
          control={formMethods.control}
          name="user"
          rules={{
            required: isBookable
              ? undefined
              : "You must select a user that will use this desk",
          }}
          render={({ field, fieldState }) => {
            return (
              <Flex align="center" gap={8}>
                <ComponentWithError
                  error={fieldState.error?.message}
                  className={styles.flexContainer}
                >
                  <ComponentWithLabel
                    label="Desk user:"
                    className={styles.flexContainer}
                  >
                    <Flex vertical>
                      {user && (
                        <div style={{ paddingInline: ".5rem" }}>
                          <UserListItem
                            user={user}
                            disabled={role === "USER"}
                          />
                        </div>
                      )}
                      <div className={styles.userSelection}>
                        <DisableByRole disabledRole="USER">
                          <DeskUserSelection
                            value={field.value}
                            className={styles.userSelection}
                            onSelect={(user) =>
                              formMethods.setValue("user", user)
                            }
                          />
                        </DisableByRole>
                      </div>
                    </Flex>
                  </ComponentWithLabel>
                </ComponentWithError>
              </Flex>
            );
          }}
        />
      )}
    </div>
  );
}
