import { useSearchParams } from "react-router-dom";
import CustomCheckbox from "../../../../../../components/common/CustomCheckbox/CustomCheckbox";
import { ComponentWithLabel } from "../../../../../../components/common/CustomInputText/CustomInputText";
import { ModalDialog } from "../../../../../../components/common/ModalDialog/ModalDialog";
import styles from "./UserFilterDialog.module.css";
type Props = {};

export default function UserFilterDialog({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const statuses = searchParams.getAll("status");
  const changeMultiValue = (
    shouldExist: boolean,
    key: string,
    value: string
  ) => {
    if (shouldExist) {
      searchParams.append(key, value);
    } else {
      const statuses = searchParams.getAll(key);
      searchParams.delete(key);
      statuses
        .filter((s) => s !== value)
        .forEach((s) => searchParams.append(key, s));
    }
    setSearchParams(searchParams);
  };
  return (
    <ModalDialog open={open} onClose={onClose} title="User filter">
      <div className={styles.container}>
        <ComponentWithLabel label="Active">
          <CustomCheckbox
            checked={statuses.includes("ACTIVE")}
            onChange={(val) => {
              changeMultiValue(val, "status", "ACTIVE");
            }}
          />
        </ComponentWithLabel>
        <ComponentWithLabel label="Deactivated">
          <CustomCheckbox
            checked={statuses.includes("DELETED")}
            onChange={(val) => {
              changeMultiValue(val, "status", "DELETED");
            }}
          />
        </ComponentWithLabel>
      </div>
    </ModalDialog>
  );
}
