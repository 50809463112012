import { DatePicker } from "antd";
import dayjs from "dayjs";
import { ComponentWithLabel } from "../../../../../../../components/common/CustomInputText/CustomInputText";
import LoadingBoundry from "../../../../../../../components/common/LoadingBoundry/LoadingBoundry";
import { RoomBooking } from "../../../../../../../repo";
import { useUsers } from "../../../../../../../repo/users";
import { largeOnMobile } from "../../../../../../../utils/reactUtils";
import { UserListItem } from "../../../../../../hr/users/UsersViewPage/UsersViewPage";
import styles from "./EventInfoDialog.module.css";
type Props = {
  booking: RoomBooking;
};

export default function EventInfoDialog({ booking }: Props) {
  const dateFrom = dayjs(booking.dateFrom);
  const dateTo = dayjs(booking.dateTo);
  const { data: users = [], isLoading, isError } = useUsers();
  const findByEmail = (email = "unknown") => {
    return (
      users.find((u) => u.email === email) || {
        email: email,
        name: email,
        teamName: "External",
      }
    );
  };
  const creator = findByEmail(booking.creator?.email);
  return (
    <div className={styles.container}>
      <ComponentWithLabel label="Booking date">
        <DatePicker.RangePicker
          disabled
          size={largeOnMobile()}
          showTime
          format={"YYYY-MM-DD HH:mm"}
          value={[dateFrom, dateTo]}
        />
      </ComponentWithLabel>
      <ComponentWithLabel label="Created at">
        <DatePicker.TimePicker
          format={"YYYY-MM-DD HH:mm"}
          size={largeOnMobile()}
          disabled
          value={dayjs(booking.createdAt)}
        />
      </ComponentWithLabel>
      <ComponentWithLabel label="Title">
        <div className={styles.title}>{booking.summary || "No title"}</div>
      </ComponentWithLabel>
      <LoadingBoundry loading={isLoading}>
        {creator && (
          <ComponentWithLabel label="Creator">
            <UserListItem user={creator} disabled />
          </ComponentWithLabel>
        )}
        <ComponentWithLabel label="Participants">
          <div className={styles.participantContainer}>
            {booking.participants.map((p) => {
              const participant = findByEmail(p.email);
              return <UserListItem key={p.email} disabled user={participant} />;
            })}
          </div>
        </ComponentWithLabel>
      </LoadingBoundry>
    </div>
  );
}
