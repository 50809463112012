import { fabric } from "fabric";
import { IPathOptions } from "fabric/fabric-impl";

fabric.Sink = fabric.util.createClass(fabric.Group, {
  type: "sink",
  initialize: function (options: any) {
    const defaults: IPathOptions = {
      fill: "black",
      stroke: "black",
      strokeWidth: 1,
      originX: "center",
      originY: "center",
      height: 100,
      width: 100,
    };
    const fouset = new fabric.Path(
      "M436 156H317l-2-2-31-49-1-1a40 40 0 0 0-66 36l2 16H75c-15 0-27 12-27 27v4a206 206 0 0 0 351 148c41-40 64-95 64-152 0-15-12-27-27-27zm-180-49c6 0 12 2 16 7l31 48 1 2 1 3c0 3-1 7-4 10-4 4-10 5-15 2l-48-31c-3-3-5-7-6-11l-1-6a24 24 0 0 1 25-24zm19 83-9 61c0 5-5 9-10 9-6 0-10-4-11-9l-10-87 40 26zm113 135a188 188 0 0 1-270-3c-35-36-55-84-55-134v-5c0-7 5-12 12-12h146l9 81a26 26 0 0 0 51 1l8-57a27 27 0 0 0 31-25h116c7 0 12 5 12 12 0 53-22 104-60 142z",
      {
        ...defaults,
      }
    );
    const frame = new fabric.Path(
      "M488 68H24C11 68 0 79 0 92v96a254 254 0 0 0 256 255 254 254 0 0 0 255-255V92c0-13-11-24-23-24zm8 120a239 239 0 0 1-240 240A239 239 0 0 1 15 188V92c0-5 4-9 9-9h464c4 0 8 4 8 9v96z",
      defaults
    );
    this.callSuper("initialize", [fouset, frame], {
      snapAngle: 5,
      scaleX: 0.15,
      scaleY: 0.15,
      ...options,
    });
  },

  toObject: function () {
    const obj = fabric.util.object.extend(this.callSuper("toObject"), {});
    const { objects, ...rest } = obj;
    return rest;
  },
});
fabric.Sink.fromObject = function (object, callback) {
  return fabric.Object._fromObject("Sink", object, callback) as any;
};
