import { Button, Tooltip } from "antd";

import {
  IconDesk,
  IconDoor,
  IconHourglassHigh,
  IconHourglassLow,
  IconHourglassOff,
  IconPlus,
  IconUser,
  TablerIconsProps
} from "@tabler/icons-react";
import dayjs from "dayjs";
import { useState } from "react";
import { Outlet, useParams, useSearchParams } from "react-router-dom";
import { IconWithBackground } from "../../../../components/IconWithBackground/IconWithBackground";
import { SearchBar } from "../../../../components/SearchBar";
import { AnimatingListItem } from "../../../../components/common/AnimatingListItem/AnimatingListItem";
import LoadingBoundry from "../../../../components/common/LoadingBoundry/LoadingBoundry";
import {
  EquipmentAssociation,
  EquipmentMinified,
} from "../../../../repo";
import { useEquipmentList } from "../../../../repo/equipment";
import { filterByTerm } from "../../../../utils/arrayUtils";
import { useNav } from "../../../../utils/reactUtils";
import IndicatingList from "../../../common/IndicatingList/IndicatingList";
import ListAndView from "../../../common/ListAndView/ListAndView";
import Scrollable from "../../../common/Scrollable/Scrollable";
import { EquipmentIcon } from "../../users/UserEditView/UserEditView";
import styles from "./EquipmentPage.module.css";
type Props = {};

export default function EquipmentPage({}: Props) {
  return <ListAndView list={<EquipmentListSection />} content={<Outlet />} />;
}

const EquipmentListSection = () => {
  let [searchParams, _] = useSearchParams();
  const nav = useNav();
  const { data, isError, isLoading, refetch } = useEquipmentList();
  let equipmentList = filterByTerm<EquipmentMinified>(
    data,
    searchParams.get("searchTerm")
  );

  return (
    <LoadingBoundry
      loading={isLoading}
      error={isError}
      description="Could not load equipment"
      onAction={refetch}
    >
      <div className={styles.controlsContainer}>
        <SearchBar path={"/equipment"} />
        <Tooltip title={"Add new equipment"} placement="bottom">
          <Button
            type="primary"
            size="large"
            onClick={() => nav("/equipment/new" + window.location.search)}
            icon={<IconPlus />}
          />
        </Tooltip>
      </div>
      <Scrollable>
        <IndicatingList<EquipmentMinified>
          emptyTitle="No equipment found"
          data={equipmentList}
          render={(equipment) => (
            <EquipmentListItem key={equipment.id} equipment={equipment} />
          )}
        />
      </Scrollable>
    </LoadingBoundry>
  );
};

const EquipmentListItem = ({ equipment }: { equipment: EquipmentMinified }) => {
  const nav = useNav();
  const { equipmentId } = useParams();
  const selected = equipmentId === equipment.id;
  const [hovered, setHovered] = useState(false);
  return (
    <AnimatingListItem
      active={selected}
      className={`${styles.equipmentListItemContainer}`}
      onClick={() => {
        nav(`/equipment/${equipment.id}${window.location.search}`);
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <IconWithBackground
        icon={<EquipmentIcon type={equipment.type} />}
        backgroundColor={selected||hovered ? "var(--supplementary-color)": undefined}
      />

      <div className={styles.equipmentListItemContent}>
        <span className={styles.equipmentListItemTitle}>{equipment.name}</span>
      </div>

      <div className={styles.equipmentListClock}>
        <EquipmentAssociationIcon type={equipment.association?.type} />
        <EquipmentExpirationIndicator expiration={equipment.expiration} />
      </div>
    </AnimatingListItem>
  );
};

export const EquipmentExpirationIndicator = ({
  expiration,
}: {
  expiration?: string | Date;
}) => {
  const expirationDay = expiration && dayjs(expiration);
  if (!expirationDay || !expirationDay.isValid()) {
    return <></>;
  }
  if (expirationDay.isBefore(dayjs())) {
    return (
      <span title="Expired">
        <IconHourglassOff color="red" />
      </span>
    );
  }
  if (expirationDay.isBefore(dayjs().add(1, "M"))) {
    return (
      <span title="About to expire">
        <IconHourglassLow />
      </span>
    );
  }
  return (
    <span title="Has expiration">
      <IconHourglassHigh />
    </span>
  );
};
interface EquipmentAssociationIconProps extends TablerIconsProps {
  type?: EquipmentAssociation['type']
}
export const EquipmentAssociationIcon = ({type,
  ...props
}: EquipmentAssociationIconProps) => {
  if (type) {
    switch (type) {
      case "USER":
        return <IconUser {...props} />;
      case "DESK":
        return <IconDesk {...props} />;
      case "ROOM":
        return <IconDoor {...props} />;
      default:
        return <></>;
    }
  }
  return <></>;
};
