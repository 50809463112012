import { Select, SelectProps } from "antd";
import { useEffect, useState } from "react";
import { usePermissionsStore } from "../../../../../context/authContext";
import { UserMinifiedResponse } from "../../../../../repo";
import { useUsers } from "../../../../../repo/users";
import { useDeskContext } from "../../context/deskContext";

interface Props extends SelectProps {
  error?: string;
  onSelect?: (user: UserMinifiedResponse) => void;
  value?: UserMinifiedResponse;
  disabledForRole?: string;
}

export default function DeskUserSelection({
  onSelect,
  disabledForRole,
  ...props
}: Props) {
  const [users, setUsers] = useState<UserMinifiedResponse[]>([]);
  const { data: allUsers } = useUsers();
  const desks = useDeskContext.use.desks();
  useEffect(() => {
    let occupiedUserIds = desks
      .map((desk) => desk.user?.email)
      .filter((id) => id !== undefined);
    let filteredUsers = allUsers?.filter(
      (user) => !occupiedUserIds.includes(user.email)
    );
    setUsers(filteredUsers || []);
  }, [desks, allUsers]);

  const { role } = usePermissionsStore();
  const isDisabled = role === disabledForRole;
  return (
    <UserSelection
      users={users}
      {...props}
      value={props.value?.name}
      disabled={isDisabled}
      onSelect={onSelect}
      error={props.error}
    />
  );
}
interface UserSelectionProps extends SelectProps {
  users?: UserMinifiedResponse[];
  onSelect?: (user: UserMinifiedResponse) => void;
  disabled?: boolean;
  error?: string;
  userFilter?: (user: UserMinifiedResponse) => boolean;
  onUnknownValueEntered?: (email: string) => void;
}

export const UserSelection: React.FC<UserSelectionProps> = ({
  onSelect,
  disabled,
  users,
  userFilter,
  onUnknownValueEntered,
  ...props
}) => {
  const isDisabled = disabled;
  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => {
    return (
      (option?.label ?? "").toLowerCase().includes(input.toLowerCase()) ||
      (option?.value ?? "").toLowerCase().includes(input.toLowerCase())
    );
  };

  const { data: allUsers, isLoading } = useUsers(users === undefined);
  const _users = (users || allUsers || []).filter((u) =>
    u.status !== "DELETED" && userFilter ? userFilter?.(u) : true
  );
  const onUserSelect = (userEmail: string) => {
    const selectedUser = _users?.find((user) => user.email === userEmail);
    selectedUser && onSelect?.(selectedUser);
  };
  const options = _users.map((obj) => {
    return { label: obj.name, value: obj.email };
  });
  return (
    <Select
      showSearch
      placeholder="Select a user"
      disabled={isDisabled || isLoading}
      className={`${props.className} ${isDisabled ? "disabledSelect" : ""}`}
      onSelect={onUserSelect}
      value={props.value}
      {...props}
      onInputKeyDown={(e) => {
        if (e.key === "Enter") {
          const target = e.target as HTMLInputElement;
          const email = target.value;
          const foundOption = options.find((o) => filterOption(email, o));
          if (foundOption) {
            return;
          }
          onUnknownValueEntered?.(email);
        }
      }}
      filterOption={filterOption}
      loading={isLoading}
      options={options}
    />
  );
};

const validateEmail = (email: string) => {
  return email.match(
    // eslint-disable-next-line
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};
