import { Button, Empty, Flex, Result, Spin } from "antd";
import { useCallback, useEffect, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import DisableByRole from "../../../../components/common/DisableByRole";
import FullHeightCentered from "../../../../components/common/FullHeightCentered";
import { usePermissionsStore } from "../../../../context/authContext";
import { useMaps } from "../../../../repo/maps";
import { useMapSelection } from "../../context/mapSelectionContext";

type Props = {};
export default function MapViewNavigator({}: Props) {
  const { mapId } = useParams();
  const { isUser } = usePermissionsStore();
  const {
    data: maps,
    isLoading,
    isError: isMapsError,
  } = useMaps({
    status: isUser() ? "ACTIVE" : undefined,
  });
  const { selectedMapId, selectMap } = useMapSelection();
  const [mapNotFound, setMapNotFound] = useState(false);
  const nav = useNavigate();

  const mapExists = useCallback(
    (mapId: string) => {
      const selectedMap = maps?.find((map) => map.id === mapId);
      return selectedMap !== undefined;
    },
    [maps]
  );

  const navigateToMap = useCallback(
    (mapId?: string) => {
      const selectedMap = maps?.find((map) => map.id === mapId);
      if (selectedMap) {
        nav(`/maps/${selectedMap.id}` + window.location.search);
      } else if (!selectedMap) {
        setMapNotFound(true);
      }
    },
    [maps, nav]
  );
  useEffect(() => {
    if (maps && maps.length > 0) {
      if (!mapId) {
        if (selectedMapId && mapExists(selectedMapId)) {
          navigateToMap(selectedMapId);
        } else {
          navigateToMap(maps[0].id);
        }
      } else {
        const selectedMap = maps.find((map) => map.id === mapId);
        if (!selectedMap) {
          setMapNotFound(true);
        } else {
          setMapNotFound(false);
        }
      }
    }
  }, [maps, mapId, mapExists, navigateToMap, selectedMapId]);
  if (isLoading) {
    return (
      <div className="appContent">
        <FullHeightCentered>
          <Spin />
        </FullHeightCentered>
      </div>
    );
  }
  if (isMapsError) {
    return (
      <div className="appContent">
        <FullHeightCentered>
          <Result
            status={500}
            title="Something wrong happened"
            subTitle="We couldn't fetch maps"
          />
        </FullHeightCentered>
      </div>
    );
  }
  if (mapNotFound) {
    return (
      <div className="appContent">
        <FullHeightCentered>
          <Result
            status={404}
            title={"This map is not found"}
            subTitle={`Map with id ${mapId} does not exist`}
          />
        </FullHeightCentered>
      </div>
    );
  }
  if (maps && maps.length === 0) {
    return (
      <div className="appContent">
        <FullHeightCentered style={{ backgroundColor: "transparent" }}>
          <Flex
            gap={".5rem"}
            vertical
            style={{ padding: "1rem" }}
            className="borderBox"
          >
            <Empty description="There are no maps yet!" />
            <DisableByRole disabledRole="USER">
              <Button type="primary" onClick={() => nav("/maps/new")}>
                Create one
              </Button>
            </DisableByRole>
          </Flex>
        </FullHeightCentered>
      </div>
    );
  }
  return <Outlet />;
}
