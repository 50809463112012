import dayjs, { Dayjs } from "dayjs";
import { fabric } from "fabric";
import { Point } from "fabric/fabric-impl";
import { WhoDeskMap } from "./WhoDeskMap";

declare module "dayjs" {
  interface Dayjs {
    addTime(time: Dayjs): Dayjs;
  }
}
declare global {
  interface String {
    capitalizeFirstLetter(): string;
    trunc(n?: number): string;
    format(args: any): string;
  }
  namespace fabric {
    type RoomEvents =
      | "meetingRoom:selected"
      | "meetingRoom:deselected"
      | "meetingRoom:mouseover"
      | "meetingRoom:mouseout";
    type EntityEvents =
      | "entity:selected"
      | "entity:deselected"
      | "entity:mouseover"
      | "entity:mouseout";
    type InteractableMapEntity = MeetingRoom | Desk;
    type EntityEventHandler = (event: {
      entity: InteractableMapEntity;
    }) => void;
    interface IObservable<T> {
      on(e: "map:reloaded", handler: (map: WhoDeskMap) => void): T;
      on(e: RoomEvents, handler: (room: MeetingRoom) => void): T;
      on(e: EntityEvents, handler: EntityEventHandler): T;
      off(e: EntityEvents, handler: EntityEventHandler): T;
      off(e: RoomEvents, handler: (room: MeetingRoom) => void): T;
      on(
        e: "settings:open",
        handler: (ev: { event: MouseEvent; target: fabric.Object }) => void
      ): T;
      off(e: "map:reloaded", handler: (map: WhoDeskMap) => void): T;
      off(
        e: "settings:open",
        handler: (ev: { event: MouseEvent; target: fabric.Object }) => void
      ): T;
    }
    interface Line {
      lastClipping?: number;
      shouldClip: () => boolean;
      clipped: () => void;
    }
    interface TooltipOptions extends IGroupOptions {
      text?: string;
      meta?: string;
      maxLength?: number;
    }
    class Tooltip extends Group {
      constructor(params: TooltipOptions);
    }
    class Chair extends Path {
      constructor(params?: IPathOptions);
    }
    class Door extends Group {
      constructor(params?: IGroupOptions);
    }
    class Sink extends Group {
      constructor(params?: IGroupOptions);
    }
    class Toilet extends Group {
      constructor(params?: IGroupOptions);
    }
    class Desk extends Group {
      active: boolean;
      id?: string;
      clickable?: boolean;
      associationId?: string;
      setAssociationId: (id?: string) => void;
      setActive: (isActive: boolean) => void;
      setDeskColor: (color: string) => void;
      setChairColor: (color: string) => void;
      makeInteractive: (canvas: Canvas) => void;
      removeHighlights: () => void;
      highlightObjects: (color?: string, width?: number) => void;
      savePreviousValues: () => void;
      prevValues: any;
      resetToValuesBeforeSelection: () => void;
    }
    class MapImage extends Image {
      static fromObject: (object: any, callback: any) => Object;
    }
    class EditMapCircle extends Circle {}
    class Grid extends Object {}
    class EditMapDynamicLineControl extends Circle {}
    class EditMapRectangle extends Rect {}
    class PolygonRect extends Polygon {
      constructor();
    }
    class MeetingRoom extends Polygon {
      valuesBefore: any;
      constructor();
      makeInteractive: () => void;
      id?: string;
      associationId?: string;
      active: boolean;
      setActive: (isActive: boolean) => void;
    }
    class EditMapClearRectangle extends Rect {}
    class EditMapIText extends IText {}
    class EditMapCenterPoint extends Group {}
    class EditMapDynamicLine extends Line {
      addControls: (canvas: Canvas) => void;
    }
    class EditMapLine extends Line {}
  }
}
String.prototype.capitalizeFirstLetter = function () {
  return this.charAt(0).toUpperCase() + this.slice(1).toLowerCase();
};
String.prototype.trunc = function (n = 10000) {
  return this.substring(0, n - 1) + (this.length > n ? "..." : "");
};
String.prototype.format = function () {
  var str = this.toString();
  if (arguments.length) {
    var t = typeof arguments[0];
    var key;
    var args =
      "string" === t || "number" === t
        ? Array.prototype.slice.call(arguments)
        : arguments[0];

    for (key in args) {
      str = str.replace(new RegExp("\\{" + key + "\\}", "gi"), args[key]);
    }
  }

  return str;
};
fabric.Line.prototype.shouldClip = function () {
  if (!this.lastClipping) {
    return true;
  }
  if (new Date().getTime() - this.lastClipping > 500) {
    return true;
  }
  return false;
};
fabric.Line.prototype.clipped = function () {
  this.lastClipping = new Date().getTime();
};
export interface DeskColorHolder {
  deskId: string;
  color: string;
  fill?: "desk" | "chair";
}

export class Rectangle {
  leftTopCorner: Point;
  width: number;
  constructor(centerPoint: Point, width: number) {
    this.leftTopCorner = new fabric.Point(
      centerPoint.x - width / 2,
      centerPoint.y - width / 2
    );
    this.width = width;
  }
  contains(pointer: fabric.Point | undefined) {
    if (!pointer) {
      return false;
    }
    return (
      this.leftTopCorner.x <= pointer.x &&
      this.leftTopCorner.x + this.width >= pointer.x &&
      this.leftTopCorner.y <= pointer.y &&
      this.leftTopCorner.y + this.width >= pointer.y
    );
  }
}