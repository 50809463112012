import { create } from "zustand";
import {
  RoomResponse,
  RoomUnavailability,
} from "../../../../../../repo";
interface RoomBookingStore {
  rooms: RoomResponse[];
  unavailabilities: RoomUnavailability[];
  availableRooms: RoomResponse[];
  setUnavailabilieties(unavailabilities: RoomUnavailability[]): void;
  setRooms(rooms: RoomResponse[]): void;
}
export const useRoomBookingStore = create<RoomBookingStore>()((set, get) => ({
  rooms: [],
  unavailabilities: [],
  availableRooms: [],
  setRooms(rooms) {
    const unavailableIds = get().unavailabilities.map((u) => u.roomId);
    set((state) => ({
      ...state,
      rooms: rooms,
      availableRooms: rooms.filter(
        (r) => r.integrationId !== undefined && !unavailableIds.includes(r.id)
      ),
    }));
  },
  setUnavailabilieties(unavailabilities) {
    const unavailableIds = unavailabilities.map((u) => u.roomId);
    set((state) => ({
      ...state,
      unavailabilities: unavailabilities,
      availableRooms: state.rooms.filter(
        (r) => r.integrationId !== undefined && !unavailableIds.includes(r.id)
      ),
    }));
  },
}));
