import { Button, Flex } from "antd";
import { useNavigate } from "react-router-dom";
import LoadingBoundry from "../../../../components/common/LoadingBoundry/LoadingBoundry";
import { CompanyResponse } from "../../../../repo";
import { useCreateCompany } from "../../../../repo/company";
import CenteredFloatingContainer from "../../../common/CenteredFloatingContainer/CenteredFloatingContainer";
import { CompanyForm } from "../CompanyView/CompanyView";

type Props = {};

export default function CompanyCreate({}: Props) {
  return (
    <LoadingBoundry>
      <CenteredFloatingContainer header={"Create company"}>
        <CompanyBody />
      </CenteredFloatingContainer>
    </LoadingBoundry>
  );
}

const CompanyBody = () => {
  const nav = useNavigate();
  const { mutate: create } = useCreateCompany((company) => {
    nav(`/companies/${company.id}`, { replace: true });
  });
  const submit = (data: CompanyResponse) => {
    create(data);
  };
  return (
    <CompanyForm onSubmit={submit}>
      <Flex justify="space-around">
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </Flex>
    </CompanyForm>
  );
};
