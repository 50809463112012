import { useMutation } from "@tanstack/react-query";
import { AuthProviderInfo, AuthResponse, GoogleLogin, LoginRequest } from ".";
import { axiosClient } from "../config/axios";
import { getHeaders } from "./common";

export const useGoogleAuth = (onSuccess: (data: AuthResponse) => void) => {
  return useMutation<AuthResponse, Error, GoogleLogin>({
    mutationFn: googleLogin,
    onSuccess,
  });
};
export const useLogin = (onSuccess: (data: AuthResponse) => void) => {
  return useMutation<AuthResponse, Error, LoginRequest>({
    mutationFn: login,
    onSuccess,
  });
};

const googleLogin = async (googleLogin: GoogleLogin) => {
  const res = await axiosClient.post<AuthResponse>("/auth/google", googleLogin);
  return res.data;
};
const login = async (login: LoginRequest) => {
  const res = await axiosClient.post<AuthResponse>("/auth/login", login);
  return res.data;
};

export const refreshToken = async (refreshToken?: string) => {
  const res = await axiosClient.get<AuthResponse>("/auth/refresh", {
    headers: { refresh: refreshToken },
  });
  return res.data;
};

export const refreshProviderToken = async (provider:AuthProviderInfo) => {
  const res = await axiosClient.get<AuthResponse>("/auth/provider/refresh", {
    headers: { refresh: provider.refreshToken, ...getHeaders() },
  });
  return res.data;
};
