import { WhoDeskMap } from "./WhoDeskMap";

class MapQueue {
  actionMap = new Map<string, ((map: WhoDeskMap) => void)[]>();
  fire(name: string) {
    const func = this.actionMap.get(name);
    if (func && this.map) {
      func.forEach((f) => f(this.map!));
    }
  }
  on(name: string, func: (map: WhoDeskMap) => void) {
    const oldFnArr = this.actionMap.get(name) || [];
    this.actionMap.set(name, [...oldFnArr, func]);
    return func;
  }
  off(name: string, func: (map: WhoDeskMap) => void) {
    const oldFnArr = (this.actionMap.get(name) || []).filter((f) => f !== func);
    this.actionMap.set(name, oldFnArr);
  }
  remove(func: (map: WhoDeskMap) => void) {
    this.queue = this.queue.filter((f) => f !== func);
    this.reloadQueue = this.reloadQueue.filter((f) => f !== func);
  }
  queue: ((map: WhoDeskMap) => void)[] = [];
  reloadQueue: ((map: WhoDeskMap) => void)[] = [];
  map: WhoDeskMap | undefined;

  add = (func: (map: WhoDeskMap) => void) => {
    if (this.map) {
      func(this.map);
    } else {
      this.queue.push(func);
    }
    return func;
  };
  onReload = (func: (map: WhoDeskMap) => void) => {
    if (!this.reloadQueue.includes(func)) {
      this.reloadQueue.push(func);
    }
    return func;
  };
  fireReadyEvent = (map: WhoDeskMap) => {
    this.map = map;
    this.reloadQueue.forEach((f) => f(map));
    while (this.queue.length > 0) {
      this.queue.shift()?.(this.map);
    }
  };
  dispose() {
    this.map = undefined;
  }
}

export const mapQueue = new MapQueue();
export const whenMapIsReady = (func: (map: WhoDeskMap) => void) => {
  mapQueue.add(func);
};
