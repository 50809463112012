import { Spin } from "antd";
import React, { useMemo } from "react";
import { ErrorIndicator, ErrorIndicatorProps } from "../ErrorIndicator";
import styles from "./LoadingBoundry.module.css";
export interface LoadingBoundryProps extends ErrorIndicatorProps {
  loading?: boolean;
  error?: any;
  children?: React.ReactNode;
}

export default function LoadingBoundry({
  children,
  loading,
  error,
  ...props
}: LoadingBoundryProps) {
  const child = useMemo(
    () => <React.Fragment>{children}</React.Fragment>,
    [children]
  );

  if (loading) {
    return (
      <div className={styles.container}>
        <div className={`${styles.loadingContainer} ${styles.indicator}`}>
          <Spin />
        </div>
        {child}
      </div>
    );
  }
  if (error) {
    return (
      <div className={styles.container}>
        <div className={`${styles.errorContainer} ${styles.indicator}`}>
          <ErrorIndicator {...props} />
        </div>
        {child}
      </div>
    );
  }
  return <>{child}</>;
}
