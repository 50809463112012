import { WhoDeskMap } from "./WhoDeskMap";

class MapHolder {
  dispose() {
    this.current?.dispose();
    this.current = undefined;
  }
  current?: WhoDeskMap;
  getMap() {
    return this.current!;
  }
}

export const editMapHolder = new MapHolder();
export const viewMapHolder = new MapHolder();
