import {
  IconCheck,
  IconCircleCheck,
  IconInfoCircle,
  IconX,
} from "@tabler/icons-react";
import { ButtonProps } from "antd";
import { ReactNode, useState } from "react";
import ButtonWithIcon, { ButtonWithIconProps } from "../ButtonWithIcon";
import LoadingBoundry, {
  LoadingBoundryProps,
} from "../LoadingBoundry/LoadingBoundry";
import { ModalDialog } from "../ModalDialog/ModalDialog";
import styles from "./ConfirmationDialog.module.css";
export const ConfirmationDialog = ({
  children,
  onConfirm,
  primaryButtonProps,
  secondaryButtonProps,
  style,
  open,
  title,
  ...props
}: {
  children?: React.ReactNode;
  onConfirm?: () => void;
  primaryButtonProps?: ButtonProps;
  secondaryButtonProps?: ButtonProps;
  style?: React.CSSProperties;
  open?: boolean;
  title?: string;
} & Partial<ConfirmDialogProps>) => {
  const [_open, setOpen] = useState(open || false);
  const toggleModal = () => {
    setOpen(!_open);
  };
  const onYes = () => {
    toggleModal();
    onConfirm?.();
  };
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        toggleModal();
      }}
      style={style}
    >
      {children}
      <ConfirmDialog
        open={_open}
        onClose={toggleModal}
        onConfirm={onYes}
        primaryButtonProps={primaryButtonProps}
        secondaryButtonProps={secondaryButtonProps}
        title={title}
        {...props}
      />
    </div>
  );
};
export interface ConfirmDialogProps extends DialogWithButtonsProps {
  type?: "confirm" | "info" | "danger";
  subHeading?: React.ReactNode;
  heading?: React.ReactNode;
}
export const ConfirmDialog = ({
  type = "confirm",
  heading,
  subHeading,
  ...props
}: ConfirmDialogProps) => {
  let icon = <IconCircleCheck className={styles.checkIcon} />;
  if (type === "info") {
    icon = <IconInfoCircle className={styles.infoIcon} />;
  }
  if (type === "danger") {
    icon = <IconInfoCircle className={styles.dangerIcon} />;
  }
  return (
    <DialogWithButtons {...props}>
      <div className={styles.iconContainer}>
        <div className={styles.innerIconContainer}>{icon}</div>
      </div>
      <div className={styles.headingContainer}>{heading}</div>
      {subHeading && (
        <div className={styles.subHeadingContainer}>{subHeading}</div>
      )}
    </DialogWithButtons>
  );
};
export interface DialogWithButtonsProps {
  open: boolean;
  onClose: () => void;
  onConfirm?: () => void;
  cancelText?: string;
  confirmText?: string;
  primaryButtonProps?: ButtonWithIconProps;
  secondaryButtonProps?: ButtonWithIconProps;
  title?: string;
  state?: LoadingBoundryProps;
  children?: ReactNode
}
export const DialogWithButtons = ({
  open,
  onClose,
  onConfirm,
  cancelText = "No",
  confirmText = "Yes",
  primaryButtonProps,
  secondaryButtonProps,
  title = "Confirmation",
  state,
  children,
}: DialogWithButtonsProps) => {
  return (
    <ModalDialog open={open} onClose={onClose} title={title}>
      <LoadingBoundry {...state}>
        <div className={styles.container}>
          {children}
          <div className={styles.buttonContainer}>
            <ButtonWithIcon
              icon={<IconX className={styles.buttonIcon} />}
              location="before"
              variant="default"
              onClick={onClose}
              className={styles.denyButton}
              size={"large"}
              {...secondaryButtonProps}
            >
              {cancelText}
            </ButtonWithIcon>
            <ButtonWithIcon
              icon={<IconCheck className={styles.buttonIcon} />}
              location="before"
              variant="primary"
              size={"large"}
              onClick={onConfirm}
              {...primaryButtonProps}
            >
              {confirmText}
            </ButtonWithIcon>
          </div>
        </div>
      </LoadingBoundry>
    </ModalDialog>
  );
};

export interface AlertDialogOptions {
  content: React.ReactNode;
  title: string;
  image?: string | React.ReactNode;
  buttonText?: string;
  buttonProps?: ButtonWithIconProps;
  withoutButton?: boolean;
  onClose?: () => void;
  onAction?: () => void;
}
export const AlertDialog = ({
  open,
  onConfirm,
  primaryButtonProps,
  options,
}: {
  open: boolean;
  onConfirm?: () => void;
  primaryButtonProps?: ButtonProps;
  options?: AlertDialogOptions;
}) => {
  const confirm = () => {
    options?.onAction?.();
    onConfirm?.();
  };
  const close = () => {
    options?.onClose?.();
    onConfirm?.();
  };
  return (
    <ModalDialog open={open} onClose={close} title={options?.title}>
      <div className={styles.container}>
        {options?.image && (
          <div className={styles.image}>
            {typeof options.image == "string" ? (
              <img
                className={styles.image}
                alt="alertImage"
                src={options.image}
              />
            ) : (
              options.image
            )}
          </div>
        )}
        <div className={styles.headingContainer}>{options?.content}</div>
        {!options?.withoutButton && (
          <div className={styles.buttonContainer}>
            <ButtonWithIcon onClick={confirm} {...options?.buttonProps}>
              {options?.buttonText || "OK"}
            </ButtonWithIcon>
          </div>
        )}
      </div>
    </ModalDialog>
  );
};
