import { IconBook, IconQrcode, IconTrash } from "@tabler/icons-react";
import { Button, Flex, Spin, Tooltip } from "antd";
import dayjs from "dayjs";
import { Desk } from "fabric/fabric-impl";
import { useEffect } from "react";
import {
  FormProvider,
  useForm,
  useFormContext,
  useWatch,
} from "react-hook-form";
import {
  createSearchParams,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { ConfirmDialog } from "../../../../../components/common/ConfirmationDialog/ConfirmationDialog";
import DisableByRole from "../../../../../components/common/DisableByRole";
import { EquipmentSection } from "../../../../../components/common/EquipmentSection/EquipmentSection";
import { ErrorIndicator } from "../../../../../components/common/ErrorIndicator";
import FullHeightCentered from "../../../../../components/common/FullHeightCentered";
import { HiddenAction } from "../../../../../components/common/HiddenAction";
import NavigationBanner from "../../../../../components/common/NavigationBanner/NavigationBanner";
import { PlatformButton } from "../../../../../components/common/PlatformButton/PlatformButton";
import QrCodeAction from "../../../../../components/common/QrCodeAction/QrCodeAction";
import { usePermissionsStore } from "../../../../../context/authContext";
import { DeskResponse, GetBookingsRequestParams } from "../../../../../repo";
import {
  useDeleteDesk,
  useDesk,
  useUpdateDesk,
  useUpdateDeskTags,
} from "../../../../../repo/desks";
import { useMapStatus } from "../../../../../repo/maps";
import { removeUndefined } from "../../../../../utils/arrayUtils";
import { BOOKABLE_CHAIR_COLOR } from "../../../../../utils/colorUtils";
import { largeOnMobile } from "../../../../../utils/reactUtils";
import { useCurrentDesk, useUserCanBookDesk } from "../../../hooks";
import ReportIssueModal from "../../../issues/ReportIssueModal/ReportIssueModal";
import { useDeskContext } from "../../context/deskContext";
import { useMapEntityContext } from "../../context/mapEntityContext";
import DeskForm from "../common/DeskForm";
import styles from "./MapDeskView.module.css";
import { DeskBookingAction } from "./components/DeskBookingAction/DeskBookingAction";
import ExtendedDeskBookingAction from "./components/ExtendedDeskBookingAction/ExtendedDeskBookingAction";
import TagSection from "./components/TagSection/TagSection";
type Props = {};

export default function EditMapDeskView({}: Props) {
  const { deskId, mapId } = useParams();
  const { role } = usePermissionsStore();
  const formMethods = useForm<DeskResponse>({
    defaultValues: { user: null as any, isBookable: null as any },
  });
  const selectedEntity = useMapEntityContext.use.selectedEntity?.();
  const setSelectedEntityId = useMapEntityContext.use.setSelectedEntityId?.();
  const setEntityColor = useMapEntityContext.use.setEntityColor?.();
  const bookings = useDeskContext.use.bookings();
  const {
    data: desk,
    isError: isDeskLoadError,
    isLoading,
  } = useDesk(mapId, deskId);

  const deskBooking = bookings.find((d) => d.deskId === desk?.id);
  useEffect(() => {
    if (desk) {
      setSelectedEntityId(desk.mapDeskId);
      const deskCopy = { ...desk };
      if (deskBooking) {
        deskCopy.user = deskBooking.user;
      }
      formMethods.setValue("equipment", []);
      formMethods.setValue("isBookable", null as any);
      formMethods.reset(deskCopy);
      formMethods.setValue("isBookable", deskCopy.isBookable);
    }
  }, [deskBooking, formMethods, desk, setSelectedEntityId]);

  let nav = useNavigate();
  const selectedDesk = selectedEntity as Desk;
  let isBookable = useWatch({
    control: formMethods.control,
    name: `isBookable`,
  });
  const teamColor = useWatch({
    control: formMethods.control,
    name: `user.teamColor`,
  });

  useEffect(() => {
    const isBookable = formMethods.getValues().isBookable;
    if (isBookable) {
      setEntityColor([
        { color: "white", type: "desk" },
        { color: BOOKABLE_CHAIR_COLOR, type: "chair" },
      ]);
    } else {
      setEntityColor([
        { color: teamColor ? teamColor : "grey", type: "desk" },
        { color: "white", type: "chair" },
      ]);
    }
  }, [
    teamColor,
    selectedDesk,
    formMethods,
    isBookable,
    deskId,
    setEntityColor,
  ]);

  useEffect(() => {
    if (role === "USER") {
      return;
    }
    if (isBookable) {
      formMethods.setValue("user", undefined);
      formMethods.clearErrors("user");
    } else {
      formMethods.setValue("user", desk?.user);
      formMethods.clearErrors("user");
    }
  }, [isBookable, desk, formMethods, role]);
  if (isLoading) {
    return (
      <FullHeightCentered>
        <Spin />
      </FullHeightCentered>
    );
  }
  if (isDeskLoadError) {
    return (
      <FullHeightCentered>
        <ErrorIndicator
          description={`Could not find the desk with id ${deskId}`}
          action="Go back"
          onAction={() => nav(`/maps/${mapId}/desks`)}
        />
      </FullHeightCentered>
    );
  }
  return (
    <FormProvider {...formMethods}>
      <form className={styles.formContainer} id="deskEditForm">
        <DeskNavigationBanner />
        <DeskForm />
        <DeskTagSection />
        <EquipmentSection
          assocId={{ deskId: desk?.id, mapId: mapId }}
          disabled={role === "USER"}
        />
        <div className={styles.buttonContainer}>
          <DisableByRole disabledRole="USER">
            <Flex>
              <DeskUpdateButton desk={desk} />
            </Flex>
          </DisableByRole>
          <DisableByRole enabledRole="USER">
            <BookNowButton enabled={desk?.isBookable} />
          </DisableByRole>
          <ReportIssueModal
            metadata={{ mapId: mapId, deskId: deskId }}
            issueType="DESK"
          >
            Report desk issue
          </ReportIssueModal>
        </div>
      </form>
    </FormProvider>
  );
}

const DeskTagSection = () => {
  const { mapId } = useParams();
  const formMethods = useFormContext<DeskResponse>();
  const desk = formMethods.watch();
  const { mutate: updateTags } = useUpdateDeskTags();
  const onChange = (tags: string[]) => {
    if (mapId) {
      updateTags({ tags, deskId: desk.id, mapId });
    }
  };
  const { isHr } = usePermissionsStore();
  const shouldDisplay = (desk.tags?.length || 0) > 0 || isHr();
  return (
    <>
      {shouldDisplay && (
        <div className={styles.tagSection}>
          <TagSection tags={desk.tags} onChange={onChange} editable={isHr()} />
        </div>
      )}
    </>
  );
};
const BookNowButton = ({ enabled }: { enabled?: boolean }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const openExtendedBooking = () => {
    searchParams.set("action", "extendedBooking");
    setSearchParams(searchParams);
  };
  const openInstantBooking = () => {
    searchParams.set("action", "booking");
    setSearchParams(searchParams);
  };
  const { canBook, reason, reasonCode } = useUserCanBookDesk();
  const options = [];
  const hasBooking = reasonCode === "BOOKING_PRESENT";
  if (!hasBooking && canBook) {
    options.push({
      content: "Book for a different date",
      onClick: openExtendedBooking,
    });
  }
  return (
    <>
      {enabled && (
        <Tooltip title={reason}>
          <PlatformButton
            variant="primary"
            disabled={!canBook && reasonCode !== "BOOKING_PRESENT"}
            onClick={hasBooking ? openExtendedBooking : openInstantBooking}
            size={largeOnMobile()}
            options={options}
          >
            {hasBooking ? "Book for later" : "Book now"}
          </PlatformButton>
        </Tooltip>
      )}
    </>
  );
};

const bookingDeletionAction = "bookingDeletion";
const bookingUpdateAction = "bookingUpdate";
const DeskUpdateButton = ({ desk }: { desk?: DeskResponse }) => {
  const { mapId } = useParams();
  const { trigger, getValues } = useFormContext<DeskResponse>();
  const [searchParams, setSearchParams] = useSearchParams();
  const openDialog = () => {
    searchParams.set("action", bookingUpdateAction);
    setSearchParams(searchParams);
  };
  const { mutate: updateMutation, isPending: isUpdating } = useUpdateDesk();
  const onSubmit = async () => {
    const valid = await trigger();
    if (valid) {
      const data = getValues();
      updateMutation(
        {
          deskId: desk?.id!,
          desk: {
            userEmail: data.user?.email,
            equipment: data.equipment,
            name: data.name,
          },
          mapId: mapId!,
        },
        {
          onError(error, variables, context) {
            if (error.response?.data.code === "DESK_BOOKINGS_PRESENT") {
              openDialog();
            }
          },
        }
      );
    }
  };
  return (
    <>
      <Button
        style={{ flex: 3 }}
        form="deskEditForm"
        onClick={onSubmit}
        type="primary"
        loading={isUpdating}
        size={largeOnMobile()}
      >
        Update
      </Button>
    </>
  );
};

const DeskDeleteAction = () => {
  const { mapId } = useParams();
  const { desk } = useCurrentDesk();
  const nav = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const openDialog = () => {
    searchParams.set("action", bookingDeletionAction);
    setSearchParams(searchParams);
  };

  const { mutate: deleteDesk, isPending: isDeleting } = useDeleteDesk();

  const onDelete = async () => {
    deleteDesk(
      { desk: desk!, mapId: mapId! },
      {
        onSuccess() {
          nav(-1);
        },
        onError(error) {
          if (error.response?.data.code === "DESK_BOOKINGS_PRESENT") {
            openDialog();
          }
        },
      }
    );
  };
  return (
    <ConfirmDialog
      heading="Are you sure you want to delete this desk?"
      subHeading="This cannot be undone"
      type="danger"
      onConfirm={onDelete}
      primaryButtonProps={{ danger: true, variant: "primary" }}
      confirmText="Delete"
      open={true}
      onClose={() => {
        searchParams.delete("action");
        setSearchParams(searchParams);
      }}
    />
  );
};

const DeskActionConfirmationDialog = () => {
  const { mapId, deskId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const onClose = () => {
    searchParams.delete("action");
    setSearchParams(searchParams);
  };
  const nav = useNavigate();
  const params: GetBookingsRequestParams = {
    mapId,
    deskId,
    pageSize: 1,
    dateFrom: dayjs().format("YYYY-MM-DD"),
  };
  const navigateToBookingView = () => {
    nav({
      pathname: "/bookings",
      search: createSearchParams(
        removeUndefined({
          ...params,
          pageSize: undefined,
        }) as any
      ).toString(),
    });
  };
  const action = searchParams.get("action");

  const open =
    action && [bookingUpdateAction, bookingDeletionAction].includes(action);
  const title = (() => {
    if (open) {
      if (action === bookingDeletionAction) {
        return "Desk delete issue";
      }
      if (action === bookingUpdateAction) {
        return "Desk update issue";
      }
    }
  })();
  const description = (() => {
    if (action === bookingDeletionAction) {
      return "This desk has bookings, cancel them before deleting it";
    }
    if (action === bookingUpdateAction) {
      return "This desk has bookings, cancel them before updating it to permanent desk";
    }
  })();
  return (
    <>
      <ConfirmDialog
        type="info"
        title={title}
        open={true}
        onClose={onClose}
        onConfirm={navigateToBookingView}
        heading="Desk has bookings"
        subHeading={description}
        primaryButtonProps={{ icon: undefined }}
        confirmText="Go to desk bookings"
        cancelText="Go back"
      />
    </>
  );
};

const DeskNavigationBanner = () => {
  const { mapId } = useParams();
  const { role } = usePermissionsStore();
  const [searchParams, setSearchParams] = useSearchParams();
  const mapStatus = useMapStatus(mapId);
  const formMethods = useFormContext<DeskResponse>();
  const desk = formMethods.watch();
  let menuOptions: any = [];
  if (role !== "USER") {
    if (desk?.isBookable && mapStatus === "ACTIVE") {
      menuOptions = [
        ...menuOptions,
        {
          key: "2",
          label: "Book",
          icon: <IconBook />,
          onClick: () => {
            searchParams.set("action", "extendedBooking");
            setSearchParams(searchParams);
          },
        },
      ];
    }
    menuOptions = [
      ...menuOptions,
      {
        key: "1",
        label: "Generate QR",
        icon: <IconQrcode />,
        onClick: () => {
          desk?.name && searchParams.set("qrTitle", desk?.name);
          searchParams.set("action", "qrCode");
          setSearchParams(searchParams);
        },
      },
      {
        key: "3",
        icon: <IconTrash />,
        label: "Delete",
        onClick: () => {
          searchParams.set("action", "deleteDesk");
          setSearchParams(searchParams);
        },
        danger: true,
      },
    ];
  }

  return (
    <>
      <NavigationBanner
        name={desk?.name || "Desk"}
        prevRoute={`/maps/${mapId}/desks`}
        options={menuOptions}
      />
      <DeskActions />
    </>
  );
};

const DeskActions = () => {
  const formMethods = useFormContext<DeskResponse>();
  const name = formMethods.watch("name");
  return (
    <>
      <HiddenAction action="qrCode">
        <QrCodeAction
          modalTitle="Desk QR"
          title={name}
          value={
            window.location.href.replace(window.location.search, "") +
            "?action=booking"
          }
        />
      </HiddenAction>
      <HiddenAction action="extendedBooking">
        <ExtendedDeskBookingAction />
      </HiddenAction>
      <HiddenAction action="booking">
        <DeskBookingAction />
      </HiddenAction>
      <HiddenAction action="deleteDesk">
        <DeskDeleteAction />
      </HiddenAction>
      <HiddenAction action="bookingDeletion">
        <DeskActionConfirmationDialog />
      </HiddenAction>
      <HiddenAction action="bookingUpdate">
        <DeskActionConfirmationDialog />
      </HiddenAction>
    </>
  );
};
