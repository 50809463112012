import { Button } from "antd";
import dayjs from "dayjs";
import { createSearchParams, useNavigate, useParams } from "react-router-dom";
import { ModalDialog } from "../../../../../../components/common/ModalDialog/ModalDialog";
import { GetBookingsRequestParams } from "../../../../../../repo";
import { removeUndefined } from "../../../../../../utils/arrayUtils";
import styles from './MapUpdateErrorDialog.module.css';
export const MapUpdateErrorDialog:React.FC<{
  open: boolean;
  onClose: () => void;
}> = ({
  open,
  onClose,
}) => {

  const { mapId } = useParams();
  const nav = useNavigate();
  const params: GetBookingsRequestParams = {
    mapId,
    pageSize: 1,
    dateFrom: dayjs().format("YYYY-MM-DD"),
  }
  const navigateToBookingView = () => {
    nav({
      pathname: "/bookings",
      search: createSearchParams(
        removeUndefined({
          ...params,
          pageSize: undefined,
        }) as any
      ).toString(),
    });
  }
  return ( <ModalDialog title={"Map update error"} open={open} onClose={onClose}>
      <div className={styles.container}>
        <div>
          {"Map has active bookings. Cancel them before changing map type"}
        </div>
        <Button onClick={navigateToBookingView}>Go to map bookings</Button>
      </div>
    </ModalDialog>
  );
};
