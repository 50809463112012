import {
  IconChevronLeft,
  IconChevronRight,
  IconDoor,
} from "@tabler/icons-react";
import { DatePicker } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BehindPermission } from "../../../../../components/common/BehindPermission/BehindPermission";
import ButtonWithIcon from "../../../../../components/common/ButtonWithIcon";
import LoadingBoundry from "../../../../../components/common/LoadingBoundry/LoadingBoundry";
import NavigationBanner from "../../../../../components/common/NavigationBanner/NavigationBanner";
import { PlatformButton } from "../../../../../components/common/PlatformButton/PlatformButton";
import { RoomResponse } from "../../../../../repo";
import { useRoomBookings } from "../../../../../repo/rooms";
import { DATE_FORMAT } from "../../../../../utils/constants";
import { mapQueue } from "../../../../../utils/map/mapQueue";
import { useNav } from "../../../../../utils/reactUtils";
import { useDialogContext } from "../../../DialogProvider/DialogProvider";
import { useCurrentRoom } from "../../../hooks";
import { dateFromAdjusted } from "../../bookings/rooms/RoomAvailability/RoomAvailability";
import { useDateContext } from "../../context/dateContext";
import styles from "./BookMapRoom.module.css";
import CalendarContainer from "./components/CalendarContainer/CalendarContainer";
import EventInfoDialog from "./components/EventInfoDialog/EventInfoDialog";
type Props = {};
export interface DateInterval {
  from: Dayjs;
  to: Dayjs;
}
export default function BookMapRoom({}: Props) {
  const { room, isLoading, error, mapId } = useCurrentRoom();
  const nav = useNav();
  useEffect(() => {
    if (room) {
      if (room.integrationId) {
        const activate = mapQueue.add((map) => {
          map.selectMapObject(room.mapRoomId);
        });
        return () => {
          mapQueue.remove(activate);
        };
      } else {
        nav(`/maps/${mapId}/rooms/${room.id}`, { replace: true });
      }
    }
  }, [room, mapId, nav]);
  return (
    <LoadingBoundry
      loading={isLoading}
      error={error}
      description="Failed to load room"
    >
      {room?.integrationId && (
        <>
          <RoomBookingNavBanner />
          <BehindPermission permission="VIEW_ROOM_SCHEDULE">
            <BookMapRoomBody room={room} />
          </BehindPermission>
        </>
      )}
    </LoadingBoundry>
  );
}
function BookMapRoomBody({ room }: { room?: RoomResponse }) {
  const dateFrom = dateFromAdjusted(dayjs());
  const dateTo = dateFrom.add(30, "m");
  const { mapId, roomId } = useParams();
  const selectedDate = useDateContext.use.date();
  const [dateInterval, setDateInterval] = useState<DateInterval | undefined>({
    from: dateFrom,
    to: dateTo,
  });
  useEffect(() => {
    if (dateInterval && !dateInterval.from.isSame(selectedDate, "day")) {
      setDateInterval(undefined);
    }
  }, [selectedDate, dateInterval]);
  const { data: roomBookings = [] } = useRoomBookings({
    roomId,
    mapId,
    dateFrom: selectedDate
      .utc()
      .set("date", selectedDate.date())
      .startOf("day")
      .toISOString(),
    dateTo: selectedDate
      .utc()
      .set("date", selectedDate.date())
      .endOf("day")
      .toISOString(),
  });
  const { show } = useDialogContext();
  const nav = useNav();
  const navToRoomBooking = () => {
    if (!dateInterval) {
      return;
    }
    const params = new URLSearchParams({
      date: selectedDate.format(DATE_FORMAT),
      timeFrom: dateInterval?.from.toISOString(),
      timeTo: dateInterval?.to.toISOString(),
    }).toString();
    nav(`/maps/${mapId}/bookings/rooms/${room?.id}?${params}`);
  };
  const onEntryClick = (id: string) => {
    roomBookings
      .filter((b) => b.id === id)
      .forEach((b) => {
        show({
          content: <EventInfoDialog booking={b} />,
          title: "Event info",
          withoutButton: true,
        });
      });
  };
  return (
    <>
      <CalendarContainer
        date={selectedDate}
        onRangeSelected={setDateInterval}
        existingBookings={roomBookings}
        selectedRange={dateInterval}
        onEntryClick={onEntryClick}
      />
      {dateInterval && (
        <PlatformButton
          variant={"primary"}
          className={styles.bookNowButton}
          onClick={navToRoomBooking}
        >
          Book now
        </PlatformButton>
      )}
    </>
  );
}

const RoomBookingNavBanner = () => {
  const { mapId } = useParams();
  const { room } = useCurrentRoom();
  const options = [];
  const nav = useNavigate();
  options.push({
    key: "1",
    label: "Check room details",
    icon: <IconDoor />,
    onClick: () => {
      nav(`/maps/${mapId}/rooms/${room?.id}`);
    },
  });
  return <NavigationBanner name={room?.name || "Room"} options={options} />;
};
const RoomDatePicker = ({
  date,
  onChange,
}: {
  date: Dayjs;
  onChange: (date: Dayjs) => void;
}) => {
  const backDisabled = date.subtract(1, "day").isBefore(dayjs(), "d");
  const onBack = () => {
    onChange(date.subtract(1, "day"));
  };
  const onForward = () => {
    onChange(date.add(1, "day"));
  };
  return (
    <div className={styles.datePickerContainer}>
      <ButtonWithIcon
        disabled={backDisabled}
        onClick={onBack}
        icon={<IconChevronLeft />}
        size="large"
      />
      <DatePicker
        size="large"
        className={styles.datePicker}
        value={date}
        onChange={(date) => onChange(date)}
        allowClear={false}
        minDate={dayjs()}
        format={"YYYY-MM-DD (dddd)"}
      />
      <ButtonWithIcon
        onClick={onForward}
        size="large"
        icon={<IconChevronRight />}
      />
    </div>
  );
};
