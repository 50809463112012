import { Button, Flex } from "antd";
import { useProviderAuthenticationStore } from "../../../context/authContext";
import { useProviderLogin } from "../../../routes/common/hooks";
import FullHeightCentered from "../FullHeightCentered";
import styles from "./BehindPermission.module.css";
const PERMISSION_MAP = [
  {
    name: "VIEW_ROOM_SCHEDULE",
    googleScope: "https://www.googleapis.com/auth/calendar.events.freebusy",
  },
  {
    name: "EVENT_CREATION",
    googleScope: "https://www.googleapis.com/auth/calendar.events.owned",
  },
];
export const BehindPermission = ({
  children,
  permission,
  description,
  emptyOnNoIntegration = false,
}: {
  children: React.ReactNode;
  permission: string;
  description?: string;
  emptyOnNoIntegration?: boolean;
}) => {
  const requiredPermission = PERMISSION_MAP.find((p) => p.name === permission);

  const { providerAuth } = useProviderAuthenticationStore();
  const hasNoPermission =
    requiredPermission &&
    !providerAuth?.scopes.includes(requiredPermission.googleScope);
  const login = useProviderLogin(requiredPermission?.googleScope);
  if (!requiredPermission) {
    throw Error("no such permission " + permission);
  }
  if (!providerAuth) {
    return (
      <>
        {emptyOnNoIntegration ? (
          <></>
        ) : (
          <FullHeightCentered>
            <Flex vertical>
              <span>You need single sign-on to enable this feature</span>
            </Flex>
          </FullHeightCentered>
        )}
      </>
    );
  }
  const requestForPermission = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    login();
  };
  if (hasNoPermission) {
    return (
      <FullHeightCentered>
        <div className={styles.container}>
          <span>
            {description || `We require some permissions to continue`}
          </span>
          <Button type="primary" onClick={requestForPermission}>
            Give permission
          </Button>
        </div>
      </FullHeightCentered>
    );
  }
  return <>{children}</>;
};
