import { InteractableMapEntity } from "fabric/fabric-impl";
import { create } from "zustand";
import { subscribeWithSelector } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import { mapService } from "../../../../services/map/MapService";
import { createSelectors } from "../../../../utils/reactUtils";
export type DeskColor = {
  color: string;
  type: "chair" | "desk";
};
type State = {
  hoveredEntityId?: string;
  selectedEntityId?: string;
  selectedEntity?: InteractableMapEntity;
  entityColor?: DeskColor[];
};

type Actions = {
  reset: () => void;
  setHoveredEntityId: (hoveredEntityId?: string) => void;
  setSelectedEntityId: (selectedEntityId?: string) => void;
  setSelectedEntity: (selectedEntity?: InteractableMapEntity) => void;
  setEntityColor: (colors: DeskColor[]) => void;
};

const initialState: State = {
  selectedEntity: undefined,
  selectedEntityId: undefined,
  hoveredEntityId: undefined,
  entityColor: undefined,
};

const useMapEntityContextInner = create(
  subscribeWithSelector(
    immer<State & Actions>((set) => ({
      ...initialState,
      reset: () => {
        set(initialState);
      },
      setHoveredEntityId(hoveredEntityId) {
        set((state) => {
          state.hoveredEntityId = hoveredEntityId;
        });
      },
      setSelectedEntityId(selectedEntityId) {
        set((state) => {
          state.selectedEntityId = selectedEntityId;
        });
      },
      setSelectedEntity(selectedEntity) {
        set((state) => {
          state.selectedEntity = selectedEntity as any;
        });
      },
      setEntityColor(color) {
        set((state) => {
          state.entityColor = color;
        });
      },
    }))
  )
);

export const useMapEntityContext = createSelectors(useMapEntityContextInner);

useMapEntityContext.subscribe(
  (state) => state.hoveredEntityId,
  (hoveredEntityId) => {
    mapService.hoverEntity(hoveredEntityId);
  }
);

useMapEntityContext.subscribe(
  (state) => state,
  (state) => {
    if (
      state.entityColor &&
      state.selectedEntity &&
      state.selectedEntity instanceof fabric.Desk
    ) {
      mapService.colorDesk(state.selectedEntity, ...state.entityColor);
    }
  }
);

useMapEntityContext.subscribe(
  (state) => state.selectedEntity,
  (current, prev) => {
    if (current !== prev && prev instanceof fabric.Desk) {
      mapService.resetValues(prev);
    }
  }
);

useMapEntityContext.subscribe(
  (state) => state.selectedEntityId,
  (selectedEntityId) => {
    mapService.selectEntity(selectedEntityId);
  }
);
