import { Button, Divider, Flex } from "antd";
import { Desk } from "fabric/fabric-impl";
import { useCallback, useEffect } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import NavigationBanner from "../../../../../components/common/NavigationBanner/NavigationBanner";
import { DeskResponse } from "../../../../../repo";
import { useCreateDesk } from "../../../../../repo/desks";
import { BOOKABLE_CHAIR_COLOR } from "../../../../../utils/colorUtils";
import { useMapEntityContext } from "../../context/mapEntityContext";
import DeskForm from "../common/DeskForm";
import styles from "./CreateMapDeskView.module.css";
type Props = {};

export default function CreateMapDeskView({}: Props) {
  const nav = useNavigate();
  const { mapId } = useParams();
  const desk = useMapEntityContext.use.selectedEntity?.() as Desk | undefined;
  const entityColor = useMapEntityContext.use.entityColor?.();
  const setEntityColor = useMapEntityContext.use.setEntityColor();
  const formMethods = useForm<DeskResponse>({
    defaultValues: { isBookable: true },
  });
  const { mutate: saveDeskMutation, isPending } = useCreateDesk((resp) => {
    desk?.setAssociationId(resp.id);
    nav(`/maps/${mapId}/desks/${resp.id}`, { replace: true });
  });

  const teamColor = useWatch({
    control: formMethods.control,
    name: `user.teamColor`,
  });

  const isBookable = useWatch({
    control: formMethods.control,
    name: `isBookable`,
  });
  const deskName = formMethods.watch("name");
  const changeDeskColor = useCallback(() => {
    if (!isBookable) {
      setEntityColor([
        { color: teamColor || "gray", type: "desk" },
        { color: "white", type: "chair" },
      ]);
    } else {
      setEntityColor([
        { color: "white", type: "desk" },
        { color: BOOKABLE_CHAIR_COLOR, type: "chair" },
      ]);
    }
  }, [teamColor, isBookable, setEntityColor]);
  useEffect(() => {
    if (!desk || desk === null) {
      nav(`/maps/${mapId}/desks`);
    } else {
      changeDeskColor();
    }
  }, [desk, mapId, nav, changeDeskColor]);

  const onSubmit = async (data: DeskResponse) => {
    if (desk) {
      saveDeskMutation({
        mapId: mapId!,
        desk: { mapDeskId: desk.id!, userEmail: data.user?.email },
      });
    }
  };
  useEffect(() => {
    changeDeskColor();
  }, [teamColor, changeDeskColor]);
  useEffect(() => {
    if (isBookable) {
      formMethods.setValue("user", undefined);
      formMethods.clearErrors("user");
    }
    changeDeskColor();
  }, [isBookable, changeDeskColor, formMethods]);
  return (
    <div>
      <NavigationBanner
        name={deskName || "New Desk"}
        prevRoute={`/maps/${mapId}/desks`}
      />
      <FormProvider {...formMethods}>
        <form
          className={styles.formContainer}
          onSubmit={formMethods.handleSubmit(onSubmit)}
        >
          <div>
            <DeskForm noName/>
            <div style={{ paddingInline: "1rem" }}>
              <Divider />
              <Flex>
                <Button
                  style={{ flex: 3 }}
                  htmlType="submit"
                  type="primary"
                  loading={isPending}
                >
                  Create
                </Button>
              </Flex>
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
}
