import { IconCopy, IconExternalLink } from "@tabler/icons-react";
import { Button, DatePicker, Flex, Input, Switch, Tag, message } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { useParams, useSearchParams } from "react-router-dom";
import { BehindPermission } from "../../../../../../components/common/BehindPermission/BehindPermission";
import { AlertDialog } from "../../../../../../components/common/ConfirmationDialog/ConfirmationDialog";
import {
  ComponentWithLabel,
  CustomInputText,
} from "../../../../../../components/common/CustomInputText/CustomInputText";
import Explanation from "../../../../../../components/common/Explanation/Explanation";
import { HiddenAction } from "../../../../../../components/common/HiddenAction";
import LoadingBoundry from "../../../../../../components/common/LoadingBoundry/LoadingBoundry";
import NavigationBanner from "../../../../../../components/common/NavigationBanner/NavigationBanner";
import MeetingSvg from "../../../../../../components/svgs/MeetingSvg";
import {
  CreateRoomBookingRequest,
  UserMinifiedResponse,
} from "../../../../../../repo";
import { useCreateRoomBooking, useRoom } from "../../../../../../repo/rooms";
import {
  addTime,
  largeOnMobile,
  useNav,
} from "../../../../../../utils/reactUtils";
import BorderDivider from "../../../../BorderDivider/ListDivider";
import { UserSelection } from "../../../components/UserSelection/UserSelection";
import { RoomListItem } from "../../../desks/MapDeskList/MapDeskList";
import styles from "./RoomBooking.module.css";
type Props = {};

export default function RoomBooking({}: Props) {
  const { roomId, mapId } = useParams();
  const nav = useNav();
  const [searchParams, setSearchParams] = useSearchParams();
  const [dates, setDates] = useState<Dayjs[]>();
  const { data: room, isLoading } = useRoom(mapId, roomId);
  useEffect(() => {
    const selectedDate = dayjs(searchParams.get("date"));
    const timeFrom = dayjs(searchParams.get("timeFrom"));
    const timeTo = dayjs(searchParams.get("timeTo"));

    if (!selectedDate.isValid() || !timeFrom.isValid() || !timeTo.isValid()) {
      nav(`/maps/${mapId}/bookings/rooms`, { replace: true });
    }
    setDates([addTime(selectedDate, timeFrom), addTime(selectedDate, timeTo)]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);
  const formMethods = useForm<CreateRoomBookingRequest>({
    defaultValues: {
      dateFrom: dates?.[0].toISOString(),
      dateTo: dates?.[1].toISOString(),
      withNotification: true,
      conference: true,
    },
  });
  const { mutate: createRoomBooking } = useCreateRoomBooking();
  const onSubmit = (data: CreateRoomBookingRequest) => {
    if (!mapId || !roomId || !dates) {
      return;
    }
    createRoomBooking(
      {
        mapId,
        roomId,
        request: {
          ...data,
          dateFrom: dates[0].toISOString(),
          dateTo: dates[1].toISOString(),
        },
      },
      {
        onSuccess(data, variables, context) {
          Object.entries(data).forEach((e) => {
            searchParams.set(e[0], e[1]);
          });
          searchParams.set("action", "bookingSuccess");
          setSearchParams(searchParams, { replace: true });
        },
        onError(error, variables, context) {
          if (error.response?.data.code === "ROOM_BOOKINGS_PRESENT") {
            searchParams.set("action", "bookingPresent");
            setSearchParams(searchParams, { replace: true });
          }
        },
      }
    );
  };
  return (
    <FormProvider {...formMethods}>
      <form
        onSubmit={formMethods.handleSubmit(onSubmit)}
        className={styles.container}
      >
        <LoadingBoundry loading={isLoading}>
          <NavigationBanner name={"Room booking"} />
          <BehindPermission
            permission="EVENT_CREATION"
            description="We require some permissions to create room booking"
          >
            <div className={styles.fieldsContainer}>
              {room && <RoomListItem room={room} />}
            </div>
            <div className={styles.fieldsContainer}>
              <ComponentWithLabel label="Booking date" required>
                <DatePicker.RangePicker
                  disabled
                  size={largeOnMobile()}
                  showTime
                  format={"YYYY-MM-DD HH:mm"}
                  value={dates ? [dates[0], dates[1]] : null}
                />
              </ComponentWithLabel>
              <ComponentWithLabel label="Title">
                <Controller
                  name="title"
                  render={({ field, fieldState }) => {
                    return (
                      <CustomInputText
                        {...field}
                        size={largeOnMobile()}
                        placeholder="Meeting title"
                        error={fieldState.error?.message}
                      />
                    );
                  }}
                  rules={{ maxLength: 250 }}
                />
              </ComponentWithLabel>
              <ParticipantSection />
              <ComponentWithLabel label="Description">
                <Controller
                  name="description"
                  render={({ field }) => {
                    return (
                      <Input.TextArea
                        {...field}
                        size={largeOnMobile()}
                        placeholder="Meeting description"
                      />
                    );
                  }}
                />
              </ComponentWithLabel>
              <ComponentWithLabel label="Options">
                <BorderDivider />
                <div className={styles.optionsContainer}>
                  <Controller
                    name="conference"
                    render={({ field }) => {
                      return (
                        <Switch
                          checked={field.value}
                          onChange={(e) => field.onChange(e)}
                          checkedChildren={"Online"}
                          unCheckedChildren={"Offline"}
                        />
                      );
                    }}
                  />
                  <Explanation
                    description={
                      'If "Online" will create an online meeting link'
                    }
                  />
                  <Controller
                    name="withNotification"
                    render={({ field }) => {
                      return (
                        <Switch
                          checked={field.value}
                          onChange={(e) => field.onChange(e)}
                          checkedChildren={"Notify"}
                          unCheckedChildren={"Silent"}
                        />
                      );
                    }}
                  />
                  <Explanation
                    description={
                      'If "Notify" will notify participants about the meeting'
                    }
                  />
                </div>
              </ComponentWithLabel>
            </div>
            <div className={styles.buttonContainer}>
              <Button type="primary" htmlType="submit">
                Book
              </Button>
            </div>
          </BehindPermission>
        </LoadingBoundry>
      </form>
      <RoomBookingActions />
    </FormProvider>
  );
}

const RoomBookingActions = () => {
  return (
    <>
      <HiddenAction action="bookingPresent">
        <BookingPresentAction />
      </HiddenAction>
      <HiddenAction action="bookingSuccess">
        <BookingSuccessAction />
      </HiddenAction>
    </>
  );
};

const BookingPresentAction = () => {
  const nav = useNav();
  const { mapId } = useParams();
  return (
    <AlertDialog
      options={{
        title: "Booking present",
        content: "Booking is already present for selected dates, please retry",
      }}
      onConfirm={() => nav(`/maps/${mapId}/bookings/rooms`)}
      open
    />
  );
};
const BookingSuccessAction = () => {
  const nav = useNav();
  const { mapId } = useParams();

  const [searchParams, setSearchParams] = useSearchParams();
  const htmlLink = searchParams.get("htmlLink");
  const conferenceLink = searchParams.get("conferenceLink");
  return (
    <AlertDialog
      options={{
        title: "Success",
        image: <MeetingSvg />,
        content: (
          <div className={styles.bookingSuccessContainer}>
            Room booked successfully
            <span>
              <>Event link: </>
              <CopyContainer>{htmlLink}</CopyContainer>
            </span>
            {conferenceLink && (
              <span>
                <>Conference link: </>
                <CopyContainer>{conferenceLink}</CopyContainer>
              </span>
            )}
          </div>
        ),
        buttonText: "Go back to map",
      }}
      onConfirm={() => nav(`/maps/${mapId}`, { replace: true })}
      open
    />
  );
};

const CopyContainer = ({ children }: { children: string | null }) => {
  if (!children) {
    return <></>;
  }
  const isLink = children.includes("https");
  const onClick = () => {
    navigator.clipboard.writeText(children);
    message.success("Text copied!", 1);
  };

  return (
    <div className={styles.copyContainer}>
      <span className={styles.copyContainerText}>{children}</span>
      <Flex gap={".2rem"}>
        <Button
          type="primary"
          icon={<IconCopy />}
          onClick={onClick}
          title="Copy"
        />
        {isLink && (
          <a href={children} rel="noreferrer" target="_blank">
            <Button
              type="primary"
              icon={<IconExternalLink />}
              title="Go to link"
            />
          </a>
        )}
      </Flex>
    </div>
  );
};

const ParticipantSection = () => {
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray<
    Partial<CreateRoomBookingRequest>
  >({
    name: "participants",
  });
  const [selectedUsers, setSelectedUsers] = useState<UserMinifiedResponse[]>(
    []
  );
  const addUser = (user: UserMinifiedResponse) => {
    append({ email: user.email });
    setSelectedUsers([...selectedUsers, user]);
  };
  const removeUser = (user: UserMinifiedResponse) => {
    remove(fields.findIndex((f) => f.id === user.email));
    setSelectedUsers(selectedUsers.filter((u) => u.email !== user.email));
  };

  return (
    <ComponentWithLabel label="Participants">
      <UserSelection
        value={null}
        size={largeOnMobile()}
        onSelect={addUser}
        placeholder="Select participants"
        userFilter={(u) =>
          selectedUsers.find((user) => user.email === u.email) === undefined
        }
        onUnknownValueEntered={(email) => {
          const emailIsValid = validateEmail(email);
          if (emailIsValid) {
            addUser({ email: email, name: email });
          } else {
            message.error("Input is not an email");
          }
        }}
      />

      <div className={styles.tagContainer}>
        {selectedUsers.map((u) => (
          <Tag closable onClose={() => removeUser(u)} key={u.email}>
            {u.name.trunc(50)}
          </Tag>
        ))}
      </div>
    </ComponentWithLabel>
  );
};
const validateEmail = (email: string) => {
  return email.match(
    // eslint-disable-next-line
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};
export const FormEntry = ({
  name,
  children,
  required,
}: {
  name: string;
  required?: boolean;
  children: React.ReactNode;
}) => {
  return (
    <>
      <div
        className={`${styles.formEntryTitle} ${
          required ? styles.required : ""
        }`}
      >
        {name}
      </div>
      <div className={styles.formEntryContainer}>{children}</div>
    </>
  );
};
