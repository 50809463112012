import { IconChevronDown } from "@tabler/icons-react";
import { uniqueId } from "lodash";
import React, { ReactNode, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import styles from "./PlatformButton.module.css";
export interface PlatformButtonProps
  extends React.HTMLAttributes<HTMLButtonElement> {
  variant?: "primary" | "default" | "link";
  disabled?: boolean;
  size?: "large" | "middle" | "small";
  options?: Option[];
  className?: string;
  danger?: boolean;
}
type Option = { content: React.ReactNode; onClick: () => void };
export const PlatformButton = ({
  options = [],
  className,
  size = "middle",
  variant = "primary",
  danger,
  ...props
}: PlatformButtonProps) => {
  return (
    <button
      type="button"
      {...props}
      className={`${styles.button} ${className || ""} ${size} ${variant}${
        danger ? " danger" : ""
      }`}
    >
      {props.children}
      {options.length > 0 && <OptionContainer options={options} />}
    </button>
  );
};

const OptionContainer = ({
  options,
}: {
  options: NonNullable<PlatformButtonProps["options"]>;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (open) {
      const close = () => {
        setOpen(false);
      };
      document.addEventListener("click", close);
      return () => {
        document.removeEventListener("click", close);
      };
    }
  }, [open]);
  return (
    <>
      <div
        className={styles.optionContainer}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        ref={ref}
      >
        <div onMouseEnter={() => setOpen(true)}>
          <IconChevronDown className={`${open ? styles.open : ""}`} />
        </div>

        {ref.current && open && (
          <Portal>
            <div
              onMouseLeave={() => setOpen(false)}
              className={`${styles.options} ${open ? styles.open : ""}`}
              style={{
                left: `${
                  ref.current?.getBoundingClientRect().left -
                  ref.current?.getBoundingClientRect().width
                }px`,
                top: `${ref.current?.getBoundingClientRect().top}px`,
              }}
            >
              {options.map((o) => (
                <OptionComp
                  key={uniqueId()}
                  o={o}
                  onClick={() => {
                    o.onClick();
                    setOpen(false);
                  }}
                />
              ))}
            </div>
          </Portal>
        )}
      </div>
    </>
  );
};

export const Portal = ({ children }: { children: ReactNode }) => {
  const [container] = React.useState(() => {
    const el = document.createElement("div");
    el.classList.add("root-portal");
    return el;
  });

  React.useEffect(() => {
    document.body.appendChild(container);
    return () => {
      document.body.removeChild(container);
    };
  }, [container]);

  return createPortal(children, container);
};
const OptionComp = ({ o, onClick }: { o: Option; onClick: () => void }) => {
  return (
    <div
      className={styles.option}
      onMouseUp={() => {
        onClick();
      }}
    >
      {o.content}
    </div>
  );
};
