import { Desk, EntityEventHandler } from "fabric/fabric-impl";
import { DeskResponse } from "../../repo";
import {
  DeskColor,
  useMapEntityContext,
} from "../../routes/common/MapViewPage/context/mapEntityContext";
import { useMapTooltip } from "../../routes/common/MapViewPage/navigator/components/MapViewTooltip";
import { WhoDeskMap } from "../../utils/map/WhoDeskMap";
import { MapColorer } from "./colorer/MapColorer";
import { MapHomeColorer } from "./colorer/MapHomeColorer";
import { MapHomeNavigator } from "./navigator/MapHomeNavigator";
import { MapNavigator } from "./navigator/MapNavigator";
type QueueFunction = (map: WhoDeskMap) => void;
export class MapService {
  queue: QueueFunction[] = [];
  navigator: MapNavigator = new MapHomeNavigator();
  colorer: MapColorer = new MapHomeColorer();
  map?: WhoDeskMap;

  selectEntity(selectedEntityId?: string) {
    this.addToQueue((map) => {
      if (selectedEntityId) {
        map.selectMapObject(selectedEntityId);
      } else {
        map.deselectObjects();
      }
    });
  }

  colorDesks(desks: DeskResponse[]) {
    this.addToQueue((map) => {
      this.colorer.colorDesks(map, desks);
    });
  }

  colorDesk(selectedDesk: Desk, ...colors: DeskColor[]) {
    this.addToQueue((map) => {
      colors.forEach((color) => {
        if (color.type === "chair") {
          selectedDesk.setChairColor(color.color);
        } else {
          selectedDesk.setDeskColor(color.color);
        }
      });
      map.renderAll();
    });
  }
  resetValues(desk: Desk) {
    this.addToQueue((map) => {
      desk.resetToValuesBeforeSelection();
      map.renderAll();
    });
  }

  hoverEntity(hoveredEntityId?: string) {
    if (hoveredEntityId) {
      this.addToQueue((map) => {
        map.fire("entity:mouseover", {
          entity: map.getObjectById(hoveredEntityId),
        });
      });
    } else {
      this.addToQueue((map) => {
        map.fire("entity:mouseout");
      });
    }
  }

  addToQueue(fn: QueueFunction) {
    if (this.map) {
      fn(this.map);
    } else {
      this.queue.push(fn);
    }
  }

  unsubscribe() {
    this.map = undefined;
  }

  subscribe(map: WhoDeskMap) {
    this.map = map;
    map.on("entity:mouseover", ({ entity }) => {
      if (!entity.canvas) {
        return;
      }
      useMapTooltip.getState().show({
        canvasObject: entity,
      });
    });
    map.on("entity:mouseout", ({ entity }) => {
      useMapTooltip.getState().hide();
    });
    map.on("entity:selected", this.onSelect);
    map.on("entity:deselected", this.onDeselect);
    while (this.queue.length > 0) {
      this.queue.shift()?.(this.map);
    }
  }

  onSelect: EntityEventHandler = ({ entity }) => {
    this.map?.clearActiveObjectHighlights();
    this.map?.activateObject(entity.id);
    this.hoverEntity(entity.id);
    if (!entity) {
      return;
    }
    useMapEntityContext.getState().setSelectedEntity(entity);
    if (entity.type === "desk") {
      this.navigator.navigateToDesk(entity);
    }
    if (entity.type === "meetingRoom") {
      this.navigator.navigateToRoom(entity);
    }
  };

  onDeselect: fabric.EntityEventHandler = ({ entity }) => {
    this.map?.clearActiveObjectHighlights();
    this.hoverEntity();
    useMapEntityContext.getState().setSelectedEntity(undefined);
  };
}
export const mapService = new MapService();
