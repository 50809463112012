import { fabric } from "fabric";

fabric.EditMapRectangle = fabric.util.createClass(fabric.Rect, {
    type: "editMapRectangle",
    initialize: function (options: any) {
      this.callSuper("initialize", {
        stroke: "black",
        width: 100,
        height: 100,
        fill: "transparent",
        strokeUniform: true,
        snapAngle: 5,
        ...options,
      });
    },
  });
  fabric.EditMapRectangle.fromObject = function (object: any, callback?: any) {
    return fabric.Object._fromObject("EditMapRectangle", object, callback) as any;
  };