import { AxiosError } from "axios";
import { ErrorResponse } from "../repo";

export const parseError = (
  error: AxiosError<ErrorResponse> | null
): string | undefined => {
  if (!error) {
    return;
  }
  if (!error.response?.data.code) {
    return;
  }
  switch (error.response?.data.code) {
    case "USER_EXISTS":
      return "User with such email already exists";
    default:
      return "Unknown error";
  }
};
