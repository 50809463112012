import _ from "lodash";
import { create } from "zustand";
import { persist } from "zustand/middleware";

const defaultTheme = {
  primary: "#4C88BC",
  secondary: "#3652AD",
  primaryTextColor: "#fff",
  secondaryTextColor: "#000",
  tertiary: "#00A5D7",
  supplementary: "#ff9f00",
  metadataColor: "rgba(0, 0, 0, 0.45)",
};

const defaultSettings = {
  theme: defaultTheme,
};
export interface CompanySettingsState {
  settings: typeof defaultSettings;
  setThemeValue: (key: keyof typeof defaultTheme, value: string) => void;
  resetTheme: (theme?: CompanySettingsState["settings"]["theme"]) => void;
  reset: (settings?: CompanySettingsState["settings"]) => void;
}
export const useCompanySettings = create<CompanySettingsState>()(
  persist(
    (set, get) => ({
      settings: defaultSettings,
      setThemeValue(key, value) {
        const newTheme = { ...get().settings.theme };
        newTheme[key] = value;
        const newSettings = { ...get().settings, theme: newTheme };
        set((state) => ({
          ...state,
          settings: newSettings,
        }));
      },
      resetTheme(theme) {
        const newSettings = { ...get().settings, theme: theme || defaultTheme };
        set((state) => ({
          ...state,
          settings: newSettings,
        }));
      },
      reset(settings?: CompanySettingsState["settings"]) {
        const newSettings = _.merge({}, defaultSettings, settings);
        set((state) => ({
          ...state,
          settings: { ...newSettings },
        }));
      },
    }),
    { name: "companySettings" }
  )
);
