import { usePermissionsStore } from "../../../context/authContext";
import { DeskResponse, RoomResponse } from "../../../repo";
import { getDeskColor } from "../../../utils/colorUtils";
import { DeskColorHolder } from "../../../utils/map/customTypes";
import { WhoDeskMap } from "../../../utils/map/WhoDeskMap";
import { MapColorer } from "./MapColorer";

export class MapHomeColorer implements MapColorer {
  colorDesks = (map: WhoDeskMap, desks: DeskResponse[]) => {
    map.clearDeskColors();
    let colors = desks.map((desk) => {
      let color: DeskColorHolder = {
        deskId: desk.mapDeskId,
        color: getDeskColor(desk),
        fill: desk.user ? "desk" : "chair",
      };
      return color;
    });
    map.colorDesks(colors);
    if (usePermissionsStore.getState().isUser()) {
      map.disableAllExcept(colors.map((c) => c.deskId));
    }
    map.renderAll();
  };
  colorRooms = (map: WhoDeskMap, rooms: RoomResponse[]) => {};
}
