import { Button, Result } from "antd";
import { useNavigate } from "react-router-dom";
import FullHeightCentered from "../../../components/common/FullHeightCentered";

type Props = {};

export default function ErrorPage({}: Props) {
  const nav = useNavigate();
  return (
    <FullHeightCentered>
      <Result
        status={404}
        title="Page not found"
        subTitle={<Button onClick={() => nav("/")}>Go home</Button>}
      />
    </FullHeightCentered>
  );
}
