import { useMutation, useQuery } from "@tanstack/react-query";
import { message } from "antd";
import { AxiosError } from "axios";
import { CompanyResponse, CreateCompanyRequest, ErrorResponse } from ".";
import { axiosClient } from "../config/axios";
import {
  invalidateQueries,
  persistentQueryClient
} from "../config/useQueryClientConfig";
import { toMilliseconds } from "../utils/dateUtils";
import { getCompanyId, getHeaders } from "./common";

export const useCompanies = () => {
  return useQuery({
    queryKey: ["companies"],
    queryFn: getCompanies,
    staleTime: Infinity,
  });
};

export const useCreateCompany = (
  onSuccess?: (data: CompanyResponse) => void
) => {
  return useMutation({
    mutationFn: createCompany,
    onSuccess(data) {
      onSuccess?.(data);
      invalidateQueries({ queryKey: ["companies"] });
    },
  });
};

export const useUpdateCompany = (
  onSuccess?: (data: CompanyResponse) => void
) => {
  const msgKey = "companyUpdate";
  return useMutation({
    mutationFn: updateCompany,
    onMutate() {
      message.open({
        type: "loading",
        content: "Updating company",
        key: msgKey,
      });
    },
    onSuccess(data) {
      onSuccess?.(data);
      invalidateQueries({ queryKey: ["companies"] });
      message.open({
        type: "success",
        content: "Updated company",
        key: msgKey,
      });
    },
    onError: () => {
      message.open({
        type: "error",
        content: "Could not update company",
        key: msgKey,
      });
    },
  });
};

export const useCompany = (id?: string) => {
  return useQuery(
    {
      queryKey: ["companies", id],
      queryFn: () => getCompany(id!),
      enabled: id !== undefined,
      staleTime: toMilliseconds({ minutes: 10 }),
    },
    persistentQueryClient
  );
};

export const useCompanyLogoUpload = () => {
  const msgKey = "companyLogo";
  return useMutation<unknown, AxiosError<ErrorResponse>, { file: Blob }>({
    mutationFn: ({ file }) => uploadCompanyLogo(file),
    onError(error, variables, context) {
      message.open({
        type: "error",
        content: "Could not update logo",
        key: msgKey,
      });
    },
  });
};

export const useCompanyLogoDelete = () => {
  return useMutation<unknown, AxiosError<ErrorResponse>, void>({
    mutationFn: () => deleteCompanyLogo(),
  });
};

const getCompanies = async () => {
  const res = await axiosClient.get<CompanyResponse[]>(`/companies`, {
    headers: getHeaders(),
  });
  return res.data;
};

const createCompany = async (company: CreateCompanyRequest) => {
  const res = await axiosClient.post<CompanyResponse>(`/companies`, company, {
    headers: getHeaders(),
  });
  return res.data;
};

const updateCompany = async (company: CompanyResponse) => {
  const res = await axiosClient.put<CompanyResponse>(
    `/companies/${company.id}`,
    company,
    {
      headers: getHeaders(),
    }
  );
  return res.data;
};

const getCompany = async (id: string) => {
  const res = await axiosClient.get<CompanyResponse>(`/companies/${id}`, {
    headers: getHeaders(),
  });
  return res.data;
};

const uploadCompanyLogo = async (file: Blob) => {
  const id = getCompanyId();
  let formData = new FormData();
  formData.set("file", file);
  const res = await axiosClient.post<CompanyResponse>(
    `/companies/${id}/logo`,
    formData,
    {
      headers: { ...getHeaders(), "Content-Type": "multipart/form-data" },
    }
  );
  return res.data;
};

const deleteCompanyLogo = async () => {
  const id = getCompanyId();
  const res = await axiosClient.delete<CompanyResponse>(
    `/companies/${id}/logo`,
    {
      headers: { ...getHeaders() },
    }
  );
  return res.data;
};
