import dayjs from "dayjs";
import { ChartEntry } from "../../../../repo";

export const CALC_TYPES = ["SUM", "AVG"];
export const getMonthlyValues = (
  values: ChartEntry[],
  reducerMode: (typeof CALC_TYPES)[number] = "SUM",
  format: string = "MMMM"
) => {
  let monthNames = Array.from(
    new Set(
      values
        .map((c) => dayjs(c.x))
        .filter((d) => d.isValid())
        .sort((a, b) => a.month() - b.month())
        .map((d) => d.format(format))
    )
  );
  return monthNames.map((dayName) => {
    const count = values
      .filter((c) => {
        let date = dayjs(c.x);
        return date.isValid() && date.format(format) === dayName;
      })
      .map((c) => c.y)
      .reduce(reducer(reducerMode), 0);
    return { x: dayName, y: count };
  });
};
export const getWeeklyValues = (values: ChartEntry[], reducerMode:(typeof CALC_TYPES)[number]) => {
  let dayNames = Array.from(
    new Set(
      values
        .map((c) => dayjs(c.x))
        .filter((d) => d.isValid())
        .sort((a, b) => a.weekday() - b.weekday())
        .map((d) => d.format("dddd"))
    )
  );
  return dayNames.map((dayName) => {
    const count = values
      .filter((c) => {
        let date = dayjs(c.x);
        return date.isValid() && date.format("dddd") === dayName;
      })
      .map((c) => c.y)
      .reduce(reducer(reducerMode), 0);
    return { x: dayName, y: count };
  });
};

function reducer(type: (typeof CALC_TYPES)[number]) {
  return (acc: any, value: any, index: any, array: any) => {
    var calculatedValue = acc + value;
    if (type === "SUM") {
      return calculatedValue;
    }

    if (index === array.length - 1) {
      return calculatedValue / array.length;
    }

    return calculatedValue;
  };
}
