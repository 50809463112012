import { EditOutlined } from "@ant-design/icons";
import { ColorPicker } from "antd";
import { ColorFactory } from "antd/es/color-picker/color";
import { getTextColorRGB } from "../../../../utils/colorUtils";
import styles from "./TeamColorPicker.module.css";

export const TeamColorPicker: React.FC<{
  value: string;
  onChange: (hex: string) => void;
}> = (props) => {
  let rgb = new ColorFactory(props.value).toRgb();
  let textColor = getTextColorRGB(rgb.r, rgb.g, rgb.b);

  const btnStyle: React.CSSProperties = {
    backgroundColor: props.value,
    color: textColor,
  };

  return (
    <>
      <div className={styles.teamColorButton}>
        <div className={styles.chooseColorText} style={btnStyle}>
          Team color
        </div>
        <ColorPicker
          value={props.value}
          onChange={(clr, hex) => props.onChange(hex)}
          disabledAlpha
        >
          <button type="button" className={styles.colorPickButtonContainer}>
            <EditOutlined />
          </button>
        </ColorPicker>
      </div>
    </>
  );
};
