import { ConfirmDialog } from "../../../../../../components/common/ConfirmationDialog/ConfirmationDialog";

export const cannotBookADeskDialog = ({
  reason,
  onClose,
}: {
  reason?: string;
  onClose: () => void;
}) => {
  return (
    <ConfirmDialog
      type="danger"
      title="Desk booking"
      heading="You cannot book the desk"
      subHeading={reason}
      cancelText="Cancel"
      primaryButtonProps={{ hidden: true }}
      open={true}
      onClose={onClose}
    />
  );
};
