import { Result, Spin } from "antd";
import CenteredEmptyPlaceholder from "../../../components/common/CenteredEmptyPlaceholder";
import FullHeightCentered from "../../../components/common/FullHeightCentered";

import styles from "./IndicatingList.module.css";
interface IndicatingListProps<T> extends React.HTMLAttributes<HTMLDivElement> {
  data?: T[];
  isLoading?: boolean;
  isError?: boolean;
  render: (obj: T) => React.ReactNode;
  footer?: React.ReactNode;
  errorTitle?: string;
  emptyTitle?: string;
}
export default function IndicatingList<T>({
  data = [],
  isLoading,
  isError,
  render,
  errorTitle,
  emptyTitle,
  footer,
  ...props
}: IndicatingListProps<T>) {
  if (isLoading) {
    return (
      <FullHeightCentered>
        <Spin />
      </FullHeightCentered>
    );
  }
  if (isError) {
    return (
      <FullHeightCentered>
        <Result
          status={500}
          title="Something wrong happened"
          subTitle={errorTitle || "Could not fetch data"}
        />
      </FullHeightCentered>
    );
  }
  if (data.length === 0) {
    return (
      <CenteredEmptyPlaceholder description={emptyTitle || "No data found"} />
    );
  }
  return (
    <div {...props} className={`${styles.listContainer} ${props.className}`}>
      {data.map(render)}
      {footer}
    </div>
  );
}
