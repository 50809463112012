import { useEffect, useRef } from "react";
import {
  To,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { create } from "zustand";

type Props = {
  name: string;
  children: React.ReactNode;
};
interface RouteContextState {
  routeContexts: Map<string, To>;
  setContext: (name: string, context: To) => void;
}
const useRouteContext = create<RouteContextState>((set, get) => ({
  routeContexts: new Map(),
  setContext(name, context) {
    const routeContexts = get().routeContexts;

    routeContexts.set(name, context);
    set((state) => ({
      ...state,
      routeContexts: state.routeContexts.set(name, context),
    }));
  },
}));

export default function RouteMemo({ name, children }: Props) {
  const nav = useNavigate();
  const { routeContexts, setContext } = useRouteContext();
  const location = useLocation();
  const firstMount = useRef(true);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const context = routeContexts.get(name);
    const isRoot =
      location.pathname.split("/").filter((p) => p.length > 0).length === 1; //tab is clicked so the path is root
    if (
      firstMount.current &&
      context &&
      isRoot &&
      location.search.length === 0
    ) {
      nav(context, { replace: true });
    }
    firstMount.current = false;
  }, [name, nav, routeContexts, location]);

  useEffect(() => {
    return () => {
      firstMount.current = true;
    };
  }, [children]);

  useEffect(() => {
    setContext(name, {
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  }, [searchParams, location, setContext, name]);

  return <>{children}</>;
}
