import { Flex } from "antd";
import { fabric } from "fabric";
import { useParams } from "react-router-dom";
import { create } from "zustand";
import { BookingUnavailability } from "../../../../../repo";
import { useRooms } from "../../../../../repo/rooms";
import { UserAvatar } from "../../../../hr/users/UsersViewPage/UsersViewPage";
import { useDeskContext } from "../../context/deskContext";
import styles from "./MapViewTooltip.module.css";
type Props = {};

export default function MapViewTooltip({}: Props) {
  const { options } = useMapTooltip();
  if (!options) {
    return <></>;
  }

  const { canvasObject, unavailableBooking } = options;
  const center = findCenter(canvasObject.getCoords());
  let left = center.x;
  let top = center.y;
  switch (canvasObject.type) {
    case "meetingRoom":
      return (
        <MeetingRoomTooltip assocId={canvasObject.id} top={top} left={left} />
      );
    case "desk":
      const dimensions = findDimensions(canvasObject);
      top = top - dimensions.height / 2;
      return (
        <DeskTooltip
          unavailableBooking={unavailableBooking}
          assocId={canvasObject.id}
          top={top}
          left={left}
        />
      );
  }
  return <></>;
}

const BaseTooltip = ({
  children,
  top,
  left,
}: { children: React.ReactNode } & Omit<TooltipOpts, "assocId">) => {
  return (
    <div style={{ top, left }} className={`${styles.tooltip} ${styles.bottom}`}>
      {children}
    </div>
  );
};

type TooltipOpts = {
  assocId?: string;
  top: number;
  left: number;
};

const MeetingRoomTooltip = ({ assocId, top, left }: TooltipOpts) => {
  const { mapId } = useParams();
  const { data: rooms = [] } = useRooms(mapId);
  const foundRoom = rooms.find((r) => r.mapRoomId === assocId);
  if (foundRoom) {
    return (
      <BaseTooltip top={top} left={left}>
        {foundRoom?.name}
      </BaseTooltip>
    );
  }
  return <></>;
};
const DeskTooltip = ({
  assocId,
  top,
  left,
  unavailableBooking,
}: { unavailableBooking?: BookingUnavailability } & TooltipOpts) => {
  const  filteredDesks  = useDeskContext.use.filteredDesks();
  const foundDesk = filteredDesks.find((d) => d.mapDeskId === assocId);
  if (!foundDesk) {
    return null;
  }
  const user = unavailableBooking?.user || foundDesk.user;
  return (
    <BaseTooltip top={top} left={left}>
      <Flex gap={"var(--spacing-sm)"}>
        {user && (
          <div>
            <UserAvatar identifier={user.email} src={user.photoUrl} />
          </div>
        )}
        <div>
          <div className={styles.title}>{foundDesk?.name}</div>
          <div className={styles.metadata}>{user?.name}</div>
        </div>
      </Flex>
      {unavailableBooking && (
        <div
          className={styles.metadata}
        >{`${unavailableBooking.dateFrom} - ${unavailableBooking.dateTo}`}</div>
      )}
    </BaseTooltip>
  );
};

interface MapTooltipOptions {
  canvasObject: fabric.MeetingRoom | fabric.Desk;
  unavailableBooking?: BookingUnavailability;
}
interface MapTooltipState {
  options?: MapTooltipOptions;
  show: (opt: MapTooltipOptions) => void;
  hide: () => void;
}
export const useMapTooltip = create<MapTooltipState>()((set, get) => ({
  show(opt) {
    set((state) => ({ ...state, options: opt }));
  },
  hide() {
    set((state) => ({ ...state, options: undefined }));
  },
}));

function findCenter(points: fabric.Point[]) {
  if (points.length !== 4) {
    throw new Error("There must be exactly four points");
  }

  // Calculate the sum of the x-coordinates and y-coordinates
  let sumX = 0;
  let sumY = 0;

  for (let i = 0; i < points.length; i++) {
    sumX += points[i].x;
    sumY += points[i].y;
  }

  // Calculate the average of the x-coordinates and y-coordinates
  let centerX = sumX / 4;
  let centerY = sumY / 4;

  return new fabric.Point(centerX, centerY);
}

function findDimensions(obj: fabric.Object) {
  const points = obj.getCoords();
  if (points.length !== 4) {
    throw new Error("There must be exactly four points");
  }

  const topLeft = points[0];
  const topRight = points[1];
  const bottomRight = points[2];
  const bottomLeft = points[3];
  // Calculate the width as the distance between the top-left and top-right points
  const width = Math.abs(bottomRight.x - topLeft.x);

  // Calculate the height as the distance between the top-left and bottom-left points
  const height = Math.abs(bottomRight.y - topLeft.y);
  const isOnSide = (Math.round(obj.angle || 0) / 90) % 2 > 0;
  return !isOnSide
    ? { width: height, height: width }
    : { width: width, height: height };
}
