import { fabric } from "fabric";
import { IPathOptions } from "fabric/fabric-impl";

fabric.Toilet = fabric.util.createClass(fabric.Group, {
  type: "toilet",
  initialize: function (options: any) {
    const defaults: IPathOptions = {
      fill: "black",
      stroke: "black",
      strokeWidth: 1,
      originX: "center",
      originY: "center",
      height: 100,
      width: 100,
    };

    const door = new fabric.Path(
      "M423.5 0h-336C74.5 0 64 10.5 64 23.5v64c0 21.4 9.4 40.6 24.4 53.7-.3.7-.4 1.5-.4 2.3v200C88 435.9 163.1 511 255.5 511S423 435.9 423 343.5v-200c0-.8-.1-1.6-.4-2.3A71.3 71.3 0 0 0 447 87.5v-64c0-13-10.5-23.5-23.5-23.5zM408 343.5c0 84-68.4 152.5-152.5 152.5S103 427.6 103 343.5V151.2a71 71 0 0 0 32.5 7.8h240a71 71 0 0 0 32.5-7.8v192.3zm24-256a56.6 56.6 0 0 1-56.5 56.5h-240A56.6 56.6 0 0 1 79 87.5v-64c0-4.7 3.8-8.5 8.5-8.5h336c4.7 0 8.5 3.8 8.5 8.5v64z",
      {
        ...defaults,
      }
    );
    const hinge = new fabric.Path(
      "M303.5 192h-96c-39.4 0-71.5 32-71.5 71.5v76.8c0 31.3 11.6 61.2 32.7 84.2a117 117 0 0 0 86.9 38.5c30.9 0 60-11.6 82.4-33 23.9-22.8 37-53.5 37-86.5v-80c0-39.4-32-71.5-71.5-71.5zM360 343.5c0 28.8-11.5 55.7-32.4 75.6a103.7 103.7 0 0 1-77.2 28.8 102 102 0 0 1-70.7-33.6 109.3 109.3 0 0 1-28.7-74v-76.8a56.6 56.6 0 0 1 56.5-56.5h96a56.6 56.6 0 0 1 56.5 56.5v80z",
      defaults
    );
    const opening = new fabric.Path(
      "M255.5 232a47.6 47.6 0 1 0 .1 95.1 47.6 47.6 0 0 0-.1-95.1zm0 80a32.5 32.5 0 1 1 0-65 32.5 32.5 0 0 1 0 65zM223.5 56a23.5 23.5 0 1 0 0 47 23.5 23.5 0 0 0 0-47zm0 32a8.5 8.5 0 1 1 0-17 8.5 8.5 0 0 1 0 17zM287.5 48a31.5 31.5 0 1 0 0 63 31.5 31.5 0 0 0 0-63zm0 48a16.5 16.5 0 1 1 0-33 16.5 16.5 0 0 1 0 33z",
      defaults
    );
    this.callSuper("initialize", [door, opening, hinge], {
      snapAngle: 5,
      scaleX: 0.15,
      scaleY: 0.15,
      ...options,
    });
  },

  toObject: function () {
    const obj = fabric.util.object.extend(this.callSuper("toObject"), {});
    const { objects, ...rest } = obj;
    return rest;
  },
});
fabric.Toilet.fromObject = function (object, callback) {
  return fabric.Object._fromObject("Toilet", object, callback) as any;
};
