import {
  ActiveSelection,
  Desk,
  IEvent,
  IText,
  Object,
} from "fabric/fabric-impl";
import { WhoDeskMap } from "./WhoDeskMap";

interface CanvasAction {
  type:
    | "modified"
    | "added"
    | "removed"
    | "selected"
    | "modified:group"
    | "selected:group";
  objectState: string;
  object: Object;
}
export class CanvasKeyActions {
  canvas: WhoDeskMap;
  ignoreEvent = false;
  _clipboard: any | undefined;
  actionQueue: CanvasAction[] = [];
  propertiesToSave = ["snapAngle"];
  eventType: keyof DocumentEventMap = "keydown";
  onDeleteFailure?: (type?:string) => void;
  constructor(canvas: WhoDeskMap) {
    this.canvas = canvas;
  }

  init() {
    document.addEventListener(this.eventType, this.listener as any);
    this.canvas.on("object:modified", this.onObjectModified);
    this.canvas.on("selection:created", this.onObjectSelected);
    this.canvas.on("object:added", this.onObjectAdded);
    this.canvas.on("object:removed", this.onObjectRemoved);
    this.canvas.on("selection:updated", this.onObjectSelected);
  }

  dispose() {
    document.removeEventListener(this.eventType, this.listener as any);
    this.canvas.off("object:modified", this.onObjectModified as any);
    this.canvas.off("selection:created", this.onObjectSelected as any);
    this.canvas.off("selection:updated", this.onObjectSelected as any);
    this.canvas.off("object:added", this.onObjectAdded as any);
    this.canvas.off("object:removed", this.onObjectRemoved as any);
  }

  resetHistory() {
    this.actionQueue = [];
  }
  listener = (e: KeyboardEvent) => {
    if (e.key === "z" && (e.metaKey || e.ctrlKey)) {
      this.undo();
    } else if (e.key === "c" && (e.metaKey || e.ctrlKey)) {
      this.copy();
    } else if (e.key === "v" && (e.metaKey || e.ctrlKey)) {
      this.paste();
    } else if (
      (e.key === "Backspace" || e.key === "Delete") &&
      (e.metaKey || e.ctrlKey)
    ) {
      this.delete();
    }
  };

  onObjectSelected = (e: IEvent<MouseEvent>) => {
    if (e.selected) {
      if (e.selected.length > 1) {
        const selection = this.canvas.getActiveObject();
        this.addToActionQueue({
          type: "selected:group",
          objectState: JSON.stringify(selection),
          object: selection as Object,
        });
      } else {
        this.addToActionQueue({
          type: "selected",
          objectState: JSON.stringify(e.selected[0]),
          object: e.selected[0],
        });
      }
    }
  };

  onObjectModified = (e: IEvent<MouseEvent>) => {
    if (e.target) {
      if (e.target.type === "activeSelection") {
        const selection = this.canvas.getActiveObject();
        this.addToActionQueue({
          type: "modified:group",
          objectState: JSON.stringify(e.target),
          object: selection as Object,
        });
      } else {
        this.addToActionQueue({
          type: "modified",
          objectState: JSON.stringify(e.target),
          object: e.target,
        });
      }
    }
  };

  onObjectAdded = (e: IEvent<MouseEvent>) => {
    if (e.target && e.target.type !== "editMapCenterPoint") {
      this.addToActionQueue({
        type: "added",
        objectState: JSON.stringify(e.target),
        object: e.target,
      });
    }
  };

  onObjectRemoved = (e: IEvent<MouseEvent>) => {
    if (e.target) {
      this.addToActionQueue({
        type: "removed",
        objectState: JSON.stringify(e.target),
        object: e.target,
      });
    }
  };

  addToActionQueue = (action: CanvasAction) => {
    if (!this.ignoreEvent) {
      this.actionQueue.push(action);
    } else {
      this.ignoreEvent = false;
    }
  };

  undo() {
    let lastAction = this.actionQueue.pop();
    if (
      lastAction?.type === "selected" &&
      lastAction.object.type === "activeSelection"
    ) {
      lastAction = this.actionQueue.pop();
    }
    if (
      lastAction?.type === "modified" ||
      lastAction?.type === "modified:group"
    ) {
      lastAction = this.actionQueue.pop();
    }
    if (!lastAction) {
      return;
    }
    this.canvas.discardActiveObject();
    if (lastAction.type === "selected") {
      lastAction.object.setOptions(JSON.parse(lastAction.objectState));
      lastAction.object.setCoords();
      this.canvas.renderAll();
    }
    if (
      lastAction.type === "selected:group" ||
      lastAction.type === "modified:group"
    ) {
      const selection = lastAction.object as ActiveSelection;
      const lastState = JSON.parse(lastAction.objectState) as any;
      selection.getObjects().forEach((obj, index) => {
        obj.set({
          left:
            lastState.objects[index].left +
            (lastState.width / 2 + lastState.left || 0),
          top:
            lastState.objects[index].top +
            (lastState.height / 2 + (lastState.top || 0)),
          angle: lastState.objects[index].angle,
          scaleX: lastState.objects[index].scaleX,
          scaleY: lastState.objects[index].scaleY,
        });
        obj.setCoords();
      });
      this.canvas.renderAll();
    }
    if (lastAction.type === "modified") {
      lastAction.object.setOptions(JSON.parse(lastAction.objectState));
      lastAction.object.setCoords();
      this.canvas.renderAll();
    }
    if (lastAction.type === "added") {
      this.ignoreEvent = true;
      this.canvas.remove(lastAction.object);
    }
    if (lastAction.type === "removed") {
      this.ignoreEvent = true;
      this.canvas.add(lastAction.object);
    }
  }
  private shouldSkipAction() {
    let selectedObject = this.canvas.getActiveObject();
    return (
      selectedObject &&
      selectedObject.type === "i-text" &&
      (selectedObject as IText).isEditing
    );
  }

  delete() {
    if (this.shouldSkipAction()) {
      return;
    }
    let selectedObject = this.canvas.getActiveObject();
    if (selectedObject) {
      if (selectedObject.type === "activeSelection") {
        let objects: Object[] = [];
        (selectedObject as ActiveSelection).forEachObject((obj: any) => {
          objects.push(obj);
        });
        this.deleteObjects(...objects);
        this.canvas.discardActiveObject();
      } else {
        this.deleteObjects(selectedObject);
      }
    }
  }

  private deleteObjects(...objects: Object[]) {
   const objWithAssoc =  objects.find(
        (obj:any) => obj.associationId !== undefined
      )
    if (objWithAssoc) {
      this.onDeleteFailure?.(objWithAssoc.type);
    } else {
      objects.forEach((obj) => this.canvas.remove(obj));
    }
  }
  copy() {
    if (this.shouldSkipAction()) {
      return;
    }
    this.canvas.getActiveObject()?.clone((cloned: any) => {
      this._clipboard = cloned;
    }, this.propertiesToSave);
  }
  paste() {
    if (this.shouldSkipAction()) {
      return;
    }
    this._clipboard?.clone((clonedObj: any) => {
      let canvas = this.canvas;
      canvas.discardActiveObject();
      clonedObj.set({
        left: clonedObj.left + 20,
        top: clonedObj.top + 20,
        evented: true,
        id: undefined,
        associationId: undefined
      });
      if (clonedObj.type === "activeSelection") {
        clonedObj.canvas = canvas;
        clonedObj.forEachObject((obj: any) => {
          this.addObject(obj);
        });
        // this should solve the unselectability
        clonedObj.setCoords();
      } else {
        this.addObject(clonedObj);
      }
      this._clipboard.top += 20;
      this._clipboard.left += 20;
      canvas.setActiveObject(clonedObj);
      canvas.requestRenderAll();
    }, this.propertiesToSave);
  }
  private addObject(obj: Object) {
    if (obj.type === "desk") {
      let desk = obj as Desk;
      desk.id = undefined;
      desk.associationId = undefined;
      this.canvas.add(desk);
    } else {
      this.canvas.add(obj);
    }
  }
}
