import dayjs from "dayjs";
import { useRef } from "react";
import { Line } from "react-chartjs-2";
import { useSearchParams } from "react-router-dom";
import CenteredEmptyPlaceholder from "../../../../../components/common/CenteredEmptyPlaceholder";
import LoadingBoundry from "../../../../../components/common/LoadingBoundry/LoadingBoundry";
import { ReportResponse } from "../../../../../repo";
import { useReport } from "../../../../../repo/reports";
import { getRandomColor } from "../../../../../utils/colorUtils";
import BorderDivider from "../../../../common/BorderDivider/ListDivider";
import ChartContainer from "../../components/ChartContainer/ChartContainer";
import {
    chartLegend,
    ChartPopup,
    ChartPopupActions,
} from "../../components/ConfigPopup/ConfigPopup";
import GraphControlContainer from "../../components/GraphControlContainer/GraphControlContainer";
import LabelPicker from "../../components/LabelPicker";
import { PeriodPicker } from "../../components/PeriodPicker";
import { useChartContext } from "../../context/chartContext";
import { getMonthlyValues } from "../../utils/calcUtils";

type Props = {};

export const ExpirationReport = (props: Props) => {
  const [searchParams, setSearchParams] = useSearchParams({
    window: "DAILY",
  });
  const timeWindow = searchParams.get("window");
  const dateFrom = dayjs(searchParams.get("dateFrom")).startOf("month");
  const dateTo = dayjs(searchParams.get("dateTo")).endOf("month");
  const {
    data: report,
    isLoading,
    isError,
    refetch,
  } = useReport({
    dateFrom:
      (dateFrom.isValid() && dateFrom.format("YYYY-MM-DD")) || undefined,
    dateTo: (dateTo.isValid() && dateTo.format("YYYY-MM-DD")) || undefined,
    type: "EQUIPMENT_EXPIRY",
    mapId: searchParams.get("mapId") || undefined,
  });
  const popupRef = useRef<ChartPopupActions>(null);
  const datasets = report?.charts?.map((chart) => {
    const color =
      useChartContext.getState().chartSettings.get(chart.name)?.color ||
      getRandomColor();
    const hidden =
      useChartContext.getState().chartSettings.get(chart.name)?.hidden === true;
    let type: any =
      useChartContext.getState().chartSettings.get(chart.name)?.type || "bar";
    let data = chart.values;
    if (timeWindow === "MONTHLY") {
      if (chart.name.includes("Expired")) {
        data = getMonthlyValues(data, undefined, MONTHLY_DATE_FORMAT);
      } else {
        type =
          useChartContext.getState().chartSettings.get(chart.name)?.type ||
          "line";
        data = data
          .filter((item, index, array) => {
            const currentDate = new Date(item.x);
            const nextDate =
              index < array.length - 1 ? new Date(array[index + 1].x) : null;

            return (
              index === array.length - 1 || // Last item in the array
              (nextDate && currentDate.getMonth() !== nextDate.getMonth()) // Different month
            );
          })
          .map((i) => ({ x: dayjs(i.x).format(MONTHLY_DATE_FORMAT), y: i.y }));
      }
    }
    return {
      label: chart.name,
      data: data,
      fill: false,
      hidden: hidden,
      type: type,
      borderColor: color,
      backgroundColor: color,
      order: type === "line" ? 1 : 2,
      tension: 0.1,
    };
  });
  let labels = getLabels(timeWindow, report);
  const options = {
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        display: false,
      },
      legend: chartLegend(popupRef),
    },
    scales: {
      y: {
        grace: "5%",
        title: {
          display: true,
          text: "Amount",
        },
      },
    },
  };
  return (
    <>
      <GraphControlContainer>
        <PeriodPicker disabledDate={() => false} />
        <LabelPicker
          searchParam="window"
          label="Time window"
          values={["DAILY", "MONTHLY"]}
        />
      </GraphControlContainer>
      <BorderDivider />
      <ChartContainer>
        <LoadingBoundry
          loading={isLoading}
          error={isError}
          description="Failed to load chart"
          onAction={refetch}
        >
          <ChartPopup ref={popupRef} />
          {labels.length > 0 ? (
            <Line
              options={options}
              data={{
                labels: labels,
                datasets: datasets || [],
              }}
            />
          ) : (
            <CenteredEmptyPlaceholder description={"No period selected"} />
          )}
        </LoadingBoundry>
      </ChartContainer>
    </>
  );
};

function getLabels(window: string | null, report?: ReportResponse) {
  if (window === "MONTHLY") {
    return Array.from(
      new Set(
        report?.charts
          .flatMap((v) => v.values)
          .map((c) => c.x)
          .sort()
          .map((c) => dayjs(c).format(MONTHLY_DATE_FORMAT))
      )
    );
  }
  return Array.from(
    new Set(
      report?.charts
        ?.flatMap((c) => c.values)
        .map((c) => c.x)
        .sort()
    )
  );
}
const MONTHLY_DATE_FORMAT = "YY MMMM";
