import { Tooltip } from "antd";
import { useParams } from "react-router-dom";
import DisableByRole from "../../../../../components/common/DisableByRole";
import { useMap } from "../../../../../repo/maps";
import styles from "./MapStatusIndicator.module.css";
type Props = {};

export default function MapStatusIndicator({}: Props) {
  const { mapId } = useParams();
  const { data: map } = useMap(mapId);
  return (
    <DisableByRole disabledRole="USER">
      <Tooltip
        title={
          map?.status === "DRAFT"
            ? "This map is in draft state, and cannot be seen by regular users. You can change that in map settings"
            : "This map is in active state, and can be seen by regular users. You can change that in map settings"
        }
      >
        <div className={styles.container}>
          <strong>{`${map?.status}`.capitalizeFirstLetter()}</strong>
        </div>
      </Tooltip>
    </DisableByRole>
  );
}
