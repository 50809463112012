import { Point } from "chart.js";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useMemo, useRef, useState } from "react";
import { RoomBooking } from "../../../../../../../repo";
import { DateInterval } from "../../BookMapRoom";
import styles from "./CalendarContainer.module.css";

const MINUTE_SNAP_ON_CLICK = 15;
const ON_CLICK_DURATION = 30;
const DRAG_SNAP = 5;
export default function CalendarContainer({
  date,
  onRangeSelected,
  selectedRange,
  existingBookings = [],
  onEntryClick,
}: {
  date: Dayjs;
  onRangeSelected?: (dates?: DateInterval) => void;
  existingBookings?: RoomBooking[];
  selectedRange?: DateInterval;
  onEntryClick?: (id: string) => void;
}) {
  const [mouseDown, setMouseDown] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const columnRef = useRef<HTMLDivElement>(null);
  const [startCoordinates, setStartCoordinates] = useState<Point>();
  const [endCoordinates, setEndCoordinates] = useState<Point>();
  const [eventBoxParameters, setEventBoxParameters] = useState<{
    height: number;
    top: number;
  }>();
  const bookings = useMemo(
    () => populateAdditionalParamsToBookings(existingBookings),
    [existingBookings]
  );

  useEffect(() => {
    if (!selectedRange || !onRangeSelected) {
      return;
    }
    const dateFrom = selectedRange.from;
    const dateTo = selectedRange.to;
    const bookingPresentInRage = bookingsInRange(bookings, dateFrom, dateTo);
    if (bookingPresentInRage) {
      onRangeSelected(undefined);
    }
  }, [bookings, date, selectedRange, onRangeSelected]);

  useEffect(() => {
    if (!eventBoxParameters) {
      return;
    }
    const startDate = date.startOf("day").minute(eventBoxParameters.top);
    const endDate = startDate.add(eventBoxParameters.height, "minutes");
    const bookingPresentInRage = bookingsInRange(bookings, startDate, endDate);
    if (!bookingPresentInRage) {
      onRangeSelected?.({
        from: startDate,
        to: endDate,
      });
    }
  }, [eventBoxParameters, date, mouseDown, onRangeSelected, bookings]);

  useEffect(() => {
    if (!startCoordinates || !endCoordinates) {
      return;
    }
    setEventBoxParameters({
      height: Math.max(
        snap(
          Math.max(startCoordinates?.y || 0, endCoordinates?.y || 0),
          DRAG_SNAP
        ) -
          snap(
            Math.min(startCoordinates?.y || 0, endCoordinates?.y || 0),
            DRAG_SNAP
          ),
        10
      ),
      top: snap(
        Math.min(startCoordinates?.y || 0, endCoordinates?.y || 0),
        DRAG_SNAP
      ),
    });
  }, [startCoordinates, endCoordinates]);

  const onMouseDown: React.DOMAttributes<HTMLDivElement>["onMouseDown"] = (
    event
  ) => {
    onGestureDown(event.clientY);
  };

  const onGestureDown = (y: number) => {
    setMouseDown(true);
    const column = columnRef.current?.getBoundingClientRect();
    if (column) {
      setStartCoordinates({
        x: 0,
        y: Math.max(y - column.top, 0),
      });
      setEndCoordinates(undefined);
    }
  };

  useEffect(() => {
    const id = setTimeout(() =>
      containerRef.current!.scrollTo({
        top: dayjs().hour() * 60,
      })
    );
    return () => {
      clearTimeout(id);
    };
  }, []);
  const onGestureUp = () => {
    if (!mouseDown) {
      return;
    }

    if (!endCoordinates && startCoordinates) {
      setEndCoordinates({
        x: startCoordinates.x,
        y: snap(startCoordinates.y + ON_CLICK_DURATION, MINUTE_SNAP_ON_CLICK),
      });
      setStartCoordinates({
        x: startCoordinates.x,
        y: snap(startCoordinates.y, MINUTE_SNAP_ON_CLICK),
      });
    }
    setMouseDown(false);
  };
  const onMouseMove: React.DOMAttributes<HTMLDivElement>["onMouseMove"] = (
    event
  ) => {
    onGestureMove(event.clientY);
  };

  const onGestureMove = (y: number) => {
    if (!mouseDown) {
      return;
    }
    const column = columnRef.current?.getBoundingClientRect();
    if (column) {
      setEndCoordinates({
        x: 0,
        y: Math.max(y - column.top, 0),
      });
    }
  };
  const onTopResize = (y: number) => {
    const column = columnRef.current?.getBoundingClientRect();
    if (column) {
      setStartCoordinates({
        x: 0,
        y: Math.max(y - column.top, 0),
      });
    }
  };
  const onBottomResize = (y: number) => {
    const column = columnRef.current?.getBoundingClientRect();
    const container = containerRef.current?.getBoundingClientRect();
    if (column && container) {
      if (container.height + container.top < y) {
        return;
      }
      setEndCoordinates({
        x: 0,
        y: Math.max(y - column.top, 0),
      });
    }
  };
  return (
    <div
      className={styles.calendarContainer}
      onMouseDown={onMouseDown}
      onMouseUp={onGestureUp}
      onMouseMove={onMouseMove}
      onMouseLeave={onGestureUp}
      ref={containerRef}
    >
      <div className={styles.calendarHourColumn}>
        {Array.from(Array(24).keys()).map((val) => {
          return (
            <div key={"hour" + val} className={styles.calendarHourContainer}>
              <span className={styles.calendarHourText}>
                {dayjs().hour(val).format("HH:00")}
              </span>
            </div>
          );
        })}
      </div>
      <div className={styles.calendarHourSlotColumn} ref={columnRef}>
        {Array.from(Array(24).keys()).map((val) => {
          return <div key={val} className={styles.calendarHourSlot}></div>;
        })}
        {bookings.map((booking) => {
          return (
            <RoomCalendarEntry
              key={booking.id}
              id={booking.id}
              title={booking.summary || "Occupied"}
              dateFrom={booking.dateFrom}
              dateTo={booking.dateTo}
              widthFactor={booking.widthFactor}
              placingFactor={booking.placingFactor}
              onClick={onEntryClick}
            />
          );
        })}
        {selectedRange && (
          <RoomCalendarEntry
            active
            dateFrom={selectedRange.from}
            dateTo={selectedRange.to}
            onTopChange={onTopResize}
            onBottomChange={onBottomResize}
          />
        )}
      </div>
    </div>
  );
}
const RoomCalendarEntry = ({
  dateFrom,
  dateTo,
  title,
  active,
  placingFactor = 1,
  widthFactor = 1,
  onClick,
  onTopChange,
  onBottomChange,
  id,
}: {
  title?: string;
  dateFrom: Dayjs;
  dateTo: Dayjs;
  active?: boolean;
  placingFactor?: number;
  widthFactor?: number;
  onClick?: (id: string) => void;
  onTopChange?: (clientY: number) => void;
  onBottomChange?: (clientY: number) => void;
  id?: string;
}) => {
  const dimensions = {
    height: dateTo.diff(dateFrom, "minutes"),
    top: getTotalMinutes(dateFrom),
    width: `${100 / widthFactor}%`,
    left: `${
      placingFactor === 1 ? 0 : (100 / widthFactor) * (placingFactor - 1)
    }%`,
  };
  return (
    <div
      className={`${styles.eventBox} ${active ? styles.eventBoxActive : ""}`}
      style={dimensions}
      onMouseDown={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        if (!active && onClick && id) {
          onClick(id);
        }
      }}
    >
      {active && (
        <DragElement className={`${styles.top}`} onMove={onTopChange} />
      )}
      <div className={styles.eventBoxContent}>
        {title && <div className={styles.eventBoxTitle}>{title}</div>}
        <div className={styles.eventBoxTime}>
          {`${dateFrom.format("HH:mm")} – ${dateTo.format("HH:mm")}`}
        </div>
      </div>
      {active && (
        <DragElement className={`${styles.bottom}`} onMove={onBottomChange} />
      )}
    </div>
  );
};

const DragElement = ({
  onMove,
  ...props
}: React.HTMLAttributes<HTMLDivElement> & { onMove?: (y: number) => void }) => {
  const [mouseHeld, setMouseHeld] = useState(false);
  useEffect(() => {
    const _onMouseMove = (e: MouseEvent) => {
      if (!mouseHeld) {
        return;
      }
      onMove?.(e.clientY);
    };
    const onMouseUp = () => {
      setMouseHeld(false);
    };
    const onTouchMove = (e: TouchEvent) => {
      if (!mouseHeld) {
        return;
      }
      onMove?.(e.touches[0].clientY);
    };
    if (mouseHeld) {
      disableScroll();
      document.addEventListener("mousemove", _onMouseMove);
      document.addEventListener("mouseup", onMouseUp);
      document.addEventListener("touchmove", onTouchMove);
      document.addEventListener("touchend", onMouseUp);
      return () => {
        enableScroll();
        document.removeEventListener("mousemove", _onMouseMove);
        document.removeEventListener("mouseup", onMouseUp);
        document.removeEventListener("touchmove", onTouchMove);
        document.removeEventListener("touchend", onMouseUp);
      };
    } else {
      enableScroll();
    }
  }, [mouseHeld, onMove]);
  const onTouchStart = (e: any) => {
    setMouseHeld(true);
    disableScroll();
  };
  return (
    <div
      {...props}
      className={`${styles.eventBoxDragElement} ${props.className}`}
      onMouseDown={() => setMouseHeld(true)}
      onTouchStart={onTouchStart}
      onTouchEnd={() => setMouseHeld(false)}
      onTouchMove={(e) => e.preventDefault()}
    />
  );
};

const populateAdditionalParamsToBookings = (bookings: RoomBooking[]) => {
  const bookingsWithDates = bookings
    .filter((b) => b.dateFrom && b.dateTo)
    .map((b) => ({
      ...b,
      dateFrom: adjusted( dayjs(b.dateFrom)),
      dateTo: adjusted(dayjs(b.dateTo)),
      widthFactor: 1,
      placingFactor: 1,
    }));

  let arrayLength = bookingsWithDates.length;
  for (let i = 0; i < arrayLength; i++) {
    let booking = bookingsWithDates[i];
    let bookingDateFrom =booking.dateFrom;
    let bookingDateTo = booking.dateTo;
    let additionalBookings = bookingsWithDates.filter((b) => {
      let dateFrom = b.dateFrom;
      let dateTo = b.dateTo;
      return (
        b.id !== booking.id &&
        (dateFrom.isSame(bookingDateFrom, "minutes") ||
          dateFrom.isBetween(bookingDateFrom, bookingDateTo) ||
          bookingDateFrom.isBetween(dateFrom, dateTo) ||
          bookingDateTo.isBetween(dateFrom, dateTo) ||
          dateTo.isBetween(bookingDateFrom, bookingDateTo))
      );
    });

    bookingsWithDates[i] = {
      ...booking,
      widthFactor: booking.widthFactor + additionalBookings.length,
      placingFactor:
        booking.placingFactor +
        additionalBookings.filter((b) => b.widthFactor !== 1).length,
    };
  }
  return bookingsWithDates;
};

const bookingsInRange = (
  bookings: any[],
  startDate: dayjs.Dayjs,
  endDate: dayjs.Dayjs
) => {
  return bookings.find(
    (b) =>
      b.dateFrom.isBetween(startDate, endDate, "m") ||
      b.dateTo.isBetween(startDate, endDate, "m") ||
      endDate.isBetween(b.dateFrom, b.dateTo, "m") ||
      startDate.isBetween(b.dateFrom, b.dateTo, "m") ||
      (b.dateFrom.isSame(startDate, "m") && b.dateTo.isSame(endDate, "m"))
  );
};

const snap = (num: number, snap: number) => {
  return Math.round(num / snap) * snap;
};

function preventDefault(e: any) {
  e.preventDefault();
}

function disableScroll() {
  document.addEventListener("touchmove", preventDefault, { passive: false });
}

function enableScroll() {
  document.removeEventListener("touchmove", preventDefault);
}
const getTotalMinutes = (date: Dayjs) => {
  return date.minute() + date.hour() * 60;
};
const adjusted = (date: Dayjs) => {
  return dayjs(date.format("HH:mm"), "HH:mm");
};
