import { IconQuestionMark } from "@tabler/icons-react";
import { Tooltip } from "antd";
import styles from "./Explanation.module.css";
type Props = {
  description: string;
};

export default function Explanation({ description }: Props) {
  return (
    <Tooltip title={description} >
      <span className={styles.bubble}>
        <IconQuestionMark size={18} className={styles.icon} />
      </span>
    </Tooltip>
  );
}
