import { Button, ButtonProps, Flex, TimePicker } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { BehindPermission } from "../../../../../../components/common/BehindPermission/BehindPermission";
import CenteredEmptyPlaceholder from "../../../../../../components/common/CenteredEmptyPlaceholder";
import LoadingBoundry from "../../../../../../components/common/LoadingBoundry/LoadingBoundry";
import NavigationBanner from "../../../../../../components/common/NavigationBanner/NavigationBanner";
import { useRoomAvailability, useRooms } from "../../../../../../repo/rooms";
import { DATE_FORMAT } from "../../../../../../utils/constants";
import { addTime, largeOnMobile } from "../../../../../../utils/reactUtils";
import BorderDivider from "../../../../BorderDivider/ListDivider";
import IndicatingList from "../../../../IndicatingList/IndicatingList";
import Scrollable from "../../../../Scrollable/Scrollable";
import { useDateContext } from "../../../context/dateContext";
import { RoomListItem } from "../../../desks/MapDeskList/MapDeskList";
import { useRoomBookingStore } from "../context/useRoomBookingStore";
import styles from "./RoomAvailability.module.css";
type Props = {};

export default function RoomAvailability({}: Props) {
  const { mapId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedDate = useDateContext.use.date();
  const timeFrom = dayjs(searchParams.get("timeFrom"));
  const timeTo = dayjs(searchParams.get("timeTo"));
  useEffect(() => {
    searchParams.set("date", selectedDate.format(DATE_FORMAT));
    setSearchParams(searchParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);
  const req =
    selectedDate.isValid() && timeFrom.isValid() && timeTo.isValid()
      ? {
          from: addTime(selectedDate, timeFrom),
          to: addTime(selectedDate, timeTo),
        }
      : undefined;
  useEffect(() => {
    searchParams.set(
      "date",
      searchParams.get("date") || dayjs().format(DATE_FORMAT)
    );
    searchParams.set(
      "timeFrom",
      searchParams.get("timeFrom") || dateFromAdjusted(dayjs()).toISOString()
    );
    searchParams.set(
      "timeTo",
      searchParams.get("timeTo") ||
        dateFromAdjusted(dayjs()).add(30, "m").toISOString()
    );
    setSearchParams(searchParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <NavigationBanner
        name={"Room booking"}
        prevRoute={`/maps/${mapId}/desks`}
      />

      <BehindPermission
        permission="VIEW_ROOM_SCHEDULE"
        description="We require some additional permissions to view available rooms"
      >
        <div className={styles.searchContainer}>
          <TimePicker.RangePicker
            className={styles.timePicker}
            size={largeOnMobile()}
            minuteStep={10}
            order={true}
            format={"HH:mm"}
            changeOnScroll
            value={
              timeFrom.isValid() && timeTo.isValid() ? [timeFrom, timeTo] : null
            }
            onChange={(time) => {
              if (time) {
                searchParams.set("timeFrom", time[0]!.toISOString());
                searchParams.set("timeTo", time[1]!.toISOString());
                setSearchParams(searchParams);
              } else {
                searchParams.delete("timeFrom");
                searchParams.delete("timeTo");
                setSearchParams(searchParams);
              }
            }}
            panelRender={(panel) => {
              return (
                <Flex>
                  <div className={styles.timeRangePresetsContainer}>
                    <span>Presets</span>
                    <PresetButton
                      onClick={() => {
                        const from = dateFromAdjusted(dayjs());
                        searchParams.set("timeFrom", from.toISOString());
                        searchParams.set(
                          "timeTo",
                          from.add(30, "m").toISOString()
                        );
                        setSearchParams(searchParams);
                      }}
                    >
                      Next 30min
                    </PresetButton>
                    <PresetButton
                      onClick={() => {
                        const from = dateFromAdjusted(dayjs());
                        searchParams.set("timeFrom", from.toISOString());
                        searchParams.set(
                          "timeTo",
                          from.add(1, "h").toISOString()
                        );
                        setSearchParams(searchParams);
                      }}
                    >
                      Next 1h
                    </PresetButton>
                    <PresetButton
                      onClick={() => {
                        const from = dateFromAdjusted(dayjs());
                        searchParams.set("timeFrom", from.toISOString());
                        searchParams.set(
                          "timeTo",
                          from.add(2, "h").toISOString()
                        );
                        setSearchParams(searchParams);
                      }}
                    >
                      Next 2h
                    </PresetButton>
                  </div>
                  {panel}
                </Flex>
              );
            }}
          />
        </div>
        <BorderDivider />
        <AvailableRoomList request={req} />
      </BehindPermission>
    </>
  );
}
const AvailableRoomList = ({ request }: { request: any }) => {
  const { mapId } = useParams();
  const {
    data: unavailableRooms,
    isLoading,
    isError,
  } = useRoomAvailability(mapId, request);
  const { setRooms, setUnavailabilieties, availableRooms } =
    useRoomBookingStore();
  const { data: rooms, refetch } = useRooms(mapId);
  useEffect(() => {
    rooms && setRooms(rooms);
  }, [rooms, setRooms]);
  useEffect(() => {
    unavailableRooms && setUnavailabilieties(unavailableRooms);
  }, [unavailableRooms, setUnavailabilieties]);
  if (!request) {
    return <CenteredEmptyPlaceholder description="Select date and time" />;
  }
  return (
    <LoadingBoundry
      loading={isLoading}
      error={isError}
      description="Could not fetch room availability"
      onAction={refetch}
    >
      {request && availableRooms.length > 0 ? (
        <Scrollable>
          <IndicatingList
            isLoading={isLoading}
            data={availableRooms}
            render={(r) => (
              <RoomListItem
                key={r.id}
                room={r}
                style={{ backgroundColor: "green" }}
              />
            )}
          />
        </Scrollable>
      ) : (
        <CenteredEmptyPlaceholder description="No available rooms found" />
      )}
    </LoadingBoundry>
  );
};

const PresetButton = (props: ButtonProps) => {
  return <Button size="small" type="primary" {...props} />;
};

export const dateFromAdjusted = (time: Dayjs, adjustment = 5) => {
  return time.add(adjustment - (time.get("minutes") % adjustment), "m");
};
