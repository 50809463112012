import { create } from "zustand";
import { persist } from "zustand/middleware";
import {
  DeskBookingResponse,
  GetBookingsRequestParams,
} from "../../../../../repo";

interface BookingsContext {
  bookings: DeskBookingResponse[];
  params: GetBookingsRequestParams;
  isLoading: boolean;
  isError: boolean;
  setParams: (params: GetBookingsRequestParams) => void;
  setBookings: (bookings: DeskBookingResponse[]) => void;
}
const initState = {
  bookings: [],
  params: {},
  isError: false,
  isLoading: false,
  shouldFilter: true,
};
export const useBookingsContext = create<BookingsContext>()(
  persist(
    (set, get) => ({
      ...initState,
      setParams(params) {
        set((state) => ({ ...state, params }));
      },
      setBookings(bookings) {
        set((state) => ({ ...state, bookings }));
      },
    }),
    {
      name: "bookings",
      storage: {
        async getItem(name: string) {
          const urlParams = new URLSearchParams(window.location.search);
          return {
            state: {
              params: Object.fromEntries(urlParams.entries()),
            },
          };
        },
        async setItem(name, storageValue): Promise<void> {
        //   const params = new URLSearchParams(window.location.search);
        //   Object.entries(storageValue.state.params).forEach((entry) => {
        //     if (entry[1] !== undefined) {
        //       params.set(entry[0], entry[1]);
        //     }
        //   });
        //   let newRelativePathQuery =
        //     window.location.pathname + "?" + params.toString();
        //   window.history.replaceState(null, "", newRelativePathQuery);
        },
        async removeItem(name: string): Promise<void> {},
      },
    }
  )
);
