import { useRef } from "react";
import { usePermissionsStore } from "../../context/authContext";
import { useNav } from "../../utils/reactUtils";

export const DEFAULT_LOGO = `${process.env.PUBLIC_URL}/name.png`;
export default function WhoDeskLogo({
  onLoadFailure,
  ...props
}: React.ImgHTMLAttributes<HTMLImageElement> & {
  onLoadFailure?: () => void;
}) {
  const { companyId } = usePermissionsStore();
  const nav = useNav();
  const imgRef = useRef<HTMLImageElement>(null);
  const navHome = () => {
    nav("/");
  };
  const loadDefaultImg = () => {
    if (imgRef.current) {
      imgRef.current.src = DEFAULT_LOGO;
      onLoadFailure?.();
    }
  };
  const imgUrl =
    props.src ||
    `https://${process.env.REACT_APP_BUCKET_URL}/company/${companyId}/logo`;
  return (
    <img
      ref={imgRef}
      src={imgUrl}
      onError={loadDefaultImg}
      alt="WhoDesk"
      onClick={navHome}
      {...props}
    />
  );
}
