import { InteractableMapEntity } from "fabric/fabric-impl";
import { navigator } from "../..";
import { RoomResponse } from "../../../repo";
import { MapNavigator } from "./MapNavigator";

export class RoomBookingNavigator implements MapNavigator {
  public availableRooms: RoomResponse[] = [];

  constructor(availableRooms: RoomResponse[]) {
    this.availableRooms = availableRooms;
  }
  navigateToRoom = (entity: InteractableMapEntity) => {
    const nav = navigator.nav;
    const mapId = navigator.mapId;
    if (!nav || !mapId) {
      return;
    }

    if (!entity.associationId) {
      return;
    }
    if (this.availableRooms.find((r) => r.mapRoomId === entity.id)) {
      nav(
        `/maps/${mapId}/bookings/rooms/${entity.associationId}${window.location.search}`,
        {
          replace: window.location.pathname.includes("/bookings/rooms/"),
        }
      );
    }
  };
  navigateToDesk = (entity: InteractableMapEntity) => {};
}
