import { fabric } from "fabric";

fabric.EditMapIText = fabric.util.createClass(fabric.IText, {
    type: "editMapIText",
    initialize: function (...args: any[]) {
      if (args.length > 1) {
        this.callSuper("initialize", args[0], {
          editable: true,
          snapAngle: 5,
          ...args[1],
          fontFamily: "roboto",
        });
      } else {
        this.callSuper("initialize", args[0].text || args[0], {
          editable: true,
          snapAngle: 5,
          ...args[0],
          fontFamily: "roboto",
        });
      }
    },
  });
  fabric.EditMapIText.fromObject = function (object: any, callback?: any) {
    return fabric.Object._fromObject("EditMapIText", object, callback) as any;
  };