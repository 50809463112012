import { fabric } from "fabric";

fabric.EditMapCircle = fabric.util.createClass(fabric.Circle, {
    type: "editMapCircle",
    initialize: function (options: any) {
      this.callSuper("initialize", {
        stroke: "black",
        radius: 50,
        fill: "white",
        strokeUniform: true,
        ...options,
      });
      this.setControlVisible("mtr", false);
    },
  });
  fabric.EditMapCircle.fromObject = function (object: any, callback?: any) {
    return fabric.Object._fromObject("EditMapCircle", object, callback) as any;
  };
  