import { Empty, EmptyProps } from "antd";
import FullHeightCentered from "./FullHeightCentered";

export default function CenteredEmptyPlaceholder(props: EmptyProps) {
  return (
    <FullHeightCentered>
      <Empty {...props} style={{...props.style, fontWeight: '500'}}/>
    </FullHeightCentered>
  );
}
