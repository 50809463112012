import styles from './IconWithBackground.module.css';
export const IconWithBackground = ({
    icon,
    backgroundColor,
  }: {
    icon: React.ReactElement;
    backgroundColor?: string;
  }) => {
    return (
      <div
        className={styles.bookingIconContainer}
        style={{ color: "var(--primary-text-color)", backgroundColor }}
      >
        {icon}
      </div>
    );
  };