import { fabric } from "fabric";
import { Desk, IObjectOptions, MeetingRoom } from "fabric/fabric-impl";
import { MapService } from "../../services/map/MapService";
import { CanvasKeyActions } from "./CanvasKeyActions";
import { DeskColorHolder } from "./customTypes";
import { mapQueue } from "./mapQueue";

interface DeskInteractionOptions {
  activeColor?: string;
  activeLineWidth?: number;
  onSelect?: (desk: Desk) => void;
  onDeselect?: (desk: Desk) => void;
  onDeleteFailure?: (type?: string) => void;
}
interface WhoDeskMapOptions extends fabric.ICanvasOptions {
  keyActionsEnabled?: boolean;
  selectableDesks?: boolean;
  deskInteraction?: DeskInteractionOptions;
  gridSize?: number;
  gridColor?: string;
  mapService?: MapService;
}
export class WhoDeskMap extends fabric.Canvas {
  actions?: CanvasKeyActions;
  selectableDesks?: boolean;
  isDirty = false;
  gridSize?: number;
  gridColor: string = "#efefef";
  mapService?: MapService;
  constructor(
    element: string | HTMLCanvasElement | null,
    options?: WhoDeskMapOptions | undefined
  ) {
    super(element, {
      defaultCursor: "grab",
      preserveObjectStacking: true,
      targetFindTolerance: 4,
      perPixelTargetFind: true,
      fireRightClick: true,
      fireMiddleClick: true,
      stopContextMenu: false,
      includeDefaultValues: false,
      ...options,
    });
    this.gridSize = options?.gridSize;
    this.mapService = options?.mapService;
    if (options?.keyActionsEnabled) {
      this.actions = new CanvasKeyActions(this);
      this.actions.onDeleteFailure = options.deskInteraction?.onDeleteFailure;
      this.actions.init();
    }
    this.initEventHandlers();
    this.selectableDesks = options?.selectableDesks;
    if (options?.containerClass) {
      document
        .querySelector(`.${options?.containerClass}`)
        ?.removeAttribute("style");
    }
  }

  dispose() {
    mapQueue.dispose();
    this.actions?.dispose();
    return super.dispose();
  }
  getObjectByAssociation(associationId: string): any | undefined {
    return this.getObjects().find(
      (d: any) => d.associationId === associationId
    );
  }
  getObjectById(id?: string): any | undefined {
    return this.getObjects().find((d: any) => d.id === id);
  }
  _renderBackground = (ctx: CanvasRenderingContext2D) => {
    if (!this.gridSize) {
      return;
    }
    ctx.fillStyle = (this.backgroundColor || "white") as any;
    ctx.fillRect(0, 0, this.width as any, this.height as any);

    let zoom = this.getZoom();
    let offsetX = this.viewportTransform![4];
    let offsetY = this.viewportTransform![5];

    ctx.strokeStyle = this.gridColor;
    ctx.lineWidth = 1;

    let gridSize = this.gridSize * zoom;

    const numCellsX = Math.ceil(this.width! / gridSize);
    const numCellsY = Math.ceil(this.height! / gridSize);

    let gridOffsetX = offsetX % gridSize;
    let gridOffsetY = offsetY % gridSize;

    ctx.save();
    ctx.beginPath();

    for (let i = 0; i <= numCellsX; i++) {
      let x = gridOffsetX + i * gridSize;
      ctx.moveTo(x, 0);
      ctx.lineTo(x, this.height!);
    }

    for (let i = 0; i <= numCellsY; i++) {
      let y = gridOffsetY + i * gridSize;
      ctx.moveTo(0, y);
      ctx.lineTo(this.width!, y);
    }

    ctx.stroke();
    ctx.closePath();
    ctx.restore();
  };
  loadMapFromJson(json: Object) {
    this.mapService?.unsubscribe();
    this.loadFromJSON(
      json,
      () => {
        this.getObjects("editMapDynamicLine").forEach((obj) => {
          (obj as fabric.EditMapDynamicLine).addControls(this);
        });
        if (this.selectableDesks) {
          this.disableObjectEvents();
          this.makeDesksInteractive();
          this.makeRoomsInteractive();
          this.on("mouse:down", (e) => {
            let activeObject: any = this.getObjects().find(
              (obj: any) => obj.active === true
            );
            if (activeObject && activeObject !== e.target) {
              activeObject.setActive?.call(activeObject, false);
              this.fire(`${activeObject.type}:deselected`, activeObject);
            }
            if (activeObject !== e.target && e.target?.type === "desk") {
              let desk: Desk = e.target as Desk;
              this.selectDesk(desk);
            }
          });
        }
        this.renderAll();
        this.zoomToFitObjects();

        this.actions?.resetHistory();
        if (this.selection === false) {
          this.getObjects().forEach((obj) => {
            obj.lockScalingX = true;
            obj.lockRotation = true;
            obj.lockSkewingX = true;
            obj.lockSkewingY = true;
            obj.lockScalingY = true;
          });
        }
        this.fire("map:reloaded", this);
        this.mapService?.subscribe(this);
      },
      (props: any, obj: Object) => {}
    );
  }
  makeDesksInteractive() {
    this.getDesks().forEach((obj) => {
      obj.makeInteractive(this);
    });
  }
  makeRoomsInteractive() {
    this.getMeetingRooms().forEach((obj) => {
      obj.makeInteractive();
    });
  }
  disableAllExcept(ids: string[]) {
    this.getDesks().forEach((obj) => {
      obj.set({ evented: obj.id !== undefined && ids.includes(obj.id) });
    });
  }
  removeType(type: string) {
    this.getObjects(type).forEach((o) => this.remove(o));
  }
  disableClicks(ids: string[]) {
    this.getDesks().forEach((obj) => {
      obj.set({ clickable: obj.id !== undefined && !ids.includes(obj.id) });
    });
  }
  enabledAllDesks() {
    this.getDesks().forEach((obj) => {
      obj.set({ evented: true });
    });
  }
  getDesks() {
    return this.getObjects("desk") as Desk[];
  }
  getMeetingRooms() {
    return this.getObjects("meetingRoom") as MeetingRoom[];
  }
  selectMapObject(id: string) {
    this.getObjects()
      .filter((o: any) => o.id === id)
      .forEach((o: any) => {
        if (!o.active) {
          o.setActive?.call(o, true);
          this.fire("entity:selected", { entity: o });
        }
      });
  }
  selectDesk(desk: string | Desk) {
    let actualDesk: Desk | undefined;
    if (typeof desk === "string") {
      actualDesk = this.getDesks().find((obj) => {
        return obj.id === desk;
      });
    } else {
      actualDesk = desk;
    }
    if (actualDesk && !actualDesk.active && actualDesk.clickable !== false) {
      actualDesk.setActive.call(actualDesk, true);
      this.bringToFront(actualDesk);
      this.renderAll();
      this.fire("entity:selected", { entity: actualDesk });
    }
  }
  activateDesk(deskId: string) {
    this.getObjectByAssociation(deskId)?.setActive(true);
  }

  colorDesks(desksAndColors: DeskColorHolder[]) {
    this.getDesks().forEach((desk) => {
      desksAndColors.forEach((deskAndColor) => {
        if (desk.id === deskAndColor.deskId) {
          if (deskAndColor.fill === "chair") {
            desk.setChairColor(deskAndColor.color);
          } else {
            desk.setDeskColor(deskAndColor.color);
          }
        }
      });
    });
  }
  clearDeskColors() {
    this.getDesks().forEach((desk) => {
      desk.setDeskColor("white");
      desk.setChairColor("white");
    });
  }
  offAll(key: string, fn: any) {
    const events = (this as any).__eventListeners;
    events[key] = events[key]?.filter((e: any) => e !== fn);
  }
  deselectDesks() {
    this.getDesks().forEach((d) => d.setActive(false));
    this.renderAll();
  }
  clearActiveObjectHighlights() {
    this.getObjects().forEach((d: any) => d.setActive?.(false));
  }
  activateObject(id?: string) {
    if (!id) {
      return;
    }
    this.getObjects().forEach((d: any) => d.id === id && d.setActive?.(true));
    this.requestRenderAll();
  }
  deselectObjects(deskId?: string) {
    this.getObjects()
      .filter((obj: any) => {
        if (deskId) {
          return obj.id === deskId;
        } else {
          return obj.active === true;
        }
      })
      .forEach((o: any) => {
        o?.setActive(false);
        this.fire("entity:deselected", { entity: o });
        this.renderAll();
      });
  }
  disableObjectEvents() {
    this.forEachObject((obj) => {
      obj.set({ evented: false });
    });
  }
  zoomToFitObjects() {
    if (this.getObjects().length < 1) {
      return;
    }
    let x1 = Math.min(
      ...this.getObjects().map((obj) => obj.getBoundingRect().left!)
    );
    let y1 = Math.min(
      ...this.getObjects().map((obj) => obj.getBoundingRect().top!)
    );
    let x2 = Math.max(
      ...this.getObjects().map(
        (obj) => obj.getBoundingRect().left! + obj.getBoundingRect().width
      )
    );
    let y2 = Math.max(
      ...this.getObjects().map(
        (obj) => obj.getBoundingRect().top! + obj.getBoundingRect().height
      )
    );
    let height = Math.abs(y1) + Math.abs(y2);
    let width = Math.abs(x1) + Math.abs(x2);
    this.setZoom(1);

    let centerPoint = { x: (x1 + x2) / 2, y: (y1 + y2) / 2 };
    const heightDist = this.getHeight() - height;
    const widthDist = this.getWidth() - width;

    this.absolutePan(
      new fabric.Point(
        -this.width! / 2 + centerPoint.x,
        -this.height! / 2 + centerPoint.y
      )
    );
    let groupDimension = 0;
    let canvasDimension = 0;
    if (heightDist < widthDist) {
      groupDimension = height;
      canvasDimension = this.getHeight();
    } else {
      groupDimension = width;
      canvasDimension = this.getWidth();
    }
    const zoom = (canvasDimension / groupDimension) * 0.8;
    this.zoomToPoint({ x: this.width! / 2, y: this.height! / 2 }, zoom);
  }
  addCenterIndicator() {
    let center = new fabric.EditMapCenterPoint();
    this.add(center);
    this.centerObject(center);
    this.renderAll();
  }

  private initEventHandlers() {
    let isMoving = false;
    let isZooming = false;
    let currSelection = this.selection;
    let lastMousePos = { x: 0, y: 0 };
    let zoomStartScale = 1;
    if (this.gridSize) {
      this.on("object:moving", (options) => {
        if (!this.gridSize || !options.target) {
          return;
        }
        let left = (options.target.left || 0) / this.gridSize;
        let top = (options.target.top || 0) / this.gridSize;
        let params: IObjectOptions = {};
        if (Math.round(left * 4) % 4 === 0) {
          params["left"] = Math.round(left * this.gridSize);
        }
        if (Math.round(top * 4) % 4 === 0) {
          params["top"] = Math.round(top * this.gridSize);
        }
        if (params.left || params.top) {
          options.target.set(params).setCoords();
        }
      });
    }
    this.on("touch:gesture", (e) => {
      const event: TouchEvent = e.e as any;
      const self = (e as any).self;
      if (event.touches && event.touches.length === 2) {
        var point = new fabric.Point(self.x, self.y);
        if (self.state === "start") {
          zoomStartScale = this.getZoom();
          isZooming = true;
        }
        var delta = zoomStartScale * self.scale;
        this.zoomToPoint(point, delta);
      }
    });
    this.on("touch:start", (e) => {
      this.selection = false;
    });
    this.on("touch:end", (e) => {
      this.selection = currSelection;
      if (!isZooming) {
        this.setViewportTransform(this.viewportTransform!);
      } else {
        isZooming = false;
      }
    });
    this.on("touch:drag", (opt) => {
      let event: any = opt;
      if (event.e.type === "touchstart") {
        this.fire("touch:start", event);
        return;
      }
      if (event.e.type === undefined) {
        this.fire("touch:end", event);
        return;
      }
      if (isZooming) {
        return;
      }
      const touchEvent: TouchEvent = event.e as any;
      if (!touchEvent.touches || touchEvent.touches.length > 1) {
        return;
      }
      let currentX = touchEvent.touches[0].clientX;
      let currentY = touchEvent.touches[0].clientY;
      let xChange = currentX - lastMousePos.x;
      let yChange = currentY - lastMousePos.y;

      if (
        Math.abs(currentX - lastMousePos.x) <= 50 &&
        Math.abs(currentY - lastMousePos.y) <= 50
      ) {
        var delta = new fabric.Point(xChange, yChange);
        this.relativePan(delta);
      }
      lastMousePos = { x: currentX, y: currentY };
    });
    this.on("mouse:wheel", (opt) => {
      let delta = opt.e.deltaY;
      let zoom = this.getZoom();
      zoom *= 0.999 ** delta;
      if (zoom > 20) zoom = 20;
      if (zoom < 0.01) zoom = 0.01;
      this.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
      opt.e.preventDefault();
      opt.e.stopPropagation();
    });
    this.on("mouse:down", (options) => {
      if (!options.target && (options.e.altKey || options.button === 2)) {
        isMoving = true;
        this.selection = false;
        lastMousePos = { x: options.e.clientX, y: options.e.clientY };
      }
      if (options.e.type === "touchstart") {
        this.selection = false;
        const touchEvent: TouchEvent = options.e as any;
        const touch = touchEvent.targetTouches[0];
        if (touch) {
          lastMousePos = { x: touch.clientX, y: touch.clientY };
        }
      }
    });
    this.on("mouse:up", (options) => {
      if (!options.target) {
        if (isMoving) {
          this.selection = currSelection;
          isMoving = false;
          this.setViewportTransform(this.viewportTransform!);
        }
      }
    });
    this.on("mouse:move", (opt) => {
      if (isMoving) {
        let e = opt.e;
        this.setCursor("grabbing");
        let vpt = this.viewportTransform!;
        vpt[4] += e.clientX - lastMousePos.x;
        vpt[5] += e.clientY - lastMousePos.y;
        this.requestRenderAll();

        lastMousePos = { x: e.clientX, y: e.clientY };
      }
    });
    if (this.actions) {
      this.on("object:modified", () => {
        this.isDirty = true;
      });
      this.on("object:added", () => {
        this.isDirty = true;
      });
      this.on("object:removed", () => {
        this.isDirty = true;
      });
      this.on("map:reloaded", () => {
        this.isDirty = false;
      });
    }
  }
}
