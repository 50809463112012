import { useAuthenticationStore } from "../../../context/authContext";
import UserEditView from "../../hr/users/UserEditView/UserEditView";
import styles from './ProfilePage.module.css';
type Props = {};

export default function ProfilePage({}: Props) {
  const { auth } = useAuthenticationStore();
  return (
    <div className={`appContent ${styles.container}`}>
      <UserEditView viewOnly={true} userEmail={auth?.userEmail} closable={false} />
    </div>
  );
}
