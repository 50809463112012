import {
  ChartOptions
} from "chart.js";
import dayjs, { Dayjs } from "dayjs";
import {
  useRef
} from "react";
import { Line } from "react-chartjs-2";
import { useSearchParams } from "react-router-dom";
import CenteredEmptyPlaceholder from "../../../../components/common/CenteredEmptyPlaceholder";
import LoadingBoundry from "../../../../components/common/LoadingBoundry/LoadingBoundry";
import { useReport } from "../../../../repo/reports";
import { getRandomColor } from "../../../../utils/colorUtils";
import { getMonthName } from "../../../../utils/dateUtils";
import ChartContainer from "../components/ChartContainer/ChartContainer";
import { chartLegend, ChartPopup, ChartPopupActions } from "../components/ConfigPopup/ConfigPopup";
import GraphControlContainer from "../components/GraphControlContainer/GraphControlContainer";
import { PeriodPicker } from "../components/PeriodPicker";
import { useChartContext } from "../context/chartContext";
import styles from "./UsersReport.module.css";
type Props = {};

export default function UsersReport({}: Props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const dateFrom = dayjs(searchParams.get("dateFrom"));
  const dateTo = dayjs(searchParams.get("dateTo"));
  const {
    data: report,
    isLoading,
    isError,
    refetch,
  } = useReport({
    dateFrom:
      (dateFrom.isValid() && dateFrom.format("YYYY-MM-DD")) || undefined,
    dateTo: (dateTo.isValid() && dateTo.format("YYYY-MM-DD")) || undefined,
    type: "USER_ROTATION",
  });
  const datasets = report?.charts?.map((chart) => {
    const color =
      useChartContext.getState().chartSettings.get(chart.name)?.color ||
      getRandomColor();
    const hidden =
      useChartContext.getState().chartSettings.get(chart.name)?.hidden === true;
    const type: any =
      useChartContext.getState().chartSettings.get(chart.name)?.type || "bar";
    return {
      label: chart.name,
      data: chart.values.map((c) => c.y),
      fill: false,
      hidden: hidden,
      type: type,
      borderColor: color,
      backgroundColor: color,
      order: type === "line" ? 1 : 2,
      tension: 0.1,
    };
  });
  const labels = Array.from(
    new Set(
      report?.charts
        ?.flatMap((c) => c.values)
        .map((c) => c.x)
        .map(getMonthName)
    )
  );
  const popupRef = useRef<ChartPopupActions>(null);

  const options: ChartOptions<"line"> = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        grace: "10%",
      },
    },
    plugins: {
      datalabels: {
        display: false,
      },
      legend: chartLegend(popupRef),
      title: {
        display: true,
        text: "Users",
      },
    },
  };
  return (
    <>
      <GraphControlContainer>
        <PeriodPicker />
      </GraphControlContainer>
      <ChartContainer>
        <LoadingBoundry
          loading={isLoading}
          error={isError}
          description="Failed to load chart"
          onAction={refetch}
        >
          <ChartPopup ref={popupRef} />
          {labels.length > 0 ? (
            <Line
              className={styles.graph}
              options={options}
              data={{
                labels: labels,
                datasets: datasets || [],
              }}
            />
          ) : (
            <CenteredEmptyPlaceholder description={"No period selected"} />
          )}
        </LoadingBoundry>
      </ChartContainer>
    </>
  );
}

function getMonthNamesBetweenDates(startDate?: Dayjs, endDate?: Dayjs) {
  if (!(startDate?.isValid() && endDate?.isValid())) {
    return [];
  }
  const months = [];
  let currentDate = dayjs(startDate);
  const format = endDate.diff(startDate, "years") > 0 ? "YYYY-MM" : "MMMM";
  while (
    currentDate.isSame(endDate, "month") ||
    currentDate.isBefore(endDate, "month")
  ) {
    const monthName = currentDate.format(format);
    months.push(monthName);
    currentDate = currentDate.add(1, "month");
  }

  return months;
}
