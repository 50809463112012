import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import { IconList, IconMap } from "@tabler/icons-react";
import { Button, SelectProps } from "antd";
import { Desk } from "fabric/fabric-impl";
import React, { useEffect, useRef, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import DisableByRole from "../../../components/common/DisableByRole";
import LoadingBoundry from "../../../components/common/LoadingBoundry/LoadingBoundry";
import { MapDropdown } from "../../../components/common/MapDropDown";
import { usePermissionsStore } from "../../../context/authContext";
import { useMap } from "../../../repo/maps";
import { mapService } from "../../../services/map/MapService";
import { viewMapHolder } from "../../../utils/map/MapHolder";
import { WhoDeskMap } from "../../../utils/map/WhoDeskMap";
import { mapQueue } from "../../../utils/map/mapQueue";
import { largeOnMobile } from "../../../utils/reactUtils";
import { useMapSelection } from "../context/mapSelectionContext";
import styles from "./MapViewPage.module.css";
import DeskCounter from "./components/DeskCounter/DeskCounter";
import MapStatusIndicator from "./components/MapStatusIndicator/MapStatusIndicator";
import { MapViewDatePicker } from "./components/MapViewDatePicker/MapViewDatePicker";
import { useMapEntityContext } from "./context/mapEntityContext";
import MapViewTooltip from "./navigator/components/MapViewTooltip";

export default function MapViewPage({}: {}) {
  const { mapId } = useParams();
  const [isHidden, setHidden] = useState(false);
  const toggleMapOnMobile = () => {
    setHidden(!isHidden);
  };

  return (
    <div className="appContent" id={styles.content}>
      <div className={styles.gridContainer}>
        <div className={styles.datePickerContainer}>
          <MapViewDatePicker />
        </div>
        <div
          className={`${isHidden ? styles.hideOnMobile : ""} ${
            styles.outletContainer
          } borderBox`}
        >
          <Outlet />
        </div>
        <div id={styles.mapContainer} className="borderBox">
          <DeskCounter />
          <MapSelector />
          <MapViewTooltip />
          <MapStatusIndicator />
          <MapView mapId={mapId} />
        </div>
      </div>

      <MapNavigationButton
        isViewingMap={isHidden}
        onClick={toggleMapOnMobile}
      />
    </div>
  );
}

const MapNavigationButton = ({
  isViewingMap,
  onClick,
}: {
  isViewingMap: boolean;
  onClick: () => void;
}) => {
  const selectedEntity = useMapEntityContext.use.selectedEntity?.();
  const buttonRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (selectedEntity) {
      buttonRef.current?.addEventListener("animationend", () => {
        buttonRef.current?.classList.remove(
          styles.highlightedMobileFloatingIcon
        );
      });
      buttonRef.current?.classList.add(styles.highlightedMobileFloatingIcon);
    }
  }, [selectedEntity]);
  return (
    <div
      className={styles.mobileFloatingIcon}
      ref={buttonRef}
      onClick={onClick}
    >
      {isViewingMap ? <IconList /> : <IconMap />}
    </div>
  );
};
const CanvasMemo = React.memo(
  React.forwardRef<HTMLCanvasElement, {}>((props, ref) => (
    <canvas ref={ref} {...props} />
  ))
);
const MapView = ({ mapId }: { mapId?: string }) => {
  const { data: map, error, refetch, isLoading } = useMap(mapId);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  function loadMapFromJson(json: Object) {
    viewMapHolder.getMap().loadMapFromJson(json);
  }
  useEffect(() => {
    const viewingMap = new WhoDeskMap(canvasRef.current, {
      height: canvasRef.current?.offsetHeight,
      width: canvasRef.current?.offsetWidth,
      containerClass: styles.canvasContainer,
      selection: false,
      selectableDesks: true,
      mapService: mapService,
    });
    viewMapHolder.current = viewingMap;
    viewingMap.zoomToFitObjects();
    viewingMap.on("entity:deselected", ({ entity }) => {
      if (entity.type !== "desk") {
        return;
      }
      const desk = entity as Desk;
      mapQueue.add((map) => {
        desk.resetToValuesBeforeSelection();
      });
    });
    if (map?.canvas) {
      loadMapFromJson(map.canvas);
    }
    return () => {
      viewMapHolder.dispose();
      mapService.unsubscribe();
    };
  }, [map]);
  useEffect(() => {
    return () => {
      viewMapHolder.dispose();
    };
  }, [error, isLoading]);
  return (
    <LoadingBoundry
      error={error}
      description="Could not load map"
      onAction={refetch}
      loading={isLoading}
    >
      <div className={styles.canvasContainer}>
        <CanvasMemo ref={canvasRef} />
      </div>
    </LoadingBoundry>
  );
};

const MapSelector = (props: {}) => {
  const { mapId } = useParams();
  const nav = useNavigate();
  const { isUser } = usePermissionsStore();
  const { selectMap } = useMapSelection();
  const onSwitch: SelectProps["onChange"] = (id) => {
    selectMap(id);
    nav(`/maps/${id}${window.location.search}`);
  };
  return (
    <div className={styles.mapSelectorContainer}>
      <MapDropdown
        className={styles.mapSelectorSelect}
        onChange={onSwitch}
        showOnlyActive={isUser()}
        size={largeOnMobile()}
      />
      <DisableByRole disabledRole="USER">
        <Button
          title="edit map"
          className={styles.hideOnMobile}
          onClick={(e) => {
            nav(`/maps/${mapId}/edit`);
            e.stopPropagation();
          }}
          icon={<EditOutlined />}
        />
      </DisableByRole>
      <DisableByRole disabledRole="USER">
        <Button
          title="add new map"
          className={styles.hideOnMobile}
          onClick={(e) => {
            nav(`/maps/new`);
            e.stopPropagation();
          }}
          icon={<PlusOutlined />}
        />
      </DisableByRole>
    </div>
  );
};
