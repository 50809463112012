import { Dayjs } from "dayjs";
import { create } from "zustand";
import {
  BookingUnavailability,
  DeskResponse,
} from "../../../../../repo";

interface MapBookingsStore {
  desks: DeskResponse[];
  availableDesks: DeskResponse[];
  unavailableDesks: DeskResponse[];
  unavailableBookings: BookingUnavailability[];
  dateFrom?: Dayjs;
  dateTo?: Dayjs;
  setDesks: (desks: DeskResponse[]) => void;
  setUnavailableBookings: (
    unavailableBookings: BookingUnavailability[]
  ) => void;
  reset: () => void;
  selectDates: (dateFrom?: Dayjs, dateTo?: Dayjs) => void;
}
const initState = {
  desks: [],
  availableDesks: [],
  unavailableDesks: [],
  unavailableBookings: [],
  dateFrom: undefined,
  dateTo: undefined,
};
export const useMapBookings = create<MapBookingsStore>()((set, get) => ({
  ...initState,
  selectDates: (dateFrom, dateTo) => {
    set((state) => ({ ...state, dateFrom, dateTo }));
  },
  setDesks: (desks) => {
    let usableDesks = desks.filter((desk) => desk.isBookable);
    let unavailableIds = get().unavailableBookings.map((d) => d.deskId);
    if (unavailableIds.length > 0) {
      let availableDesks = usableDesks.filter(
        (desk) => !unavailableIds.includes(desk.id)
      );
      set((state) => ({ ...state, availableDesks, desks: usableDesks }));
    } else {
      set((state) => ({ ...state, desks: usableDesks }));
    }
  },
  setUnavailableBookings: (unavailableBookings) => {
    set((state) => ({ ...state, unavailableBookings: unavailableBookings }));
    let desks = get().desks;
    const unavailableIds = unavailableBookings.map((d) => d.deskId);
    let availableDesks = desks.filter(
      (desk) => !unavailableIds.includes(desk.id)
    );
    let unavailableDesks = desks.filter((desk) =>
      unavailableIds.includes(desk.id)
    );
    set((state) => ({ ...state, availableDesks, unavailableDesks }));
  },
  reset: () => {
    set((state) => ({ ...initState, desks: state.desks }));
  },
}));
