import { DatePicker, DatePickerProps, SelectProps } from "antd";
import dayjs from "dayjs";
import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import DisableByRole from "../../../../../components/common/DisableByRole";
import LoadingBoundry from "../../../../../components/common/LoadingBoundry/LoadingBoundry";
import { MapDropdown } from "../../../../../components/common/MapDropDown";
import {
  useAuthenticationStore,
  usePermissionsStore,
} from "../../../../../context/authContext";
import { DeskBookingResponse } from "../../../../../repo";
import { useBookings, useDeleteBooking } from "../../../../../repo/bookings";
import { largeOnMobile } from "../../../../../utils/reactUtils";
import BorderDivider from "../../../BorderDivider/ListDivider";
import CenteredFloatingContainer from "../../../CenteredFloatingContainer/CenteredFloatingContainer";
import { UserSelection } from "../../components/UserSelection/UserSelection";
import { InfiniteBookingList } from "../UserBookingsOverview/UserBookingsOverview";
import { HrBookingListItem } from "../components/BookingListItem";
import { useBookingsContext } from "../context/bookingsContext";
import styles from "./HrBookingOverview.module.css";
type Props = {};

export default function HrBookingOverview({}: Props) {
  return (
    <div className="appContent">
      <BookingsContainer />
    </div>
  );
}
const BookingsContainer = () => {
  const { bookings } = useBookingsContext();
  const { isPending, refetch, getNextPage, hasNextPage, isError } =
    useFetchBookings();
  const { mutate: deleteBooking } = useDeleteBooking();
  const onDelete = (booking: DeskBookingResponse) => {
    deleteBooking(booking.id, {
      onSuccess() {
        refetch();
      },
    });
  };
  return (
    <LoadingBoundry
      error={isError}
      description="Failed to fetch bookings"
      onAction={refetch}
    >
      <CenteredFloatingContainer header={"Bookings"} withoutClose>
        <BookingOverviewBody>
          <BookingOverviewFilter />
          <BorderDivider />
          <InfiniteBookingList
            bookings={bookings}
            isLoading={isPending}
            onLoadMore={getNextPage}
            hasNextPage={hasNextPage}
            render={(booking) => (
              <HrBookingListItem
                key={booking.id}
                booking={booking}
                onDelete={onDelete}
              />
            )}
          />
        </BookingOverviewBody>
      </CenteredFloatingContainer>
    </LoadingBoundry>
  );
};
const allowedSearchValues = [
  "mapId",
  "deskId",
  "dateFrom",
  "dateTo",
  "userEmail",
];
export const BookingOverviewFilter = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { isUser } = usePermissionsStore();
  const { setParams, params } = useBookingsContext();
  const onUserChange = (email: string) => {
    if (!email) {
      searchParams.delete("userEmail");
    } else {
      searchParams.set("userEmail", email);
    }
    setSearchParams(searchParams, { replace: true });
  };
  const onDateFromChange: DatePickerProps["onChange"] = (date) => {
    if (date === null) {
      searchParams.delete("dateFrom");
    } else {
      searchParams.set("dateFrom", date.format("YYYY-MM-DD"));
    }
    setSearchParams(searchParams, { replace: true });
  };
  const onDateToChange: DatePickerProps["onChange"] = (date) => {
    if (date === null) {
      searchParams.delete("dateTo");
    } else {
      searchParams.set("dateTo", date.format("YYYY-MM-DD"));
    }
    setSearchParams(searchParams, { replace: true });
  };
  const onMapChange: SelectProps["onChange"] = (mapId) => {
    if (!mapId) {
      searchParams.delete("mapId");
    } else {
      searchParams.set("mapId", mapId);
    }
    setSearchParams(searchParams, { replace: true });
  };
  useEffect(() => {
    const data = Object.fromEntries(searchParams);
    const result = _.pickBy(data, (value, key) =>
      allowedSearchValues.includes(key)
    );
    if (!_.isEqual(params, result)) {
      setParams(result);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);
  return (
    <div className={styles.filterContainer}>
      <DisableByRole disabledRole="USER">
        <UserSelection
          onChange={onUserChange}
          value={params.userEmail}
          className={styles.userFilter}
          allowClear
          size={largeOnMobile()}
        />
      </DisableByRole>
      <MapDropdown
        showOnlyActive={isUser()}
        className={styles.mapFilter}
        onChange={onMapChange}
        value={params.mapId}
        placeholder={"Select map"}
        allowClear
        hideOnSingleMap
        defaultValue={undefined}
        size={largeOnMobile()}
      />
      <div className={styles.datePickers}>
        <DatePicker
          placeholder="Date from"
          onChange={onDateFromChange}
          className={styles.dateFilter}
          defaultPickerValue={
            params.dateTo ? dayjs(params.dateTo).subtract(2, "day") : undefined
          }
          disabledDate={(day) => {
            if (params.dateTo) {
              return day.isAfter(dayjs(params.dateTo));
            }
            return false;
          }}
          value={params.dateFrom ? dayjs(params.dateFrom) : undefined}
          size={largeOnMobile()}
        />
        <DatePicker
          placeholder="Date to"
          onChange={onDateToChange}
          defaultPickerValue={
            params.dateFrom ? dayjs(params.dateFrom).add(2, "day") : undefined
          }
          className={styles.dateFilter}
          disabledDate={(day) => {
            if (params.dateFrom) {
              return day.isBefore(dayjs(params.dateFrom));
            }
            return false;
          }}
          value={params.dateTo ? dayjs(params.dateTo) : undefined}
          size={largeOnMobile()}
        />
      </div>
    </div>
  );
};
const DEBOUNCE_TIME = 1500;
export const useFetchBookings = () => {
  const { auth } = useAuthenticationStore();
  const { isUser } = usePermissionsStore();
  const { setBookings, params, bookings } = useBookingsContext();
  const [isTimerPending, setIsTimerPending] = useState(false);
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const getParams = () => {
    if (isUser()) {
      return { ...params, userEmail: auth?.userEmail };
    } else {
      return params;
    }
  };
  const { data, isPending, isError, refetch, hasNextPage, getNextPage } =
    useBookings({ ...getParams(), pageSize: 100 }, false);

  useEffect(() => {
    let timer = timerRef.current;
    if (timer !== null) {
      clearTimeout(timer);
    }
    setIsTimerPending(true);
    timer = setTimeout(() => {
      setIsTimerPending(false);
      refetch();
    }, DEBOUNCE_TIME);

    return () => {
      if (timer !== null) {
        clearTimeout(timer);
      }
    };
  }, [refetch, params]);
  useEffect(() => {
    if (data) {
      setBookings(data.pages.flatMap((p) => p));
    }
  }, [data, setBookings]);
  return {
    isPending: isTimerPending || isPending,
    isError,
    refetch,
    hasNextPage,
    getNextPage,
  };
};

export const BookingOverviewBody = (
  props: React.HTMLAttributes<HTMLDivElement>
) => {
  return <div className={styles.body} {...props} />;
};
