import { Button, Flex, Tooltip } from "antd";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { CustomInputText } from "../../../../components/common/CustomInputText/CustomInputText";
import LoadingBoundry from "../../../../components/common/LoadingBoundry/LoadingBoundry";
import { CreateCompanyUserRequest } from "../../../../repo";
import { useUserCreate } from "../../../../repo/users";
import { parseError } from "../../../../utils/errorUtils";
import CenteredFloatingContainer from "../../../common/CenteredFloatingContainer/CenteredFloatingContainer";
import styles from "./UserCreateView.module.css";
type Props = {};

export default function UserCreateView({}: Props) {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<CreateCompanyUserRequest>();
  const nav = useNavigate();
  const {
    mutate: createUser,
    isPending: isCreating,
    isError,
    error,
    reset,
  } = useUserCreate();
  const onSubmit = (data: CreateCompanyUserRequest) => {
    createUser(
      { user: data },
      {
        onSuccess(data, { user }, context) {
          nav(`/people/${user.email}${window.location.search}`);
        },
      }
    );
  };
  return (
    <LoadingBoundry
      loading={isCreating}
      error={isError}
      description={parseError(error) || "Failed to create user"}
      onAction={reset}
    >
      <CenteredFloatingContainer header={"Create user"} backRoute={"/people"}>
        <CompanyUserForm onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.body}>
            <Controller
              name="name"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <CustomInputText
                    label="Name"
                    className="padding-top"
                    placeholder="Jane Doe"
                    {...field}
                    error={fieldState.error?.message}
                    required={true}
                  />
                );
              }}
              rules={{
                required: "Name is required",
                maxLength: { value: 250, message: "Value too long" },
              }}
            />
            <Controller
              name="email"
              control={control}
              render={({ field }) => {
                return (
                  <Tooltip title="You will not be able to change this in the future">
                    <CustomInputText
                      label="Email"
                      className="padding-top"
                      placeholder="jane.doe@who.com"
                      {...field}
                      error={(errors as any)?.email?.message}
                      required={true}
                    />
                  </Tooltip>
                );
              }}
              rules={{
                required: "Email is required",
                maxLength: { value: 250, message: "Value too long" },
                pattern: {
                  value:
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: "Invalid email address",
                },
              }}
            />
            <Flex id={styles.buttonContainer} justify="space-between">
              <Button size="middle" danger onClick={() => nav(-1)}>
                Cancel
              </Button>
              <Button size="middle" htmlType="submit" type="primary">
                Save
              </Button>
            </Flex>
          </div>
        </CompanyUserForm>
      </CenteredFloatingContainer>
    </LoadingBoundry>
  );
}

export const CompanyUserForm = (
  props: React.FormHTMLAttributes<HTMLFormElement>
) => {
  return <form {...props} id={styles.userCreateViewForm} />;
};
