import { Button } from "antd";
import {
  logOut,
  setGlobalPermissions,
  usePermissionsStore
} from "../../../context/authContext";
import { useCompanies } from "../../../repo/company";
import CenteredFloatingContainer from "../CenteredFloatingContainer/CenteredFloatingContainer";
import WavyBackground from "../WavyBackground/WavyBackground";
import styles from "./CompanySelectorPage.module.css";
type Props = {};

export default function CompanySelectorPage({}: Props) {
  const { isAdmin, permissions } = usePermissionsStore();
  const { data: companies = [] } = useCompanies();
  const onCompanyClick = (id: string) => {
    const companyPermissions = permissions?.find((p) => p.companyId === id);
    if (companyPermissions) {
      setGlobalPermissions({
        companyId: companyPermissions.companyId,
        role: companyPermissions.role as any,
      });
    } else if (isAdmin()) {
      setGlobalPermissions({
        companyId: id,
        role: "HR",
      });
    }
  };
  const cancel = () => {
    logOut();
  };
  return (
    <WavyBackground>
      <div className={styles.content}>
        <CenteredFloatingContainer header={"Select a company"} withoutClose>
          <div className={styles.container}>
            {companies.map((c) => (
              <Button key={c.id} onClick={() => onCompanyClick(c.id)}>
                {c.name}
              </Button>
            ))}
            <Button danger onClick={cancel}>
              Cancel
            </Button>
          </div>
        </CenteredFloatingContainer>
      </div>
    </WavyBackground>
  );
}
