import dayjs, { Dayjs } from "dayjs";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { createSelectors } from "../../../../utils/reactUtils";

type State = {
  date: Dayjs;
};

type Actions = {
  reset: () => void;
  setDate: (date: dayjs.Dayjs) => void;
};

const initialState: State = {
  date: dayjs(),
};

const useDateContextInner = create(
  immer<State & Actions>((set) => ({
    ...initialState,
    reset: () => set(initialState),
    setDate: (date) => {
      set((state) => {
        state.date = date;
      });
    },
  }))
);
export const useDateContext = createSelectors(useDateContextInner);
