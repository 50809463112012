import { Canvas } from "fabric/fabric-impl";
import { editMapHolder } from "../../../../../../utils/map/MapHolder";
import styles from "./MapObjectSelector.module.css";
type Props = {};

export default function MapObjectSelector({}: Props) {
  return (
    <div id={styles.mapEditControls} className="borderBox">
      <span className={styles.mapEditControlsTitle}>Shapes</span>
      <div id={styles.controlsColumn}>
        <ControlElement onClick={() => addLine(editMapHolder.getMap())}>
          <line
            transform="matrix(1 0 0 1 75 75)"
            x1="-50"
            y1="0"
            x2="50"
            y2="0"
          />
        </ControlElement>
        <ControlElement
          onClick={() => {
            createNewRectangle(editMapHolder.getMap());
          }}
        >
          <g fill="#fff" stroke="black" strokeWidth="1">
            <rect
              transform="matrix(1 0 0 1 75 75)"
              x="-50"
              y="-50"
              rx="0"
              ry="0"
              width="100"
              height="100"
            />
          </g>
        </ControlElement>
        <ControlElement
          onClick={() => {
            createNewMeetingRoom(editMapHolder.getMap());
          }}
          viewBox="0 0 100 100"
        >
          <g fill="#fff" stroke="black" strokeWidth="1">
            <text x="0" y="20" fill="black" style={{ fontSize: "1rem" }}>
              Meeting room
            </text>
            <rect
              transform="translate(20,30)"
              x="0"
              y="0"
              rx="0"
              ry="0"
              width="60"
              height="60"
            />
          </g>
        </ControlElement>
        <ControlElement
          onClick={() => {
            createNewDesk(editMapHolder.getMap());
          }}
          viewBox="-60 -60 120 120"
        >
          <g transform="matrix(0.25 0 0 0.25 -0.75 15.44)">
            <path
              strokeWidth={3}
              transform=" translate(-100, -110)"
              d="M 30 168 L 30 30 Q 20 10 10 30 L 10 170 M 190 170 L 190 30 Q 180 10 170 30 L 170 168 M 10 170 Q 10 180 20 190 Q 100 210 180 190 Q 190 180 190 170 M 190 170 Q 100 160 10 170 M 30 55 Q 100 30 170 55 L 170 168 Q 100 160 30 168 Z"
            />
          </g>
          <g transform="matrix(1 0 0 1 0 -13.81)">
            <rect x="-50" y="-25" rx="0" ry="0" width="100" height="50" />
          </g>
        </ControlElement>
        <ControlElement
          onClick={() => {
            createNewCircle(editMapHolder.getMap());
          }}
        >
          <circle cx="75" cy="75" r="40" />
        </ControlElement>
        <ControlElement
          onClick={() => {
            addChair(editMapHolder.getMap());
          }}
          viewBox="0 -50 200 300"
        >
          <path
            strokeWidth={3}
            d="M30,168 V30 Q20 10 10 30 V170M190 170 V30 Q180 10 170 30 V168M10 170 Q10 180 20 190 Q100 210 180 190 Q190 180 190 170 M190 170 Q100 160 10 170 M30 55 Q100 30 170 55 V168 Q100 160 30 168 Z"
          />
        </ControlElement>
        <ControlElement
          onClick={() => {
            addDoors(editMapHolder.getMap());
          }}
          viewBox="0 -50 200 300"
        >
          <path d="M190 0 Q0 0 0 180" strokeWidth={3} />
          <path
            transform=" translate(-3, 0)"
            d="M200 180 V0"
            strokeWidth={10}
          />
          <path d="M0 180 H200 V210 H0 Z" strokeWidth={3} />
        </ControlElement>{" "}
        <ControlElement
          onClick={() => {
            addSink(editMapHolder.getMap());
          }}
          viewBox="0 0 511 511"
        >
          <path
            fill="black"
            d="M488 68H24C11 68 0 79 0 92v96a254 254 0 0 0 256 255 254 254 0 0 0 255-255V92c0-13-11-24-23-24zm8 120a239 239 0 0 1-240 240A239 239 0 0 1 15 188V92c0-5 4-9 9-9h464c4 0 8 4 8 9v96z"
          />
          <path
            fill="black"
            d="M436 156H317l-2-2-31-49-1-1a40 40 0 0 0-66 36l2 16H75c-15 0-27 12-27 27v4a206 206 0 0 0 351 148c41-40 64-95 64-152 0-15-12-27-27-27zm-180-49c6 0 12 2 16 7l31 48 1 2 1 3c0 3-1 7-4 10-4 4-10 5-15 2l-48-31c-3-3-5-7-6-11l-1-6a24 24 0 0 1 25-24zm19 83-9 61c0 5-5 9-10 9-6 0-10-4-11-9l-10-87 40 26zm113 135a188 188 0 0 1-270-3c-35-36-55-84-55-134v-5c0-7 5-12 12-12h146l9 81a26 26 0 0 0 51 1l8-57a27 27 0 0 0 31-25h116c7 0 12 5 12 12 0 53-22 104-60 142z"
          />
        </ControlElement>
        <ControlElement
          onClick={() => {
            addToilet(editMapHolder.getMap());
          }}
          viewBox="0 0 511 511"
        >
          <path
            fill="black"
            d="M423.5 0h-336C74.5 0 64 10.5 64 23.5v64c0 21.4 9.4 40.6 24.4 53.7-.3.7-.4 1.5-.4 2.3v200C88 435.9 163.1 511 255.5 511S423 435.9 423 343.5v-200c0-.8-.1-1.6-.4-2.3A71.3 71.3 0 0 0 447 87.5v-64c0-13-10.5-23.5-23.5-23.5zM408 343.5c0 84-68.4 152.5-152.5 152.5S103 427.6 103 343.5V151.2a71 71 0 0 0 32.5 7.8h240a71 71 0 0 0 32.5-7.8v192.3zm24-256a56.6 56.6 0 0 1-56.5 56.5h-240A56.6 56.6 0 0 1 79 87.5v-64c0-4.7 3.8-8.5 8.5-8.5h336c4.7 0 8.5 3.8 8.5 8.5v64z"
          />
          <path
            fill="black"
            d="M303.5 192h-96c-39.4 0-71.5 32-71.5 71.5v76.8c0 31.3 11.6 61.2 32.7 84.2a117 117 0 0 0 86.9 38.5c30.9 0 60-11.6 82.4-33 23.9-22.8 37-53.5 37-86.5v-80c0-39.4-32-71.5-71.5-71.5zM360 343.5c0 28.8-11.5 55.7-32.4 75.6a103.7 103.7 0 0 1-77.2 28.8 102 102 0 0 1-70.7-33.6 109.3 109.3 0 0 1-28.7-74v-76.8a56.6 56.6 0 0 1 56.5-56.5h96a56.6 56.6 0 0 1 56.5 56.5v80z"
          />
          <path
            fill="black"
            d="M255.5 232a47.6 47.6 0 1 0 .1 95.1 47.6 47.6 0 0 0-.1-95.1zm0 80a32.5 32.5 0 1 1 0-65 32.5 32.5 0 0 1 0 65zM223.5 56a23.5 23.5 0 1 0 0 47 23.5 23.5 0 0 0 0-47zm0 32a8.5 8.5 0 1 1 0-17 8.5 8.5 0 0 1 0 17zM287.5 48a31.5 31.5 0 1 0 0 63 31.5 31.5 0 0 0 0-63zm0 48a16.5 16.5 0 1 1 0-33 16.5 16.5 0 0 1 0 33z"
          />
        </ControlElement>
        <ControlElement
          onClick={() => {
            addText(editMapHolder.getMap());
          }}
        >
          <text x="35" y="90" fill="black" style={{ fontSize: "3rem" }}>
            Text
          </text>
        </ControlElement>
        <ControlElement
          isNonSvg
          onClick={() => {
            addImage(`${process.env.PUBLIC_URL}/mapItems/extinguisher.png`);
          }}
        >
          <div className={styles.controlBoxImage}>
            <img
              src={process.env.PUBLIC_URL + "/mapItems/extinguisher.png"}
              style={{ maxHeight: "50%" }}
              alt="extinguisher"
            />
          </div>
        </ControlElement>
        <ControlElement
          isNonSvg
          onClick={() => {
            addImage(process.env.PUBLIC_URL + "/mapItems/exit.webp");
          }}
        >
          <div className={styles.controlBoxImage}>
            <img
              src={process.env.PUBLIC_URL + "/mapItems/exit.webp"}
              style={{ maxHeight: "50%" }}
              alt="exit"
            />
          </div>
        </ControlElement>
      </div>
    </div>
  );
}
const ControlElement: React.FC<{
  children: React.ReactNode;
  onClick: () => void;
  description?: string;
  isNonSvg?: boolean;
  viewBox?: string;
}> = (props) => {
  return (
    <div onClick={props.onClick} className={styles.controlBox}>
      {props.isNonSvg ? (
        props.children
      ) : (
        <svg
          width="100%"
          height="100%"
          viewBox={props.viewBox || "0 0 150 150"}
        >
          <g fill="#fff" stroke="black" strokeWidth="1">
            {props.children}
          </g>
        </svg>
      )}
    </div>
  );
};

function addChair(canvas: Canvas) {
  focusAndCenter(canvas, new fabric.Chair());
}
function addLine(canvas: Canvas) {
  focusAndCenter(canvas, new fabric.EditMapLine([0, 50, 100, 50]));
}
function addDoors(canvas: Canvas) {
  focusAndCenter(canvas, new fabric.Door());
}

function addSink(canvas: Canvas) {
  focusAndCenter(canvas, new fabric.Sink());
}

function addToilet(canvas: Canvas) {
  focusAndCenter(canvas, new fabric.Toilet());
}

function createNewRectangle(canvas: Canvas) {
  let newRect = new fabric.PolygonRect();
  focusAndCenter(canvas, newRect);
}
function createNewMeetingRoom(canvas: Canvas) {
  let newRect = new fabric.MeetingRoom();
  focusAndCenter(canvas, newRect);
}
function focusAndCenter(canvas: fabric.Canvas, obj: fabric.Object) {
  canvas.discardActiveObject();
  canvas.add(obj);
  canvas.setActiveObject(obj);
  canvas.viewportCenterObject(obj);
  canvas.renderAll();
}

function createNewCircle(canvas: Canvas) {
  let circle = new fabric.EditMapCircle();
  focusAndCenter(canvas, circle);
}
function addText(canvas: Canvas) {
  let text = new fabric.EditMapIText("Tap and edit");
  focusAndCenter(canvas, text);
}

function createNewDesk(canvas: Canvas) {
  let newDesk = new fabric.Desk();
  focusAndCenter(canvas, newDesk);
}
function createEditableLine(coords: number[]) {
  let canvas = editMapHolder.getMap();
  let line = new fabric.EditMapDynamicLine(coords);

  focusAndCenter(canvas, line);
  line.addControls(canvas);
}
function addImage(imageUrl: string) {
  fabric.MapImage.fromURL(imageUrl, (img) => {
    img.scaleToHeight(100);
    focusAndCenter(editMapHolder.getMap(), img);
  });
}
