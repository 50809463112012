import { Button } from "antd";
import { Outlet, useNavigate } from "react-router-dom";
import { SearchBar } from "../../../../components/SearchBar";
import LoadingBoundry from "../../../../components/common/LoadingBoundry/LoadingBoundry";
import { usePermissionsStore } from "../../../../context/authContext";
import { CompanyResponse } from "../../../../repo";
import { useCompanies } from "../../../../repo/company";
import { filterByTerm } from "../../../../utils/arrayUtils";
import IndicatingList from "../../../common/IndicatingList/IndicatingList";
import ListAndView from "../../../common/ListAndView/ListAndView";
import Scrollable from "../../../common/Scrollable/Scrollable";
import { TeamColorIndicator } from "../../../hr/teams/TeamsPage/TeamsPage";
import styles from "./CompaniesPage.module.css";

type Props = {};

export default function CompaniesPage({}: Props) {
  return <ListAndView list={<CompanyList />} content={<CompanyView />} />;
}

const CompanyView = () => {
  return <Outlet />;
};

const CompanyList = () => {
  const nav = useNavigate();
  let params = new URL(document.location as any).searchParams;
  let searchTerm = params.get("searchTerm");
  const { isLoading, isError, data, refetch } = useCompanies();
  let companies = filterByTerm<CompanyResponse>(data, searchTerm);
  return (
    <LoadingBoundry loading={isLoading} error={isError} onAction={refetch}>
      <div className={styles.controlsContainer}>
        <SearchBar path="/companies" />
        <Button
          type="primary"
          size="large"
          onClick={() => nav("/companies/new")}
        >
          Add company
        </Button>
      </div>
      <Scrollable>
        <IndicatingList<CompanyResponse>
          data={companies}
          errorTitle="Could not fetch teams"
          emptyTitle="No companies found"
          render={(company) => (
            <CompanyListElement key={company.id} company={company} />
          )}
        />
      </Scrollable>
    </LoadingBoundry>
  );
};

const CompanyListElement: React.FC<{ company: CompanyResponse }> = ({
  company,
}) => {
  const nav = useNavigate();
  const { companyId } = usePermissionsStore();
  return (
    <div
      className={styles.listItemContainer}
      onClick={() => {
        nav(`/companies/${company.id}`);
      }}
    >
      <strong className={styles.listItemName}>{company.name}</strong>
      {company.id === companyId && <TeamColorIndicator color="blue" />}
    </div>
  );
};
