import { fabric } from "fabric";
import { polygonDragControl } from "./Controls";

fabric.PolygonRect = fabric.util.createClass(fabric.Polygon, {
  type: "polygonRect",
  initialize: function (options: any) {
    this.callSuper(
      "initialize",
      [
        { x: 0, y: 0 },
        { x: 100, y: 0 },
        { x: 100, y: 100 },
        { x: 0, y: 100 },
      ],
      {
        stroke: "black",
        width: 100,
        height: 100,
        fill: "#0000",
        strokeUniform: true,
        snapAngle: 5,
        ...options,
      }
    );
    this.on("selected", (e: any) => {
      var lastControl = this.points.length - 1;
      this.controls = this.points.reduce(function (
        acc: any,
        point: any,
        index: any
      ) {
        acc["p" + index] = polygonDragControl(index, lastControl);
        return acc;
      },
      {});
      const { tl, bl, tr, br, ...restControls } =
        fabric.Object.prototype.controls;
      this.controls = {
        ...this.controls,
        ...restControls,
      };
      e.target.canvas.requestRenderAll();
    });
  },
});

fabric.PolygonRect.fromObject = function (object: any, callback?: any) {
  return fabric.Object._fromObject("PolygonRect", object, callback) as any;
};
