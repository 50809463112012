import { IconLine, IconRectangle } from "@tabler/icons-react";
import {
  ColorPicker,
  ColorPickerProps,
  DatePicker,
  Flex,
  Select,
  SelectProps,
} from "antd";
import {
  Chart,
  ChartDataset,
  LegendElement,
  LegendItem,
  LegendOptions,
} from "chart.js";
import dayjs from "dayjs";
import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Line, Pie } from "react-chartjs-2";
import { useSearchParams } from "react-router-dom";
import CustomCheckbox from "../../../../components/common/CustomCheckbox/CustomCheckbox";
import { ComponentWithLabel } from "../../../../components/common/CustomInputText/CustomInputText";
import LoadingBoundry from "../../../../components/common/LoadingBoundry/LoadingBoundry";
import { MapDropdown } from "../../../../components/common/MapDropDown";
import { ChartEntry } from "../../../../repo";
import { useReport } from "../../../../repo/reports";
import { isEventInElement } from "../../../../utils/reactUtils";
import BorderDivider from "../../../common/BorderDivider/ListDivider";
import ChartContainer from "../components/ChartContainer/ChartContainer";
import GraphControlContainer from "../components/GraphControlContainer/GraphControlContainer";
import TimeWindowPicker, { TIME_WINDOWS } from "../components/LabelPicker";
import { PeriodPicker } from "../components/PeriodPicker";
import { useChartContext } from "../context/chartContext";
import { CALC_TYPES, getMonthlyValues, getWeeklyValues } from "../utils/calcUtils";
import styles from "./DeskAvailabilityReport.module.css";
type Props = {};
const { RangePicker } = DatePicker;

interface DataSet {
  label: string;
  data: ChartEntry[];
  fill: boolean;
  hidden: boolean;
  type: any;
  borderColor: string;
  backgroundColor: string;
  order: number;
}
const CHART_TYPES = ["SERIES", "PIE"];
export default function DeskAvailabilityReport({}: Props) {
  const [searchParams, setSearchParams] = useSearchParams({
    window: TIME_WINDOWS[0],
    chartType: CHART_TYPES[0],
    calc: CALC_TYPES[0],
  });
  const dateFrom = dayjs(searchParams.get("dateFrom")).startOf("month");
  const dateTo = dayjs(searchParams.get("dateTo")).endOf("month");
  const {
    data: report,
    isLoading,
    isError,
    refetch,
  } = useReport({
    dateFrom:
      (dateFrom.isValid() && dateFrom.format("YYYY-MM-DD")) || undefined,
    dateTo: (dateTo.isValid() && dateTo.format("YYYY-MM-DD")) || undefined,
    type: "DESK_AVAILABILITY",
    mapId: searchParams.get("mapId") || undefined,
  });
  const calc: any = searchParams.get("calc") || "SUM";

  const datasets =
    report?.charts?.map((chart) => {
      const color =
        useChartContext.getState().chartSettings.get(chart.name)?.color ||
        getRandomColor();
      const hidden =
        useChartContext.getState().chartSettings.get(chart.name)?.hidden ===
        true;
      const type: any =
        useChartContext.getState().chartSettings.get(chart.name)?.type ||
        "line";
      let data = chart.values;
      switch (searchParams.get("window")) {
        case "WEEK":
          data = getWeeklyValues(chart.values, calc);
          break;
        case "MONTH":
          data = getMonthlyValues(chart.values, calc);
          break;
      }
      return {
        tension: 0.2,
        label: chart.name,
        data: data,
        fill: false,
        hidden: hidden,
        type: type,
        borderColor: color,
        backgroundColor: color,
        order: type === "line" ? 1 : 2,
      };
    }) || [];
  const popupRef = useRef<PopupActions>(null);
  const chartType = searchParams.get("chartType");
  return (
    <>
      <GraphControlContainer>
        <PeriodPicker />
        <ComponentWithLabel
          label="Map selection"
          className={styles.mapDropDown}
        >
          <MapDropdown
            allowClear
            showOnlyActive
            value={searchParams.get("mapId")}
            onClear={() => {
              searchParams.delete("mapId");
              setSearchParams(searchParams);
            }}
            onSelect={(mapId) => {
              if (mapId) {
                searchParams.set("mapId", mapId);
                setSearchParams(searchParams);
              } else {
                searchParams.delete("mapId");
                setSearchParams(searchParams);
              }
            }}
          />
        </ComponentWithLabel>
        <div className={styles.chartModifiers}>
          <ComponentWithLabel label="Chart type">
            <Select
              value={searchParams.get("chartType")}
              onChange={(key) => {
                searchParams.set("chartType", key);
                setSearchParams(searchParams);
              }}
            >
              {CHART_TYPES.map((s) => {
                return (
                  <Select.Option key={s}>
                    {s.capitalizeFirstLetter()}
                  </Select.Option>
                );
              })}
            </Select>
          </ComponentWithLabel>
          {(searchParams.get("window") !== "DAILY" ||
            searchParams.get("chartType") === "PIE") && (
            <ComponentWithLabel label="Calc">
              <Select
                value={searchParams.get("calc")}
                onChange={(key) => {
                  searchParams.set("calc", key);
                  setSearchParams(searchParams);
                }}
              >
                {CALC_TYPES.map((s) => {
                  return (
                    <Select.Option key={s}>
                      {s.capitalizeFirstLetter()}
                    </Select.Option>
                  );
                })}
              </Select>
            </ComponentWithLabel>
          )}
          <TimeWindowPicker searchParam="window" label="Time window" values={TIME_WINDOWS}/>
        </div>
      </GraphControlContainer>
      <BorderDivider />
      <ChartContainer>
        <LoadingBoundry
          loading={isLoading}
          error={isError}
          description="Failed to load chart"
          onAction={refetch}
        >
          <Popup ref={popupRef} />
          {chartType?.toLowerCase() === "series" && (
            <AvailabilityLineChart datasets={datasets} popupRef={popupRef} />
          )}
          {chartType?.toLowerCase() === "pie" && (
            <>
              <AvailabilityPieChart datasets={datasets} popupRef={popupRef} />
            </>
          )}
        </LoadingBoundry>
      </ChartContainer>
    </>
  );
}

const AvailabilityLineChart = ({
  datasets,
  popupRef,
}: {
  datasets: DataSet[];
  popupRef: React.RefObject<PopupActions>;
}) => {
  return (
    <Line
      options={{
        responsive: true,
        scales: {
          y: {
            grace: "3%",
          },
        },
        maintainAspectRatio: false,
        plugins: {
          legend: chartLegend(popupRef),
          title: {
            display: true,
            text: "Bookings",
          },
          datalabels: {
            display: false,
          },
        },
      }}
      data={{
        labels: [],
        datasets: datasets || [],
      }}
    />
  );
};
const AvailabilityPieChart = ({
  datasets,
  popupRef,
}: {
  datasets: DataSet[];
  popupRef: React.RefObject<PopupActions>;
}) => {
  const sumFromDataSet = (datasets: DataSet[], label: string) => {
    return datasets
      .find((d) => d.label === label)
      ?.data.map((d) => d.y)
      .map((d) => d as any)
      .reduce((prev, sum) => prev + sum, 0);
  };
  const booked = sumFromDataSet(datasets, "Booked");
  const permanent = sumFromDataSet(datasets, "Permanent");
  const capacity = sumFromDataSet(datasets, "Capacity");
  const notUsed = capacity - permanent - booked;
  return (
    <Pie
      className={styles.graph}
      options={{
        responsive: true,
        plugins: {
          title: {
            display: true,
            text: "Bookings",
          },
          legend: {
            ...chartLegend(popupRef),
            display: true,
            labels: {
              generateLabels(chart) {
                const original =
                  Chart.overrides.pie.plugins.legend.labels.generateLabels;
                const labelsOriginal = original.call(this, chart);
                return labelsOriginal;
              },
            },
          },
          datalabels: {
            display(context) {
              return !(context.dataset.data.length > 10);
            },
            formatter: (value, ctx) => {
              let sum = 0;
              let dataArr = ctx.dataset.data;
              dataArr.forEach((data: any) => {
                sum += data;
              });
              const offset =
                ctx.chart.data.datasets[ctx.datasetIndex - 1]?.data?.length ||
                0;
              let percentage = (value * 100) / sum;
              if (percentage === 0) {
                return "";
              }
              return `${
                ctx.chart.data.labels?.[ctx.dataIndex + offset]
              }\n${percentage.toFixed(0)}%`;
            },
            color: "#fff",
          },
        },
      }}
      data={{
        labels: ["Booked", "Permanent", "Free"],
        datasets: [
          {
            data: [booked, permanent, notUsed],
            hoverOffset: 4,
            backgroundColor: ["Booked", "Permanent", "Free"].map(
              (d) =>
                useChartContext.getState().chartSettings.get(d)?.color ||
                getRandomColor()
            ),
          },
        ],
      }}
    />
  );
};

function getRandomColor() {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}
interface PopupActions {
  showPopup: (
    x: number,
    y: number,
    legend: LegendElement<any>,
    legendItem: LegendItem
  ) => void;
  hidePopup: () => void;
}
const Popup = React.forwardRef<PopupActions, any>((props, ref) => {
  const [coords, setCoords] = useState<{ x: number; y: number }>();

  const { updateSetting } = useChartContext();
  const [chartOpts, setChartOpts] = useState<{
    legend: LegendElement<any>;
    legendItem: LegendItem;
    dataset?: ChartDataset;
    type?: string;
  }>();
  const [divRef, setDivRef] = useState<HTMLDivElement>();
  useEffect(() => {
    if (!divRef) {
      return;
    }
    const clickHandler = (e: MouseEvent) => {
      if (
        !isEventInElement(e, divRef) &&
        !isEventInElement(
          e,
          document.getElementsByClassName("ant-color-picker")[0] as any
        )
      ) {
        setCoords(undefined);
      }
    };
    document.addEventListener("click", clickHandler);
    return () => {
      document.removeEventListener("click", clickHandler);
    };
  }, [divRef]);
  const onRefChange = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      setDivRef(node);
    }
  }, []);

  useImperativeHandle(ref, () => ({
    showPopup(
      x: number,
      y: number,
      legend: LegendElement<any>,
      legendItem: LegendItem
    ) {
      if ((legend.chart.config as any).type === "pie") {
        setChartOpts({
          legend,
          legendItem,
          type: (legend.chart.config as any).type,
        });
      } else {
        setChartOpts({
          legend,
          legendItem,
          dataset: legend.chart.data.datasets[legendItem.datasetIndex!],
        });
      }
      setCoords({ x, y });
    },
    hidePopup() {
      setCoords(undefined);
    },
  }));
  const onColorChange: ColorPickerProps["onChange"] = (_, hex) => {
    if (!chartOpts) {
      return;
    }
    if (chartOpts?.type === "pie") {
      chartOpts.legend.chart.data.datasets[
        chartOpts.legendItem.datasetIndex || 0
      ].backgroundColor[chartOpts.legendItem.index || 0] = hex;

      chartOpts.legendItem.text &&
        updateSetting(chartOpts.legendItem.text, { color: hex });
    }
    if (chartOpts?.dataset) {
      chartOpts.dataset.borderColor = hex;
      chartOpts.dataset.backgroundColor = hex;
      if ((chartOpts.dataset as any).pointBackgroundColor) {
        (chartOpts.dataset as any).pointBackgroundColor = hex;
      }
      chartOpts.dataset.label &&
        updateSetting(chartOpts.dataset.label, { color: hex });
    }
    chartOpts.legend.chart.update();
  };
  const onCheckboxChange = (val: boolean) => {
    if (chartOpts?.type === "pie") {
      const chart = chartOpts!.legend.chart;
      if (chartOpts?.legendItem?.index !== undefined) {
        chart.toggleDataVisibility(chartOpts?.legendItem?.index);
        chart.update();
      }
    } else {
      (Chart.defaults.plugins.legend as any).onClick(
        {} as any,
        chartOpts?.legendItem,
        chartOpts?.legend
      );
      chartOpts?.dataset?.label &&
        updateSetting(chartOpts.dataset.label, {
          hidden: val,
        });
    }
  };
  const onTypeChange: SelectProps["onChange"] = (val) => {
    if (chartOpts && chartOpts.dataset?.label) {
      chartOpts.dataset.type = val;
      updateSetting(chartOpts.dataset.label, { type: val });
      chartOpts.legend.chart.update();
    }
  };
  if (coords?.x && coords.y) {
    let color: any = chartOpts?.dataset?.borderColor;
    switch (chartOpts?.type) {
      case "pie":
        color =
          chartOpts.legend.chart.data.datasets[0].backgroundColor[
            chartOpts.legendItem.index || 0
          ];
        break;
    }
    return (
      <div
        ref={onRefChange}
        className={styles.legendPopup}
        style={{
          top: coords?.y,
          left: coords?.x,
        }}
      >
        <div>
          {"Hidden  "}
          <CustomCheckbox
            checked={chartOpts?.legendItem.hidden}
            onChange={onCheckboxChange}
          />
        </div>
        {chartOpts?.type !== "pie" && (
          <Select
            placeholder="Type"
            value={chartOpts?.dataset?.type}
            onChange={onTypeChange}
          >
            <Select.Option key={"bar"}>
              <Flex align="center">
                <IconRectangle
                  color={chartOpts?.dataset?.backgroundColor as any}
                />
              </Flex>
            </Select.Option>
            <Select.Option key={"line"}>
              <Flex align="center">
                <IconLine color={chartOpts?.dataset?.borderColor as any} />
              </Flex>
            </Select.Option>
          </Select>
        )}
        <div>
          <ColorPicker defaultValue={color} onChange={onColorChange} />
        </div>
      </div>
    );
  }
  return <></>;
});
const chartLegend: (
  ref: React.RefObject<PopupActions>
) => Partial<LegendOptions<any>> = (ref) => ({
  position: "top" as const,
  onClick(event, legendItem, legend) {
    if (
      legendItem.datasetIndex !== undefined ||
      legendItem.index !== undefined
    ) {
      ref.current?.showPopup(event.x!, event.y!, legend, legendItem);
    }
  },
  onHover: function (event, legendItem, legend) {
    var index = legendItem.datasetIndex;
    if (index === undefined) {
      return;
    }
    legend.chart.data.datasets.forEach((dataSet, dsIndex) => {
      if (dataSet.backgroundColor instanceof Array) {
      } else {
        const color =
          dsIndex === index
            ? dataSet.backgroundColor
            : dimColor(dataSet.backgroundColor, 0.5);
        dataSet.borderColor = color;
        dataSet.backgroundColor = color;
      }
    });
    legend.chart.update();
  },
  onLeave: function (event, legendItem, legend) {
    var index = legendItem.datasetIndex;
    if (index === undefined) {
      return;
    }
    legend.chart.data.datasets.forEach((dataSet, dsIndex) => {
      if (dataSet.backgroundColor instanceof Array) {
      } else {
        const color =
          dsIndex === index
            ? dataSet.backgroundColor
            : unDimColor(dataSet.backgroundColor, 0.5);
        dataSet.borderColor = color;
        dataSet.backgroundColor = color;
      }
    });
    legend.chart.update();
  },
});
const dimColor = (color: string, level: number) => {
  const isArgb = color.length === 5 || color.length === 9;
  if (isArgb) {
    const modifiedColor =
      color.substring(0, 7) +
      Math.min(
        255,
        Math.round(parseInt(color.substring(7), 16) * level)
      ).toString(16);
    return modifiedColor;
  } else {
    return (
      color + Math.min(255, Math.round(parseInt("ff", 16) * level)).toString(16)
    );
  }
};
const unDimColor = (color: string, level: number) => {
  const isArgb = color.length === 5 || color.length === 9;
  if (isArgb) {
    const modifiedColor =
      color.substring(0, 7) +
      Math.min(
        255,
        Math.round(parseInt(color.substring(7), 16) / level)
      ).toString(16);
    return modifiedColor;
  } else {
    return (
      color + Math.min(255, Math.round(parseInt("ff", 16) / level)).toString(16)
    );
  }
};
