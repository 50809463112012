import { Select } from "antd";
import { useSearchParams } from "react-router-dom";
import { ComponentWithLabel } from "../../../../components/common/CustomInputText/CustomInputText";
import { largeOnMobile } from "../../../../utils/reactUtils";

export const TIME_WINDOWS = ["WEEK", "MONTH", "DAILY"];
type Props = {
  values: string[];
  searchParam: string;
  label: string;
  value?: string;
};

function LabelPicker({ values, searchParam, label ,value}: Props) {
  const [searchParams, setSearchParams] = useSearchParams();
  return (
    <ComponentWithLabel label={label}>
      <LabelPickerInternal
        value={searchParams.get(searchParam)}
        values={values}
        onChange={(key) => {
          searchParams.set(searchParam, key);
          setSearchParams(searchParams);
        }}
      />
    </ComponentWithLabel>
  );
}

export default LabelPicker;

const LabelPickerInternal = ({
  value,
  onChange,
  values,
}: {
  value?: string | null;
  onChange?: (key: string) => void;
  values: string[];
}) => {
  return (
    <Select
      value={value || values[0]}
      onChange={onChange}
      size={largeOnMobile()}
    >
      {values.map((s) => {
        return (
          <Select.Option key={s}>{s.capitalizeFirstLetter()}</Select.Option>
        );
      })}
    </Select>
  );
};
