import { useMutation, useQuery } from "@tanstack/react-query";
import { message } from "antd";
import { AxiosError } from "axios";
import {
  CreateEquipmentAssociationRequest,
  CreateEquipmentRequest,
  EquipmentListQuery,
  EquipmentMinified,
  EquipmentResponse,
  ErrorResponse,
  UpdateEquipmentAssociation,
} from ".";
import { axiosClient } from "../config/axios";
import { invalidateQueries } from "../config/useQueryClientConfig";
import { getCompanyId, getHeaders, getToken } from "./common";

export const useEquipmentList = () => {
  return useQuery({
    queryKey: ["equipment"],
    queryFn: () => listEquipment(),
    staleTime: Infinity,
  });
};
export const useAvailableEquipment = () => {
  return useQuery({
    queryKey: ["equipment", "AVAILABLE"],
    queryFn: () => listEquipment({ availability: "AVAILABLE" }),
  });
};

export const useEquipment = (id?: string) => {
  return useQuery<EquipmentResponse, AxiosError<ErrorResponse>>({
    queryKey: ["equipment", id],
    queryFn: () => getEquipment(id!),
    enabled: id !== undefined,
  });
};

export const useCreateEquipment = (
  onSuccess?: (data: EquipmentMinified) => void
) => {
  const msgKey = "createEquipment";
  return useMutation({
    mutationFn: createEquipment,
    onMutate: () => {
      message.open({
        type: "loading",
        content: "Creating equipment...",
        key: msgKey,
      });
    },
    onError: () => {
      message.open({
        type: "error",
        content: "Failed to create equipment",
        key: msgKey,
      });
    },
    onSuccess: (data) => {
      invalidateQueries({ queryKey: ["equipment"] });
      onSuccess?.(data);
      message.open({
        type: "success",
        key: msgKey,
        content: "Equipment created successfully",
      });
    },
  });
};

export const useDeleteEquipment = () => {
  const msgKey = "deletingEquipment";
  return useMutation<void, AxiosError<ErrorResponse>, EquipmentResponse>({
    mutationFn: (e) => deleteEquipment(e.id),
    onMutate: () => {
      message.open({
        type: "loading",
        content: "Deleting equipment...",
        key: msgKey,
      });
    },
    onError: () => {
      message.open({
        type: "error",
        content: "Failed to delete equipment",
        key: msgKey,
      });
    },
    onSuccess(data, variables) {
      invalidateQueries({ queryKey: ["equipment"] });
      const assoc = variables.association;
      invalidateAssociatedResources(assoc);
      message.open({
        type: "success",
        key: msgKey,
        content: "Equipment deleted successfully",
      });
    },
  });
};
const invalidateAssociatedResources = (
  assoc?: EquipmentResponse["association"]
) => {
  if (assoc) {
    if (assoc.type === "USER") {
      invalidateQueries({ queryKey: ["users", assoc.id] });
    } else {
      invalidateQueries({
        queryKey: ["desks", assoc.id, assoc.metadata.mapId],
      });
    }
  }
};

export const useUpdateEquipment = () => {
  const msgKey = "updateEquipment";
  return useMutation<
    EquipmentMinified,
    Error,
    { equipmentId: string; data: EquipmentResponse }
  >({
    mutationFn: updateEquipment,
    onMutate: () => {
      message.open({
        type: "loading",
        content: "Updating equipment...",
        key: msgKey,
      });
    },
    onError: () => {
      message.open({
        type: "error",
        content: "Failed to update equipment",
        key: msgKey,
      });
    },

    onSuccess: (data, params) => {
      invalidateQueries({ queryKey: ["equipment"] });
      message.open({
        type: "success",
        key: msgKey,
        content: "Equipment updated successfully",
      });
      const assoc = params.data.association;
      invalidateAssociatedResources(assoc);
    },
  });
};

const updateEquipment = async (equipment: {
  equipmentId: string;
  data: CreateEquipmentRequest;
}) => {
  let companyId = await getCompanyId();
  let token = await getToken();
  const res = await axiosClient.put<EquipmentMinified>(
    `/companies/${companyId}/equipment/${equipment.equipmentId}`,
    equipment.data,
    { headers: getHeaders() }
  );
  return res.data;
};

const createEquipment = async (equipment: CreateEquipmentRequest) => {
  let companyId = await getCompanyId();
  let token = await getToken();
  const res = await axiosClient.post<EquipmentMinified>(
    `/companies/${companyId}/equipment`,
    equipment,
    { headers: getHeaders() }
  );
  return res.data;
};

const deleteEquipment = async (equipmentId: string) => {
  let companyId = await getCompanyId();
  let token = await getToken();
  const res = await axiosClient.delete(
    `/companies/${companyId}/equipment/${equipmentId}`,
    { headers: getHeaders() }
  );
  return res.data;
};

const listEquipment = async (query?: EquipmentListQuery) => {
  let companyId = await getCompanyId();
  let token = await getToken();
  const res = await axiosClient.get<EquipmentMinified[]>(
    `/companies/${companyId}/equipment`,
    { headers: getHeaders(), params: query }
  );
  return res.data;
};
const getEquipment = async (id: string) => {
  let companyId = await getCompanyId();
  let token = await getToken();
  const res = await axiosClient.get<EquipmentResponse>(
    `/companies/${companyId}/equipment/${id}`,
    { headers: getHeaders() }
  );
  return res.data;
};

export const useCreateEquipmentAssociation = (
  assoc?: CreateEquipmentAssociationRequest
) => {
  const msgKey = "deskEquipmentAdd";
  return useMutation<
    any,
    Error,
    {
      request: CreateEquipmentAssociationRequest;
      equipment: EquipmentMinified;
    }
  >({
    mutationFn: (params) =>
      addEquipmentAssoc(params.request, params.equipment.id),
    onMutate: () => {
      message.open({
        type: "loading",
        content: "Adding equipment...",
        key: msgKey,
      });
    },
    onSuccess: (_, params) => {
      message.open({
        type: "success",
        content: "Equipment added",
        key: msgKey,
      });
      invalidateAssoc(assoc);
      invalidateQueries({
        queryKey: ["equipment", params.equipment.id],
      });
      invalidateQueries({ queryKey: ["equipment"] });
    },
    onError: (_, params) => {
      message.open({
        type: "error",
        content: "Failed to add equipment",
        key: msgKey,
      });
    },
  });
};

const invalidateAssoc = (assoc?: CreateEquipmentAssociationRequest) => {
  if (assoc) {
    let key: any = [];
    if (assoc.deskId) {
      key = ["desks", assoc.mapId, assoc.deskId];
    }
    if (assoc.roomId) {
      key = ["rooms", assoc.mapId, assoc.roomId];
    }
    if (assoc.userEmail) {
      key = ["users", assoc.userEmail];
    }
    invalidateQueries({
      queryKey: key,
    });
  }
};

const addEquipmentAssoc = async (
  request: CreateEquipmentAssociationRequest,
  equipmentId: string
) => {
  let companyId = await getCompanyId();
  const res = await axiosClient.post<any>(
    `/companies/${companyId}/equipment/${equipmentId}/association`,
    request,
    { headers: getHeaders() }
  );
  return res.data;
};

const removeEquipmentAssoc = async (equipmentId: string) => {
  let companyId = await getCompanyId();
  const res = await axiosClient.delete<any>(
    `/companies/${companyId}/equipment/${equipmentId}/association`,
    { headers: getHeaders() }
  );
  return res.data;
};

export const useRemoveEquipmentAssoc = (
  assoc?: CreateEquipmentAssociationRequest
) => {
  const msgKey = "equipmentAssocDelete";
  return useMutation<any, Error, { id: string }>({
    mutationFn: (params) => removeEquipmentAssoc(params.id),
    onMutate: () => {
      message.open({
        type: "loading",
        content: "Removing equipment...",
        key: msgKey,
      });
    },
    onSuccess: (_, params) => {
      message.open({
        type: "success",
        content: "Equipment removed",
        key: msgKey,
      });

      invalidateAssoc(assoc);
      invalidateQueries({ queryKey: ["equipment", params.id] });
      invalidateQueries({ queryKey: ["equipment"] });
    },
    onError: () => {
      message.open({
        type: "error",
        content: "Failed to remove equipment",
        key: msgKey,
      });
    },
  });
};
interface UpdateEquipmentAssocParam {
  type: "users" | "rooms" | "desks";
  id?: string;
}
export const useUpdateEquipmentAssoc = (
  assoc?: CreateEquipmentAssociationRequest
) => {
  const msgKey = "deskEquipmentUpdate";
  return useMutation<
    any,
    Error,
    {
      id: string;
      req: UpdateEquipmentAssociation;
    }
  >({
    mutationFn: (params) => updateEquipmentAssoc(params.id, params.req),
    onMutate: () => {
      message.open({
        type: "loading",
        content: "Updating equipment association...",
        key: msgKey,
      });
    },
    onSuccess: (_, params) => {
      message.open({
        type: "success",
        content: "Equipment association updated",
        key: msgKey,
      });

      invalidateAssoc(assoc);
    },
    onError: () => {
      message.open({
        type: "error",
        content: "Failed to update equipment association",
        key: msgKey,
      });
    },
  });
};

export const updateEquipmentAssoc = async (
  equipmentId: string,
  request: UpdateEquipmentAssociation
) => {
  let companyId = getCompanyId();
  const res = await axiosClient.put<any[]>(
    `/companies/${companyId}/equipment/${equipmentId}/association`,
    request,
    { headers: getHeaders() }
  );
  return res.data;
};
