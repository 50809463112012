import { ArrowRightOutlined, LineOutlined } from "@ant-design/icons";
import { IconChevronRight } from "@tabler/icons-react";
import { Button, Checkbox, Flex } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { fabric } from "fabric";
import { Desk } from "fabric/fabric-impl";
import { useCallback, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  createSearchParams,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { BehindPermission } from "../../../../../components/common/BehindPermission/BehindPermission";
import ButtonWithIcon from "../../../../../components/common/ButtonWithIcon";
import { AlertDialogOptions } from "../../../../../components/common/ConfirmationDialog/ConfirmationDialog";
import DisableByRole from "../../../../../components/common/DisableByRole";
import { EquipmentSection } from "../../../../../components/common/EquipmentSection/EquipmentSection";
import { ErrorIndicator } from "../../../../../components/common/ErrorIndicator";
import LoadingBoundry from "../../../../../components/common/LoadingBoundry/LoadingBoundry";
import NavigationBanner from "../../../../../components/common/NavigationBanner/NavigationBanner";
import BookingSuccessSvg from "../../../../../components/svgs/BookingSuccessSvg";
import { useAuthenticationStore } from "../../../../../context/authContext";
import {
  BookingUnavailability,
  CreateBookingRequest,
  DeskBookingResponse,
  DeskResponse,
} from "../../../../../repo";
import { useCreateBooking } from "../../../../../repo/bookings";
import { useDesk } from "../../../../../repo/desks";
import { DATE_FORMAT } from "../../../../../utils/constants";
import { mapQueue } from "../../../../../utils/map/mapQueue";
import BorderDivider from "../../../BorderDivider/ListDivider";
import { useDialogContext } from "../../../DialogProvider/DialogProvider";
import { useUserCanBookDesk } from "../../../hooks";
import { UserSelection } from "../../components/UserSelection/UserSelection";
import { useMapTooltip } from "../../navigator/components/MapViewTooltip";
import { bookingTooltipHandler } from "../MapDesksAvailability/MapDesksAvailability";
import { useMapBookings } from "../context/mapBookingsContext";
import styles from "./CreateMapBooking.module.css";
type Props = {};
export default function CreateMapBooking({}: Props) {
  const { deskId, mapId } = useParams();
  const nav = useNavigate();
  const formMethods = useForm<DeskResponse>();
  const [searchParams] = useSearchParams();
  const { data: deskResponse, isError: isDeskLoadError } = useDesk(
    mapId,
    deskId
  );
  const {
    reset,
    unavailableBookings,
    unavailableDesks,
    availableDesks,
    desks,
  } = useMapBookings();
  const { auth } = useAuthenticationStore();
  const { show } = useDialogContext();
  const [userEmail, setUserEmail] = useState(auth?.userEmail);
  const {
    mutate: createBooking,
    isPending,
    error,
  } = useCreateBooking((data) => {
    reset();
    show(bookingCreatedAlert(nav, data));
  });

  const dateFrom = dayjs(searchParams.get("dateFrom"));
  const dateTo = dayjs(searchParams.get("dateTo"));
  useEffect(() => {
    const mouseOutHandler = () => {
      useMapTooltip.getState().hide();
    };

    const handler: fabric.EntityEventHandler = ({ entity }) => {
      if (entity.type !== "desk") {
        return;
      }
      const desk = entity as Desk;
      bookingTooltipHandler(desk, desks, unavailableBookings);
    };
    mapQueue.add((map) => {
      map.on("entity:mouseover", handler);
      map.on("entity:mouseout", mouseOutHandler);
      map.disableClicks(unavailableDesks.map((d) => d.mapDeskId));
    });

    return () => {
      mapQueue.add((map) => {
        map.disableClicks([]);
        map.off("entity:mouseout", mouseOutHandler);
        map.off("entity:mouseover", handler);
      });
    };
  }, [unavailableBookings, unavailableDesks, availableDesks, desks]);
  useEffect(() => {
    if (deskResponse) {
      formMethods.setValue("equipment", []);
      formMethods.reset(deskResponse);
    }
    if (!deskResponse?.mapDeskId) {
      return;
    }
    const onDeskSelected: fabric.EntityEventHandler = ({ entity }) => {
      if (entity.type === "desk") {
        if (entity.associationId !== deskResponse?.id) {
          nav(
            `/maps/${mapId}/bookings/desks/${entity.associationId}${window.location.search}`,
            {
              replace: true,
            }
          );
          useMapTooltip.getState().show({ canvasObject: entity });
        }
      }
    };
    const onDeskDeselected: fabric.EntityEventHandler = ({ entity }) => {
      nav(`/maps/${mapId}/bookings`, { replace: true });
    };
    mapQueue.add((map) => {
      map.on("entity:selected", onDeskSelected);
      map.on("entity:deselected", onDeskDeselected);
    });
    return () => {
      mapQueue.add((map) => {
        map.off("entity:selected", onDeskSelected);
        map.off("entity:deselected", onDeskDeselected);
      });
      useMapTooltip.getState().hide();
    };
  }, [deskResponse, formMethods, mapId, nav]);

  const { canBook } = useUserCanBookDesk();

  useEffect(() => {
    if (!canBook) {
      nav(`/maps/${mapId}`);
    }
  }, [canBook, nav, mapId]);

  const onSubmit = () => {
    if (!userEmail) {
      return;
    }
    const actions = searchParams.getAll("actions");
    const creationReq: CreateBookingRequest = {
      dateFrom: dateFrom!.format(DATE_FORMAT),
      dateTo: dateTo!.format(DATE_FORMAT),
      mapId: mapId!,
      deskId: deskId!,
      userEmail: userEmail,
      actions: actions as any,
    };
    createBooking(creationReq);
  };
  const errorMsg =
    error?.response?.data.code === "UNAVAILABLE"
      ? "Desk is no more available"
      : "Failed to create booking";
  return (
    <FormProvider {...formMethods}>
      <LoadingBoundry
        loading={isPending}
        error={error}
        description={errorMsg}
        action="Go back"
        onAction={() => {
          reset();
          nav(-1);
        }}
      >
        <form
          className={styles.formContainer}
          onSubmit={formMethods.handleSubmit(onSubmit)}
        >
          <NavigationBanner name={deskResponse?.name || "New booking"} />
          {isDeskLoadError ? (
            <ErrorIndicator
              description={`Could not find the desk with id ${deskId}`}
              action="Go back"
              onAction={() => nav(-1)}
            />
          ) : (
            <>
              <DisableByRole disabledRole="USER">
                <UserSelection
                  className={styles.userSelector}
                  value={userEmail}
                  onSelect={(user) => {
                    setUserEmail(user.email);
                  }}
                />
                <BorderDivider style={{ marginBottom: "1rem" }} />
              </DisableByRole>

              <EquipmentSection disabled />
              <BookingTimeDisplay dateTo={dateTo} dateFrom={dateFrom} />
              <BookingAction
                userEmail={userEmail}
                unavailableBookings={unavailableBookings}
                currentEmail={auth?.userEmail}
              />
            </>
          )}
        </form>
      </LoadingBoundry>
    </FormProvider>
  );
}

const BookingTimeDisplay = ({
  dateTo,
  dateFrom,
}: {
  dateTo: Dayjs;
  dateFrom: Dayjs;
}) => {
  const bookedDays = (dateTo?.diff(dateFrom, "day") || 0) + 1;
  return (
    <>
      <div className={styles.bookingTitle}>Booking time</div>
      <Flex justify="space-evenly" align="center">
        <div className={styles.timeSlotContainer}>
          <span>{dateFrom?.format(DATE_FORMAT)}</span>
        </div>
        <LineOutlined />
        <div
          className={styles.timeSlotContainer}
          style={{
            borderColor: "var(--supplementary-color)",
            borderWidth: "2px",
          }}
        >
          <span>{`${bookedDays} ${bookedDays > 1 ? "days" : "day"}`}</span>
        </div>
        <ArrowRightOutlined />
        <div className={styles.timeSlotContainer}>
          <span>{dateTo?.format(DATE_FORMAT)}</span>
        </div>
      </Flex>{" "}
    </>
  );
};

const BookingAction = ({
  userEmail,
  unavailableBookings,
  currentEmail,
}: {
  userEmail?: string;
  unavailableBookings: BookingUnavailability[];
  currentEmail?: string;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { mapId } = useParams();
  const bookingForUserAndDate = unavailableBookings.find(
    (b) => b.user.email === userEmail
  );
  const nav = useNavigate();
  const navToBooking = () => {
    if (!bookingForUserAndDate || !mapId) {
      return;
    }
    nav({
      pathname: "/bookings",
      search: createSearchParams({
        mapId: mapId,
        userEmail: bookingForUserAndDate.user.email,
        dateFrom: bookingForUserAndDate.dateFrom,
        dateTo: bookingForUserAndDate.dateTo,
      }).toString(),
    });
  };

  return (
    <div className={styles.buttonContainer}>
      <Flex justify="center">
        {bookingForUserAndDate !== undefined ? (
          <Flex flex={1} gap={"1rem"} justify="center" align="center" vertical>
            <BorderDivider />
            {`Booking in this period exists`}
            <ButtonWithIcon
              icon={<IconChevronRight />}
              variant="default"
              onClick={navToBooking}
            >
              View booking
            </ButtonWithIcon>
          </Flex>
        ) : (
          <div className={styles.bookingContainer}>
            {currentEmail === userEmail && (
              <BookingOptionsBehindPermissions selectedEmail={currentEmail} />
            )}
            <Button style={{ width: "60%" }} htmlType="submit" type="primary">
              Book
            </Button>
          </div>
        )}
      </Flex>
    </div>
  );
};

export const BookingOptionsBehindPermissions = ({
  selectedEmail,
}: {
  selectedEmail?: string;
}) => {
  return (
    <BehindPermission
      permission="EVENT_CREATION"
      description="We require some permissions to add an event to your calendar"
      emptyOnNoIntegration={true}
    >
      <BookingOptions selectedEmail={selectedEmail} />
    </BehindPermission>
  );
};
const BookingOptions = ({ selectedEmail }: { selectedEmail?: string }) => {
  const { auth } = useAuthenticationStore();
  const [searchParams, setSearchParams] = useSearchParams();
  const handleCreateCalendarCheckbox = useCallback(
    (shouldAddAction: boolean) => {
      const hasActionInParams = searchParams
        .getAll("actions")
        .includes("ADD_EVENT");
      if (shouldAddAction && !hasActionInParams) {
        searchParams.append("actions", "ADD_EVENT");
      } else if (!shouldAddAction && hasActionInParams) {
        const leftActions = searchParams
          .getAll("actions")
          .filter((a) => a !== "ADD_EVENT");
        searchParams.delete("actions");
        leftActions.forEach((a) => searchParams.append("actions", a));
      }
      setSearchParams(searchParams, { replace: true });
    },
    [searchParams, setSearchParams]
  );
  const userEmail = auth?.userEmail;
  const [userClick, setUserClick] = useState(false);
  useEffect(() => {
    if (!userClick && userEmail === selectedEmail) {
      handleCreateCalendarCheckbox(true);
    }
  }, [handleCreateCalendarCheckbox, userClick, userEmail, selectedEmail]);
  useEffect(() => {
    handleCreateCalendarCheckbox(userEmail === selectedEmail);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userEmail, selectedEmail]);
  return (
    <>
      {userEmail === selectedEmail && (
        <div className={styles.bookingOptionContainer}>
          <Checkbox
            checked={searchParams.getAll("actions").includes("ADD_EVENT")}
            onChange={(e) => {
              setUserClick(true);
              handleCreateCalendarCheckbox(e.target.checked);
            }}
          >
            Add to calendar
          </Checkbox>
        </div>
      )}
    </>
  );
};
export function bookingCreatedAlert(
  nav: any,
  data: DeskBookingResponse
): AlertDialogOptions {
  return {
    title: "Success",
    buttonProps: { variant: "link", icon: <IconChevronRight/> },
    content: "Your desk booking was successful!",
    image: <BookingSuccessSvg />,
    buttonText: "View booking",
    onAction() {
      nav(
        {
          pathname: `/bookings`,
          search: createSearchParams({
            mapId: data.mapId,
            userEmail: data.userEmail,
            dateFrom: data.dateFrom,
            dateTo: data.dateTo,
            deskId: data.deskId,
          }).toString(),
        },
        { replace: true }
      );
    },
    onClose: () => {
      nav(`/maps/${data.mapId}`);
    },
  };
}
