import styles from "./CustomCheckbox.module.css";
type Props = {
  onChange?: (checked: boolean) => void;
  checked?: boolean;
};

export default function CustomCheckbox(props: Props) {
  return (
    <input
      type="checkbox"
      className={styles.checkbox}
      {...props}
      onChange={(e) => props.onChange?.(e.target.checked)}
    />
  );
}
