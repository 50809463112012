import { Input } from "antd";
import { useSearchParams } from "react-router-dom";
const { Search } = Input;

export const SearchBar: React.FC<{ path: string; placeholder?: string }> = ({
  path,
  placeholder,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const handleSearch = (val: string) => {
    if (searchParams.get("searchTerm") !== val) {
      searchParams.set("searchTerm", val);
      setSearchParams(searchParams);
    }
  };
  const setText = (val: string) => {
    searchParams.set("searchTerm", val);
    setSearchParams(searchParams,{replace:true});
  };

  return (
    <Search
      placeholder={placeholder || "Search"}
      onSearch={handleSearch}
      size="large"
      value={searchParams.get("searchTerm") || undefined}
      onChange={(e) => setText(e.target.value)}
    />
  );
};
