import { IconX } from "@tabler/icons-react";
import { useEffect } from "react";
import { createPortal } from "react-dom";
import styles from "./ModalDialog.module.css";
export interface ModalDialogProps {
  open: boolean;
  onClose?: () => void;
  children: React.ReactNode;
  footer?: React.ReactNode;
  title?: React.ReactNode;
}

export const ModalDialog: React.FC<ModalDialogProps> = ({
  open,
  onClose,
  children,
  footer,
  title,
}) => {
  const handleCloseModal = () => {
    onClose?.();
  };
  useEffect(() => {
    if (open) {
      const onEsc = (e: KeyboardEvent) => {
        if (e.key === "Escape") {
          onClose?.();
        }
      };
      document.addEventListener("keyup", onEsc);
      return () => {
        document.removeEventListener("keyup", onEsc);
      };
    }
  }, [open, onClose]);
  return (
    <>
      {open &&
        createPortal(
          <div className={`${styles.backdrop}`} onClick={handleCloseModal}>
            <div className={styles.modal} onClick={(e) => e.stopPropagation()}>
              <div className={styles.container}>
                <div className={styles.header}>
                  <span className={styles.title}>{title}</span>
                  <div
                    className={styles.closeButton}
                    onClick={handleCloseModal}
                  >
                    <IconX />
                  </div>
                </div>
                <div className={styles.body}>{children}</div>
                {footer && <div className={styles.footer}>{footer}</div>}
              </div>
            </div>
          </div>,
          document.getElementById("root")!
        )}
    </>
  );
};
