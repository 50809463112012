import { fabric } from "fabric";
import { IPathOptions } from "fabric/fabric-impl";

fabric.Chair = fabric.util.createClass(fabric.Path, {
  type: "chair",
  initialize: function (options: any) {
    const defaults: IPathOptions = {
      fill: "white",
      stroke: "black",
      scaleX: 0.25,
      scaleY: 0.25,
      snapAngle: 5,
      strokeWidth: 3,
      originX: "center",
      originY: "center",
    };
    this.callSuper(
      "initialize",
      "M30,168 V30 Q20 10 10 30 V170M190 170 V30 Q180 10 170 30 V168M10 170 Q10 180 20 190 Q100 210 180 190 Q190 180 190 170 M190 170 Q100 160 10 170 M30 55 Q100 30 170 55 V168 Q100 160 30 168 Z",
      {
        ...defaults,
        ...options,
      }
    );
  },

  toObject: function () {
    const obj = fabric.util.object.extend(this.callSuper("toObject"), {});
    const { path, ...rest } = obj;
    return rest;
  },
});
fabric.Chair.fromObject = function (object, callback) {
  return fabric.Object._fromObject("Chair", object, callback) as any;
};
