import { useQuery } from "@tanstack/react-query";
import { ReportRequest, ReportResponse, ReportType } from ".";
import { axiosClient } from "../config/axios";
import { getCompanyId, getHeaders } from "./common";

export const useReportTypes = () => {
  return useQuery({
    queryKey: ["report", "types"],
    queryFn: getReportTypes,
  });
};

export const useReport = (req: Partial<ReportRequest>) => {
  return useQuery({
    queryKey: ["report", req],
    queryFn: () => getReport(req as any),
    enabled: req.dateFrom !== undefined && req.dateTo !== undefined,
    staleTime: Infinity,
  });
};

const getReportTypes = async () => {
  let companyId = await getCompanyId();
  const res = await axiosClient.get<ReportType[]>(
    `/companies/${companyId}/reports`,
    { headers: getHeaders() }
  );
  return res.data;
};
const getReport = async (req: ReportRequest) => {
  let companyId = await getCompanyId();
  const res = await axiosClient.get<ReportResponse>(
    `/companies/${companyId}/reports`,
    {
      headers: getHeaders(),
      params: req,
    }
  );
  return res.data;
};
