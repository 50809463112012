import { fabric } from "fabric";
import { Canvas, Desk, IGroupOptions } from "fabric/fabric-impl";
import { v4 } from "uuid";

fabric.Desk = fabric.util.createClass(fabric.Group, {
  type: "desk",
  active: false,
  id: undefined,
  associationId: undefined,
  initialize: function (options: any) {
    let Desk = new fabric.Rect({
      height: 50,
      width: 100,
      fill: "white",
      stroke: "black",
      strokeUniform: true,
      strokeWidth: 1,
      rx: 10,
      ry: 2,
    });
    let chair = new fabric.Chair({
      top: 55,
      left: 50,
    });
    this.callSuper("initialize", [chair, Desk], {
      snapAngle: 5,
      ...options,
    });
    this.set("id", options?.id);
    this.set("associationId", options?.associationId);
  },
  setAssociationId(id: string) {
    let thisDesk: Desk = this;
    thisDesk.associationId = id;
    thisDesk.prevValues = undefined;
  },
  toObject: function () {
    const obj = fabric.util.object.extend(this.callSuper("toObject"), {
      id: this.get("id") || v4(),
      associationId: this.get("associationId"),
    });
    const { objects, ...rest } = obj;
    return rest;
  },
  highlightObjects(color?: string, width: number = 4) {
    let currObj: Desk = this;
    currObj.forEachObject((obj) =>
      obj.set({ stroke: color || "red", strokeWidth: width })
    );
  },
  removeHighlights() {
    let currObj: Desk = this;
    currObj.forEachObject((obj) =>
      obj.set({ stroke: "black", strokeWidth: obj.type === "rect" ? 1 : 3 })
    );
  },
  resetToValuesBeforeSelection() {
    let thisDesk = this as Desk;
    thisDesk.getObjects("rect")[0]?.set({...thisDesk.prevValues.desk});
    thisDesk.getObjects("chair")[0]?.set({...thisDesk.prevValues.chair});
    thisDesk.prevValues = undefined;
    thisDesk.removeHighlights();
  },
  savePreviousValues(){
    let thisDesk = this as Desk;
    if (!thisDesk.prevValues) {
      thisDesk.prevValues = {
        desk: {...thisDesk.getObjects("rect")[0]},
        chair: {...thisDesk.getObjects("chair")[0]},
      };
    }
  },
  setActive(isActive: boolean) {
    let thisDesk = this as Desk;
    if (!isActive) {
      thisDesk.removeHighlights();
    } else {
      thisDesk.savePreviousValues();
      thisDesk.highlightObjects();
    }
    thisDesk.active = isActive;
  },
  makeInteractive(canvas: Canvas) {
    let opts: IGroupOptions = {
      lockMovementX: true,
      lockMovementY: true,
      evented: true,
      hasBorders: false,
      hasControls: false,
      hoverCursor: "pointer",
    };
    this.set(opts);
    let thisDesk: Desk = this;
    thisDesk.on("mouseover", (opt) => {
      if (!thisDesk.active) {
        thisDesk.highlightObjects();
      }
      thisDesk.bringToFront();
      opt.target?.canvas?.fire("entity:mouseover", { entity: thisDesk });
      canvas.renderAll();
    });
    thisDesk.on("mouseout", (opt) => {
      if (!thisDesk.active) {
        thisDesk.removeHighlights();
      }
      opt.target?.canvas?.fire("entity:mouseout", { entity: thisDesk });
      canvas.renderAll();
    });
  },
  setDeskColor(color: string) {
    let thisDesk = this as Desk;
    thisDesk.getObjects("rect").forEach((obj) => obj.set({ fill: color }));
  },
  setChairColor(color: string) {
    let thisDesk = this as Desk;
    thisDesk.getObjects("chair").forEach((obj) => obj.set({ fill: color }));
  },
});
fabric.Desk.fromObject = function (object, callback) {
  return fabric.Object._fromObject("Desk", object, callback);
};
