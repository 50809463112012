import { useMutation, useQuery } from "@tanstack/react-query";
import { message } from "antd";
import { AxiosError } from "axios";
import {
  CreateDeskRequest,
  DeskResponse,
  ErrorResponse,
  UpdateDeskRequest,
} from ".";
import { axiosClient } from "../config/axios";
import { invalidateQueries } from "../config/useQueryClientConfig";
import { getCompanyId, getHeaders } from "./common";
import { UpdateDeskTagRequest } from "./index.d";
export const useCreateDesk = (onSuccess: (data: DeskResponse) => void) => {
  const msgKey = "deskCreate";

  return useMutation<
    DeskResponse,
    Error,
    { mapId: string; desk: CreateDeskRequest }
  >({
    mutationFn: (data) => createDesk(data.mapId, data.desk),
    onMutate: () => {
      message.open({
        type: "loading",
        content: "Creating desk...",
        key: msgKey,
      });
    },
    onError: () => {
      message.open({
        type: "error",
        content: "Could not create desk",
        key: msgKey,
      });
    },
    onSuccess: async (data, params) => {
      await invalidateQueries({ queryKey: ["maps", params.mapId] });
      invalidateQueries({ queryKey: ["desks"] });
      onSuccess(data);

      message.open({
        type: "success",
        content: "Desk created",
        key: msgKey,
      });
    },
  });
};
export const useUpdateDesk = (onSuccess?: (data: DeskResponse) => void) => {
  const msgKey = "deskUpdate";

  return useMutation<
    DeskResponse,
    AxiosError<ErrorResponse>,
    { mapId: string; deskId: string; desk: UpdateDeskRequest }
  >({
    mutationFn: (data) => updateDesk(data.mapId, data.deskId, data.desk),
    onMutate: () => {
      message.open({
        type: "loading",
        content: "Updating desk...",
        key: msgKey,
      });
    },
    onError: () => {
      message.open({
        type: "error",
        content: "Could not update desk",
        key: msgKey,
      });
    },
    onSuccess: (data, params) => {
      message.open({
        type: "success",
        content: "Desk updated",
        key: msgKey,
      });
      invalidateQueries({ queryKey: ["maps", params.mapId] });
      invalidateQueries({ queryKey: ["desks"] });
    },
  });
};

export const useUpdateDeskTags = () => {
  const msgKey = "updateDeskTags";
  return useMutation<
    DeskResponse,
    AxiosError<ErrorResponse>,
    { tags: string[]; deskId: string; mapId: string }
  >({
    mutationFn: (data) =>
      updateDeskTags({ tags: data.tags }, data.mapId, data.deskId),
    onMutate: () => {
      message.open({
        type: "loading",
        content: "Updating desk tags...",
        key: msgKey,
      });
    },
    onError: () => {
      message.open({
        type: "error",
        content: "Failed to update desk tags",
        key: msgKey,
      });
    },
    onSuccess: (data, params) => {
      message.open({
        type: "success",
        content: "Desk tags updated",
        key: msgKey,
      });
      invalidateQueries({ queryKey: ["desks"] });
    },
  });
};
export const useDeleteDesk = () => {
  const msgKey = "deskDelete";
  return useMutation<
    DeskResponse,
    AxiosError<ErrorResponse>,
    { desk: DeskResponse; mapId: string }
  >({
    mutationFn: (data) => deleteDesk(data.mapId, data.desk.id),
    onMutate: () => {
      message.open({
        type: "loading",
        content: "Deleting desk...",
        key: msgKey,
      });
    },
    onError: () => {
      message.open({
        type: "error",
        content: "Failed to delete desk",
        key: msgKey,
      });
    },
    onSuccess: (data, params) => {
      message.open({
        type: "success",
        content: "Desk deleted",
        key: msgKey,
      });
      invalidateQueries({ queryKey: ["desks"] });
      invalidateQueries({ queryKey: ["maps", params.mapId] });
      if (params.desk.equipment) {
        params.desk.equipment.forEach((e) => {
          invalidateQueries({ queryKey: ["equipment", e.id] });
        });
      }
    },
  });
};

export const useDesks = (mapId?: string) => {
  return useQuery({
    queryKey: ["desks", mapId],
    queryFn: () => getDesks(mapId!),
    staleTime: Infinity,
    enabled: mapId !== undefined && mapId !== null,
  });
};

export const useDesk = (mapId?: string, deskId?: string) => {
  return useQuery({
    queryKey: ["desks", mapId, deskId],
    queryFn: () => getDesk(mapId!, deskId!),
    enabled:
      mapId !== undefined &&
      mapId !== null &&
      deskId !== undefined &&
      deskId !== null,
  });
};
const getDesks = async (mapId: string) => {
  let companyId = await getCompanyId();
  const res = await axiosClient.get<DeskResponse[]>(
    `/companies/${companyId}/maps/${mapId}/desks`,
    { headers: getHeaders() }
  );
  return res.data;
};
const getDesk = async (mapId: string, deskId: string) => {
  let companyId = await getCompanyId();
  const res = await axiosClient.get<DeskResponse>(
    `/companies/${companyId}/maps/${mapId}/desks/${deskId}`,
    { headers: getHeaders() }
  );
  return res.data;
};

const deleteDesk = async (mapId: string, deskId: string) => {
  let companyId = getCompanyId();
  const res = await axiosClient.delete<any>(
    `/companies/${companyId}/maps/${mapId}/desks/${deskId}`,
    { headers: getHeaders() }
  );
  return res.data;
};

const updateDeskTags = async (
  req: UpdateDeskTagRequest,
  mapId: string,
  deskId: string
) => {
  let companyId = getCompanyId();
  const res = await axiosClient.put<DeskResponse>(
    `/companies/${companyId}/maps/${mapId}/desks/${deskId}/tags`,
    req,
    { headers: getHeaders() }
  );
  return res.data;
};
const createDesk = async (mapId: string, desk: CreateDeskRequest) => {
  let companyId = await getCompanyId();
  const res = await axiosClient.post<DeskResponse>(
    `/companies/${companyId}/maps/${mapId}/desks`,
    desk,
    { headers: getHeaders() }
  );
  return res.data;
};

const updateDesk = async (
  mapId: string,
  deskId: string,
  desk: UpdateDeskRequest
) => {
  let companyId = await getCompanyId();
  const res = await axiosClient.put<DeskResponse>(
    `/companies/${companyId}/maps/${mapId}/desks/${deskId}`,
    desk,
    { headers: getHeaders() }
  );
  return res.data;
};
