import {
  IconCamera,
  IconCategory,
  IconDeviceDesktop,
  IconDeviceMobile,
  IconDevices2,
  IconDeviceSpeaker,
  IconDeviceTv,
  IconDeviceUsb,
  IconDice5
} from "@tabler/icons-react";
import { Button, Switch, Tooltip } from "antd";
import { useEffect } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { ConfirmationDialog } from "../../../../components/common/ConfirmationDialog/ConfirmationDialog";
import {
  ComponentWithError,
  ComponentWithLabel,
  CustomInputText,
} from "../../../../components/common/CustomInputText/CustomInputText";
import { EquipmentSection } from "../../../../components/common/EquipmentSection/EquipmentSection";
import LoadingBoundry from "../../../../components/common/LoadingBoundry/LoadingBoundry";
import { useAuthenticationStore } from "../../../../context/authContext";
import { EquipmentMinified, UserResponse } from "../../../../repo";
import { useUser, useUserDelete, useUserUpdate } from "../../../../repo/users";
import CenteredFloatingContainer from "../../../common/CenteredFloatingContainer/CenteredFloatingContainer";
import { CompanyUserForm } from "../UserCreateView/UserCreateView";
import TeamSelector from "../components/TeamSelector/TeamSelector";
import UserAvatarPicker from "../components/UserAvatarPicker/UserAvatarPicker";
import styles from "./UserEditView.module.css";
type Props = {
  userEmail?: string;
  viewOnly?: boolean;
  closable?: boolean;
};
export function UserPage() {
  const { userEmail } = useParams();
  return <UserEditView userEmail={userEmail} />;
}
export default function UserEditView({
  userEmail,
  viewOnly,
  closable = true,
}: Props) {
  const form = useForm<UserResponse>({
    defaultValues: { companyRole: null as any, equipment: null as any },
  });
  const { auth } = useAuthenticationStore();
  let { data, isError: isLoadError, isLoading, refetch } = useUser(userEmail);
  useEffect(() => {
    form.reset({
      ...data,
      companyRole: data?.companyRole || (null as any),
      equipment: data?.equipment || (null as any),
    });
  }, [userEmail, data, form]);

  const nav = useNavigate();
  const {
    mutate: updateUser,
    isPending: isUpdating,
    isError: isUpdateError,
    reset: resetUpdate,
  } = useUserUpdate();
  const {
    mutate: deleteUser,
    isPending: isDeleting,
    isError: isDeleteError,
    reset: resetDelete,
  } = useUserDelete(() => {
    nav("/people" + window.location.search);
  });
  const isDeleted = data?.status === "DELETED";
  const onSubmit = (data: UserResponse) => {
    updateUser({
      user: { ...data, status: isDeleted ? "ACTIVE" : data.status || "ACTIVE" },
    });
  };
  const onDelete = () => {
    deleteUser(data!);
  };
  return (
    <CenteredFloatingContainer
      header={
        <span className={styles.header}>{data?.name || "Edit user"}</span>
      }
      withoutClose={!closable}
      backRoute={"/people"}
    >
      <UserAvatarPicker
        src={data?.photoUrl}
        email={data?.email}
        className={styles.cardAvatar}
      />
      <LoadingBoundry
        loading={isLoading || isDeleting || isUpdating}
        error={isLoadError || isDeleteError || isUpdateError}
        description={
          isLoadError
            ? "Failed to load user"
            : isDeleteError
            ? "Failed to delete user"
            : "Failed to update user"
        }
        onAction={
          isLoadError ? refetch : isDeleteError ? resetDelete : resetUpdate
        }
      >
        <FormProvider {...form}>
          <CompanyUserForm onSubmit={form.handleSubmit(onSubmit)}>
            <div className={styles.bodyContainer}>
              <div className={styles.formColumn} style={{ paddingTop: "3rem" }}>
                <Controller
                  name="email"
                  control={form.control}
                  render={({ field, formState }) => {
                    return (
                      <Tooltip title="User email cannot be edited after the user is created">
                        <CustomInputText
                          label="Email"
                          className="padding-top"
                          disabled
                          value={field.value}
                          // {...field}
                        />
                      </Tooltip>
                    );
                  }}
                />
                <Controller
                  name="name"
                  control={form.control}
                  render={({ field, fieldState }) => {
                    return (
                      <CustomInputText
                        label="Name"
                        className="padding-top"
                        {...field}
                        error={fieldState.error?.message}
                        required={!viewOnly}
                        disabled={viewOnly}
                      />
                    );
                  }}
                  rules={{
                    required: "Name is required",
                    maxLength: { value: 250, message: "Value too long" },
                  }}
                />
                <Controller
                  name="companyRole"
                  control={form.control}
                  render={({ field, fieldState }) => {
                    return (
                      <CustomInputText
                        label="Role"
                        placeholder={"Developer"}
                        className="padding-top"
                        disabled={viewOnly}
                        {...field}
                        error={fieldState.error?.message}
                      />
                    );
                  }}
                  rules={{
                    maxLength: { value: 250, message: "Value too long" },
                  }}
                />

                {(viewOnly ? data?.teamId : true) && (
                  <TeamSelector
                    disabled={viewOnly}
                    setValue={form.setValue}
                    control={form.control}
                  />
                )}

                <ComponentWithError />
                {userEmail !== auth?.userEmail && (
                  <Controller
                    name="role"
                    control={form.control}
                    render={({ field, formState }) => {
                      return (
                        <>
                          <ComponentWithLabel label="WhoDesk role:" />
                          <Switch
                            value={field.value === "HR"}
                            onChange={(val) => {
                              field.onChange(val ? "HR" : "USER");
                            }}
                            style={{ width: "30%" }}
                            checkedChildren={"Admin"}
                            unCheckedChildren={"User"}
                          />
                        </>
                      );
                    }}
                  />
                )}
              </div>
              <div className={styles.formColumn}>
                <EquipmentSection
                  assocId={{ userEmail: data?.email }}
                  disabled={viewOnly}
                />
              </div>
            </div>

            {!viewOnly && (
              <div id={styles.buttonContainer}>
                {userEmail !== auth?.userEmail && !isDeleted && (
                  <DeactivateButton onDelete={onDelete} />
                )}
                <Button
                  size="middle"
                  htmlType="submit"
                  type="primary"
                  loading={isUpdating}
                >
                  {isDeleted ? "Activate" : "Update"}
                </Button>
              </div>
            )}
          </CompanyUserForm>
        </FormProvider>
      </LoadingBoundry>
    </CenteredFloatingContainer>
  );
}

const DeactivateButton = ({ onDelete }: { onDelete: () => void }) => {
  return (
    <ConfirmationDialog
      primaryButtonProps={{ type: "default", danger: true }}
      heading="Are you sure you want to deactivate this user?"
      subHeading="You can later activate them back"
      onConfirm={onDelete}
      confirmText="Deactivate"
      type="danger"
    >
      <Button size="middle" danger>
        Deactivate
      </Button>
    </ConfirmationDialog>
  );
};

export const EquipmentIcon: React.FC<{ type: EquipmentMinified["type"] }> = ({
  type,
}) => {
  switch (type?.toLowerCase()) {
    case "accessory":
      return <IconDeviceUsb />;
    case "computer":
      return <IconDevices2 />;
    case "software":
      return <IconCategory />;
    case "phone":
      return <IconDeviceMobile />;
    case "monitor":
      return <IconDeviceDesktop />;
    case "speaker":
      return <IconDeviceSpeaker />;
    case "camera":
      return <IconCamera />;
    case "tv":
      return <IconDeviceTv />;
    default:
      return <IconDice5 />;
  }
};
