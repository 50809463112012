import { GoogleOAuthProvider } from "@react-oauth/google";
import { QueryClientProvider } from "@tanstack/react-query";
import { ConfigProvider } from "antd";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import relativeTime from "dayjs/plugin/relativeTime";
import updateLocale from "dayjs/plugin/updateLocale";
import utc from "dayjs/plugin/utc";
import weekday from "dayjs/plugin/weekday";
import "./App.css";

import { queryClient } from "./config/useQueryClientConfig";
import { ThemeProvider, useTheme } from "./context/ThemeContext";
import ApplicationRouter from "./routes";
dayjs.extend(relativeTime);
dayjs.extend(updateLocale);
dayjs.extend(weekday);
dayjs.extend(utc);
dayjs.extend(isBetween);
dayjs.updateLocale("en", {
  weekStart: 1,
});
const App = () => {
  const theme = useTheme();
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: theme.secondary,
              colorTextLightSolid: "var(--secondary-text-color)",
            },
            components: {
              Tooltip: {
                colorTextLightSolid: "white",
              },
            },
          }}
        >
          <GoogleOAuthProvider
            clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID!}
          >
            <ApplicationRouter />
          </GoogleOAuthProvider>
        </ConfigProvider>
        {!process.env.REACT_APP_PROD && <div className="devIndicator">DEV</div>}
      </ThemeProvider>
    </QueryClientProvider>
  );
};

export default App;
