import { IconArrowRight, IconSettings } from "@tabler/icons-react";
import { AvatarProps, Button, ColorPicker, Flex } from "antd";
import { useEffect, useState } from "react";
import { IconWithBackground } from "../../../../../components/IconWithBackground/IconWithBackground";
import CustomCheckbox from "../../../../../components/common/CustomCheckbox/CustomCheckbox";
import { ModalDialog } from "../../../../../components/common/ModalDialog/ModalDialog";
import { useUserPhotoUpdate } from "../../../../../repo/users";
import { removeUndefined } from "../../../../../utils/arrayUtils";
import { UserAvatar } from "../../UsersViewPage/UsersViewPage";
import styles from "./UserAvatarPicker.module.css";
type Props = {
  email?: string;
  src?: string;
};

export default function UserAvatarPicker({
  email,
  className,
  src,
  ...props
}: Props & AvatarProps) {
  const { mutate: update } = useUserPhotoUpdate();
  const [showModal, setShowModal] = useState(false);
  const [avatarParams, setAvatarParams] = useState<any>({
    seed: email,
  });
  const srcUrl = `https://api.dicebear.com/8.x/miniavs/svg?${new URLSearchParams(
    removeUndefined(avatarParams)
  ).toString()}`;
  const toggleModal = () => {
    setShowModal(!showModal);
  };
  const save = () => {
    if (email) {
      update(
        { photoUrl: srcUrl, email: email },
        {
          onSuccess() {
            toggleModal();
          },
        }
      );
    } else {
      toggleModal();
    }
  };
  useEffect(() => {
    const urlParams = new URLSearchParams(src);
    const params = Object.fromEntries(urlParams);
    setAvatarParams({ seed: email, ...params });
  }, [email, src]);
  return (
    <>
      <div className={`${className} ${styles.container}`} onClick={toggleModal}>
        <UserAvatar
          identifier={email}
          src={src}
          className={`${styles.avatar}`}
        />
        <div className={styles.cover}>
          <IconSettings className={styles.settingsIcon} />
        </div>
      </div>

      <ModalDialog
        open={showModal}
        onClose={toggleModal}
        title={"Avatar settings"}
      >
        <div className={styles.modalContainer}>
          <div className={styles.controlsContainer}>
            {options.map((o) => (
              <ControlRow
                title={o.name}
                option={o.name}
                key={o.name}
                values={avatarParams}
                onSet={(k, value) => {
                  avatarParams[k] = value;
                  setAvatarParams({ ...avatarParams });
                }}
              />
            ))}
          </div>
          <Flex vertical gap={"1rem"}>
            <UserAvatar
              identifier={email}
              src={srcUrl}
              className={styles.modalAvatar}
            />
            <Button type="primary" onClick={save}>
              Save
            </Button>
          </Flex>
        </div>
      </ModalDialog>
    </>
  );
}

const ControlRow = ({
  title,
  option,
  onSet,
  values,
}: {
  title: string;
  option: string;
  values: any;
  onSet: (k: string, val?: string) => void;
}) => {
  const value = values[option];
  const optionFromJson = options.find((o) => o.name === option);
  const optionValues = optionFromJson?.values.map((v) => v.name);
  const isCheckBox =
    optionValues?.length === 2 && optionValues.includes(undefined);
  const color = optionFromJson?.color;
  const probability = optionFromJson?.probability;
  const nextValue = () => {
    if (optionValues) {
      const currentIndex = optionValues.indexOf(value);
      const nextIndex = (currentIndex + 1) % optionValues.length;
      onSet(option, optionValues[nextIndex]);
      if (probability) {
        onSet(probability as any, optionValues[nextIndex] ? "100" : "0");
      }
    }
  };
  return (
    <div className={styles.controlRow}>
      <span className={styles.controlRowTitle}>
        {title.capitalizeFirstLetter()}
      </span>
      {isCheckBox ? (
        <CustomCheckbox checked={value !== undefined} onChange={nextValue} />
      ) : (
        <IconWithBackground
          icon={
            <IconArrowRight style={{ cursor: "pointer" }} onClick={nextValue} />
          }
        ></IconWithBackground>
      )}
      {color && (
        <ColorPicker
          placement="top"
          value={values[color] || "#fff"}
          disabledAlpha
          onChange={debounce((col: any, hex: any) => {
            onSet(color, hex.replace("#", ""));
          }, 300)}
        />
      )}
    </div>
  );
};
const debounce = (callback: any, wait: any) => {
  let timeoutId: any = null;
  return (...args: any) => {
    window.clearTimeout(timeoutId);
    timeoutId = window.setTimeout(() => {
      callback(...args);
    }, wait);
  };
};
const options = [
  {
    name: "head",
    width: 64,
    height: 64,
    color: "skinColor",
    values: [
      {
        name: "normal",
        content:
          '<path d="M45.89 36.1c0 8.5-1.26 18.86-10.89 19.82v9.95S31.36 68 26.5 68 18 64.52 18 64.52V42a5 5 0 0 1-1.3-9.83C15.36 22.64 17.5 13 32 13c14.59 0 14.24 11.08 13.96 19.81q-.07 1.73-.07 3.29" fill="url(#color-skin)"/><path d="M35 55.92q-.72.07-1.5.07c-8.88 0-13.9-7.15-15.5-14.6v23.13S21.64 68 26.5 68s8.5-2.13 8.5-2.13z" fill="black" fill-opacity="0.07"/><path d="M34.63 55.95q-.55.04-1.13.04c-6.53 0-10.97-3.86-13.5-8.87v1.12c0 5.38 2.61 9.75 8.28 9.75h1.35c3.34.03 4.6.04 5-2.04M16.7 32.17A5 5 0 0 0 18.14 42a25 25 0 0 1-.71-5.9 47 47 0 0 1-.73-3.93" fill="black" fill-opacity="0.07"/><rect x="36" y="41" width="3" height="2" rx="1" fill="black" fill-opacity="0.07"/>',
        default: true,
      },
      {
        name: "wide",
        content:
          '<path d="M46.07 40.4c.47 7.5.94 15-11.07 15.56v9.91S31.36 68 26.5 68 18 64.52 18 64.52V45.1q-.3-1.53-.41-3.12a5 5 0 0 1-.9-9.8C15.37 22.63 17.52 13 32 13c14.59 0 14.24 11.08 13.96 19.81q-.07 1.73-.07 3.29c0 1.4.09 2.86.18 4.3" fill="url(#color-skin)"/><path d="M17.59 41.98c-.16-1.97-.16-3.97-.16-5.88a47 47 0 0 1-.73-3.93 5 5 0 0 0 .89 9.8m.41 3.13v19.42S21.64 68 26.5 68s8.5-2.13 8.5-2.13v-9.91q-.72.03-1.5.03C22.71 56 19.16 51.05 18 45.1" fill="black" fill-opacity="0.07"/><path d="M34.93 55.96q-.69.03-1.43.03c-7.25 0-11.23-2.23-13.41-5.5.5 4.82 3.16 8.5 8.3 8.5h1.36c3.8.03 4.92.04 5.18-3.03" fill="black" fill-opacity="0.07"/><rect x="36" y="41" width="3" height="2" rx="1" fill="black" fill-opacity="0.07"/>',
        default: true,
      },
      {
        name: "thin",
        content:
          '<path d="M45.89 36.1c0 8.92-3.9 18.9-10.39 20.4l-.5-.06v9.43S31.36 68 26.5 68 18 64.52 18 64.52V42a5 5 0 0 1-1.3-9.83C15.36 22.64 17.5 13 32 13c14.59 0 14.24 11.08 13.96 19.81q-.07 1.73-.07 3.29" fill="url(#color-skin)"/><path d="M18 42v22.52S21.64 68 26.5 68s8.5-2.13 8.5-2.13v-9.43c-7.26-1.02-13.9-7.5-16.45-14.47q-.28.03-.55.03" fill="black" fill-opacity="0.07"/><path d="M16.7 32.17a5 5 0 0 0 1.85 9.8 17 17 0 0 1-1.12-5.87 47 47 0 0 1-.73-3.93m18 24.23c-6.75-1.1-12.9-6.87-15.7-13.3v.4c0 5.65 6.27 14.47 9.38 15.5 2.57.82 5.4.64 6.32-2.6" fill="black" fill-opacity="0.07"/><rect x="36" y="41" width="3" height="2" rx="1" fill="black" fill-opacity="0.07"/>',
        default: true,
      },
    ],
  },
  {
    name: "hair",
    width: 64,
    height: 64,
    color: "hairColor",
    values: [
      {
        name: "balndess",
        content:
          '<rect x="14" y="26" width="9" height="2" rx="1" fill="url(#color-hair)"/><rect x="14" y="33" width="7" height="2" rx="1" fill="url(#color-hair)"/><rect x="12" y="29" width="13" height="3" rx="1.5" fill="url(#color-hair)"/>',
        default: true,
      },
      {
        name: "slaughter",
        content:
          '<path d="M24 25q0 .8-.3 1.5A4 4 0 0 1 20 32a4 4 0 0 1-8 0c0-1.05.07-2.78.37-4.32a4 4 0 0 1 4.8-5.5A3.99 3.99 0 0 1 24 25m21.85 8.9a4 4 0 0 0 2.78-5.4 4 4 0 0 0-3.13-5.46c.6 3.14.48 6.61.38 9.78q0 .54-.03 1.07" fill="url(#color-hair)"/>',
        default: true,
      },
      {
        name: "ponyTail",
        content:
          '<path d="M0 10c8-8 13 3 13 3l-2 8s-2 2.5 0 10-2.5 11.5-2.5 11.5S5 45 2 43s-3.4-3.92-3-7.5c.5-4.5 2-3 2-7.5s-9-10-1-18" fill="url(#color-hair)"/><path d="m13.03 13.18 5.5 2.38-3.56 8.26-5.51-2.38S8.5 19.5 10 16.5s3.03-3.32 3.03-3.32" fill="#FF6C98"/><path d="m18 41-1.32-8.82A5 5 0 0 0 18 42v8.3q-2.34-2.07-4.08-4.83c-1.7-2.7-2.9-5.8-3.48-9.1-.6-3.28-.58-6.68.04-9.96a26 26 0 0 1 3.55-9.03 21 21 0 0 1 6.43-6.5A18 18 0 0 1 31 8c2.5 0 4.97.45 7.27 1.33s4.4 2.17 6.16 3.8a17.5 17.5 0 0 1 4.12 5.67q1.13 2.5 1.38 5.2H50v4.29s-.33.15-1 .36v8.5s0 1.4-1.5 2.45c-.52.36-1.22.64-1.9.85q.29-2.18.29-4.35 0-1.56.07-3.3.06-1.66.08-3.43c-1.85.34-4.37.63-7.58.63C30.62 30 26 28.29 26 28.29v-1A26 26 0 0 0 24 31c-.75 1.75-.75 3.54-.75 5.05s0 2.73-.75 3.34c-1.5 1.2-4.5 1.6-4.5 1.6" fill="url(#color-hair)"/>',
        default: true,
      },
      {
        name: "long",
        content:
          '<path fill-rule="evenodd" clip-rule="evenodd" d="M48 25a18 18 0 0 0-36-.5V65h11V34.6a65 65 0 0 0 12.2-3.76 54 54 0 0 0 10.68-5.64c.24 2.53.15 5.16.08 7.61q-.07 1.73-.08 3.29l-.02 1.23q.35-.1.64-.24c1.5-.7 1.5-1.6 1.5-1.6zm-33 8a4.98 4.98 0 0 0 0 8z" fill="url(#color-hair)"/>',
        default: true,
      },
      {
        name: "curly",
        content:
          '<path d="M45.97 32.48a4 4 0 0 0 1.78-4.88 4 4 0 0 0-2.97-7.41c1.42 3.67 1.31 8.22 1.19 12.3" fill="url(#color-hair)"/><path d="M36.3 22.76a5 5 0 0 1-6.36.2 7 7 0 0 1-7 1.74q-.4.43-.88.73a3.98 3.98 0 0 1-1.35 6.19q.3.37.3.88A1.5 1.5 0 0 1 19.5 34a4 4 0 0 1-7.3-3.17 4 4 0 0 1 0-5.66 4 4 0 0 1 .74-3.71 7 7 0 0 1 4.1-10.17v-.31a4 4 0 0 1 5.94-3.5 7 7 0 0 1 9.8-2.42 6.48 6.48 0 0 1 10.78 1.95 4 4 0 0 1 3.43 4.58 7.3 7.3 0 0 1 3.05 5.9c0 4.15-3.58 7.5-8 7.5a8.3 8.3 0 0 1-5.7-2.23" fill="url(#color-hair)"/>',
        default: true,
      },
      {
        name: "stylish",
        content:
          '<path d="M20.67 28.22v6.02c0 .76-.67 1.76-1.17 2.26s-2 1.5-2.85 1.5-3.54-.45-4.83-2.26c-1.28-1.8-.9-11.32 0-13.54s4.19-6.88 8.85-10.45A26 26 0 0 1 33.55 6.4C58.48 3.4 49.22 21 46 25.5c-5.5-2-9.5-2.5-16.72-1.53-7.21.99-8.01 1.99-8.6 4.24" fill="url(#color-hair)"/>',
        default: true,
      },
      {
        name: "elvis",
        content:
          '<path d="M22 28c-.63 3 1 6.98 1 7.74 0 .77-3.93 3.03-5 3.76s-1.5-7-1.5-7c-3 0-3.5 5.5-3.5 5.5s-2.25-.74-3-4.5c-.51-2.54.3-8.09.5-9.5.5-3.5 1-11.5 7.5-15.5s23-4 27-3c9.9 2.47 11.22 16 8 20.5-5 5.5-19-1-23.5-1s-6.87 0-7.5 3" fill="url(#color-hair)"/>',
        default: true,
      },
      {
        name: "classic02",
        content:
          '<path d="M28.12 27.47C35.92 28.6 49.6 30.6 51 18 52 9 38.5-1.5 24 8c-9.1 1.35-11.39 8.28-11.9 14.26a4 4 0 0 0-.8 4.24 4 4 0 0 0 .87 4.33A4 4 0 1 0 20 32a4 4 0 0 0 3.87-5H24c.95 0 2.4.21 4.12.47" fill="url(#color-hair)"/>',
        default: true,
      },
      {
        name: "classic01",
        content:
          '<path d="M29.7 26.2c5.6 1.87 13.39 4.45 21.53-3.7 4-4-14-21-28.5-11.5-11.33 1.68-10.68 8.47-10.18 13.71q.17 1.51.18 2.8 0 .78.2 1.2a27 27 0 0 0-.2 3.3 4 4 0 1 0 8 0 4 4 0 0 0 3.88-5h.12a4 4 0 0 0 3.06-1.4z" fill="url(#color-hair)"/>',
        default: true,
      },
    ],
  },
  {
    name: "blushes",
    width: 64,
    height: 64,
    probability: "blushesProbability",

    values: [
      {
        name: "default",
        content:
          '<rect x="24" y="39" width="5" height="3" rx="1.5" fill="#FF859B"/><rect x="43" y="39" width="4" height="3" rx="1.5" fill="#FF859B"/>',
        default: true,
      },
      {
        name: undefined,
        content:
          '<rect x="24" y="39" width="5" height="3" rx="1.5" fill="#FF859B"/><rect x="43" y="39" width="4" height="3" rx="1.5" fill="#FF859B"/>',
        default: true,
      },
    ],
  },
  {
    name: "eyes",
    width: 64,
    height: 64,
    values: [
      {
        name: "normal",
        content:
          '<rect x="30" y="36" width="3" height="4" rx="1.5" fill="url(#color-hair)"/><rect x="40" y="36" width="3" height="4" rx="1.5" fill="url(#color-hair)"/><use href="#component-blushes"/>',
        default: true,
      },
      {
        name: "confident",
        content:
          '<use href="#component-blushes"/><path d="M43 37.5a1.5 1.5 0 0 1-3 0v-1.23c0-.15.12-.27.27-.27h2.46c.15 0 .27.12.27.27zm-10 0a1.5 1.5 0 0 1-3 0v-1.23c0-.15.12-.27.27-.27h2.46c.15 0 .27.12.27.27z" fill="url(#color-hair)"/><line x1="29.5" y1="36.5" x2="33.5" y2="36.5" stroke="url(#color-hair)" stroke-linecap="round"/><line x1="39.5" y1="36.5" x2="43.5" y2="36.5" stroke="url(#color-hair)" stroke-linecap="round"/>',
        default: true,
      },
      {
        name: "happy",
        content:
          '<use href="#component-blushes"/><path d="M30 37.5a1.5 1.5 0 0 1 3 0v1.23c0 .15-.12.27-.27.27h-2.46a.27.27 0 0 1-.27-.27zm10 0a1.5 1.5 0 0 1 3 0v1.23c0 .15-.12.27-.27.27h-2.46a.27.27 0 0 1-.27-.27z" fill="#1B0B47"/>',
        default: true,
      },
    ],
  },
  {
    name: "glasses",
    width: 64,
    height: 64,
    probability: "glassesProbability",
    values: [
      {
        name: "normal",
        content:
          '<circle cx="30.2689" cy="36.2689" r="4.26891" stroke="black" stroke-width="2"/><circle cx="44.2689" cy="36.2689" r="4.26891" stroke="black" stroke-width="2"/><line x1="33.9852" y1="35.1056" x2="40.9852" y2="35.002" stroke="black" stroke-width="2"/>',
        default: true,
      },
      {
        name: undefined,
        content:
          '<circle cx="30.2689" cy="36.2689" r="4.26891" stroke="black" stroke-width="2"/><circle cx="44.2689" cy="36.2689" r="4.26891" stroke="black" stroke-width="2"/><line x1="33.9852" y1="35.1056" x2="40.9852" y2="35.002" stroke="black" stroke-width="2"/>',
        default: true,
      },
    ],
  },
  {
    name: "mouth",
    width: 64,
    height: 64,
    values: [
      {
        name: "default",
        content:
          '<path d="M27.93 46a1 1 0 0 1 1-1h9.14a1 1 0 0 1 1 1 5 5 0 0 1-5 5h-1.14a5 5 0 0 1-5-5" fill="#66253C"/><path d="M35.76 50.7a5 5 0 0 1-1.69.3h-1.14a5 5 0 0 1-5-4.8c.77-.29 1.9-.25 3.02-.22L32 46c2.21 0 4 1.57 4 3.5q0 .64-.24 1.2" fill="#B03E67"/><path d="M29 45h10v1a1 1 0 0 1-1 1h-8a1 1 0 0 1-1-1z" fill="white"/>',
        default: true,
      },
      {
        name: "missingTooth",
        content:
          '<path d="M27.93 46a1 1 0 0 1 1-1h9.14a1 1 0 0 1 1 1 5 5 0 0 1-5 5h-1.14a5 5 0 0 1-5-5" fill="#66253C"/><path d="M35.76 50.7a5 5 0 0 1-1.69.3h-1.14a5 5 0 0 1-5-4.8c.77-.29 1.9-.25 3.02-.22L32 46c2.21 0 4 1.57 4 3.5q0 .64-.24 1.2" fill="#B03E67"/><path d="M29 45h10v1a1 1 0 0 1-1 1h-8a1 1 0 0 1-1-1z" fill="white"/><path d="M31 45.3c0-.17.13-.3.3-.3h1.4c.17 0 .3.13.3.3v2.4a.3.3 0 0 1-.3.3h-1.4a.3.3 0 0 1-.3-.3z" fill="#B03E67"/>',
        default: true,
      },
    ],
  },
  {
    name: "mustache",
    width: 64,
    height: 64,
    probability: "mustacheProbability",
    values: [
      {
        name: "pencilThinBeard",
        content:
          '<path d="M26.5 45s4.5-.5 11-2v2c-1.5.67-5.5 1.6-7.5 2-2.5.5-3.5-2-3.5-2m21.5-.24S44 44 38 43v2c1.43.59 4.6 1.15 6.5 1.5 2.38.44 3.5-1.74 3.5-1.74M33.31 55.24a1 1 0 0 1 .97-1.24h3.44a1 1 0 0 1 .97 1.24l-.25 1a1 1 0 0 1-.97.76h-2.94a1 1 0 0 1-.97-.76z" fill="url(#color-hair)"/>',
        default: true,
      },
      {
        name: undefined,
        content:
          '<path d="M26.5 45s4.5-.5 11-2v2c-1.5.67-5.5 1.6-7.5 2-2.5.5-3.5-2-3.5-2m21.5-.24S44 44 38 43v2c1.43.59 4.6 1.15 6.5 1.5 2.38.44 3.5-1.74 3.5-1.74M33.31 55.24a1 1 0 0 1 .97-1.24h3.44a1 1 0 0 1 .97 1.24l-.25 1a1 1 0 0 1-.97.76h-2.94a1 1 0 0 1-.97-.76z" fill="url(#color-hair)"/>',
        default: true,
      },
      {
        name: "pencilThin",
        content:
          '<path d="M37.5 43c-6.5 1.5-11 2-11 2s1 2.5 3.5 2c2-.4 6-1.33 7.5-2zm.5 0c6 1 10 1.76 10 1.76s-1.12 2.18-3.5 1.74l-.32-.06c-1.92-.35-4.83-.89-6.18-1.44z" fill="url(#color-hair)"/>',
        default: true,
      },
      {
        name: "horshoe",
        content:
          '<path d="M27 46a3 3 0 0 1 3-3h11a3 3 0 0 1 3 3v11a4 4 0 0 1-4-4v-7h-9v7a4 4 0 0 1-4 4z" fill="url(#color-hair)"/>',
        default: true,
      },
      {
        name: "freddy",
        content:
          '<path d="M28 46a3 3 0 0 1 3-3h9a3 3 0 0 1 3 3z" fill="url(#color-hair)"/>',
        default: true,
      },
    ],
  },
  {
    name: "body",
    width: 64,
    height: 64,
    color: "bodyColor",
    values: [
      {
        name: "tShirt",
        content:
          '<rect x="7" y="60" width="40" height="23" rx="9" fill="url(#color-body)"/>',
        default: true,
      },
      {
        name: "golf",
        content:
          '<rect x="7" y="60" width="40" height="23" rx="9" fill="url(#color-body)"/><path d="M17 58h19v3s-5 1-9.5 1-9.5-1-9.5-1z" fill="url(#color-body)"/><path d="M17 58h19v2s-3 1.5-9.5 1.5S17 60 17 60z" fill="black" fill-opacity="0.2"/><path d="M16.5 59c0-1.1.9-2 2-2h16a2 2 0 0 1 2 2s-5 1-10 1-10-1-10-1" fill="url(#color-body)"/>',
        default: true,
      },
    ],
  },
];
