import React, { ReactNode, useEffect } from "react";
import { useCompany } from "../repo/company";
import { getTextColorFromHex, hexToRGB } from "../utils/colorUtils";
import { usePermissionsStore } from "./authContext";
import { useCompanySettings } from "./companyContext";

export const ThemeProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { settings, reset } = useCompanySettings();
  const { companyId } = usePermissionsStore();
  const { data: company } = useCompany(companyId);

  useEffect(() => {
    if (company) {
      reset(company.settings as any);
    }
  }, [company, reset]);

  useEffect(() => {
    const { theme } = settings;
    document.getElementsByTagName('body')[0].setAttribute(
      "style",
      `
        --primary-color:${theme.primary};
        --primary-color-light:${hexToRGB(theme.primary, 0.5)};
        --secondary-color:${theme.secondary};
        --secondary-color-light:${hexToRGB(theme.secondary, 0.8)};
        --tertiary-color:${theme.tertiary};
        --supplementary-color:${theme.supplementary};
        --metadata-color:${theme.metadataColor};
        --primary-text-color:${getTextColorFromHex(theme.primary)};
        --secondary-text-color:${getTextColorFromHex(theme.secondary)};
      `
    );
  }, [settings]);
  return <>{children}</>;
};

export const useTheme = () => useCompanySettings().settings.theme;
