import { fabric } from "fabric";
import { IPolylineOptions, MeetingRoom } from "fabric/fabric-impl";
import { v4 } from "uuid";
import { polygonDragControl } from "./Controls";

fabric.MeetingRoom = fabric.util.createClass(fabric.Polygon, {
  type: "meetingRoom",
  active: false,
  valuesBefore: {},
  associationId: undefined,
  highlightColor: "red",
  highlightColorOverride: undefined,
  overrides: {} as any,
  initialize: function (options: any) {
    this.callSuper(
      "initialize",
      [
        { x: 0, y: 0 },
        { x: 100, y: 0 },
        { x: 100, y: 100 },
        { x: 0, y: 100 },
      ],
      {
        stroke: "black",
        width: 100,
        height: 100,
        fill: "#0000",
        strokeUniform: true,
        snapAngle: 5,
        ...options,
      }
    );
    this.on("selected", (e: any) => {
      var lastControl = this.points.length - 1;
      this.controls = this.points.reduce(function (
        acc: any,
        point: any,
        index: any
      ) {
        acc["p" + index] = polygonDragControl(index, lastControl);
        return acc;
      },
      {});
      const { tl, bl, tr, br, ...restControls } =
        fabric.Object.prototype.controls;
      this.controls = {
        ...this.controls,
        ...restControls,
      };
      e.target.canvas.requestRenderAll();
    });
  },

  toObject: function () {
    const obj = fabric.util.object.extend(this.callSuper("toObject"), {
      id: this.get("id") || v4(),
      associationId: this.get("associationId"),
    });
    return obj;
  },
  setActive(isActive: boolean) {
    const target = this! as any;
    if (isActive && !target.active) {
      target.valuesBefore["stroke"] = target.stroke;
      target.valuesBefore["strokeWidth"] = target.strokeWidth;
      target.stroke = target.overrides?.highlightColor || target.highlightColor;
      target.strokeWidth = (target.strokeWidth || 0) + 2;
      target.dirty = true;
      target.canvas?.requestRenderAll();
    } else {
      target.set({ ...target.valuesBefore });
      target.dirty = true;
      target.canvas?.requestRenderAll();
    }
    target.active = isActive;
  },
  makeInteractive() {
    let opts: IPolylineOptions = {
      lockMovementX: true,
      lockMovementY: true,
      evented: true,
      hasBorders: false,
      hasControls: false,
      hoverCursor: "pointer",
    };
    let thisRoom: MeetingRoom = this;
    thisRoom.set(opts);
    thisRoom.valuesBefore = {};
    thisRoom.on("mouseover", (e) => {
      if (!thisRoom.active) {
        thisRoom.setActive.call(thisRoom, true);
        this.active = false;
      }
      thisRoom.canvas?.fire("entity:mouseover", { entity: thisRoom });
    });
    thisRoom.on("mouseout", (e) => {
      if (!thisRoom.active) {
        thisRoom.setActive.call(thisRoom, false);
      }
      thisRoom.canvas?.fire("entity:mouseout", { entity: thisRoom });
    });
    thisRoom.on("mousedown", (e) => {
      thisRoom.active = true;
      e.target?.canvas?.fire("entity:selected", { entity: thisRoom });
    });
  },
});

fabric.MeetingRoom.fromObject = function (object: any, callback?: any) {
  return fabric.Object._fromObject("MeetingRoom", object, callback) as any;
};
