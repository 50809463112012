import { Select, SelectProps } from "antd";
import { useParams } from "react-router-dom";
import { useMaps } from "../../repo/maps";

interface MapDropdownProps extends SelectProps {
    hideOnSingleMap?: boolean;
    showOnlyActive?: boolean;
  }
  export const MapDropdown = ({
    hideOnSingleMap,
    showOnlyActive,
    ...props
  }: MapDropdownProps) => {
    const { mapId } = useParams();
    const { data: maps } = useMaps({
      status: showOnlyActive ? "ACTIVE" : undefined,
    });
    if (hideOnSingleMap && maps && maps.length < 2) {
      return <></>;
    }
    return (
      <Select
        defaultValue={
          maps && maps.length > 0 ? "No map selected" : "No maps to select"
        }
        placeholder="Select a map"
        value={mapId}
        options={maps?.map((map) => ({ value: map.id, label: map.name }))}
        {...props}
      />
    );
  };