import { IconPlus } from "@tabler/icons-react";
import { Input, InputRef, Tag, Tooltip } from "antd";
import { truncate } from "lodash";
import { useEffect, useRef, useState } from "react";
import { ComponentWithLabel } from "../../../../../../../components/common/CustomInputText/CustomInputText";
import styles from "./TagSection.module.css";
type Props = {
  tags?: string[];
  onChange?: (tags: string[]) => void;
  editable?: boolean;
};

export default function TagSection({ tags = [], onChange, editable }: Props) {
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef<InputRef>(null);

  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);

  const handleClose = (removedTag: string) => {
    const newTags = tags.filter((tag) => tag !== removedTag);
    onChange?.(newTags);
  };

  const showInput = () => {
    setInputVisible(true);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleInputConfirm = () => {
    if (inputValue && !tags.includes(inputValue) && inputValue.length < 50) {
      onChange?.([...tags, inputValue]);
    }
    setInputVisible(false);
    setInputValue("");
  };

  return (
    <ComponentWithLabel label="Tags:">
      <div className={styles.container}>
        {tags.map<React.ReactNode>((tag) => {
          const isLongTag = tag.length > 20;
          const tagElem = (
            <Tag
              key={tag}
              closable={editable}
              className={styles.tag}
              onClose={() => handleClose(tag)}
              color="var(--primary-color)"
            >
              {isLongTag ? `${truncate(tag, { length: 20 })}` : tag}
            </Tag>
          );
          return isLongTag ? (
            <Tooltip title={tag} key={tag}>
              {tagElem}
            </Tooltip>
          ) : (
            tagElem
          );
        })}
        {editable &&
          (inputVisible ? (
            <Input
              className={styles.tagInput}
              ref={inputRef}
              type="text"
              size="small"
              value={inputValue}
              onChange={handleInputChange}
              onBlur={handleInputConfirm}
              onPressEnter={handleInputConfirm}
            />
          ) : (
            <Tag
              className={styles.plusTag}
              icon={<IconPlus size={15} />}
              onClick={showInput}
              color="var(--secondary-color)"
            >
              New Tag
            </Tag>
          ))}
      </div>
    </ComponentWithLabel>
  );
}
