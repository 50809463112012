import { useMutation, useQuery } from "@tanstack/react-query";
import { message } from "antd";
import { v4 } from "uuid";
import { CreateTeamRequest, TeamResponse, UpdateTeamRequest } from ".";
import { axiosClient } from "../config/axios";
import { invalidateQueries, queryClient } from "../config/useQueryClientConfig";
import { getCompanyId, getHeaders } from "./common";
export const useTeams = () => {
  return useQuery<TeamResponse[]>({
    queryKey: ["teams"],
    queryFn: getTeams,
    staleTime: Infinity,
  });
};
export const useTeamUpdate = () => {
  const msgKey = "teamUpdate";
  return useMutation<
    TeamResponse,
    Error,
    { team: TeamResponse },
    { previousTeams: any }
  >({
    mutationFn: ({ team }) => updateCompanyTeam(team),
    onMutate: async ({ team }) => {
      message.open({
        type: "loading",
        content: "Updating team...",
        key: msgKey,
      });
      await queryClient.cancelQueries({ queryKey: ["teams"] });
      const previousTeams = queryClient.getQueryData(["teams"]);
      queryClient.setQueryData(["teams"], (old: any[]) => {
        return old.map((obj) => {
          if (obj.id === team.id) {
            return team;
          }
          return obj;
        });
      });
      return { previousTeams };
    },
    onError: (err, newTeam, context) => {
      message.open({
        type: "error",
        content: "Could not update team",
        key: msgKey,
      });
      queryClient.setQueryData(["teams"], context?.previousTeams);
    },
    onSuccess: (data) => {
      message.open({
        type: "success",
        content: "Team updated",
        key: msgKey,
      });
      invalidateQueries({ queryKey: ["teams", data.id] });
      invalidateQueries({ queryKey: ["users"] });
    },
  });
};
export const useTeamCreate = (onSuccess?: (data: TeamResponse) => void) => {
  const msgKey = "teamCreate";
  return useMutation<TeamResponse, Error, CreateTeamRequest, any>({
    mutationFn: (team) => addTeamToCompany(team),
    onMutate: async (newTeam) => {
      message.open({
        type: "loading",
        content: "Creating team...",
        key: msgKey,
      });
      await queryClient.cancelQueries({ queryKey: ["teams"] });
      const previousTeams = queryClient.getQueryData(["teams"]);
      queryClient.setQueryData(["teams"], (old: any) => [
        ...(old || []),
        { ...newTeam, id: v4() },
      ]);
      return { previousTeams };
    },
    onError: (err, newTeam, context) => {
      message.open({
        type: "error",
        content: "Failed to create team",
        key: msgKey,
      });
      queryClient.setQueryData(["teams"], context?.previousTeams);
    },
    onSuccess(data, variables, context) {
      queryClient.setQueryData(["teams"], (old: any[]) => {
        return old.map((obj) => {
          if (obj.name === data.name) {
            return data;
          }
          return obj;
        });
      });

      message.open({
        type: "success",
        content: "Team created",
        key: msgKey,
      });
      onSuccess?.(data);
    },
  });
};
export const useTeamDelete = (onSuccess?: () => void) => {
  const msgKey = "teamDelete";
  return useMutation<any, Error, { teamId: string }, { previousTeams: any }>({
    mutationFn: ({ teamId }) => deleteCompanyTeam(teamId),
    onMutate: async ({ teamId }) => {
      message.open({
        type: "loading",
        content: "Deleting team",
        key: msgKey,
      });
      await queryClient.cancelQueries({ queryKey: ["teams"] });
      const previousTeams = queryClient.getQueryData(["teams"]);
      queryClient.setQueryData(["teams"], (old: any[]) => {
        return old.filter((obj) => obj.id !== teamId);
      });
      return { previousTeams };
    },
    onError: (err, oldUser, context) => {
      message.open({
        type: "error",
        content: "Could not delete team",
        key: msgKey,
      });
      queryClient.setQueryData(["teams"], context?.previousTeams);
    },
    onSuccess(data, variables, context) {
      message.open({
        type: "success",
        content: "Team deleted",
        key: msgKey,
      });
      onSuccess?.();
    },
  });
};
export const useTeam = (teamId?: string) => {
  return useQuery<TeamResponse>({
    queryKey: ["teams", teamId],
    queryFn: () => getTeam(teamId!),
    enabled: teamId !== undefined,
    staleTime: Infinity,
  });
};
const getTeams = async () => {
  let companyId = await getCompanyId();
  const res = await axiosClient.get<TeamResponse[]>(
    `/companies/${companyId}/teams`,
    { headers: getHeaders() }
  );
  return res.data;
};
const getTeam = async (teamId: string) => {
  let companyId = await getCompanyId();
  const res = await axiosClient.get<TeamResponse>(
    `/companies/${companyId}/teams/${teamId}`,
    { headers: getHeaders() }
  );
  return res.data;
};
export const addTeamToCompany = async (team: CreateTeamRequest) => {
  let companyId = await getCompanyId();
  const res = await axiosClient.post<TeamResponse>(
    `/companies/${companyId}/teams`,
    team,
    { headers: getHeaders() }
  );
  return res.data;
};
export const updateCompanyTeam = async (team: UpdateTeamRequest) => {
  let companyId = await getCompanyId();
  const res = await axiosClient.put<TeamResponse>(
    `/companies/${companyId}/teams/${team.id}`,
    team,
    { headers: getHeaders() }
  );
  return res.data;
};
export const deleteCompanyTeam = async (teamId: string) => {
  let companyId = await getCompanyId();
  const res = await axiosClient.delete<any[]>(
    `/companies/${companyId}/teams/${teamId}`,
    { headers: getHeaders() }
  );
  return res.data;
};
