import { IconUpload } from "@tabler/icons-react";
import { Button, ColorPicker } from "antd";
import _, { debounce } from "lodash";
import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import ButtonWithIcon from "../../../../components/common/ButtonWithIcon";
import { CustomInputText } from "../../../../components/common/CustomInputText/CustomInputText";
import LoadingBoundry from "../../../../components/common/LoadingBoundry/LoadingBoundry";
import WhoDeskLogo, {
  DEFAULT_LOGO,
} from "../../../../components/common/WhoDeskLogo";
import { usePermissionsStore } from "../../../../context/authContext";
import {
  CompanySettingsState,
  useCompanySettings,
} from "../../../../context/companyContext";
import { CompanyResponse } from "../../../../repo";
import {
  useCompany,
  useCompanyLogoDelete,
  useCompanyLogoUpload,
  useUpdateCompany,
} from "../../../../repo/company";
import BorderDivider from "../../../common/BorderDivider/ListDivider";
import CenteredFloatingContainer from "../../../common/CenteredFloatingContainer/CenteredFloatingContainer";
import styles from "./CompanyPage.module.css";
type Props = {};

export default function CompanyPage({}: Props) {
  const { companyId } = usePermissionsStore();
  const { resetTheme, settings } = useCompanySettings();
  const { data: company, isLoading, error } = useCompany(companyId);
  const { mutate: uploadLogo } = useCompanyLogoUpload();
  const { mutate: deleteLogo } = useCompanyLogoDelete();
  const { mutate: update } = useUpdateCompany();
  const [logo, setLogo] = useState<Blob>();
  const [shouldDeleteLogo, setShouldDeleteLogo] = useState<Boolean>();
  const themeRef = useRef(settings.theme);
  const {
    handleSubmit,
    control,
    reset: resetForm,
  } = useForm<CompanyResponse>();
  useEffect(() => {
    return () => {
      resetTheme(themeRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (company) {
      resetForm(company);
    }
  }, [company, resetForm]);
  const onSubmit = (data: CompanyResponse) => {
    themeRef.current = settings.theme;
    update({
      ...data,
      settings: _.merge(data.settings, settings),
    });
    const reload = () => window.location.reload();
    if (logo) {
      uploadLogo(
        { file: logo },
        {
          onSuccess: reload,
        }
      );
    } else if (shouldDeleteLogo) {
      deleteLogo(undefined, {
        onSuccess: reload,
      });
    }
  };
  return (
    <div className={`appContent`}>
      <CenteredFloatingContainer header="Company settings" withoutClose>
        <LoadingBoundry loading={isLoading} error={error}>
          <form className={styles.content} onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.title}>Details</div>
            <BorderDivider />
            <div className={styles.detailsContainer}>
              <Controller
                name="name"
                control={control}
                render={({ field }) => {
                  return (
                    <CustomInputText
                      label="Company name"
                      required
                      placeholder="WhoDesk"
                      {...field}
                    />
                  );
                }}
                rules={{
                  required: "Name is required",
                  maxLength: { value: 250, message: "Input too long" },
                }}
              />
            </div>
            <LogoUploadSection
              onUpload={setLogo}
              onDelete={() => setShouldDeleteLogo(true)}
            />
            <div className={styles.title}>Theme</div>
            <BorderDivider />
            <div className={styles.colorContainer}>
              <ThemeColor name="Primary color" id="primary" />
              <ThemeColor name="Secondary color" id="secondary" />
              <ThemeColor name="Tertiary color" id="tertiary" />
              <ThemeColor name="Supplementary color" id="supplementary" />
              <Button
                className={styles.defaultButton}
                onClick={() => resetTheme()}
              >
                Reset colors
              </Button>
            </div>

            <Button
              type="primary"
              className={styles.saveButton}
              htmlType="submit"
            >
              Save changes
            </Button>
          </form>
        </LoadingBoundry>
      </CenteredFloatingContainer>
    </div>
  );
}
const ThemeColor = ({
  name,
  id,
}: {
  name: string;
  id: keyof CompanySettingsState["settings"]["theme"];
}) => {
  const { settings, setThemeValue } = useCompanySettings();
  const { theme } = settings;
  return (
    <div className={styles.themeColor}>
      {name}
      <ColorPicker
        value={theme[id]}
        onChange={debounce((color, hex) => {
          setThemeValue(id, hex);
        }, 300)}
      />
    </div>
  );
};

function LogoUploadSection({
  onUpload,
  onDelete,
}: {
  onUpload?: (file: Blob) => void;
  onDelete?: () => void;
}) {
  const inputFileRef = useRef<HTMLInputElement>(null);
  const onFileUpload = () => {
    if (!inputFileRef.current) {
      return;
    }
    inputFileRef.current.click();
  };
  const _onDelete = () => {
    setCompanyLogoPresent(false);
    let output = getPlaceholderImage();
    output.src = DEFAULT_LOGO;
    onDelete?.();
  };
  const getPlaceholderImage = () => {
    return document.getElementById(styles.imagePreview) as any;
  };
  const [companyLogoPresent, setCompanyLogoPresent] = useState<boolean>(true);
  return (
    <>
      <div className={styles.title}>Logo</div>
      <BorderDivider />
      <div className={styles.imageUploadContainer}>
        <div className={styles.uploadContainer}>
          <label>
            <ButtonWithIcon
              icon={<IconUpload size={16} />}
              onClick={onFileUpload}
            >
              Upload
            </ButtonWithIcon>
            <input
              ref={inputFileRef}
              type="file"
              id={styles.fileUploadInput}
              accept="image/*"
              onChange={(event) => {
                if (!event.target.files || !event.target.files[0]) {
                  return;
                }
                let output = getPlaceholderImage();
                output.src = URL.createObjectURL(event.target.files[0]);
                output.onload = function () {
                  URL.revokeObjectURL(output.src); // free memory
                };
                onUpload?.(event.target.files[0]);
              }}
            />
          </label>
          {companyLogoPresent && (
            <Button danger onClick={_onDelete}>
              Delete
            </Button>
          )}
        </div>
        <BorderDivider horizontal />
        <div className={styles.imagePreviewContainer}>
          <WhoDeskLogo
            id={styles.imagePreview}
            alt="preview"
            onLoadFailure={() => setCompanyLogoPresent(false)}
          />
        </div>
      </div>
    </>
  );
}
