import { useQuery } from "@tanstack/react-query";
import { GetNotificationsQuery, NotificationResponse } from ".";
import { axiosClient } from "../config/axios";
import { invalidateQueries } from "../config/useQueryClientConfig";
import { getCompanyId, getHeaders } from "./common";

export function useNotifications(query?: GetNotificationsQuery) {
  return useQuery({
    queryKey: ["notifications"],
    queryFn: () => getNotifications(query),
  });
}

export function useNotificationMetadata(query?: GetNotificationsQuery) {
  return useQuery({
    queryKey: ["notifications", "metadata"],
    queryFn: () => getNotificationsMetadata(query),
  });
}

const getNotifications = async (query?: GetNotificationsQuery) => {
  let companyId = await getCompanyId();
  const res = await axiosClient.get<NotificationResponse[]>(
    `/companies/${companyId}/notifications`,
    { headers: getHeaders(), params: query }
  );
  return res.data;
};
const getNotificationsMetadata = async (query?: GetNotificationsQuery) => {
  let companyId = await getCompanyId();
  const res = await axiosClient.get<{ count: number }>(
    `/companies/${companyId}/notifications/metadata`,
    { headers: getHeaders(), params: query }
  );
  return res.data;
};
export const setNotificationAsViewed = async (notificationId: string) => {
  let companyId = await getCompanyId();
  const res = await axiosClient.put<NotificationResponse[]>(
    `/companies/${companyId}/notifications/${notificationId}`,
    { headers: getHeaders() }
  );
  invalidateQueries({ queryKey: ["notifications"] });
  return res.data;
};
