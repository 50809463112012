import { IconSettings } from "@tabler/icons-react";
import {
  Button,
  InputNumber,
  InputNumberProps,
  Switch,
  SwitchProps,
} from "antd";
import { useState } from "react";
import Explanation from "../../../../../../components/common/Explanation/Explanation";
import { ModalDialog } from "../../../../../../components/common/ModalDialog/ModalDialog";
import BorderDivider from "../../../../../common/BorderDivider/ListDivider";
import { AdditionalMapSettings } from "../../MapEditPage";
import styles from "./MapSettingsDialog.module.css";
export const DEFAULT_MAP_FUTURE_BOOKING_DAYS = 30;
export const MapSettingsDialog = ({
  settings,
  onChange,
}: {
  settings?: AdditionalMapSettings;
  onChange: (settings: AdditionalMapSettings) => void;
}) => {
  const _settings = settings || { status: "DRAFT", settings: { workDays: [] } };
  const [showSettings, setShowSettings] = useState(false);
  const toggleSettings = () => {
    setShowSettings(!showSettings);
  };
  const onStatusChange: SwitchProps["onChange"] = (val) => {
    onChange({ ..._settings, status: val ? "ACTIVE" : "DRAFT" });
  };
  const onWorkDaySwitch = (index: number, state: boolean) => {
    const modifiedSettings = settings;
    if (!modifiedSettings) {
      return;
    }
    const workDays = new Set(modifiedSettings.settings.workDays);
    if (state) {
      workDays.add(index);
    } else {
      workDays.delete(index);
    }
    modifiedSettings.settings.workDays = Array.from(workDays);
    onChange({ ...modifiedSettings });
  };
  const onFutureBookingDaysChange: InputNumberProps["onChange"] = (val) => {
    const modifiedSettings = settings;
    if (!modifiedSettings || !val) {
      return;
    }
    modifiedSettings.settings.futureBookingDays = val as number;
    onChange({ ...modifiedSettings });
  };
  return (
    <>
      <Button
        onClick={toggleSettings}
        style={{ display: "flex", alignItems: "center" }}
        icon={<IconSettings />}
      >
        Map settings
      </Button>
      <ModalDialog
        title={"Map settings"}
        open={showSettings}
        onClose={toggleSettings}
      >
        <strong>Status</strong>
        <Explanation description="When draft: will not be shown to users, no booking is possible" />
        <br />
        <BorderDivider />
        <div className={styles.mapStatusContainer}>
          <Switch
            checkedChildren="Active"
            unCheckedChildren="Draft"
            onChange={onStatusChange}
            value={_settings.status === "ACTIVE"}
          />
        </div>
        <strong>Working days</strong>
        <Explanation description="Week days when the map will be bookable" />
        <br />
        <BorderDivider />
        <div className={styles.mapWorkDayContainer}>
          {["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"].map(
            (day, index) => {
              return (
                <WorkDaySwitch
                  key={index + day}
                  dayName={day}
                  working={_settings.settings.workDays.includes(index)}
                  onChange={(state) => onWorkDaySwitch(index, state)}
                />
              );
            }
          )}
        </div>
        <strong>Amount of days possible to book in advance</strong>
        <br />
        <BorderDivider />
        <div className={styles.mapStatusContainer}>
          <InputNumber
            max={180}
            min={1}
            defaultValue={DEFAULT_MAP_FUTURE_BOOKING_DAYS}
            value={settings?.settings.futureBookingDays}
            onChange={onFutureBookingDaysChange}
          />
        </div>
      </ModalDialog>
    </>
  );
};

const WorkDaySwitch = ({
  dayName,
  onChange,
  working,
}: {
  dayName: string;
  onChange: (stateChange: boolean) => void;
  working: boolean;
}) => {
  const toggleOn = () => {
    onChange(!working);
  };
  return (
    <div className={styles.workDaySwitch} onClick={toggleOn}>
      <div className={styles.workDaySwitchDay}>{dayName}</div>
      <div
        className={styles.workDaySwitchColor}
        style={{ backgroundColor: working ? "green" : "red" }}
      ></div>
    </div>
  );
};
