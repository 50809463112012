import { Button, Flex } from "antd";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import {
  ComponentWithError,
  CustomInputText,
} from "../../../../components/common/CustomInputText/CustomInputText";
import { TeamResponse } from "../../../../repo";

import { IconTrash } from "@tabler/icons-react";
import { ConfirmationDialog } from "../../../../components/common/ConfirmationDialog/ConfirmationDialog";
import LoadingBoundry from "../../../../components/common/LoadingBoundry/LoadingBoundry";
import { useTeam, useTeamDelete, useTeamUpdate } from "../../../../repo/teams";
import CenteredFloatingContainer from "../../../common/CenteredFloatingContainer/CenteredFloatingContainer";
import { TeamColorPicker } from "../components/TeamColorPicker";
import styles from "./TeamEditView.module.css";
type Props = {};

export default function TeamEditView({}: Props) {
  const { handleSubmit, control, reset } = useForm<TeamResponse>();
  const { teamId } = useParams();
  const { data: team, isError, isLoading, refetch } = useTeam(teamId);
  useEffect(() => {
    reset(team);
  }, [team, reset]);
  const nav = useNavigate();
  const {
    mutate: updateTeam,
    isPending: isUpdating,
    isError: isUpdateError,
    reset: resetUpdate,
  } = useTeamUpdate();
  const {
    mutate: deleteTeam,
    isPending: isDeleting,
    isError: isDeleteError,
    reset: resetDelete,
  } = useTeamDelete(() => {
    nav(`/teams`, { replace: true });
  });
  const onSubmit = (data: TeamResponse) => {
    updateTeam({ team: data });
  };
  const onDelete = () => {
    teamId && deleteTeam({ teamId });
  };

  return (
    <CenteredFloatingContainer header={"Edit team"} backRoute={"/teams"}>
      <LoadingBoundry
        loading={isLoading || isUpdating || isDeleting}
        error={isError || isDeleteError || isUpdateError}
        description={
          isError
            ? "Failed to fetch team"
            : isDeleteError
            ? "Failed to delete team"
            : "Failed to update team"
        }
        onAction={isError ? refetch : isDeleteError ? resetDelete : resetUpdate}
      >
        <TeamForm onSubmit={handleSubmit(onSubmit)}>
          <TeamFormBody control={control} />
          <Flex id={styles.buttonContainer} justify="space-between">
            <DeleteButton onDelete={onDelete} />
            <Button
              size="middle"
              loading={isUpdating}
              htmlType="submit"
              type="primary"
            >
              Update
            </Button>
          </Flex>
        </TeamForm>
      </LoadingBoundry>
    </CenteredFloatingContainer>
  );
}

export const TeamFormBody = ({ control }: { control: any }) => {
  return (
    <>
      <Controller
        name="name"
        control={control}
        render={({ field, fieldState }) => {
          return (
            <CustomInputText
              label="Team name"
              className="padding-top"
              {...field}
              error={fieldState.error?.message}
              count={{
                show: true,
                max: 250,
              }}
              required={true}
            />
          );
        }}
        rules={{
          required: "Name is required",
          maxLength: { value: 250, message: "Value too long" },
        }}
      />
      <Controller
        name="color"
        control={control}
        render={({ field, fieldState }) => {
          return (
            <ComponentWithError error={fieldState.error?.message}>
              <TeamColorPicker
                value={field.value || "#fff"}
                onChange={field.onChange}
              />
            </ComponentWithError>
          );
        }}
        rules={{ required: "Color is required" }}
      />
    </>
  );
};

export const TeamForm = (props: React.FormHTMLAttributes<HTMLFormElement>) => {
  return <form id={styles.teamViewForm} {...props} />;
};

const DeleteButton = ({ onDelete }: { onDelete: () => void }) => {
  return (
    <>
      <ConfirmationDialog
        primaryButtonProps={{
          type: "default",
          danger: true,
          icon: <IconTrash />,
        }}
        heading="Are you sure you want to delete this team?"
        subHeading="This cannot be undone"
        confirmText="Delete"
        type="danger"
        onConfirm={onDelete}
      >
        <Button size="middle" danger>
          Delete
        </Button>
      </ConfirmationDialog>
    </>
  );
};
