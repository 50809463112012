export function filterByTerm<T extends object>(
  arr: Array<T> = [],
  term: string | null
): Array<T> {
  if (!term) {
    return arr; 
  }

  const normalizedTerm = term.toLowerCase(); 

  return arr.filter((item) => {
    return objectContainsTerm(item, normalizedTerm);
  });
}

function objectContainsTerm(obj: any, term: string): boolean {
  for (const key of Object.keys(obj)) {
    const value = obj[key];
    if (typeof value === "string" && value.toLowerCase().includes(term)) {
      return true;
    } else if (
      typeof value === "object" &&
      value !== null &&
      objectContainsTerm(value, term)
    ) {
      return true;
    }
  }
  return false;
}
export function removeUndefined(obj: any) {
  Object.keys(obj).forEach((key) =>
    obj[key] === undefined ? delete obj[key] : {}
  );
  return obj;
}
