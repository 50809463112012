import { useMutation, useQuery } from "@tanstack/react-query";
import { message } from "antd";
import { AxiosError } from "axios";
import {
  CreateMapRequest,
  ErrorResponse,
  FindMapsQuery,
  MapMinifiedResponse,
  MapResponse,
  UpdateMapRequest,
} from ".";
import { axiosClient } from "../config/axios";
import {
  invalidateQueries,
  persistentQueryClient
} from "../config/useQueryClientConfig";
import { toMilliseconds } from "../utils/dateUtils";
import { getCompanyId, getHeaders } from "./common";

export const useMaps = (query?: FindMapsQuery) => {
  return useQuery<MapMinifiedResponse[]>(
    {
      queryKey: ["maps", query],
      queryFn: () => {
        return getMaps(query);
      },
      staleTime: toMilliseconds({ minutes: 10 }),
    },
    persistentQueryClient
  );
};

export const useMap = (mapId?: string) => {
  return useQuery<MapResponse>(
    {
      queryKey: ["maps", mapId],
      queryFn: () => getMap(mapId!),
      enabled: mapId !== undefined,
      staleTime: toMilliseconds({ minutes: 10 }),
    },
    persistentQueryClient
  );
};

export function useMapStatus(mapId?: string) {
  const { data } = useMap(mapId);
  return data?.status;
}

export const useCreateMap = (onSuccess?: (data: MapResponse) => void) => {
  return useMutation<MapResponse, Error, { map: CreateMapRequest }>({
    mutationFn: ({ map }) => createMap(map),
    onSuccess(data, variables, context) {
      onSuccess?.(data);
      invalidateQueries({ queryKey: ["maps"] });
    },
  });
};

export const useDeleteMap = (onSuccess?: (data: MapResponse) => void) => {
  return useMutation<MapResponse, Error, { mapId: string }>({
    mutationFn: ({ mapId }) => deleteMap(mapId),
    async onSuccess(data, variables, context) {
      await invalidateQueries({ queryKey: ["maps"] });
      onSuccess?.(data);
    },
  });
};
export const useUpdateMap = () => {
  const msgKey = "mapUpdate";
  return useMutation<
    MapResponse,
    AxiosError<ErrorResponse>,
    { mapId: string; map: MapResponse }
  >({
    mutationFn: ({ map, mapId }) => _updateMap(mapId, map),
    onMutate(variables) {
      message.open({
        key: msgKey,
        type: "loading",
        content: "Updating map...",
      });
    },
    onError(error, variables, context) {
      message.open({
        type: "error",
        key: msgKey,
        content: "Could not update the map",
      });
    },
    onSuccess(data, variables, context) {
      invalidateQueries({ queryKey: ["maps"] });
      message.open({
        type: "success",
        key: msgKey,
        content: "Map updated successfully",
      });
    },
  });
};

const getMaps = async (query?: FindMapsQuery) => {
  let companyId = await getCompanyId();
  const res = await axiosClient.get<MapMinifiedResponse[]>(
    `/companies/${companyId}/maps`,
    { headers: getHeaders(), params: query }
  );
  return res.data;
};
const getMap = async (mapId: string) => {
  let companyId = await getCompanyId();
  const res = await axiosClient.get<MapResponse>(
    `/companies/${companyId}/maps/${mapId}`,
    { headers: getHeaders() }
  );
  return res.data;
};
const deleteMap = async (mapId: string) => {
  let companyId = await getCompanyId();
  const res = await axiosClient.delete<any>(
    `/companies/${companyId}/maps/${mapId}`,
    { headers: getHeaders() }
  );
  return res.data;
};

export const createMap = async (data: CreateMapRequest) => {
  let companyId = await getCompanyId();
  const res = await axiosClient.post<MapResponse>(
    `/companies/${companyId}/maps`,
    data,
    { headers: getHeaders() }
  );
  return res.data;
};
const _updateMap = async (mapId: string, data: UpdateMapRequest) => {
  let companyId = getCompanyId();
  const res = await axiosClient.put<MapResponse>(
    `/companies/${companyId}/maps/${mapId}`,
    data,
    { headers: getHeaders() }
  );
  return res.data;
};
export const updateMap = async (mapId: string, data: UpdateMapRequest) => {
  const msgKey = "mapUpdate";
  message.open({
    key: msgKey,
    type: "loading",
    content: "Updating map...",
  });
  let companyId = getCompanyId();
  try {
    const res = await axiosClient.put<MapResponse>(
      `/companies/${companyId}/maps/${mapId}`,
      data,
      { headers: getHeaders() }
    );
    invalidateQueries({ queryKey: ["maps", mapId] });
    invalidateQueries({ queryKey: ["maps"] });

    message.open({
      type: "success",
      key: msgKey,
      content: "Map updated successfully",
    });
    return res.data;
  } catch (error) {
    message.open({
      type: "error",
      key: msgKey,
      content: "Could not update the map",
    });
    return Promise.reject((error as AxiosError<ErrorResponse>).response?.data);
  }
};
