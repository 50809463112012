import { ChartData } from "chart.js";
import { useRef } from "react";
import { Bar } from "react-chartjs-2";
import LoadingBoundry from "../../../../../components/common/LoadingBoundry/LoadingBoundry";
import { EquipmentMinified } from "../../../../../repo";
import { useEquipmentList } from "../../../../../repo/equipment";
import ChartContainer from "../../components/ChartContainer/ChartContainer";
import {
  chartLegend,
  ChartPopup,
  ChartPopupActions,
} from "../../components/ConfigPopup/ConfigPopup";
import { useChartContext } from "../../context/chartContext";

import { getRandomColor } from "../../../../../utils/colorUtils";
type Props = {};

export const InventoryReport = (props: Props) => {
  const { data: equipment = [], isError, isLoading } = useEquipmentList();
  const popupRef = useRef<ChartPopupActions>(null);
  const options: any = {
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        display: false,
      },
      legend: chartLegend(popupRef),
    },

    scales: {
      y: {
        grace: "5%",
        title: {
          display: true,
          text: "Amount",
        },
      },
      x: {
        title: {
          display: true,
          text: "Types",
        },
      },
    },
  };
  const typeLabels = Array.from(
    new Set(equipment.map((e) => e.type?.capitalizeFirstLetter()).sort())
  );
  const data: ChartData<"bar"> = {
    labels: ["Total", ...typeLabels],
    datasets: [
      {
        label: "Total",
        data: [
          equipment.length,
          ...typeLabels.map(
            (label) =>
              equipment.filter(
                (e) => e.type?.toUpperCase() === label?.toUpperCase()
              ).length
          ),
        ],
        backgroundColor:
          useChartContext.getState().chartSettings.get("Total")?.color ||
          getRandomColor(),
        stack: "total",
      },
      {
        label: "Free",
        data: [
          equipment.filter((e) => !equipmentHasAssociation(e)).length,
          ...typeLabels.map(
            (label) =>
              equipment.filter(
                (e) =>
                  e.type?.toUpperCase() === label?.toUpperCase() &&
                  !equipmentHasAssociation(e)
              ).length
          ),
        ],
        backgroundColor:
          useChartContext.getState().chartSettings.get("Free")?.color ||
          getRandomColor(),
        stack: "Free",
      },
      {
        label: "Associated",
        data: [
          equipment.filter((e) => equipmentHasAssociation(e)).length,
          ...typeLabels.map(
            (label) =>
              equipment.filter(
                (e) =>
                  e.type?.toUpperCase() === label?.toUpperCase() &&
                  equipmentHasAssociation(e)
              ).length
          ),
        ],
        backgroundColor:
          useChartContext.getState().chartSettings.get("Associated")?.color ||
          getRandomColor(),
        stack: "Associated",
      },
    ],
  };
  return (
    <ChartContainer>
      <LoadingBoundry error={isError} loading={isLoading}>
        <ChartPopup ref={popupRef} noType />
        <Bar data={data} options={options} />
      </LoadingBoundry>
    </ChartContainer>
  );
};

const equipmentHasAssociation = (e: EquipmentMinified) => {
  return e.association !== undefined;
};
