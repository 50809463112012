import { Flex } from "antd";
import { ReactNode } from "react";
import { PlatformButton, PlatformButtonProps } from "./PlatformButton/PlatformButton";
export interface ButtonWithIconProps extends PlatformButtonProps {
  location?: "before" | "after";
  icon?: ReactNode
}
export default function ButtonWithIcon({
  children,
  location = "after",
  icon,
  ...props
}: ButtonWithIconProps) {
  return (
    <PlatformButton {...props}>
      <Flex align="center" justify="center" gap={"var(--spacing-xs)"}>
        {location === "before" && icon}
        {children}
        {location === "after" && icon}
      </Flex>
    </PlatformButton>
  );
}
