import { Button, Flex, Select } from "antd";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { CustomInputText } from "../../../../components/common/CustomInputText/CustomInputText";
import LoadingBoundry from "../../../../components/common/LoadingBoundry/LoadingBoundry";
import {
  setGlobalPermissions,
  usePermissionsStore,
} from "../../../../context/authContext";
import {
  CompanyResponse,
  DeskResponse,
  WhoDeskRole,
} from "../../../../repo";
import { useCompany, useUpdateCompany } from "../../../../repo/company";
import CenteredFloatingContainer from "../../../common/CenteredFloatingContainer/CenteredFloatingContainer";

type Props = {};

export default function CompanyView({}: Props) {
  const { companyId } = useParams();
  const { role } = usePermissionsStore();
  const { data, isLoading, isError, refetch } = useCompany(companyId);
  const [selectedRole, setSelectedRole] = useState<WhoDeskRole | undefined>(
    role
  );

  const {
    mutate: update,
    isPending,
    isError: isUpdateError,
  } = useUpdateCompany();
  const submit = (data: CompanyResponse) => {
    update(data);
  };
  const onRoleChange = (val: WhoDeskRole) => {
    setSelectedRole(val);
  };

  useEffect(() => {
    if (companyId && selectedRole) {
      setGlobalPermissions({ companyId: companyId, role: selectedRole });
    }
  }, [selectedRole, companyId]);

  return (
    <LoadingBoundry
      loading={isLoading || isPending}
      error={isError || isUpdateError}
      onAction={refetch}
    >
      <CenteredFloatingContainer header={"Company"}>
        <CompanyForm onSubmit={submit} company={data}>
          <Flex justify="space-around">
            <Button type="primary" htmlType="submit">
              Update
            </Button>
            <Select value={selectedRole} onChange={onRoleChange}>
              {["HR", "USER"].map((r) => (
                <Select.Option key={r}>{r}</Select.Option>
              ))}
            </Select>
          </Flex>
        </CompanyForm>
      </CenteredFloatingContainer>
    </LoadingBoundry>
  );
}

export const CompanyForm = ({
  children,
  onSubmit,
  company,
}: {
  children: React.ReactNode;
  onSubmit: (data: CompanyResponse) => void;
  company?: CompanyResponse;
}) => {
  const { control, handleSubmit, reset } = useForm<DeskResponse>();
  useEffect(() => {
    reset(company);
  }, [company, reset]);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="name"
        control={control}
        render={({ field, fieldState }) => {
          return (
            <CustomInputText
              label="Company name"
              placeholder="WhoDesk"
              {...field}
              error={fieldState.error?.message}
            />
          );
        }}
        rules={{ required: "Required" }}
      />
      {children}
    </form>
  );
};
