import { useMutation, useQuery } from "@tanstack/react-query";
import { message } from "antd";
import { AxiosError } from "axios";
import { Dayjs } from "dayjs";
import {
  CreateRoomBookingRequest,
  CreateRoomBookingResponse,
  CreateRoomRequest,
  DeskResponse,
  ErrorResponse,
  FindRoomBookingsRequest,
  RoomAvailabilityRequest,
  RoomBooking,
  RoomResponse,
  RoomUnavailability,
  UpdateRoomRequest,
} from ".";
import { axiosClient } from "../config/axios";
import { invalidateQueries, persistentQueryClient } from "../config/useQueryClientConfig";
import { toMilliseconds } from "../utils/dateUtils";
import { getCompanyId, getHeaders, getProviderHeaders } from "./common";

export const useRoomBookings = (req: {
  roomId?: string;
  mapId?: string;
  dateFrom: string;
  dateTo: string;
}) => {
  return useQuery({
    queryKey: ["rooms", req.roomId, "bookings", req],
    queryFn: () =>
      getRoomBookings(req.mapId!, req.roomId!, {
        dateFrom: req.dateFrom,
        dateTo: req.dateTo,
      }),
    enabled: req.roomId !== undefined && req.mapId !== undefined,
    refetchInterval: 1000 * 15,
    refetchOnWindowFocus: true
  });
};

export const useCreateRoom = () => {
  const msgKey = "roomCreate";

  return useMutation<
    RoomResponse,
    Error,
    { mapId: string; room: CreateRoomRequest }
  >({
    mutationFn: (data) => createRoom(data.mapId, data.room),
    onMutate: () => {
      message.open({
        type: "loading",
        content: "Creating room...",
        key: msgKey,
      });
    },
    onError: () => {
      message.open({
        type: "error",
        content: "Could not create room",
        key: msgKey,
      });
    },
    onSuccess: async (data, params) => {
      invalidateQueries({ queryKey: ["rooms"] });
      await invalidateQueries({ queryKey: ["maps", params.mapId] });
      message.open({
        type: "success",
        content: "Room created",
        key: msgKey,
      });
    },
  });
};
export const useUpdateRoom = () => {
  const msgKey = "deskUpdate";

  return useMutation<
    DeskResponse,
    AxiosError<ErrorResponse>,
    { mapId: string; roomId: string; room: UpdateRoomRequest }
  >({
    mutationFn: (data) => updateRoom(data.mapId, data.roomId, data.room),
    onMutate: () => {
      message.open({
        type: "loading",
        content: "Updating room...",
        key: msgKey,
      });
    },
    onError: () => {
      message.open({
        type: "error",
        content: "Could not update room",
        key: msgKey,
      });
    },
    onSuccess: (data, params) => {
      message.open({
        type: "success",
        content: "Room updated",
        key: msgKey,
      });
      invalidateQueries({ queryKey: ["maps", params.mapId] });
      invalidateQueries({ queryKey: ["rooms"] });
    },
  });
};
export const useDeleteRoom = () => {
  const msgKey = "roomDelete";
  return useMutation<
    DeskResponse,
    AxiosError<ErrorResponse>,
    { room: RoomResponse; mapId: string }
  >({
    mutationFn: (data) => deleteRoom(data.mapId, data.room.id),
    onMutate: () => {
      message.open({
        type: "loading",
        content: "Deleting room...",
        key: msgKey,
      });
    },
    onError: () => {
      message.open({
        type: "error",
        content: "Failed to delete room",
        key: msgKey,
      });
    },
    onSuccess: (data, params) => {
      message.open({
        type: "success",
        content: "Room deleted",
        key: msgKey,
      });
      invalidateQueries({ queryKey: ["rooms"] });
      invalidateQueries({ queryKey: ["maps", params.mapId] });
      if (params.room.equipment) {
        params.room.equipment.forEach((e) => {
          invalidateQueries({ queryKey: ["equipment", e.id] });
        });
      }
    },
  });
};
export const useRooms = (mapId?: string) => {
  return useQuery({
    queryKey: ["rooms", mapId],
    queryFn: () => getRooms(mapId!),
    enabled: mapId !== undefined && mapId !== null,
    staleTime: toMilliseconds({ minutes: 5 }),
  },
  persistentQueryClient);
};
export const useRoom = (mapId?: string, roomId?: string) => {
  return useQuery({
    queryKey: ["rooms", mapId, roomId],
    queryFn: () => getRoom(mapId!, roomId!),
    enabled:
      mapId !== undefined &&
      mapId !== null &&
      roomId !== undefined &&
      roomId !== null,
  });
};

export const useRoomAvailability = (
  mapId?: string,
  request?: { from: Dayjs; to: Dayjs }
) => {
  return useQuery({
    queryKey: ["rooms", mapId, request],
    queryFn: () =>
      getRoomAvailability(mapId!, {
        dateFrom: request!.from.toISOString(),
        dateTo: request!.to.toISOString(),
      }),
    enabled:
      mapId !== undefined && request?.from.isValid() && request.to.isValid(),
  });
};

export const useCreateRoomBooking = () => {
  const msgKey = "roomBooking";
  return useMutation<
    CreateRoomBookingResponse,
    AxiosError<ErrorResponse>,
    {
      mapId: string;
      roomId: string;
      request: CreateRoomBookingRequest;
    }
  >({
    onMutate: () => {
      message.open({
        type: "loading",
        content: "Booking a room...",
        key: msgKey,
      });
    },
    onError: () => {
      message.open({
        type: "error",
        content: "Failed to book a room",
        key: msgKey,
      });
    },
    onSuccess: (data, params) => {
      message.open({
        type: "success",
        content: "Room booked",
        key: msgKey,
      });
      invalidateQueries({ queryKey: ["rooms"] });
    },
    mutationFn: (req) => createRoomBooking(req.mapId, req.roomId, req.request),
  });
};

const getRoomAvailability = async (
  mapId: string,
  req: RoomAvailabilityRequest
) => {
  let companyId = getCompanyId();
  const res = await axiosClient.get<RoomUnavailability[]>(
    `/companies/${companyId}/maps/${mapId}/rooms/availability`,
    { headers: { ...getHeaders(), ...getProviderHeaders() }, params: req }
  );
  return res.data;
};

const createRoomBooking = async (
  mapId: string,
  roomId: string,
  req: CreateRoomBookingRequest
) => {
  let companyId = getCompanyId();
  const res = await axiosClient.post<CreateRoomBookingResponse>(
    `/companies/${companyId}/maps/${mapId}/rooms/${roomId}/bookings`,
    req,
    { headers: { ...getHeaders(), ...getProviderHeaders() } }
  );
  return res.data;
};

const getRoomBookings = async (
  mapId: string,
  roomId: string,
  req: FindRoomBookingsRequest
) => {
  let companyId = getCompanyId();
  const res = await axiosClient.get<RoomBooking[]>(
    `/companies/${companyId}/maps/${mapId}/rooms/${roomId}/bookings`,
    { params: req, headers: { ...getHeaders(), ...getProviderHeaders() } }
  );
  return res.data;
};

const getRooms = async (mapId: string) => {
  let companyId = getCompanyId();
  const res = await axiosClient.get<RoomResponse[]>(
    `/companies/${companyId}/maps/${mapId}/rooms`,
    { headers: getHeaders() }
  );
  return res.data;
};
const getRoom = async (mapId: string, roomId: string) => {
  let companyId = getCompanyId();
  const res = await axiosClient.get<RoomResponse>(
    `/companies/${companyId}/maps/${mapId}/rooms/${roomId}`,
    { headers: getHeaders() }
  );
  return res.data;
};

const deleteRoom = async (mapId: string, roomId: string) => {
  let companyId = getCompanyId();
  const res = await axiosClient.delete<any>(
    `/companies/${companyId}/maps/${mapId}/rooms/${roomId}`,
    { headers: getHeaders() }
  );
  return res.data;
};
const createRoom = async (mapId: string, room: CreateRoomRequest) => {
  let companyId = getCompanyId();
  const res = await axiosClient.post<RoomResponse>(
    `/companies/${companyId}/maps/${mapId}/rooms`,
    room,
    { headers: getHeaders() }
  );
  return res.data;
};

const updateRoom = async (
  mapId: string,
  roomId: string,
  room: UpdateRoomRequest
) => {
  let companyId = getCompanyId();
  const res = await axiosClient.put<DeskResponse>(
    `/companies/${companyId}/maps/${mapId}/rooms/${roomId}`,
    room,
    { headers: getHeaders() }
  );
  return res.data;
};
