import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import {
  InvalidateOptions,
  InvalidateQueryFilters,
  QueryClient,
} from "@tanstack/react-query";
import { persistQueryClient } from "@tanstack/react-query-persist-client";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
      retry: 0,
    },
  },
});
const localStoragePersister = createSyncStoragePersister({
  storage: window.localStorage,
});
export const invalidateQueries = (
  filters?: InvalidateQueryFilters,
  options?: InvalidateOptions
) => {
  return Promise.all([
    queryClient.invalidateQueries(filters, options),
    persistentQueryClient.invalidateQueries(filters, options),
  ]);
};
export const persistentQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
      retry: 0, // 24 hours
    },
  },
});
persistQueryClient({
  queryClient: persistentQueryClient,
  persister: localStoragePersister,
});
