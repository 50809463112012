import React, { useEffect } from "react";
import {
  Navigate,
  Outlet,
  RouteObject,
  RouterProvider,
  createBrowserRouter,
  useNavigate,
} from "react-router-dom";
import { usePermissionsStore } from "../context/authContext";
import { CustomUserClaims } from "../repo";
import CompaniesPage from "./admin/companies/CompaniesPage/CompaniesPage";
import CompanyCreate from "./admin/companies/CompanyCreate/CompanyCreate";
import CompanyView from "./admin/companies/CompanyView/CompanyView";
import CompanySelectorPage from "./common/CompanySelectorPage/CompanySelectorPage";
import EmptyPage from "./common/EmptyPage/EmptyPage";
import ErrorPage from "./common/ErrorPage/ErrorPage";
import MapViewPage from "./common/MapViewPage/MapViewPage";
import CreateMapBooking from "./common/MapViewPage/bookings/CreateMapBooking/CreateMapBooking";
import HrBookingOverview from "./common/MapViewPage/bookings/HrBookingOverview/HrBookingOverview";
import MapDesksAvailability from "./common/MapViewPage/bookings/MapDesksAvailability/MapDesksAvailability";
import UserBookingsOverview from "./common/MapViewPage/bookings/UserBookingsOverview/UserBookingsOverview";
import RoomAvailability from "./common/MapViewPage/bookings/rooms/RoomAvailability/RoomAvailability";
import RoomBooking from "./common/MapViewPage/bookings/rooms/RoomBooking/RoomBooking";
import RoomBookingNavigator from "./common/MapViewPage/bookings/rooms/components/RoomBookingNavigator";
import CreateMapDeskView from "./common/MapViewPage/desks/CreateMapDeskView/CreateMapDeskView";
import MapDeskList from "./common/MapViewPage/desks/MapDeskList/MapDeskList";
import EditMapDeskView from "./common/MapViewPage/desks/MapDeskView/MapDeskView";
import MapViewNavigator from "./common/MapViewPage/navigator/MapViewNavigator";
import BookMapRoom from "./common/MapViewPage/rooms/BookMapRoom/BookMapRoom";
import CreateMapRoom from "./common/MapViewPage/rooms/CreateMapRoom/CreateMapRoom";
import ViewMapRoom from "./common/MapViewPage/rooms/ViewMapRoom/ViewMapRoom";
import PageWithToolbar from "./common/PageWithToolbar/PageWithToolbar";
import RouteMemo from "./common/RouteMemo/RouteMemo";
import ProfilePage from "./common/profile/ProfilePage";
import CompanyPage from "./hr/company/CompanyPage/CompanyPage";
import EquipmentCreateView from "./hr/equipment/EquipmentCreateView/EquipmentCreateView";
import EquipmentEditPage from "./hr/equipment/EquipmentEditPage/EquipmentEditPage";
import EquipmentPage from "./hr/equipment/EquipmentPage/EquipmentPage";
import MapEditPage from "./hr/maps/MapEditPage/MapEditPage";
import MapsCreationPage from "./hr/maps/MapsCreationPage/MapsCreationPage";
import DeskAvailabilityReport from "./hr/reporting/DeskAvailabilityReport/DeskAvailabilityReport";
import EquipmentReport from "./hr/reporting/EquipmentReport/EquipmentReport";
import ReportingPage from "./hr/reporting/ReportingPage/ReportingPage";
import UsersReport from "./hr/reporting/UsersReport/UsersReport";
import TeamCreateView from "./hr/teams/TeamCreateView/TeamCreateView";
import TeamEditView from "./hr/teams/TeamEditView/TeamEditView";
import TeamsPage from "./hr/teams/TeamsPage/TeamsPage";
import UserCreateView from "./hr/users/UserCreateView/UserCreateView";
import { UserPage } from "./hr/users/UserEditView/UserEditView";
import UsersViewPage from "./hr/users/UsersViewPage/UsersViewPage";
import LoginPage from "./unauthenticated/LoginPage/LoginPage";
import UnauthenticatedPage from "./unauthenticated/UnauthenticatedPage/UnauthenticatedPage";
import WelcomePage from "./unauthenticated/WelcomePage/WelcomePage";

const Redirect: React.FC<{ url: string }> = ({ url }) => {
  const nav = useNavigate();
  useEffect(() => {
    nav(url);
  });
  return <div></div>;
};
const mapViewRoute = (role: CustomUserClaims["role"]): RouteObject[] => {
  return [
    {
      element: <MapViewNavigator />,
      path: "/maps",
      children: [
        {
          path: ":mapId",
          element: <MapViewPage />,
          children: [
            {
              index: true,
              element: (
                  <MapDeskList />
              ),
            },
            {
              path: "desks",
              children: [
                {
                  index: true,
                  element: <MapDeskList />,
                },
                {
                  path: ":deskId",
                  element: <EditMapDeskView />,
                },
                role === "USER"
                  ? {}
                  : {
                      path: "new",
                      element: <CreateMapDeskView />,
                    },
              ],
            },
            {
              path: "rooms",
              children: [
                {
                  index: true,
                  element: <MapDeskList />,
                },
                role === "USER"
                  ? {}
                  : {
                      path: "new",
                      element: <CreateMapRoom />,
                    },
                {
                  path: ":roomId",
                  children: [
                    {
                      index: true,
                      element: <ViewMapRoom />,
                    },
                    {
                      path: "bookings",
                      children: [
                        { index: true, element: <BookMapRoom /> },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              path: "bookings/desks",
              element: <Outlet />,
              children: [
                {
                  index: true,
                  element: <MapDesksAvailability />,
                },
                {
                  path: ":deskId",
                  element: <CreateMapBooking />,
                },
              ],
            },
            {
              path: "bookings/rooms",
              element: <RoomBookingNavigator />,
              children: [
                {
                  index: true,
                  element: <RoomAvailability />,
                },
                {
                  path: ":roomId",
                  element: <RoomBooking />,
                },
              ],
            },
          ],
        },
      ],
    },
    role !== "USER"
      ? {
          path: "/maps/new",
          element: <MapsCreationPage />,
        }
      : {},
    role !== "USER"
      ? {
          path: "/maps/:mapId/edit",
          element: <MapEditPage />,
        }
      : {},
    {
      path: "/profile",
      element: <ProfilePage />,
    },
  ];
};
const userRouter = createBrowserRouter([
  {
    element: <PageWithToolbar />,
    path: "",
    errorElement: <ErrorPage />,
    children: [
      ...mapViewRoute("USER"),
      {
        element: <Navigate to="/maps" replace={true} />,
        index: true,
      },
      { path: "/bookings", element: <UserBookingsOverview /> },
    ],
  },
]);
const hrRoutes: RouteObject[] = [
  {
    element: <PageWithToolbar />,
    path: "",
    errorElement: <ErrorPage />,
    children: [
      {
        element: <Navigate to="/maps" replace={true} />,
        index: true,
      },
      {
        path: "/people",
        element: (
          <RouteMemo name="people">
            <UsersViewPage />
          </RouteMemo>
        ),
        children: [
          {
            index: true,
            element: <EmptyPage description="No user selected" />,
          },
          {
            path: "new",
            element: <UserCreateView />,
          },
          {
            path: ":userEmail",
            element: <UserPage />,
          },
        ],
      },
      ...mapViewRoute("HR"),
      {
        path: "/teams",
        element: (
          <RouteMemo name="teams">
            <TeamsPage />
          </RouteMemo>
        ),
        children: [
          {
            index: true,
            element: <EmptyPage description="No team selected" />,
          },
          {
            path: ":teamId",
            element: <TeamEditView />,
          },
          {
            path: "new",
            element: <TeamCreateView />,
          },
        ],
      },
      {
        path: "/bookings",
        element: (
          <RouteMemo name="bookings">
            <HrBookingOverview />
          </RouteMemo>
        ),
      },
      {
        path: "/equipment",
        element: (
          <RouteMemo name="equipment">
            <EquipmentPage />
          </RouteMemo>
        ),
        children: [
          {
            index: true,
            element: <EmptyPage description="No equipment selected" />,
          },
          {
            path: ":equipmentId",
            element: <EquipmentEditPage />,
          },
          {
            path: "new",
            element: <EquipmentCreateView />,
          },
        ],
      },
      {
        path: "/reporting",
        element: (
          <RouteMemo name="reports">
            <ReportingPage />
          </RouteMemo>
        ),
        children: [
          {
            index: true,
            element: <Navigate to={"users"} relative={"route"} />,
          },
          {
            path: "users",
            element: <UsersReport />,
          },
          {
            path: "desks",
            element: <DeskAvailabilityReport />,
          },
          {
            path: "equipment",
            element: <EquipmentReport />,
          },
        ],
      },
      {
        path: "/company",
        element: <CompanyPage />,
      },
    ],
  },
];
const hrRouter = createBrowserRouter(hrRoutes);
const unauthRouter = createBrowserRouter([
  {
    element: <UnauthenticatedPage />,
    children: [
      {
        element: <WelcomePage />,
        path: "/",
      },
      {
        element: <LoginPage />,
        path: "/login",
      },
    ],
    errorElement: <Redirect url="/" />,
  },
  {
    element: <Redirect url="/" />,
    path: "/*",
  },
]);
const adminRouter = () => {
  const routes = hrRoutes;
  routes[0].children?.push({
    path: "companies",
    element: (
      <RouteMemo name="companies">
        <CompaniesPage />
      </RouteMemo>
    ),
    children: [
      {
        path: ":companyId",
        element: <CompanyView />,
      },
      {
        path: "new",
        element: <CompanyCreate />,
      },
    ],
  });
  return createBrowserRouter(routes);
};

const companySelector = () => {
  return createBrowserRouter([{ path: "*", element: <CompanySelectorPage /> }]);
};

const ApplicationRouter: React.FC = () => {
  const permissionsStore = usePermissionsStore();
  if (
    permissionsStore.permissions &&
    permissionsStore.permissions.length > 0 &&
    !permissionsStore.companyId
  ) {
    return <RouterProvider router={companySelector()} />;
  }
  if (permissionsStore.isAdmin()) {
    return <RouterProvider router={adminRouter()} />;
  } else if (permissionsStore.isHr()) {
    return <RouterProvider router={hrRouter} />;
  } else if (permissionsStore.isUser()) {
    return <RouterProvider router={userRouter} />;
  }
  return <RouterProvider router={unauthRouter} />;
};
export default ApplicationRouter;
