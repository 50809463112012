import { useParams, useSearchParams } from "react-router-dom";
import { ConfirmDialog } from "../../../../../../../components/common/ConfirmationDialog/ConfirmationDialog";
import { PlatformButton } from "../../../../../../../components/common/PlatformButton/PlatformButton";
import { useAuthenticationStore } from "../../../../../../../context/authContext";
import { useCreateBooking } from "../../../../../../../repo/bookings";
import {
  DATE_FORMAT,
  DATE_WITH_WEEKDAY_FORMAT,
} from "../../../../../../../utils/constants";
import { useNav } from "../../../../../../../utils/reactUtils";
import { useDialogContext } from "../../../../../DialogProvider/DialogProvider";
import { useDeskBookings, useUserCanBookDesk } from "../../../../../hooks";
import {
  bookingCreatedAlert,
  BookingOptionsBehindPermissions,
} from "../../../../bookings/CreateMapBooking/CreateMapBooking";
import { useDateContext } from "../../../../context/dateContext";
import { cannotBookADeskDialog } from "../common";
import styles from "./DeskBookingAction.module.css";
export const DeskBookingAction = () => {
  const { deskId, mapId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const userCanBook = useUserCanBookDesk();
  const { auth } = useAuthenticationStore();
  const date = useDateContext.use.date();
  const { show } = useDialogContext();
  const { data: bookings = [], isLoading, isError } = useDeskBookings(date);
  const { mutate: createBooking, isPending } = useCreateBooking();
  const onClose = () => {
    searchParams.delete("action");
    setSearchParams(searchParams, { replace: true });
  };
  const onBookAnotherDate = () => {
    searchParams.set("action", "extendedBooking");
    setSearchParams(searchParams, { replace: true });
  };
  const bookingsPresent = bookings?.length > 0;
  const nav = useNav();
  const onConfirm = () => {
    if (!mapId || !deskId || !auth) {
      return;
    }
    createBooking(
      {
        dateFrom: date.format(DATE_FORMAT),
        dateTo: date.format(DATE_FORMAT),
        mapId,
        deskId,
        userEmail: auth.userEmail,
        actions: searchParams.getAll("actions") as any,
      },
      {
        onSuccess(data) {
          onClose();
          show(bookingCreatedAlert(nav, data));
        },
      }
    );
  };

  if (!userCanBook.canBook) {
    return cannotBookADeskDialog({
      reason: userCanBook.reason,
      onClose: onClose,
    });
  }
  return (
    <ConfirmDialog
      type={userCanBook.canBook ? "confirm" : "danger"}
      open={true}
      title={"Booking confirmation"}
      onClose={onClose}
      onConfirm={onConfirm}
      subHeading={
        <>
          <div className={styles.bodyContainer}>
            Are you sure you want to book this desk for{" "}
            <strong>{date.format(DATE_WITH_WEEKDAY_FORMAT)}</strong>?
          </div>
          <div>
            <PlatformButton variant="link" onClick={onBookAnotherDate}>
              {" "}
              Book for another date instead
            </PlatformButton>
          </div>
          <BookingOptionsBehindPermissions selectedEmail={auth?.userEmail} />
        </>
      }
      heading={`You are about to book a desk`}
      confirmText="Confirm"
      cancelText="Cancel"
      state={{
        loading: isLoading || isPending,
        error: bookingsPresent || isError,
        description: bookingsPresent
          ? "Desk is busy for today"
          : "Unexpected error happened",
        action: bookingsPresent ? "Book for another date" : "Go back",
        onAction() {
          bookingsPresent ? onBookAnotherDate() : onClose();
        },
      }}
    />
  );
};
