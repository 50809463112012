import { FunctionComponent } from 'react';
import { create } from "zustand";
import { AlertDialog, AlertDialogOptions } from "../../../components/common/ConfirmationDialog/ConfirmationDialog";

interface OwnProps {
}

type Props = OwnProps;

const DialogProvider: FunctionComponent<Props> = (props) => {
  const {options, hide} = useDialogContext();
  if (!options) {
    return <></>
  }
  return <AlertDialog open={true} options={options} onConfirm={hide}/>;
};

interface DialogContextProps {
  options?: AlertDialogOptions
  hide: () => void;
  show: (options: AlertDialogOptions) => void;
}



export const useDialogContext = create<DialogContextProps>()((set, get) => ({
  show(options) {
    set(state => ({...state, options}))
  },
  hide() {
    set(state => ({...state, options: undefined}))
  }
}))

export default DialogProvider;
