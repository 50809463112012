import { MeetingRoom } from "fabric/fabric-impl";
import { useEffect, useRef } from "react";
import { Outlet } from "react-router-dom";
import { mapQueue } from "../../../../../../utils/map/mapQueue";
import { useRoomBookingStore } from "../context/useRoomBookingStore";

type Props = {};

export default function RoomBookingColorer({}: Props) {
  const { availableRooms } = useRoomBookingStore();
  const colorRef = useRef(
    new Map<
      string,
      {
        stroke?: string;
        strokeWidth?: number;
      }
    >()
  );
  useEffect(() => {
    if (availableRooms.length === 0) {
      return;
    }
    const roomsBeforeColor = colorRef.current;
    const roomIds = availableRooms.map((r) => r.mapRoomId);
    const getColors = mapQueue.add((map) => {
      map.getObjects("meetingRoom").forEach((r: any) => {
        const room: MeetingRoom = r;
        if (!room.id) {
          return;
        }
        const isAvailable = roomIds.includes(room.id);
        !roomsBeforeColor.get(room.id) &&
          roomsBeforeColor.set(room.id, {
            stroke: room.stroke,
            strokeWidth: room.strokeWidth,
          });
        const props = {
          stroke: isAvailable ? "green" : "red",
          strokeWidth: 10,
          evented: isAvailable,
          overrides: {
            highlightColor: isAvailable ? "green" : "red",
          },
        };
        room.set({ ...props, valuesBefore: props });
      });
      map.requestRenderAll();
    });
    return () => {
      mapQueue.remove(getColors);
      mapQueue.add((map) => {
        roomsBeforeColor.forEach((v, k) => {
          map.getObjectById(k)?.set({
            ...v,
            evented: true,
            overrides: {},
            valuesBefore: v,
          });
          roomsBeforeColor.delete(k);
        });
        map.requestRenderAll();
      });
    };
  }, [availableRooms]);
  return <Outlet />;
}
