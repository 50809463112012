import { IconQrcode, IconTrash } from "@tabler/icons-react";
import { Button } from "antd";
import { useEffect } from "react";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ConfirmationDialog } from "../../../../../components/common/ConfirmationDialog/ConfirmationDialog";
import DisableByRole from "../../../../../components/common/DisableByRole";
import { EquipmentSection } from "../../../../../components/common/EquipmentSection/EquipmentSection";
import { HiddenAction } from "../../../../../components/common/HiddenAction";
import LoadingBoundry from "../../../../../components/common/LoadingBoundry/LoadingBoundry";
import NavigationBanner, {
  BannerOption,
} from "../../../../../components/common/NavigationBanner/NavigationBanner";
import QrCodeAction from "../../../../../components/common/QrCodeAction/QrCodeAction";
import { usePermissionsStore } from "../../../../../context/authContext";
import { RoomResponse, UpdateRoomRequest } from "../../../../../repo";
import {
  useDeleteRoom,
  useRoom,
  useUpdateRoom,
} from "../../../../../repo/rooms";
import { mapQueue } from "../../../../../utils/map/mapQueue";
import { useNav } from "../../../../../utils/reactUtils";
import { MapRoomButtons, MapRoomForm } from "../CreateMapRoom/CreateMapRoom";

type Props = {};

export default function ViewMapRoom({}: Props) {
  const { mapId, roomId } = useParams();

  const methods = useForm<UpdateRoomRequest>({
    defaultValues: {
      integrationId: null as any,
    },
  });
  const { data: roomResponse, isLoading, error } = useRoom(mapId, roomId);
  const nav = useNavigate();
  const { mutate: updateRoom, isPending } = useUpdateRoom();
  const { reset, handleSubmit } = methods;
  const onSubmit = (data: UpdateRoomRequest) => {
    if (!mapId || !roomId) {
      return;
    }
    updateRoom({
      mapId: mapId,
      roomId: roomId,
      room: { ...data },
    });
  };
  useEffect(() => {
    reset({
      ...roomResponse,
      integrationId: roomResponse?.integrationId || (null as any),
    });
  }, [roomResponse, reset]);
  return (
    <FormProvider {...methods}>
      <RoomNavigationBanner roomResponse={roomResponse} />
      <LoadingBoundry
        loading={isLoading || isPending}
        error={error}
        description={"Could not fetch room"}
        onAction={() => nav(-1)}
        action="Go back"
      >
        <MapRoomForm onSubmit={handleSubmit(onSubmit)}>
          <EquipmentSection
            assocId={{ roomId: roomResponse?.id, mapId: mapId }}
          />
          <MapRoomButtons>
            <DisableByRole disabledRole="USER">
              <Button type="primary" htmlType="submit" style={{ flex: 1 }}>
                Update
              </Button>
            </DisableByRole>
          </MapRoomButtons>
        </MapRoomForm>
        <RoomActions room={roomResponse} />
      </LoadingBoundry>
    </FormProvider>
  );
}

const RoomNavigationBanner = ({
  roomResponse,
}: {
  roomResponse?: RoomResponse;
}) => {
  const { mapId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { isUser } = usePermissionsStore();
  const options: BannerOption[] = [];
  if (!isUser()) {
    options.push({
      key: "1",
      label: "Generate QR",
      icon: <IconQrcode />,
      onClick: () => {
        roomResponse?.name && searchParams.set("qrTitle", roomResponse?.name);
        searchParams.set("action", "qrCode");
        setSearchParams(searchParams);
      },
    });

    options.push({
      key: "2",
      onClick() {
        searchParams.set("action", "deleteRoom");
        setSearchParams(searchParams);
      },
      label: "Delete",
      icon: <IconTrash />,
      danger: true,
    });
  }
  const formMethods = useFormContext<RoomResponse>();
  const name = formMethods.watch("name");
  return (
    <NavigationBanner
      name={name || "Room"}
      prevRoute={`/maps/${mapId}`}
      options={options}
    />
  );
};

const RoomDeleteAction = ({ room }: { room?: RoomResponse }) => {
  const { mapId } = useParams();
  const nav = useNav();
  const { mutate: deleteDesk, isPending: isDeleting } = useDeleteRoom();

  const onDelete = async () => {
    if (!room || !mapId) {
      return;
    }
    deleteDesk(
      { room: room, mapId: mapId },
      {
        onSuccess(data, variables, context) {
          mapQueue.add((map) => {
            map.deselectObjects();
          });

          nav(`/maps/${mapId}`, { replace: true });
        },
        onError(error, variables, context) {
          console.log(error);
        },
      }
    );
  };
  return (
    <ConfirmationDialog
      heading="Are you sure you want to delete this room?"
      subHeading="This cannot be undone"
      onConfirm={onDelete}
      type="danger"
      confirmText="Delete"
      open={true}
      primaryButtonProps={{ danger: true, type: "default", icon: <IconTrash/> }}
    />
  );
};

const RoomActions = ({ room }: { room?: RoomResponse }) => {
  return (
    <>
      <HiddenAction action="deleteRoom">
        <RoomDeleteAction room={room} />
      </HiddenAction>
      <HiddenAction action="qrCode">
        <QrCodeAction
          modalTitle="Room QR"
          title={room?.name || "Room"}
          value={
            window.location.href.replace(window.location.search, "") +
            "/bookings"
          }
        />
      </HiddenAction>
    </>
  );
};
