import { useMutation, useQuery } from "@tanstack/react-query";
import { message } from "antd";
import { CreateIssueRequest, IssueResponse } from ".";
import { axiosClient } from "../config/axios";
import { getCompanyId, getHeaders } from "./common";

export const useCreateIssue = (onSuccess?: () => void) => {
  const msgKey = "createIssue";
  return useMutation({
    mutationFn: createIssue,
    onMutate: () => {
      message.open({
        type: "loading",
        content: "Creating issue...",
        key: msgKey,
      });
    },
    onError: () => {
      message.open({
        type: "error",
        content: "Failed to create issue",
        key: msgKey,
      });
    },

    onSuccess: (data) => {
      message.open({
        type: "success",
        content: "Issue created",
        key: msgKey,
      });
      onSuccess?.();
    },
  });
};

export const useIssue = (issueId?: string) => {
  const issue = useQuery({
    queryKey: ["issues", issueId],
    queryFn: () => getIssue(issueId!),
    enabled: issueId !== undefined,
  });
  if (issue.error) {
    message.open({
      type: "error",
      content: "Failed to fetch the issue",
      key: "issue",
    });
  }
  return issue;
};
const getIssue = async (issueId: string) => {
  let companyId = await getCompanyId();
  const res = await axiosClient.get<IssueResponse>(
    `/companies/${companyId}/issues/${issueId}`,
    { headers: getHeaders() }
  );
  return res.data;
};
const createIssue = async (issue: CreateIssueRequest) => {
  let companyId = await getCompanyId();
  const res = await axiosClient.post<IssueResponse>(
    `/companies/${companyId}/issues`,
    issue,
    { headers: getHeaders() }
  );
  return res.data;
};
