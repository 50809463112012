import { fabric } from "fabric";
import "./objects/Chair";
import "./objects/Circle";
import "./objects/Controls";
import "./objects/Desk";
import "./objects/Door";
import "./objects/DynamicLine";
import "./objects/Line";
import "./objects/MapImage";
import "./objects/MeetingRoom";
import "./objects/PolygonRect";
import "./objects/Rectangle";
import "./objects/Sink";
import "./objects/Text";
import "./objects/Toilet";
import "./objects/Tooltip";
fabric.EditMapClearRectangle = fabric.util.createClass(fabric.Rect, {
  type: "editMapClearRectangle",
  initialize: function (options: any) {
    this.callSuper("initialize", {
      width: 100,
      height: 100,
      fill: "white",
      strokeUniform: true,
      snapAngle: 5,
      ...options,
    });
  },
});
fabric.EditMapClearRectangle.fromObject = function (
  object: any,
  callback?: any
) {
  return fabric.Object._fromObject(
    "EditMapClearRectangle",
    object,
    callback
  ) as any;
};

fabric.EditMapCenterPoint = fabric.util.createClass(fabric.Group, {
  type: "editMapCenterPoint",
  toObject: function () {},
  initialize: function (options: any) {
    this.callSuper(
      "initialize",
      [
        new fabric.Line([50, 0, 50, 100], { stroke: "black" }),
        new fabric.Line([0, 50, 100, 50], { stroke: "black" }),
      ],
      {
        lockMovementX: true,
        lockMovementY: true,
        evented: false,
        selectable: false,
      }
    );
  },
});
fabric.EditMapCenterPoint.fromObject = function (object, callback) {
  return fabric.Object._fromObject("EditMapCenterPoint", object, callback);
};
