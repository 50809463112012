import { DatePicker } from "antd";
import { RangePickerProps } from "antd/es/date-picker";
import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";
import { ComponentWithLabel } from "../../../../components/common/CustomInputText/CustomInputText";
import { largeOnMobile } from "../../../../utils/reactUtils";

type Props = {};
const { RangePicker } = DatePicker;

export const PeriodPicker = (props: RangePickerProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dateFrom = dayjs(searchParams.get("dateFrom")).startOf("month");
  const dateTo = dayjs(searchParams.get("dateTo")).endOf("month");
  const onDateChange: RangePickerProps["onChange"] = (changedDates) => {
    if (changedDates?.[0] && changedDates[1]) {
      searchParams.set(
        "dateFrom",
        changedDates?.[0]?.startOf("month").format("YYYY-MM-DD")
      );
      searchParams.set(
        "dateTo",
        changedDates?.[1]?.endOf("month").format("YYYY-MM-DD")
      );
      setSearchParams(searchParams);
    } else {
      searchParams.delete("dateFrom");
      searchParams.delete("dateTo");
      setSearchParams(searchParams);
    }
  };
  return (
    <ComponentWithLabel label="Period">
      <RangePicker
        picker="month"
        disabledDate={(d) => d.isAfter(dayjs().add(6, "M"))}
        value={
          dateFrom.isValid() && dateTo.isValid()
            ? [dateFrom.startOf("month"), dateTo.endOf("month")]
            : undefined
        }
        onChange={onDateChange}
        size={largeOnMobile()}
        {...props}
      />
    </ComponentWithLabel>
  );
};
