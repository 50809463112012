import { Button, Flex, message } from "antd";
import { useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { largeOnMobile } from "../../../utils/reactUtils";
import { ModalDialog } from "../ModalDialog/ModalDialog";
import { QRCodeCanvas } from "../QrCodeHolder/QrCodeHolder";

type Props = {
  modalTitle: string;
  title: string;
  value: string;
};

export default function QrCodeAction({ modalTitle, title, value }: Props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const canvasRef = useRef<HTMLCanvasElement>(null);
  function copyCanvas() {
    let canvas = canvasRef.current;
    if (canvas) {
      let data = [
        new ClipboardItem({ "image/png": getBlobFromCanvas(canvas) } as any),
      ];
      navigator.clipboard.write(data).then(() => {
        message.success("Copied to clipboard!");
      });
    }
  }
  const close = () => {
    searchParams.delete("action");
    setSearchParams(searchParams);
  };
  return (
    <>
      <ModalDialog title={modalTitle} open={true} onClose={close}>
        <Flex justify="center" vertical align="center" gap={"1rem"}>
          <QRCodeCanvas
            size={280}
            imageSettings={{
              src: `${process.env.PUBLIC_URL}/appIcon.png`,
              height: 50,
              width: 50,
              excavate: false,
            }}
            style={{
              border: "2px solid rgba(5, 5, 5, 0.06)",
              borderRadius: "10px",
            }}
            marginSize={3}
            title={title}
            value={value}
            bgColor={"white"}
            ref={canvasRef}
          />
          <Button type="primary" size={largeOnMobile()} onClick={copyCanvas}>
            Copy
          </Button>
        </Flex>
      </ModalDialog>
    </>
  );
}
async function getBlobFromCanvas(canvas: HTMLCanvasElement) {
  return new Promise((resolve, reject) => {
    canvas.toBlob((blob) => {
      if (blob) {
        resolve(blob);
      } else {
        reject(new Error("Canvas toBlob failed"));
      }
    });
  });
}
