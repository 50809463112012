import { IconFilter, IconPlus } from "@tabler/icons-react";
import { Avatar, AvatarProps, Button, Spin, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { Outlet, useParams, useSearchParams } from "react-router-dom";
import { IconWithBackground } from "../../../../components/IconWithBackground/IconWithBackground";
import { SearchBar } from "../../../../components/SearchBar";
import { AnimatingListItem } from "../../../../components/common/AnimatingListItem/AnimatingListItem";
import LoadingBoundry from "../../../../components/common/LoadingBoundry/LoadingBoundry";
import { UserMinifiedResponse } from "../../../../repo";
import { useUsers } from "../../../../repo/users";
import { filterByTerm } from "../../../../utils/arrayUtils";
import { useNav } from "../../../../utils/reactUtils";
import IndicatingList from "../../../common/IndicatingList/IndicatingList";
import ListAndView from "../../../common/ListAndView/ListAndView";
import Scrollable from "../../../common/Scrollable/Scrollable";
import { TeamColorIndicator } from "../../teams/TeamsPage/TeamsPage";
import styles from "./UsersViewPage.module.css";
import UserFilterDialog from "./components/UserFilterDialog/UserFilterDialog";
type Props = {};

export default function UsersViewPage({}: Props) {
  return <ListAndView list={<UserListView />} content={<Outlet />} />;
}

const UserListView = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { isLoading, isError, data, refetch } = useUsers();
  useEffect(() => {
    if (!searchParams.get("status")) {
      searchParams.set("status", "ACTIVE");
      setSearchParams(searchParams, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  let searchTerm = searchParams.get("searchTerm");
  let statuses = searchParams.getAll("status");
  let users = data?.filter((u) => u.status && statuses.includes(u.status));
  users = filterByTerm<UserMinifiedResponse>(users, searchTerm);
  const nav = useNav();

  return (
    <LoadingBoundry
      loading={isLoading}
      error={isError}
      description="Failed to fetch users"
      onAction={refetch}
    >
      <div className={styles.controlsContainer}>
        <SearchBar path={"/people"} />
        <UserFilter />
        <Tooltip title={"Add new user"} placement="bottom">
          <Button
            type="primary"
            size="large"
            onClick={() => nav("/people/new" + window.location.search)}
            icon={<IconPlus />}
          />
        </Tooltip>
      </div>
      <Scrollable>
        <IndicatingList<UserMinifiedResponse>
          emptyTitle="No users found"
          data={users}
          render={(user) => <UserListItem key={user.email} user={user} />}
        />
      </Scrollable>
    </LoadingBoundry>
  );
};

export const UserListItem: React.FC<{
  user: UserMinifiedResponse;
  disabled?: boolean;
}> = ({ user, disabled }) => {
  const nav = useNav();
  const { userEmail } = useParams();
  const selected = userEmail === user.email;
  const [hovered, setHovered] = useState(false);
  return (
    <AnimatingListItem
      active={selected}
      className={styles.userListItemContainer}
      onClick={() => {
        !disabled && nav(`/people/${user.email}${window.location.search}`);
      }}
      onMouseEnter={() => !disabled && setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <IconWithBackground
        icon={<UserAvatar identifier={user.email} src={user.photoUrl} />}
        backgroundColor={
          selected || hovered ? "var(--supplementary-color)" : "white"
        }
      />
      <div className={styles.userListItemContent}>
        <span className={styles.userListItemTitle}>
          {user.status === "DELETED" ? "(Deactivated) " : ""}
          {user.name}
        </span>
        <div className={`listItemMetadata ${styles.userListTeamName}`}>
          <UserTeamName teamName={user.teamName} />
        </div>
      </div>

      <TeamColorIndicator square color={user.teamColor || "grey"} />
    </AnimatingListItem>
  );
};
export const UserAvatar = ({
  identifier,
  src,
  ...props
}: { identifier?: string } & AvatarProps) => {
  const loading = !identifier || src === undefined;
  return (
    <Avatar
      src={src || `https://api.dicebear.com/8.x/miniavs/svg?seed=${identifier}`}
      {...props}
      icon={loading && <Spin />}
      style={{ backgroundColor: "var(--tertiary-color)", ...props.style }}
    />
  );
};
const actionKey = "action";
const filterAction = "userFilter";
const UserFilter = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const onClick = () => {
    searchParams.set(actionKey, filterAction);
    setSearchParams(searchParams);
  };
  const onClose = () => {
    searchParams.delete(actionKey);
    setSearchParams(searchParams);
  };
  const open = searchParams.get(actionKey) === filterAction;
  return (
    <>
      <Tooltip title="Filter users" placement="bottom">
        <Button
          type="primary"
          size="large"
          onClick={onClick}
          icon={<IconFilter />}
        />
      </Tooltip>
      <UserFilterDialog onClose={onClose} open={open} />
    </>
  );
};

export const UserTeamName = ({ teamName }: { teamName?: string }) => {
  if (teamName) {
    return (
      <span>
        <strong>Team: </strong>
        {`${teamName}`}
      </span>
    );
  }
  return <span>No team</span>;
};
