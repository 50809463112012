import { jwtDecode } from "jwt-decode";
import _ from "lodash";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import { queryClient } from "../config/useQueryClientConfig";
import {
  AuthProviderInfo,
  AuthResponse,
  CompanyRole,
  CustomUserClaims,
  WhoDeskRole,
} from "../repo";

export interface AuthStoreData {
  token: string;
  refreshToken: string;
  permissions: CompanyRole[];
  userId: string;
  userEmail: string;
}
interface ProviderAuthStore {
  providerAuth?: AuthProviderInfo;
  setProvider: (provider: AuthProviderInfo) => void;
}
export const useProviderAuthenticationStore = create<ProviderAuthStore>()(
  persist(
    (set, get) => ({
      setProvider(provider) {
        set((state) => ({
          ...state,
          providerAuth: { ...state.providerAuth, ...provider },
        }));
      },
    }),
    {
      name: "provider-auth-store",
      storage: createJSONStorage(() => localStorage),
      partialize: (state) => ({
        providerAuth: state.providerAuth,
      }),
    }
  )
);
interface AuthStore {
  claims?: CustomUserClaims;
  auth?: AuthStoreData;
  selectedCompanyId?: string;
  logIn: (authData: AuthResponse) => void;
  reset: () => void;
  setCompanyId: (id: string) => void;
}
const initState = {
  user: undefined,
  claims: undefined,
  auth: undefined,
  selectedCompanyId: undefined,
};
export const useAuthenticationStore = create<AuthStore>()(
  persist(
    (set, get) => ({
      setCompanyId: (id) => {
        set((state) => ({ ...state, selectedCompanyId: id }));
        queryClient.getQueryCache().clear();
      },
      logIn: async (authData: AuthResponse) => {
        let token = jwtDecode(authData.token) as any;
        const permissions = token["permissions"];
        let auth: AuthStoreData = {
          token: authData.token,
          refreshToken: authData.refreshToken,
          permissions: permissions,
          userId: token["sub"],
          userEmail: token["email"],
        };
        set((state) => ({ ...state, auth: auth }));
        usePermissionsStore.getState().setPermissions(permissions);
      },
      reset() {
        set((state) => initState);
      },
      getRole() {
        if (get().auth && get().auth!.permissions.length > 0) {
          return get().auth?.permissions[0].role;
        }
        return undefined;
      },
    }),
    {
      name: "auth-storage",
      storage: createJSONStorage(() => localStorage),
      partialize: (state) => ({
        auth: state.auth,
        selectedCompanyId: state.selectedCompanyId,
      }),
    }
  )
);

export const logOut = () => {
  queryClient.getQueryCache().clear();
  usePermissionsStore.getState().clear();
  useProviderAuthenticationStore.setState((state) => ({
    providerAuth: undefined,
  }));
  useAuthenticationStore.getState().reset();
};

interface PermissionStore {
  companyId?: string;
  role?: WhoDeskRole;
  permissions?: CompanyRole[];
  setPermissions: (permissions: CompanyRole[]) => void;
  isHr: () => boolean;
  isAdmin: () => boolean;
  isUser: () => boolean;
  clear: () => void;
}
export const usePermissionsStore = create<PermissionStore>()(
  persist(
    (set, get) => ({
      clear() {
        set((state) => ({
          role: undefined,
          companyId: undefined,
          permissions: undefined,
        }));
      },
      setPermissions(permissions) {
        if (get().isAdmin() || _.isEqual(permissions, get().permissions)) {
          return;
        }
        if (permissions.length === 1) {
          set((state) => ({
            role: permissions[0].role as any,
            companyId: permissions[0].companyId,
            permissions: permissions,
          }));
          return;
        }
        const companyId = get().companyId;
        if (companyId) {
          const role = permissions.find((p) => p.companyId === companyId)?.role;
          if (role) {
            set((state) => ({
              role: role as any,
              companyId: companyId,
            }));
          } else {
            get().clear();
            return;
          }
        }
        set((state) => ({ ...state, permissions }));
      },
      isAdmin() {
        return get().permissions?.find((r) => r.role === "ADMIN") !== undefined;
      },
      isUser() {
        return get().role === "USER";
      },
      isHr() {
        return get().role === "HR";
      },
    }),
    {
      name: "whodesk-permissions",

      storage: createJSONStorage(() => localStorage),
      partialize: (state) => ({
        role: state.role,
        companyId: state.companyId,
        permissions: state.permissions,
      }),
    }
  )
);
export const setGlobalPermissions = (params: {
  companyId: string;
  role: WhoDeskRole;
}) => {
  queryClient.getQueryCache().clear();
  usePermissionsStore.setState((state) => ({ ...state, ...params }));
};
