import { IconAugmentedReality2, IconDesk, IconUser } from "@tabler/icons-react";
import { Tooltip } from "antd";
import {
  ArcElement,
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Tooltip as ChartJsTooltip,
  Legend,
  LineController,
  LineElement,
  LinearScale,
  PieController,
  PointElement,
  Title,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Outlet, useLocation } from "react-router-dom";
import { useNav } from "../../../../utils/reactUtils";
import styles from "./ReportingPage.module.css";

type Props = {};

ChartJS.register(
  PieController,
  ChartDataLabels,
  CategoryScale,
  LinearScale,
  BarElement,
  BarController,
  LineController,
  Title,
  ChartJsTooltip,
  Legend,
  PointElement,
  LineElement,
  ArcElement
);

export default function ReportingPage({}: Props) {
  return (
    <div className={`${styles.viewContainer} appContent`}>
      <div className={`${styles.reportTypesPicker} borderBox`}>
        <ReportTypeItem
          icon={<IconUser />}
          type="users"
          title="User rotation"
        />
        <ReportTypeItem
          icon={<IconDesk />}
          type="desks"
          title="Desk availability changes"
        />
        <ReportTypeItem
          icon={<IconAugmentedReality2 />}
          type="equipment"
          title="Equipment expiry"
        />
      </div>
        <Outlet />
    </div>
  );
}

const ReportTypeItem = (props: {
  icon: React.ReactNode;
  type: string;
  title?: string;
}) => {
  const nav = useNav();
  const location = useLocation();
  const goToReport = () => {
    if (!location.pathname.includes(props.type)) {
      nav(props.type+window.location.search);
    }
  };
  return (
    <Tooltip title={props.title} placement="top">
      <div
        className={`${styles.reportTypeItem} ${[
          location.pathname.includes(props.type) ? styles.active : "",
        ]}`}
        onClick={goToReport}
      >
        {props.icon}
        <span>{props.type.capitalizeFirstLetter()}</span>
      </div>
    </Tooltip>
  );
};
