import type { Draft } from "immer";
import { create } from "zustand";
import { subscribeWithSelector } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import { BookingUnavailability, DeskResponse } from "../../../../repo";
import { mapService } from "../../../../services/map/MapService";
import { createSelectors } from "../../../../utils/reactUtils";
type State = {
  bookings: BookingUnavailability[];
  desks: DeskResponse[];
  filteredDesks: DeskResponse[];
  searchTerm: string;
};

type Actions = {
  reset: () => void;
  setBookings: (bookings: BookingUnavailability[]) => void;
  setDesks: (desks: DeskResponse[]) => void;
  setSearchTerm: (searchTerm: string) => void;
};

const initialState: State = {
  bookings: [],
  desks: [],
  filteredDesks: [],
  searchTerm: "",
};
const useDeskContextBase = create(
  subscribeWithSelector(
    immer<State & Actions>((set) => ({
      ...initialState,
      reset: () => {
        set(initialState);
      },
      setBookings(bookings) {
        set((state) => {
          state.bookings = bookings;
          state.filteredDesks = filterDesks(state);
        });
      },
      setDesks(desks) {
        set((state) => {
          state.desks = desks;
          state.filteredDesks = filterDesks(state);
        });
      },
      setSearchTerm(searchTerm) {
        set((state) => {
          state.searchTerm = searchTerm;
          state.filteredDesks = filterDesks(state);
        });
      },
    }))
  )
);
const filterDesks = (state: Draft<State>) => {
  return state.desks
    .map((d) => {
      let booking = state.bookings.find((b) => b.deskId === d.id);
      if (booking) {
        return { ...d, user: booking.user, };
      }
      return d;
    })
    .filter((desk) => {
      const { isBookable, ...obj } = desk as any;
      if (desk.isBookable) {
        obj.tag = obj.tag + "bookable";
      }
      return JSON.stringify(obj)
        .toLowerCase()
        .includes(state.searchTerm.toLowerCase());
    })
    .sort((a, b) => {
      if (a.user && b.user) {
        return a.user.name.localeCompare(b.user.name);
      }
      if (a.user && !b.user) {
        return -1;
      }
      if (!a.user && b.user) {
        return 1;
      }
      return a.name.localeCompare(b.name, undefined, { numeric: true });
    });
};

export const useDeskContext = createSelectors(useDeskContextBase);

useDeskContext.subscribe(
  (state) => state.filteredDesks,
  (desks) => {
    mapService.colorDesks(desks);
  }
);

