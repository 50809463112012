import { InteractableMapEntity } from "fabric/fabric-impl";
import { navigator } from "../..";
import { usePermissionsStore } from "../../../context/authContext";
import { MapNavigator } from "./MapNavigator";

export class MapHomeNavigator implements MapNavigator {
  navigateToRoom = (entity: InteractableMapEntity) => {
    const nav = navigator.nav;
    const mapId = navigator.mapId;
    if (!nav || !mapId) {
      return;
    }
    if (!entity.associationId) {
      !usePermissionsStore.getState().isUser() &&
        nav(`/maps/${mapId}/rooms/new`);
    } else if (!window.location.pathname.includes(entity.associationId)) {
      nav(`/maps/${mapId}/rooms/${entity.associationId}/bookings`, {
        replace: window.location.pathname.includes("bookings"),
      });
    }
  };
  navigateToDesk = (entity: InteractableMapEntity) => {
    const nav = navigator.nav;
    const mapId = navigator.mapId;
    if (!nav || !mapId) {
      return;
    }
    if (!entity.associationId) {
      !usePermissionsStore.getState().isUser() &&
        nav(`/maps/${mapId}/desks/new`);
    } else {
      nav(
        `/maps/${mapId}/desks/${entity.associationId}${window.location.search}`,
        {
          replace: window.location.pathname.includes("new"),
        }
      );
    }
  };
}
