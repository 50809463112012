import { fabric } from "fabric";

fabric.EditMapLine = fabric.util.createClass(fabric.Line, {
  type: "editMapLine",
  initialize: function (points: any, opts: any) {
    if (!points) {
      points = [0, 0, 0, 0];
    }
    this.callSuper("initialize", points, {
      strokeUniform: true,
      stroke: "black",
      snapAngle: 5,
      ...opts,
    });
  },
});
fabric.EditMapLine.fromObject = function (object: any, callback?: any) {
  function _callback(instance: any) {
    delete instance.points;
    callback && callback(instance);
  }
  var options = { ...object };
  options.points = [object.x1, object.y1, object.x2, object.y2];
  return fabric.Object._fromObject(
    "EditMapLine",
    options,
    _callback,
    "points"
  ) as any;
};
