import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDesks } from "../../../../../repo/desks";
import { WhoDeskMap } from "../../../../../utils/map/WhoDeskMap";
import { mapQueue } from "../../../../../utils/map/mapQueue";
import styles from "./DeskCounter.module.css";
type Props = {};

export default function DeskCounter({}: Props) {
  const { mapId } = useParams();
  const { data: desks = [] } = useDesks(mapId);
  const [total, setTotal] = useState(0);
  const bookable = desks.filter((d) => d.isBookable).length;
  const occupied = desks.filter((d) => !d.isBookable).length;
  useEffect(() => {
    const getTotalDeskCount = (map: WhoDeskMap) => {
      setTotal(map.getDesks().length);
    };
    mapQueue.onReload(getTotalDeskCount);
    setTotal(0);
    return () => {
      mapQueue.remove(getTotalDeskCount);
    };
  }, [mapId, setTotal]);
  if (total === 0) {
    return <></>;
  }
  return (
    <div className={styles.container}>
      <CountEntry count={total}>Total</CountEntry>
      <CountEntry count={total - occupied - bookable}>Available</CountEntry>
      <CountEntry count={bookable}>Bookable</CountEntry>
      <CountEntry count={occupied}>Permanent</CountEntry>
    </div>
  );
}

const CountEntry = ({
  count,
  children,
}: {
  count: number;
  children?: React.ReactNode;
}) => {
  if (count === 0) {
    return <></>;
  }
  return (
    <div className={styles.countEntryContainer}>
      <div className={styles.countEntryCount}>{count}</div>
      <div className={styles.countEntryMeta}>{children}</div>
    </div>
  );
};
