import { useSearchParams } from "react-router-dom";

export const HiddenAction = ({
  children,
  action,
}: {
  children: React.ReactNode;
  action: string;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const open = searchParams.get("action") === action;
  return <>{open && children}</>;
};
