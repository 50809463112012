import { Button } from "antd";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { CustomInputText } from "../../../../components/common/CustomInputText/CustomInputText";
import { ErrorIndicator } from "../../../../components/common/ErrorIndicator";
import { CreateMapRequest } from "../../../../repo";
import { useCreateMap } from "../../../../repo/maps";
import CenteredFloatingContainer from "../../../common/CenteredFloatingContainer/CenteredFloatingContainer";
import styles from "./MapsCreationPage.module.css";

type Props = {};

export default function MapsCreationPage({}: Props) {
  const nav = useNavigate();
  const { handleSubmit, control } = useForm<CreateMapRequest>();
  const {
    mutate: create,
    isError,
    isPending,
    reset,
  } = useCreateMap((data) => {
    nav(`/maps/${data.id}/edit`);
  });
  const onSubmit = (data: CreateMapRequest) => {
    create({ map: data });
  };
  return (
    <div className="appContent centeredFlexBox">
      <CenteredFloatingContainer header={"Create map"}>
        <>
          {isError && (
            <ErrorIndicator
              description="Could not create a map"
              onAction={() => reset()}
            />
          )}
          {!isError && (
            <form onSubmit={handleSubmit(onSubmit)} className={styles.content}>
              <span>
                To continue please enter a meaningful name for your map
              </span>
              <Controller
                name="name"
                control={control}
                render={({ field, fieldState }) => {
                  return (
                    <CustomInputText
                      {...field}
                      placeholder="Map name"
                      id={styles.nameInput}
                      error={fieldState.error?.message}
                    />
                  );
                }}
                rules={{ required: "Map name is required" }}
              />
              <div className={styles.buttonContainer}>
                <Button onClick={() => nav(-1)}>Cancel</Button>
                <Button type="primary" htmlType="submit" loading={isPending}>
                  Create
                </Button>
              </div>
            </form>
          )}
        </>
      </CenteredFloatingContainer>
    </div>
  );
}
