import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
interface ChartSetting {
  color?: string;
  hidden?: boolean;
  type?: string;
}
interface ChartContext {
  chartSettings: Map<string, ChartSetting>;
  updateSetting: (label: string, setting: ChartSetting) => void;
}
const initState = {
  chartSettings: new Map(),
};
export const useChartContext = create<ChartContext>()(
  persist(
    (set, get) => ({
      ...initState,
      updateSetting(label: string, setting: ChartSetting) {
        const currentSetting = get().chartSettings.get(label);
        let updatedSetting = setting;
        if (currentSetting) {
          updatedSetting = { ...currentSetting, ...setting };
        }
        set((state) => ({
          ...state,
          chartSettings: state.chartSettings.set(label, updatedSetting),
        }));
      },
    }),
    {
      name: "chart-settings",
      storage: createJSONStorage(() => localStorage),
      partialize: (state) => ({
        chartSettings: Object.fromEntries(state.chartSettings),
      }),
      merge(persistedState: any, currentState) {
        return {
          ...currentState,
          ...persistedState,
          chartSettings: new Map(Object.entries(persistedState.chartSettings)),
        };
      },
    }
  )
);
