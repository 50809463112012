import { Flex, Input, InputProps, InputRef } from "antd";
import React from "react";
import { FieldError, FieldErrorsImpl, Merge } from "react-hook-form";
import { usePermissionsStore } from "../../../context/authContext";
import styles from "./CustomInputText.module.css";

interface CustomInputTextProps extends InputProps {
  error?:
    | string
    | FieldError
    | Merge<FieldError, FieldErrorsImpl<any>>
    | undefined;
  label?: React.ReactNode;
  disabledForRole?: string;
  required?: boolean;
}

export const CustomInputText = React.forwardRef<InputRef, CustomInputTextProps>(
  ({ error, label, disabledForRole, required, ...props }, ref) => {
    const { role } = usePermissionsStore();
    const isDisabled =
      disabledForRole !== undefined && role === disabledForRole;
    const input = (
      <Input
        ref={ref}
        disabled={isDisabled}
        status={error === undefined ? "" : "error"}
        type={error && "error"}
        size={props.size || "large"}
        style={{
          backgroundColor: isDisabled ? "transparent" : undefined,
          color: isDisabled ? "#000" : "",
          cursor: "auto",
        }}
        {...props}
        className="w-full"
      />
    );
    return (
      <ComponentWithError error={error as any}>
        <>
          {label ? (
            <ComponentWithLabel label={label as any} required={required}>
              {input}
            </ComponentWithLabel>
          ) : (
            input
          )}
        </>
      </ComponentWithError>
    );
  }
);
interface ComponentWithLabelProps
  extends React.LabelHTMLAttributes<HTMLLabelElement> {
  label?: string;
  required?: boolean;
}
export const ComponentWithLabel: React.FC<ComponentWithLabelProps> = ({
  label,
  children,
  required,
  ...props
}) => {
  return (
    <>
      {label && (
        <label
          {...props}
          style={{
            paddingBottom: "5px",
            display: "flex",
            flexDirection: "column",
            fontWeight: "500",
            fontSize: "15px",
            lineHeight: "1.05rem",
            color: 'var(--text-color)'
          }}
        >
          <span
            className={`${styles.labelText} ${required ? styles.required : ""}`}
          >
            {label}
          </span>
          {children}
        </label>
      )}
    </>
  );
};

export const ComponentWithError: React.FC<{
  error?: string;
  children?: React.ReactElement | React.ReactElement[];
  flex?: number;
  className?: any;
}> = ({ error, children, flex, className }) => {
  return (
    <Flex vertical flex={flex} className={className}>
      {children}

      <span
        style={{
          color: "red",
          fontSize: "0.8rem",
          height: "1rem",
          marginLeft: "2px",
        }}
        className="error-message"
      >
        {error}
      </span>
    </Flex>
  );
};
