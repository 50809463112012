import { Button, Tooltip } from "antd";

import {
  IconChevronRight,
  IconEye,
  IconEyeOff,
  IconTrash,
} from "@tabler/icons-react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { EquipmentMinified } from "../../../repo";
import { EquipmentExpirationIndicator } from "../../../routes/hr/equipment/EquipmentPage/EquipmentPage";
import { EquipmentIcon } from "../../../routes/hr/users/UserEditView/UserEditView";
import styles from "./EquipmentItem.module.css";
interface Props {
  index: number;
  remove?: (id: string) => void;
  hide?: (id: string) => void;
  equipment: EquipmentMinified;
  modifyable?: boolean;
}
export function EquipmentItem(props: Props) {
  const nav = useNavigate();
  const navToEquipment = () => {
    if (!props.modifyable) {
      return;
    }
    nav(`/equipment/${props.equipment.id}`);
  };
  const [hovered, setHovered] = useState(false);

  return (
    <div
      className={styles.container}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {!props.modifyable && props.equipment.hidden ? null : (
        <div
          className={`${styles.equipmentListItem} ${
            props.equipment.hidden ? styles.equipmentListItemHidden : ""
          }`}
          onClick={navToEquipment}
        >
          <div className={styles.iconContainer}>
            <EquipmentIcon type={props.equipment?.type} />
          </div>
          <span className={styles.equipmentListItemText}>
            {props.equipment?.name || "No name"}
          </span>
          <EquipmentExpirationIndicator
            expiration={props.equipment?.expiration}
          />
          {hovered && props.modifyable && (
            <IconChevronRight style={{ color: "var(--secondary-color)" }} />
          )}
        </div>
      )}
      {props.modifyable && hovered && (
        <>
          <Tooltip title="If disabled - will not show the equipment to the user">
            <Button
              className={styles.actionIcon}
              onClick={() => {
                props.hide?.(props.equipment.id);
              }}
              icon={props.equipment.hidden ? <IconEyeOff /> : <IconEye />}
            />
          </Tooltip>
          <Button
            danger
            className={styles.actionIcon}
            onClick={() => {
              props.remove?.(props.equipment.id);
            }}
            icon={<IconTrash size={20} />}
          />
        </>
      )}
    </div>
  );
}
