import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
interface MapSelectionStore {
  selectedMapId?: string;
  selectMap: (mapId: string) => void;
}
export const useMapSelection = create<MapSelectionStore>()(
  persist(
    (set, get) => ({
      selectMap: (mapId) => {
        set((state) => ({
          ...state,
          selectedMapId: mapId,
        }));
      },
    }),
    {
      name: "mapSelection",

      storage: createJSONStorage(() => sessionStorage),
      partialize: (state) => ({ selectedMapId: state.selectedMapId }),
    }
  )
);
