import { IconCircleMinus, IconCirclePlus } from "@tabler/icons-react";
import { AnimatingListItem } from "../AnimatingListItem/AnimatingListItem";
import styles from "./ListCloser.module.css";
import { listCloserContext } from "./listCloserContext";
export const ListCloser = ({
  name,
  children,
  hidden,
}: {
  name: string;
  children: React.ReactNode;
  hidden?: boolean;
}) => {
  const { setForName, getForName } = listCloserContext();
  const open = (getForName(name) ?? true) || hidden;
  return (
    <>
      {!hidden && (
        <AnimatingListItem className={styles.listCloser}>
          <div className={styles.listCloserText} 
              onClick={() => setForName(name, !open)}>
            {name}
            <div
              style={{ cursor: "pointer" }}
              onClick={() => setForName(name, !open)}
            >
              {open ? <IconCircleMinus /> : <IconCirclePlus />}
            </div>
          </div>
        </AnimatingListItem>
      )}
      {open && children}
    </>
  );
};
