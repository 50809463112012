import styles from './AnimatingListItem.module.css';
interface AnimatingListItemProps extends React.HTMLAttributes<HTMLDivElement> {
    active?: boolean;
  }
  export const AnimatingListItem = ({
    active,
    className,
    ...props
  }: AnimatingListItemProps) => {
    return (
      <div
        className={`${className} ${styles.animatingListItem} ${
          active ? styles.active : ""
        }`}
        {...props}
      />
    );
  };