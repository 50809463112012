import React from "react";
import { usePermissionsStore } from "../../context/authContext";
import { CustomUserClaims } from "../../repo";

type Props = {
  disabledRole?: CustomUserClaims["role"];
  enabledRole?: CustomUserClaims["role"];
  children: React.ReactNode;
};

export default function DisableByRole(props: Props) {
  const { role } = usePermissionsStore();
  let isEnabled = false;
  if (props.disabledRole) {
    isEnabled = role !== props.disabledRole;
  }
  if (props.enabledRole) {
    isEnabled = role === props.enabledRole;
  }
  return <>{isEnabled && props.children}</>;
}

export const AdminEnabled = ({ children }: { children: React.ReactNode }) => {
  const { isAdmin } = usePermissionsStore();
  return <>{isAdmin() && children}</>;
};
