import { Flex, FlexProps } from "antd";

type Props = {
  children?: React.ReactNode;
};

export default function FullHeightCentered(props: FlexProps) {
  return (
    <Flex
      {...props}
      justify="center"
      align="center"
      style={{ ...props.style, height: "100%" }}
      vertical
    ></Flex>
  );
}
