import dayjs from "dayjs";
import { fabric } from "fabric";
import { Desk } from "fabric/fabric-impl";
import { useCallback, useEffect } from "react";
import {
  createSearchParams,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  AvailabilityDatePicker,
} from "../../../../../components/common/AvailabilityDatePicker";
import { ErrorIndicator } from "../../../../../components/common/ErrorIndicator";
import FullHeightCentered from "../../../../../components/common/FullHeightCentered";
import NavigationBanner from "../../../../../components/common/NavigationBanner/NavigationBanner";
import {
  useAuthenticationStore,
  usePermissionsStore,
} from "../../../../../context/authContext";
import {
  BookingUnavailability,
  DeskResponse,
} from "../../../../../repo";
import { useUnavailableBookings } from "../../../../../repo/bookings";
import { useDesks } from "../../../../../repo/desks";
import { useMap } from "../../../../../repo/maps";
import { getDeskColor } from "../../../../../utils/colorUtils";
import { DATE_FORMAT } from "../../../../../utils/constants";
import { WhoDeskMap } from "../../../../../utils/map/WhoDeskMap";
import { DeskColorHolder } from "../../../../../utils/map/customTypes";
import { mapQueue } from "../../../../../utils/map/mapQueue";
import BorderDivider from "../../../BorderDivider/ListDivider";
import Scrollable from "../../../Scrollable/Scrollable";
import { useUserCanBookDesk } from "../../../hooks";
import { DeskList } from "../../desks/MapDeskList/MapDeskList";
import { useMapTooltip } from "../../navigator/components/MapViewTooltip";
import { useMapBookings } from "../context/mapBookingsContext";
import styles from "./MapDesksAvailability.module.css";
type Props = {};

export default function MapDesksAvailability({}: Props) {
  const nav = useNavigate();
  const { mapId } = useParams();
  const { isLoading, isError, data: deskResponse } = useDesks(mapId);
  const { data: mapRes } = useMap(mapId);

  const {
    availableDesks,
    unavailableBookings,
    desks,
    setDesks,
    setUnavailableBookings,
    reset,
  } = useMapBookings();
  useEffect(() => {
    deskResponse && setDesks(deskResponse);
  }, [deskResponse, setDesks]);
  let [searchParams, setSearchParams] = useSearchParams({});

  const disabledDate = useCallback(
    (curr: dayjs.Dayjs) => {
      if (mapRes && mapRes.settings) {
        return !mapRes.settings.workDays.includes(curr.weekday());
      }
      return false;
    },
    [mapRes]
  );

  useEffect(() => {
    if (searchParams.has("dateFrom") || searchParams.has("dateTo")) {
      return;
    }
    const today = dayjs();
    if (!disabledDate(today)) {
      const todayAsString = today.format(DATE_FORMAT);
      searchParams.set("dateFrom", todayAsString);
      searchParams.set("dateTo", todayAsString);
      setSearchParams(searchParams, { replace: true });
    } else {
      for (let index = 0; index < 7; index++) {
        const modifiedDay = today.add(index, "d");
        if (!disabledDate(modifiedDay)) {
          const modifiedDayAsString = modifiedDay.format(DATE_FORMAT);
          searchParams.set("dateFrom", modifiedDayAsString);
          searchParams.set("dateTo", modifiedDayAsString);
          setSearchParams(searchParams, { replace: true });
          return;
        }
      }
      searchParams.delete("dateFrom");
      searchParams.delete("dateTo");
      setSearchParams(searchParams, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabledDate]);

  const {
    mutate: getUnavailableBookings,
    isPending: isBookingLoading,
    isError: isBookingError,
    data: unavailableBookingsResponse,
    reset: resetUnavailableIds,
  } = useUnavailableBookings();

  useEffect(() => {
    if (unavailableBookingsResponse) {
      setUnavailableBookings(unavailableBookingsResponse);
    }
  }, [unavailableBookingsResponse, setUnavailableBookings]);

  useEffect(() => {
    const mouseOutHandler = () => {
      useMapTooltip.getState().hide();
    };
    const handler: fabric.EntityEventHandler = ({ entity }) => {
      if (entity.type !== "desk") {
        return;
      }
      const desk = entity as Desk;
      bookingTooltipHandler(desk, desks, unavailableBookings);
    };

    mapQueue.add((map) => {
      map.on("entity:mouseover", handler);
      map.on("entity:mouseout", mouseOutHandler);
    });
    return () => {
      mapQueue.add((map) => {
        map.off("entity:mouseout", mouseOutHandler);
        map.off("entity:mouseover", handler);
      });
    };
  }, [availableDesks, desks, unavailableBookings]);
  useEffect(() => {
    const onDeskSelected: fabric.EntityEventHandler = ({ entity }) => {
      if (entity.type === "desk") {
        if (entity.associationId) {
          nav(
            `/maps/${mapId}/bookings/desks/${entity.associationId}` +
              window.location.search
          );
        }
      }
    };
    const onDeskDeselected = () => {
      nav(-1);
    };
    mapQueue.add((map) => {
      map.clearActiveObjectHighlights();
      map.on("entity:selected", onDeskSelected);
      map.on("entity:deselected", onDeskDeselected);
    });
    return () => {
      mapQueue.add((map) => {
        map.off("entity:selected", onDeskSelected);
        map.off("entity:deselected", onDeskDeselected);
      });
    };
  }, [mapId, nav]);
  useEffect(() => {
    const dateFrom = dayjs(searchParams.get("dateFrom"));
    const dateTo = dayjs(searchParams.get("dateTo"));
    if (dateFrom.isValid() && dateTo.isValid()) {
      resetUnavailableIds();
      getUnavailableBookings({
        mapId: mapId!,
        dateFrom: dateFrom.format(DATE_FORMAT),
        dateTo: dateTo.format(DATE_FORMAT),
      });
    } else {
      reset();
    }
  }, [searchParams, getUnavailableBookings, resetUnavailableIds, reset, mapId]);
  const { canBook } = useUserCanBookDesk();
  const prevRoute = `/maps/${mapId}/desks`;
  useEffect(() => {
    if (!canBook) {
      nav(prevRoute);
    }
  }, [canBook, nav, prevRoute]);
  return (
    <>
      <NavigationBanner name={"Desk booking"} prevRoute={prevRoute} />

      <div className={styles.searchContainer}>
        <span className={styles.searchExplanationText}>Period:</span>
        <AvailabilityDatePicker panesEnabled="RIGHT"/>
      </div>
      <BorderDivider />
      <AvailableDesksList
        isLoading={isLoading || isBookingLoading}
        isError={isError || isBookingError}
        availableDesks={availableDesks}
        unavailableBookings={unavailableBookings}
      />
    </>
  );
}
const AvailableDesksList = ({
  isLoading,
  isError,
  availableDesks,
  unavailableBookings,
}: {
  isLoading: boolean;
  isError: boolean;
  availableDesks: DeskResponse[];
  unavailableBookings: BookingUnavailability[];
}) => {
  const { role } = usePermissionsStore();
  const { auth } = useAuthenticationStore();
  const { mapId } = useParams();
  const nav = useNavigate();
  if (role === "USER" && unavailableBookings.length > 0) {
    const bookingForUserAndDate = unavailableBookings.find(
      (b) => b.user.email === auth?.userEmail
    );
    const goToBookings = () => {
      if (!mapId || !bookingForUserAndDate) {
        return;
      }
      nav(
        {
          pathname: "/bookings",
          search: createSearchParams({
            mapId: mapId,
            userEmail: bookingForUserAndDate.user.email,
            dateFrom: bookingForUserAndDate.dateFrom,
            dateTo: bookingForUserAndDate.dateTo,
          }).toString(),
        },
        { replace: true }
      );
    };
    if (bookingForUserAndDate !== undefined) {
      return (
        <FullHeightCentered>
          <ErrorIndicator
            action="View booking"
            description="Booking in this period already exists"
            onAction={goToBookings}
          />
        </FullHeightCentered>
      );
    }
  }
  return (
    <Scrollable>
      <DeskList
        emptyTitle="Select a date"
        isLoading={isLoading}
        isError={isError}
        desks={availableDesks}
      />
    </Scrollable>
  );
};
export const bookingTooltipHandler = (
  desk: Desk,
  desks: DeskResponse[],
  unavailableBookings: BookingUnavailability[]
) => {
  const hoveredDesk = desks.find((d) => d.mapDeskId === desk.id);
  if (!hoveredDesk) {
    return;
  }
  const unavailableBooking = unavailableBookings.find(
    (b) => b.deskId === hoveredDesk.id
  );
  useMapTooltip.getState().show({
    canvasObject: desk,
    unavailableBooking: unavailableBooking,
  });
};
export const colorDeskAvailability = (
  map: WhoDeskMap,
  availableDesks: DeskResponse[],
  unavailableDesks: DeskResponse[],
  desks?: DeskResponse[]
) => {
  const allBookableDesks = (desks || []).filter((d) => d.isBookable);
  map.clearDeskColors();
  let availableColors = availableDesks.map((desk) => {
    let color: DeskColorHolder = {
      deskId: desk.mapDeskId,
      color: getDeskColor(desk),
      fill: "chair",
    };
    return color;
  });
  let unavailableColors = unavailableDesks.map((desk) => {
    let color: DeskColorHolder = {
      deskId: desk.mapDeskId,
      color: "red",
      fill: "chair",
    };
    return color;
  });
  map.disableAllExcept(allBookableDesks.map((d) => d.mapDeskId));
  map.disableClicks(
    desks
      ?.map((d) => d.mapDeskId)
      .filter(
        (mapDeskId) => !availableDesks.find((d) => d.mapDeskId === mapDeskId)
      ) || []
  );
  map.colorDesks([...unavailableColors, ...availableColors]);
  map.renderAll();
};
