import { fabric } from "fabric";
import { Control } from "fabric/fabric-impl";

fabric.Object.prototype.controls.flipControl = new fabric.Control({
  x: 0.5,
  y: -0.5,
  offsetY: -16,
  offsetX: 16,
  cursorStyle: "pointer",
  actionName: "flip",
  render: (
    ctx: CanvasRenderingContext2D,
    left: number,
    top: number,
    styleOverride: any,
    fabricObject: fabric.Object
  ) => {
    if (fabricObject.type === "mapImage" || fabricObject.type === "door") {
      return renderIcon("/flipIcon.webp")(
        ctx,
        left,
        top,
        styleOverride,
        fabricObject
      );
    }
  },
  mouseUpHandler: (e, transform) => {
    const target = transform.target;
    target.set({ flipX: !target.get("flipX") });
    target.canvas?.renderAll();
    return true;
  },
});
const allowedControlTypes = [
  "editMapRectangle",
  "editMapLine",
  "editMapCircle",
  "polygonRect",
  "door",
];
fabric.Object.prototype.controls.settingsControl = new fabric.Control({
  x: -0.5,
  y: -0.5,
  offsetY: -16,
  offsetX: -16,
  cursorStyle: "pointer",
  actionName: "settings",
  render: (
    ctx: CanvasRenderingContext2D,
    left: number,
    top: number,
    styleOverride: any,
    fabricObject: fabric.Object
  ) => {
    // if (fabricObject.type && allowedControlTypes.includes(fabricObject.type)) {
    return renderIcon("/mapItems/gear.png")(
      ctx,
      left,
      top,
      styleOverride,
      fabricObject
    );
    // }
  },
  mouseUpHandler: (e, transform) => {
    transform.target.canvas?.fire("settings:open", {
      event: e,
      target: transform.target,
    });
    return true;
  },
});
function renderIcon(name: string) {
  var icon = document.createElement("img");
  icon.src = process.env.PUBLIC_URL + name;
  return function renderIcon(
    ctx: CanvasRenderingContext2D,
    left: number,
    top: number,
    styleOverride: any,
    fabricObject: fabric.Object
  ) {
    var size = 24;
    ctx.save();
    ctx.translate(left, top);
    ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle || 0));
    ctx.drawImage(icon, -size / 2, -size / 2, size, size);
    ctx.restore();
  };
}
export const polygonDragControl = (index: number, lastControl: number) => {
  const control = new fabric.Control({
    actionHandler: anchorWrapper(
      index > 0 ? index - 1 : lastControl,
      actionHandler
    ),
    render: function (
      ctx: any,
      left: any,
      top: any,
      styleOverride: any,
      fabricObject: any
    ) {
      (fabric as any).controlsUtils.renderCircleControl.call(
        this,
        ctx,
        left,
        top,
        {
          cornerColor: "white",
          transparentCorners: false,
          cornerStrokeColor: "black",
        },
        fabricObject
      );
    },
    actionName: "modifyPolygon",
    pointIndex: index,
  } as any);
  control.positionHandler = (
    dim: {
      x: number;
      y: number;
    },
    finalMatrix: any,
    fabricObject: fabric.Object
  ) => {
    return polygonPositionHandler(dim, finalMatrix, fabricObject, control);
  };
  return control;
};

const polygonPositionHandler: Control["positionHandler"] = (
  dim: any,
  finalMatrix: any,
  fabricObject: fabric.PolygonRect,
  control: any
) => {
  let x =
      fabricObject.points![control.pointIndex].x - fabricObject.pathOffset.x,
    y = fabricObject.points![control.pointIndex].y - fabricObject.pathOffset.y;
  return fabric.util.transformPoint(
    new fabric.Point(x, y),
    fabric.util.multiplyTransformMatrices(
      fabricObject.canvas!.viewportTransform!,
      fabricObject.calcTransformMatrix()
    )
  );
};

// define a function that can keep the polygon in the same position when we change its
// width/height/top/left.
function anchorWrapper(anchorIndex: any, fn: any) {
  return function (eventData: any, transform: any, x: any, y: any) {
    var fabricObject = transform.target,
      absolutePoint = fabric.util.transformPoint(
        new fabric.Point(
          fabricObject.points[anchorIndex].x - fabricObject.pathOffset.x,
          fabricObject.points[anchorIndex].y - fabricObject.pathOffset.y
        ),
        fabricObject.calcTransformMatrix()
      ),
      actionPerformed = fn(eventData, transform, x, y),
      newDim = fabricObject._setPositionDimensions({}),
      polygonBaseSize = getObjectSizeWithStroke(fabricObject),
      newX =
        (fabricObject.points[anchorIndex].x - fabricObject.pathOffset.x) /
        polygonBaseSize.x,
      newY =
        (fabricObject.points[anchorIndex].y - fabricObject.pathOffset.y) /
        polygonBaseSize.y;
    fabricObject.setPositionByOrigin(absolutePoint, newX + 0.5, newY + 0.5);
    return actionPerformed;
  };
}
function getObjectSizeWithStroke(object: any) {
  let stroke = new fabric.Point(
    object.strokeUniform ? 1 / object.scaleX : 1,
    object.strokeUniform ? 1 / object.scaleY : 1
  ).multiply(object.strokeWidth);
  return new fabric.Point(object.width + stroke.x, object.height + stroke.y);
}
// define a function that will define what the control does
// this function will be called on every mouse move after a control has been
// clicked and is being dragged.
// The function receive as argument the mouse event, the current trasnform object
// and the current position in canvas coordinate
// transform.target is a reference to the current object being transformed,

function actionHandler(eventData: any, transform: any, x: any, y: any) {
  let polygon = transform.target,
    currentControl = polygon.controls[polygon.__corner],
    mouseLocalPosition = polygon.toLocalPoint(
      new fabric.Point(x, y),
      "center",
      "center"
    ),
    polygonBaseSize = getObjectSizeWithStroke(polygon),
    size = polygon._getTransformedDimensions(0, 0),
    finalPointPosition = {
      x:
        (mouseLocalPosition.x * polygonBaseSize.x) / size.x +
        polygon.pathOffset.x,
      y:
        (mouseLocalPosition.y * polygonBaseSize.y) / size.y +
        polygon.pathOffset.y,
    };
  if (polygon.canvas.gridSize) {
    let gridSize = polygon.canvas.gridSize;
    let left = (finalPointPosition.x || 0) / gridSize;
    let top = (finalPointPosition.y || 0) / gridSize;
    if (Math.round(left * 4) % 4 === 0) {
      finalPointPosition.x = Math.round(left * gridSize);
    }
    if (Math.round(top * 4) % 4 === 0) {
      finalPointPosition.y = Math.round(top * gridSize);
    }
  }
  polygon.points[currentControl.pointIndex] = finalPointPosition;
  return true;
}
