import { useParams } from "react-router-dom";
import { navigator } from "../../../services";
import { useNav } from "../../../utils/reactUtils";

export const NavigatorProvider = () => {
  const {mapId} = useParams();
  navigator.nav = useNav();
  navigator.mapId = mapId;
  return null;
};
